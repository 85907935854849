import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

import { faCheckCircle, faCircleExclamation, faCog, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { SidebarService } from '../core/services/sidebar.service';
import { LoginService } from '../core/services/login.service';
import { ControlValuesMatchValidator } from '../core/validators/control-values-match.directive';


@Component({
  selector: 'wf-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  isChangingPassword: boolean = false ;
  successMessage: string = "";
  changePasswordForm = this.fb.group({
    currentPassword: ['', Validators.required],
    newPassword: ['', Validators.compose([
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(20),
      Validators.pattern(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9_!?]){8,20}$/)
    ])],
    confirmPassword: ['', Validators.required ]
  },
  {
    validator: ControlValuesMatchValidator('newPassword', 'confirmPassword'),
  }) ;
  showCurrentPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  successIcon = faCheckCircle ;
  errorIcon = faCircleExclamation ;
  settingsIcon = faCog ;
  loadingIcon = faSpinner ;

  constructor(
    public loginService: LoginService,
    public sidebarService: SidebarService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  changePassword() {
    this.isChangingPassword = true ;
    this.successMessage = '';

    this.loginService.changePassword(this.currentPassword?.value, this.newPassword?.value).subscribe({
      next: (status) => {
        this.isChangingPassword = false ;

        if (status === 200)
        {
          this.successMessage = 'Password Successfully Updated!' ;
          this.currentPassword?.setErrors(null) ;
          this.newPassword?.setErrors(null) ;
          this.confirmPassword?.setErrors(null) ;
        }
        else if (status === 400)
        {
          this.newPassword?.setErrors({ pattern: true }) ;
        }
        else if (status === 401)
        {
          this.currentPassword?.setErrors({ incorrect: true }) ;
        }
      }
    })
  }

  currentPasswordToggle() {
    this.showCurrentPassword = !this.showCurrentPassword;

    return this.showCurrentPassword;
  }
  newPasswordToggle() {
    this.showNewPassword = !this.showNewPassword;

    return this.showNewPassword;
  }

  confirmPasswordToggle() {
    this.showConfirmPassword = !this.showConfirmPassword;

    return this.showConfirmPassword;
  }

  // Utility methods to get our form controls
  get currentPassword() {
    return this.changePasswordForm.get('currentPassword') ;
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword') ;
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword') ;
  }
}
