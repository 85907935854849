import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';

import { ReportsService } from '../../../core/services/reports.service';
import { LoadingService } from '../../../core/services/loading.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DistrictUsageResolver  {
  constructor(
    private reportsService: ReportsService,
    private sessionService: SessionStorageService,
    private loadingService: LoadingService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.loadingService.start() ;

    // From Redmine Issue 5504, we do not want to show the Clever District/School in the District Reports,
    // so if we are coming to this report from a Detailed Report where the Clever District *was* selected,
    // we need to set a different district (default to the first available)
    const selectedReportsDistrict = this.reportsService.getSelectedDistrictForReports() ;
    if (selectedReportsDistrict.name === 'Clever District')
    {
      this.sessionService.clearSelectedDistrict() ;
      this.sessionService.clearSelectedSchool() ;
      this.sessionService.clearSelectedTeacher() ;
    }

    return this.reportsService.getDistrictUsageData().pipe(
      map((data) => {
        this.loadingService.finish() ;

        return data ;
      }),
      catchError((error) => {
        this.loadingService.finish() ;

        return of(error.status) ;
      })
    ) ;
  }
}
