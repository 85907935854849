import { Injectable } from '@angular/core' ;
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router' ;
import { catchError, concatMap, Observable, of, switchMap } from 'rxjs' ;
import { ReportsService } from '../../../../core/services/reports.service' ;

@Injectable({
  providedIn: 'root'
})
export class StudentSummaryResolver  {
  constructor(
    private reportsService: ReportsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // On Student Summary load, we might be a district user or fil user, so if our selected
    // school is 'All' (ID of 0), then we cannot load students because we do not have
    // any teachers loaded
    if (this.reportsService.getSelectedSchoolForReports().schoolID === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      }) ;
    }

    if (this.reportsService.getSelectedTeacherForReports().userID === 0)
    {
      return of({
        erred: true,
        message: 'Please select a Teacher',
      }) ;
    }

    return this.reportsService.getStudentsForTeacher(this.reportsService.getSelectedTeacherForReports().userID).pipe(
      concatMap((students) => {
        if (!students.length) {
          return of({
            students: [],
            studentSummaryData: {},
          }) ;
        }

        return this.reportsService.getStudentSummaryReportData(this.reportsService.getSelectedTeacherForReports().userID, students[0].userID).pipe(
          switchMap((studentSummaryData) => {
            return of({
              students,
              studentSummaryData,
            });
          })
        )
      }),
      catchError((err, caught) => {
        return of({
          erred: true,
          message: err.message,
        }) ;
      })
    ) ;
  }
}
