import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.css']
})
export class RefreshButton implements OnInit, OnDestroy {
 
  refreshIcon = faRefresh;

  constructor(

  ) { }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
  }

}
