import { Student, StudentAdapter } from './student.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapter';

export class StudentSession {
  student: Student ;
  avgWeeklyUsage: number ;
  startDate: string ;
  lastUsageDate: string ;
  currentUnit: number ;
  currentLevel: number ;
  objectives: any;
  totalPoints: number ;
  previousWeeksPoints: number ;
  totalSessionTime: number ;
  usageRangeTotalTime: number ;
  prevUsageRangeTotalTime: number ;
  currentWeekLevelsCompleted: number ;
  previousWeekLevelsCompleted: number ;
  currentWeekCompletedTaskCounts: number ;
  previousWeekCompletedTaskCounts: number ;
  previousWeekLevelsGoal: boolean ;
  isCompleted: boolean ;

  constructor(item: any) {
    this.student = item.student ;
    this.avgWeeklyUsage = item.avgWeeklyUsage ;
    this.startDate = item.startDate ;
    this.lastUsageDate = item.lastUsageDate ;
    this.currentUnit = item.currentUnit ;
    this.currentLevel = item.currentLevel ;
    this.objectives = item.objectives ;
    this.totalPoints = item.totalPoints ;
    this.previousWeeksPoints = item.previousWeeksPoints ;
    this.totalSessionTime = item.totalSessionTime ;
    this.usageRangeTotalTime = item.usageRangeTotalTime ;
    this.prevUsageRangeTotalTime = item.prevUsageRangeTotalTime ;
    this.currentWeekLevelsCompleted = item.currentWeekLevelsCompleted ;
    this.previousWeekLevelsCompleted = item.previousWeekLevelsCompleted ;
    this.currentWeekCompletedTaskCounts = item.currentWeekCompletedTaskCounts ;
    this.previousWeekCompletedTaskCounts = item.previousWeekCompletedTaskCounts ;
    this.previousWeekLevelsGoal = (item.previousWeekLevelsCompleted >= 8) ;
    this.isCompleted = item.completedStatus ;
  }
}

@Injectable({
  providedIn: 'root'
})
export class StudentSessionAdapter implements Adapter<StudentSession> {
  constructor(
    private studentAdapter: StudentAdapter,
  ) {}

  adapt(item: any): StudentSession {
    item.student = this.studentAdapter.adapt(item.student) ;

    return new StudentSession(item) ;
  }
}
