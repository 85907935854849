import { Component, OnDestroy, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { SidebarService } from 'src/app/core/services/sidebar.service';
import { SubscriptionTypes } from 'src/app/core/models/subscription-types.model';
import { ManagementUser } from 'src/app/core/models/management-user.model';
import { Category } from 'src/app/core/models/resource-category.model';
import { faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wf-category-select',
  templateUrl: './category-select.component.html',
  styles: [
  ]
})
export class ResourcesCategoryComponent implements OnDestroy {
  drawerIsOpen: boolean = false;
  openDrawer: boolean = this.sidebarService.toggleDrawer();
  freeProduct: string = SubscriptionTypes.FreeProduct;
  fullProduct: string = SubscriptionTypes.FullProduct;
  diagnosticProduct: string = SubscriptionTypes.DiagnosticProduct;
  currentUser: ManagementUser | null = null ;
  allCategories: Category[] = [];
  selectedProduct: string | null = '';
  selectedGroup: string | null = '' ;
  displayGroup: string | null = '' ;
  resourcesIcon = faBookOpenReader ;
  paramsSubscription: Subscription ;

  CURRICULUM_GUIDES: string = "Curriculum Guides";
  SECONDARY_CURRICULUMPACKETS: string = "Curriculum Packets for Grade 5+";
  PBLG: string = "Project-Based Learning Guides for Grade 5+";
  POEMS_PASSAGES: string = "Poems & Passages";
  SCOPE_SEQUENCE: string = "Scope and Sequence";
  USER_GUIDES: string = "User Guides";
  TECHNICAL_REPORT: string = "Technical Report";
  LESSONS: string = 'Lessons' ;
  STUDENT_GOAL_SETTING: string = "Student Goal Setting";

  constructor(
    private sidebarService: SidebarService,
    private sessionStorage: SessionStorageService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // Setup our initial data
    this.currentUser = this.sessionStorage.getUserData() ;

    this.paramsSubscription = this.route.params.subscribe((params) => {
      this.selectedProduct = params['product'].toUpperCase() ;
      this.selectedGroup = params['group'].toUpperCase() ;

      // On route param change, set our categories
      if (this.currentUser!.isParentUser())
      {
        this.allCategories = [];
      }
      else if (this.selectedProduct === this.fullProduct.toUpperCase() && this.selectedGroup === 'ELEMENTARY')
      {
        this.displayGroup = 'instructional tools: grades 2-4' ;
        this.allCategories = [];
        this.allCategories.push({name: this.POEMS_PASSAGES, icon: '/assets/images/resourceCategories/poemsAndPassages.svg'});
      }
      else if (this.selectedProduct === this.fullProduct.toUpperCase() && this.selectedGroup === 'SECONDARY')
      {
        this.displayGroup = 'instructional tools: grades 5+' ;
        this.allCategories = [];
        this.allCategories.push({name: this.POEMS_PASSAGES, icon: '/assets/images/resourceCategories/poemsAndPassages.svg'});
        this.allCategories.push({name: this.SECONDARY_CURRICULUMPACKETS, icon: '/assets/images/resourceCategories/secondaryCurriculumPackets.svg'});
        this.allCategories.push({name: this.PBLG, icon: '/assets/images/resourceCategories/projectBasedLearningGuide.svg'});
      }
      //TODO: change the condition to something more robust other than hardcoding 'lessons' string.
      else if (this.selectedProduct === 'LESSONS'){
        const filterAllCategories = "All";
        this.allCategories = [];
        // Categories for lessons. All lesons under one category initially. Could be categorized under say grades in the future.
        this.allCategories.push({name: filterAllCategories, icon: '/assets/images/resourceCategories/all.svg'});
      }
      else
      {
        const filterAllCategories = "All";
        this.displayGroup = 'system' ;
        this.allCategories = [];
        this.allCategories.push({name: filterAllCategories, icon: '/assets/images/resourceCategories/all.svg'});
        this.allCategories.push({name: this.TECHNICAL_REPORT, icon: '/assets/images/resourceCategories/technicalReport.svg'});
      }
    }) ;
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe() ;
  }

  goToLink(index: number){
    const selectedCategory = this.allCategories[index].name;

    this.sessionStorage.setResourceCategory(selectedCategory) ;
    this.router.navigate([ '/resources/', this.selectedProduct?.toLowerCase(), this.selectedGroup?.toLowerCase(), selectedCategory ]) ;
  }
}
