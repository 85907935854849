import { Injectable } from '@angular/core';

import { SchoolClass } from '../models/school-class.model';
import { Student } from '../models/student.model';
import { SubscriptionTypes } from '../models/subscription-types.model';
import {Assessment} from "../models/assessment.model";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  sortGrades(grades: string[]): string[] {
    // A Set cannot be sorted (it always keeps insertion order), so convert to an array,
    // then we can sort with our custom comparator, and finally return a new Set
    return grades.sort((grade1, grade2) => {
      return this.compareGrades(grade1, grade2) ;
    }) ;
  }

  sortClasses(classes: SchoolClass[]): SchoolClass[] {
    classes.sort((classA, classB) => {
      return classA.name.toLocaleLowerCase().localeCompare(classB.name.toLocaleLowerCase()) ;
    }) ;

    return classes ;
  }

  sortStudentsTimeGoals(studentDataA: any, studentDataB: any) {
    if (this.getDisplayedSessionTime(studentDataA.sessionTime) < this.getDisplayedSessionTime(studentDataB.sessionTime))
    {
      return 1 ;
    }

    if (this.getDisplayedSessionTime(studentDataA.sessionTime) > this.getDisplayedSessionTime(studentDataB.sessionTime))
    {
      return -1 ;
    }

    // Times are the same, so sort by name
    let studentAName = studentDataA.student.lastName + studentDataA.student.firstName ;
    let studentBName = studentDataB.student.lastName + studentDataB.student.firstName ;
    return studentAName.localeCompare(studentBName) ;
  }

  getStudentGrade(gradeValue: number): string {
    if (gradeValue === 1) return 'K' ;
    else if (gradeValue === 14) return 'Other' ;
    else return `${gradeValue - 1}` ;
  }

  compareGrades(grade1: string, grade2: string) {
    return this.getNumericValueOfGrade(grade1) - this.getNumericValueOfGrade(grade2) ;
  }

  getNumericValueOfGrade(grade: string): number {
    if (grade === 'K') return 0 ;
    else if (grade === 'Other') return 100 ;
    else return Number(grade) ;
  }

  getUniqueSchoolClasses(students: any[]): SchoolClass[] {
    let classes: SchoolClass[] = [] ;
    students.forEach((student) => {
      let inserted = false ;
      if (student.schoolClass && student.schoolClass.schoolClassID)
      {
        inserted = classes.some((schoolClass) => {
          return schoolClass.schoolClassID === student.schoolClass?.schoolClassID ;
        }) ;

        if (!inserted)
        {
          classes.push(student.schoolClass) ;
        }
      }
    }) ;

    return classes ;
  }

  getWeekNumber() {
    let target = new Date();
    let dayNr = (target.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNr + 3);
    let firstThursday = target.valueOf();
    target.setMonth(0, 1);

    if (target.getDay() != 4) {
      target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
    }

    return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000);
  }

  getDisplayedSessionTime(sessionTime: number) {
    return Math.round(sessionTime / 60) ;
  }

  getTestWindow(assessmentStartDate: number | undefined, product?: string | null, testType?: number | null) {
    let testWindow = '' ;
    if (assessmentStartDate && product !== SubscriptionTypes.FullProduct)
    {
      let date = new Date(assessmentStartDate) ;
      let month = date.getMonth() ;
      let year = date.getFullYear() ;
      let season = this.getWindowSeason(month) ;
      if (season === 'Winter')
      {
        if (month < 3)
        {
          year-- ;
        }
        year -= 2000 ;
        testWindow = `${season} ${year}/${year + 1}` ;
      }
      else
      {
        testWindow = `${season} ${year}` ;
      }
    }
    else if (product === SubscriptionTypes.FullProduct && testType !== null)
    {
      if (testType === 1) testWindow = 'Pre Diagnostic' ;
      else if (testType === 3) testWindow = 'Mid Diagnostic' ;
      else if (testType === 4) testWindow = 'Post Diagnostic' ;
    }

    return testWindow ;
  }

  getWindowSeason(month: number) {
    if (month === 11 || month < 3) return 'Winter' ;
    else if (month> 6) return 'Fall' ;
    else return 'Spring' ;
  }

  pushIfNotExists(elements: any, newElement: any){
    let found: boolean = false;
    for ( let index in elements ) {
      if (elements[index] == newElement) {
        found = true;
        break;
      }
    }
    if (!found) {
      elements.push(newElement)
    }
  }

  formatDate(dateString: string) {
    let formattedDate: string = '' ;
    let formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) ;
    if (dateString)
    {
      let date = new Date(dateString) ;
      formattedDate = formatter.format(date) ;
    }

    return formattedDate ;
  }

  formatDateNum(date: number) {
    return this.formatDate(new Date(date).toLocaleDateString()) ;
  }

  formatTime(timestamp: string) {
    let formattedTime = '';

    if (timestamp)
    {
      let date = new Date(timestamp);
      let hours = date.getHours();
      let ampm = hours < 12 ? 'AM' : 'PM';
      let zone = date.toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2]
      hours = hours % 12;
      hours = hours ? hours : 12;

      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

      formattedTime = hours + ':' + minutes + ' ' + ampm + ' ' + zone;
    }

    return formattedTime;
  }

  // Subscription Type Constants:
  SUBSCRIPTION_TYPE_SCREENER: number = 0;
  SUBSCRIPTION_TYPE_DIAGNOSTIC: number = 1;
  SUBSCRIPTION_TYPE_INTERVENTION: number = 2;
  SUBSCRIPTION_TYPE_DIAGNOSTIC_MID: number = 3;
  SUBSCRIPTION_TYPE_DIAGNOSTIC_POST: number = 4;

}
