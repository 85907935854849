import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, map, Observable, of } from 'rxjs';

import { StudentsService } from '../../core/services/students.service';
import { EnrollmentService } from '../../core/services/enrollment.service';
import { UtilityService } from '../../core/services/utility.service';

import { Student } from '../../core/models/student.model';
import { SchoolClass } from '../../core/models/school-class.model';
import { AssessmentTypes } from '../../core/models/assessment-types.model';

@Injectable({
  providedIn: 'root'
})
export class StudentsResolver  {
  constructor(
    private studentService: StudentsService,
    private enrollService: EnrollmentService,
    private utilityService: UtilityService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      this.studentService.getStudents(),
      this.enrollService.getClassOptions(),
      this.enrollService.getRemainingInterventionLicensesCount(null, null),
    ]).pipe(
      map((results) => {
        let students: Student[] = results[0] ;
        let classes: SchoolClass[] = results[1] ;
        let licenseCount: number = results[2] ;
        let gradesSet: Set<string> = new Set() ;
        let grades: string[] = [] ;

        // Add in all our current grade options
        students.forEach((student: Student) => {
          gradesSet.add(this.utilityService.getStudentGrade(student.grade)) ;
        }) ;

        // Return sorted grades
        grades = [ 'All' ].concat(this.utilityService.sortGrades(Array.from(gradesSet))) ;

        // Return sorted school classes
        classes = [ {
          name: 'All',
          schoolClassID: 0,
          teacherID: 0,
          createDate: (new Date()),
          status: ''
        }].concat(this.utilityService.sortClasses(classes)) ;

        return {
          students: students,
          grades: grades,
          classes: classes,
          licenseCount: licenseCount,
        } ;
      })
    ) ;
  }
}
