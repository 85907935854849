import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { VgApiService } from '@videogular/ngx-videogular/core';

/**
 * This HtmlResourceComponent represents a Resource asset that itself is HTML and should be
 * rendered in the app. Other resource assets are external files that are loaded via
 * link in a new browser window. Because these should be viewed in app, we need a component to
 * render them.
 */
@Component({
  selector: 'wf-html-resource',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.css']
})
export class HtmlResourcesComponent implements OnInit {
  resourceContent: string = '' ;
  viewResourceVideo: boolean = false ;
  playerAPI: VgApiService;
  videoToLoad = null;
  videoSource: string = '';

  constructor(
    private route: ActivatedRoute,
    public vgApiService: VgApiService,
  ) {
    this.playerAPI = vgApiService ;
  }

  ngOnInit(): void {
    this.resourceContent = this.route.snapshot.data['resolveData']['content'] ;
    this.videoSource = this.route.snapshot.data['resolveData']['videoUrl'] ;
    if (this.videoSource) this.viewResourceVideo = true ;
  }

  onPlayerReady(API: VgApiService) {
    this.playerAPI = API;
    this.playerAPI.getDefaultMedia().subscriptions.ended.subscribe({
      next: () => { this.onCompleteVideo(); }
    });
  }

  onCompleteVideo(){
    this.playerAPI.pause();
    this.playerAPI.currentTime = 0;
    this.viewResourceVideo = false;
  }

  setVideo(video: any){
    this.videoSource = "/assets/resources/" + video;
  }

  playVideo(video: any) {
    if (this.playerAPI != null) {
      this.viewResourceVideo = true;
      this.setVideo(video);
      this.playerAPI.play();
    }
  }

  replayVideo(){
    this.playVideo(this.videoToLoad)
  };
}
