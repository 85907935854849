<div #printContent id="printContent">
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
  </div>
  <div>
    <div class="report-section-header split-flex">
      <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="detailIcon"></fa-icon> Student Diagnostic Results</h5>
      <div class="group-summary-text">{{ student?.school }}: {{ student?.firstName }} {{ student?.lastName }}</div>
      <button id="print-button" class="btn print-summary-button print-hide" (click)="printDetail()"><fa-icon [icon]="printIcon"></fa-icon> Print Results</button>
    </div>
  </div>
  <div class="flex split-flex print-hide margin-top-10">
    <div>
      <button type="button" class="btn return-to-summary-button btn-icon" (click)="goToSummary()"><fa-icon [icon]="returnIcon"></fa-icon> Back to Summary</button>
    </div>
    <div>
      <button type="button" class="btn prev-detail-button margin-right-5 btn-icon" [disabled]="!canMovePrev" (click)="goToPrev()"><fa-icon [icon]="previousIcon"></fa-icon> Previous</button>
      <button type="button" class="btn prev-next-button btn-icon" [disabled]="!canMoveNext" (click)="goToNext()"><fa-icon [icon]="nextIcon"></fa-icon> Next</button>
    </div>
  </div>
  <div *ngIf="erred ; else details">
    Uh-oh, it looks like we had a problem finding the selected student. Please go back to the summary and try again
  </div>
  <ng-template #details>
    <div *ngFor="let assessmentScore of student!.assessmentScores; let idx = index" class="margin-top-10 page-break-after">
      <table class="diagnosticproduct-management-reports-table m">
        <thead class="table-section-header">
          <th class="detail-table-section-header-alignment">First Name</th>
          <th class="detail-table-section-header-alignment">Last Name</th>
          <th class="detail-table-section-header-alignment">Grade</th>
          <th class="detail-table-section-header-alignment">{{ wordTitle }}</th>
          <th class="detail-table-section-header-alignment">Date Completed</th>
          <th class="detail-table-section-header-alignment">Test</th>
        </thead>
        <tbody>
          <tr class="table-row-alignment">
            <td>{{ student!.firstName }}</td>
            <td>{{ student!.lastName }}</td>
            <td>{{ student!.grade | displayGrade }}</td>
            <td>
              <img [src]="getReportIcon(assessmentScore.systemScore, 'automaticity')" />
              <p class="student-score">{{ assessmentScore.systemScore }}</p>
            </td>
            <td>{{ formatDate(assessmentScore.dateCompleted) }}</td>
            <td>{{ assessmentScore.testWindow }}</td>
          </tr>
        </tbody>
      </table>
      <div class="chart-background margin-top-10">
        <div class="flex space-around">
          <h3 class="bold light-grey">Knowledge</h3>
          <h3 class="bold light-grey">Use</h3>
        </div>
        <div class="flex" style="height: 90% ; width: 100% ;">
          <div class="decoding-charts-section" [ngClass]="getReportBorderClass(assessmentScore.decodingScore, 'decoding')">
            <h3 [ngClass]="getReportHeaderClass(assessmentScore.decodingScore, 'decoding')">DECODING</h3>
            <div *ngIf="!isElementary">
              <img [src]="getReportIcon(assessmentScore.decodingScore, 'decoding')" class="margin-top-5 margin-left-5" />
              <p class="chart-score">{{ assessmentScore.decodingScore }}</p>
            </div>
            <!--<div [ngClass]="getDecodingDivChartClass()" style="height: 75%; margin: 1% 1% 2% 1%;">-->
            <div [ngClass]="getDecodingDivChartClass()" style="margin: 1% 1% 2% 1%;" [style.height]="isElementary ? '75%' : '65%'">
              <canvas #decodingBar class="chart chart-bar print-hide" style="padding: 2% 2% 0 0 ;" [id]="'decodingBar_' + idx"
                      baseChart
                      [data]="generateDecodingChartData(assessmentScore.dataDecoding[0])"
                      [type]="chartType"
                      [options]="decodingChartOptions"></canvas>
              <img #decodingImg [id]="'decodingImg_' + idx" class="chart-img print-show" style="margin: auto ; padding: 2% 2% 0 0 ;" />
              <img class="detail-brackets-img" src="/assets/images/reportIcons/studentDetailBrackets.svg" />
              <div>
                <h5 class="decoding-subhead-consonants" [ngClass]="getDecodingCategoryColor(assessmentScore.decodingConsonantOverall, 'consonant')">Consonants</h5>
                <h5 class="decoding-subhead-vowels" [ngClass]="getDecodingCategoryColor(assessmentScore.decodingVowelOverall, 'vowel')">Vowels</h5>
              </div>
            </div>
          </div>
          <div class="generalization-charts-section" [ngClass]="getReportBorderClass(assessmentScore.systemScore, 'automaticity')">
            <h3 [ngClass]="getReportHeaderClass(assessmentScore.systemScore, 'automaticity')">{{ automaticityReportTitle }}</h3>
            <div [ngClass]="getGeneralizationDivClass()" style="height: 75% ; margin: 1% ;">
              <canvas #generalizationBar class="chart chart-bar print-hide" style="padding: 9% 2% 1% 0 ;" [id]="'generalizationBar_' + idx"
                      baseChart
                      [data]="generateGeneralizationChartData(assessmentScore.dataGeneralization)"
                      [type]="chartType"
                      [options]="generalizationChartOptions"></canvas>
              <img #generalizationImg [id]="'generalizationImg_' + idx" class="chart-img print-show" style="margin: auto; padding: 9% 2% 1% 0%"/>
              <h5 class="generalization-subhead">Word Recognition Skills</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="instructional-priority-details">
      </div>
      <div class="instructional-priority-details">
        <h5 style="text-transform: uppercase ;">Instructional Zone</h5>
        <div class="zone-container">
          <img class="scale-container" src="/assets/images/reportIcons/studentDetail-InstructionalZone{{assessmentScore.zone}}.svg" />
        </div>
      </div>
    </div>
  </ng-template>
</div>
