import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  drawerIsOpen: boolean = false ;

  constructor() { }

  toggleDrawer(): boolean {
    this.drawerIsOpen = !this.drawerIsOpen ;

    return this.drawerIsOpen ;
  }
}
