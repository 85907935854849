import { Pipe, PipeTransform } from '@angular/core';
import {UtilityService} from "../services/utility.service";

@Pipe({
  name: 'displayGrade',
  pure: true
})
export class DisplayGradePipe implements PipeTransform {

  constructor(
    private utilityService: UtilityService,
  ) {}

  transform(value: number | undefined): string {
    if (!value) return '' ;

    return this.utilityService.getStudentGrade(value) ;
  }

}
