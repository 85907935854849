import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable()
export class BasicAuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private sessionStorageService: SessionStorageService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Defaults to basic auth if request has credentials but there is no SSO Provider.
    let modifyRequest = request.withCredentials && !this.sessionStorageService.getSSOInfo()?.provider;

    if (modifyRequest)
    {
      let authHeader = 'Basic ' + btoa(`${this.sessionStorageService.getUsername()}:${this.sessionStorageService.getPassword()}`);
      request = request.clone({
        setHeaders: {
          Authorization: authHeader,
        }
      });
    }

    return next.handle(request);
  }
}
