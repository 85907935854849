<div class="dialog-container-reports" *ngIf="showWaitDialog">
  <div class="save-data-dialog-top-container-reports" style="height: 100%;">
    <label class="save-data-dialog-text-reports">Please wait until section data is loaded.</label>
  </div>
</div>
<div class="dialog-container-reports" *ngIf="showAddingDialog">
  <div class="save-data-dialog-top-container-reports" style="height: 100%;">
    <label class="save-data-dialog-text-reports">Adding your selected sections. Please wait...</label>
  </div>
</div>
<div class="container center-content">
  <div class="well-login col-lg-8 col-md-10">
    <h2>Add your Clever sections</h2>
    <p>
      Please select the Clever sections you would like to add, and confirm each section's grade.
      <br><strong>*NOTE:</strong>
      <br>If you select "Other" for a section's grade, those students will be assigned a Secondary Screener Assessment.
      <br>Select grades 1-4 to assign an Elementary Screener Assessment.
    </p>
    <form [formGroup]="rosterForm" (ngSubmit)="submitSections()">
      <div class="row bottom-addon top-addon">
        <div class="col-xs-12">
          <div class="form-group-md">
            <input type="checkbox" (click)="toggleAll($event)">
            <label>Select All</label>
          </div>
        </div>
      </div>
      <ng-container formArrayName="rosterSections">
        <ng-container *ngFor="let sectionForm of rosterSections.controls; let index = index">
          <div class="row top-addon" [formGroupName]="index">
            <div class="col-xs-6">
              <div class="form-group-md">
                <input type="checkbox" formControlName="checked"
                  (change)="updateSection(index)">
                <label class="col-form-label-lg">{{sectionForm.get('name')?.value}}</label>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="form-group-md form-inline">
                <label>Grade: </label>
                <select class="form-control" id="grade" formControlName="grade">
                  <option *ngFor="let grade of gradeOptions" value="{{grade.key}}">
                    {{grade.value}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="row top-addon bottom-addon">
        <div class="col-md-4 submit-btn">
          <button class="btn btn-primary" type="submit">Add Sections</button>
          <button style="margin-left: 8px" class="btn btn-danger" (click)="skipSections()">Skip</button>
        </div>
      </div>
    </form>
    <div *ngIf="showError" class="alert alert-danger" role="alert">
      {{errorMessage}}
    </div>
  </div>
</div>