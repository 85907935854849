export const AssessmentTypes: any = {
  '0': 'Screener',
  '1': 'Diagnostic',
  '2': 'System',
}

export const DiagnosticTypes: any = {
  'Pre': 1,
  'Mid': 3,
  'Post': 4
}

export const AssessmentTypeNames: any = {
  SCREENER: 0,
  PREDIAGNOSTIC: 1,
  MIDDIAGNOSTIC: 3,
  POSTDIAGNOSTIC: 4,
} ;
