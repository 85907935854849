import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ReportsService } from '../../../../core/services/reports.service';

@Injectable({
  providedIn: 'root'
})
export class SessionSummaryResolver  {
  constructor(
    private reportsService: ReportsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let today: Date = new Date();
    // On load, we start with sessions just for the current date
    return this.reportsService.getSessionSummaryData([ today.setHours(0,0,1), today.setHours(23,59,59) ]) ;
  }
}
