import { Component, OnDestroy, OnInit } from '@angular/core';

import {concatMap, of, Subscription, switchMap} from 'rxjs';

import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LoginService } from '../../../core/services/login.service';
import { SurveyService } from 'src/app/core/services/surveys.service';
import { UserRoles } from '../../../core/models/user.model';
import { SidebarService } from '../../../core/services/sidebar.service';
import { environment } from '../../../../environments/environment';
import { SSOCredentials } from '../../../core/models/credentials.model';
import { Router } from '@angular/router';
import { faDesktop, faGear, faRightFromBracket, faComments } from '@fortawesome/free-solid-svg-icons';
import { ManagementUser } from '../../../core/models/management-user.model';

@Component({
  selector: 'wf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  userData: ManagementUser | null = null ;
  userLoggedIn: boolean = false ;
  userRole: string = '' ;
  isDistrictUser: boolean | undefined = false ;
  studentAppLink: string = '' ;
  greeting: string = 'Welcome' ;
  logoutIcon = faRightFromBracket ;
  settingsIcon = faGear ;
  demoIcon = faDesktop ;
  feedbackIcon = faComments ;
  loginStatusSubscription: Subscription | undefined ;
  surveySubmissionSubscription: Subscription | undefined ;
  showFeedbackButton: boolean = false ;
  userSurveys: any[] = [];
  surveyIds: number[] = [] ;

  constructor(
    public loginService: LoginService,
    public sessionStorageService: SessionStorageService,
    public sidebarService: SidebarService,
    public surveyService: SurveyService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.loginStatusSubscription = this.loginService.loginStatus$.subscribe((loggedIn) => {
      this.userLoggedIn = loggedIn ;
      if (loggedIn)
      {
        this.userData = this.sessionStorageService.getUserData() ;
        this.userRole = this.userData?.userRole ?? '' ;
        this.isDistrictUser = this.userData?.isDistrictUser() ;

        let ssoInfo: SSOCredentials | null = this.sessionStorageService.getSSOInfo() ;
        if (ssoInfo?.enabled)
        {
          if (ssoInfo?.provider.toLocaleLowerCase() === 'clever')
          {
            this.studentAppLink = environment.studentSiteURL + '/clever/login?token=' + ssoInfo?.bearerToken ;
          }
          else if (ssoInfo?.provider.toLocaleLowerCase() === 'classlink')
          {
            this.studentAppLink = environment.studentSiteURL + '/classlink/login?token=' + ssoInfo?.bearerToken ;
          }
        }

        if (this.userData)
        {
          this.getSurveyData(this.userData.userID);
        }
      }
    }) ;

    this.surveySubmissionSubscription = this.surveyService.surveySubmission$.subscribe({
      next: (completed) => {
        if (completed && this.userData)
        {
          this.getSurveyData(this.userData?.userID) ;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.loginStatusSubscription?.unsubscribe() ;
    this.surveySubmissionSubscription?.unsubscribe() ;
  }

  getSurveyData(userId: number): void {
    this.surveyService.getAvailableSurveys().pipe(
      concatMap((surveyIds) => {
        return this.surveyService.getUserSurveys(userId).pipe(
          switchMap((surveys) => {
            return of({
              surveyIds,
              surveys,
            }) ;
          })
        );
      })
    ).subscribe({
      next: (surveyData) => {
        this.userSurveys = surveyData.surveys ;
        this.surveyIds = surveyData.surveyIds ;
        this.showFeedbackButton = this.showFeedbackPrompt() ;
      }
    }) ;
  }

  showFeedbackPrompt(): boolean {
    if (this.userData?.isFILUser() || !this.surveyIds.length) {
      return false;
    }
    const now = new Date();
    const startWindow = new Date(now.getFullYear(), 3, 15);
    const endWindow = new Date(now.getFullYear(), 11, 15);

    const isNowInWindow = now >= startWindow && now <= endWindow;

    if (!this.userSurveys || this.userSurveys.length === 0) {
      return (this.userData?.numberOfUserLogins ?? 0) >= 5;
    } else {
      const latestSurvey = this.userSurveys[this.userSurveys.length - 1];
      const latestSurveyDate = new Date(latestSurvey.DateTaken[0], latestSurvey.DateTaken[1] - 1, latestSurvey.DateTaken[2], latestSurvey.DateTaken[3], latestSurvey.DateTaken[4], latestSurvey.DateTaken[5]);
      const isLatestSurveyInWindow = latestSurveyDate >= startWindow && latestSurveyDate <= endWindow;

      return isNowInWindow !== isLatestSurveyInWindow;
    }
  }


  logout() {
    this.loginService.logout() ;
  }
  goToFeedback() {
    this.router.navigateByUrl('survey');
  }
}
