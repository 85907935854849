import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'underscore';

import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';

import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { ManagementUser } from '../../../core/models/management-user.model';
import { District } from '../../../core/models/district.model';
import { School } from '../../../core/models/school.model';
import { ReportsService } from '../../../core/services/reports.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { PrintService } from '../../../core/services/print.service';
import { GaugeComponent } from '../../../shared/components/gauge/gauge.component';

@Component({
  selector: 'wf-district-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class DistrictProgressComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  isSchoolReportsUser: boolean = false ;
  schoolFilterError: string = '' ;
  loadError: string = '' ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  progressData: any ;
  selectedDistrictId: number = -1 ;
  selectedSchoolId: number = 0 ;
  showTeacherTable: boolean = false ;
  showSchoolTable: boolean = false ;
  schoolTableSwitch: string = '+' ;
  teacherTableSwitch: string = '+' ;
  teacherCols: string[] = [
    'First Name',
    'Last Name',
    'Grade',
    'School',
    '% of students completing at least one unit per week'
  ] ;
  schoolCols: string[] = [
    'School',
    'Number of Teachers',
    'WordFlight Students',
    '% of students completing at least one unit per week'
  ] ;
  predictedCompletionPercent: number = 0 ;
  predictedCompletionPercentDisplay: string = '' ;
  unitCompletionPercent: number = 0 ;
  unitCompletionPercentDisplay: string = '' ;
  teacherAggregateData: any[] = [] ;
  schoolAggregateData: any[] = [] ;
  chartType: ChartType = 'pie' ;
  schoolData: ChartData<'pie', number[], string | string[]> = {
    labels: [ '100%', '65-99%', '64% & below' ],
    datasets: [{
      data: [ ],
      backgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
      hoverBackgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
    }]
  } ;
  teacherData: ChartData<'pie', number[], string | string[]> = {
    labels: [ '100%', '65-99%', '64% & below' ],
    datasets: [{
      data: [ ],
      backgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
      hoverBackgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
    }]
  } ;
  chartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  } ;
  printIcon = faPrint ;

  @ViewChildren(BaseChartDirective) charts!: QueryList<BaseChartDirective> ;
  @ViewChild('predictedCompletionGauge') predictedGauge!: GaugeComponent ;
  @ViewChild('unitCompletionGauge') unitGauge!: GaugeComponent ;
  @ViewChild('schoolChartCanvas') schoolChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('schoolChartImg') schoolChartImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('teacherChartCanvas') teacherChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('teacherChartImg') teacherChartImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private sessionStorageService: SessionStorageService,
    private printService: PrintService,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;
    this.isSchoolReportsUser = this.currentUser ? this.currentUser?.isSchoolUser() : false;

    // Get progress data
    this.progressData = this.route.snapshot.data['resolveData'] ;

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts.filter((district: District) => district.name !== 'Clever District') ;
    this.schools = this.route.snapshot.data['filterData'].schools.filter((school: School) => school.name !== 'Clever School') ;
    this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;
    this.selectedDistrictId = this.reportsService.getSelectedDistrictForReports().districtID ;
    this.selectedSchoolId = this.reportsService.getSelectedSchoolForReports().schoolID ;

    // We call filter initially on Init because we might have a school previously selected from other reports and we might
    // have to then filter based on that. The Resolver ensures that our district data is correct (it uses the selected district to load)
    this.filterSchoolTeacher({ district: this.reportsService.getSelectedDistrictForReports(), school: this.reportsService.getSelectedSchoolForReports() }) ;
  }

  filterSchoolTeacher(filterOpts: any) {
    if (filterOpts.district.districtID !== this.selectedDistrictId)
    {
      // District was changed, so we need to load new report data
      this.selectedDistrictId = filterOpts.district.districtID ;
      this.selectedSchoolId = 0 ;
      this.reportsService.getDistrictProgressData().subscribe({
        next: (data: any) => {
          this.progressData = data ;

          // Now update our data on the report
          this.calculateReportValues(this.progressData.schools) ;
        }
      }) ;
    }
    else
    {
      // School updated
      this.selectedSchoolId = filterOpts.school.schoolID ;

      let filteredData: any = this.reportsService.filterDistrictReportData(this.progressData.schools, this.selectedSchoolId) ;
      this.calculateReportValues(filteredData) ;
    }
  }

  toggleTeacherTableSwitch() {
    this.teacherTableSwitch = (this.teacherTableSwitch === '+') ? '-' : '+' ;
    this.showTeacherTable = !this.showTeacherTable ;
  }

  toggleSchoolTableSwitch() {
    this.schoolTableSwitch = (this.schoolTableSwitch === '+') ? '-' : '+' ;
    this.showSchoolTable = !this.showSchoolTable ;
  }

  sortSchoolTable(sortOpts: any) {
    this.schoolAggregateData = (sortOpts.sortReverse) ? _.sortBy(this.schoolAggregateData, this.reportsService.sortSchoolFunction, sortOpts).reverse() : _.sortBy(this.schoolAggregateData, this.reportsService.sortSchoolFunction, sortOpts) ;
  }

  sortTeacherTable(sortOpts: any) {
    this.teacherAggregateData = (sortOpts.sortReverse) ? _.sortBy(this.teacherAggregateData, this.reportsService.sortTeacherFunction, sortOpts).reverse() : _.sortBy(this.teacherAggregateData, this.reportsService.sortTeacherFunction, sortOpts) ;
  }

  calculateReportValues(data: any) {
    // Generate our teacher and school aggregate data
    let schoolData: any[] = [] ;
    let teacherData: any[] = [] ;
    let schoolsLow = 0 ;
    let schoolsMedium = 0 ;
    let schoolsHigh = 0 ;
    let teachersLow = 0 ;
    let teachersMedium = 0 ;
    let teachersHigh = 0 ;
    let totalStudents = 0 ;
    let totalStudentsOnTrack = 0 ;
    let totalStudentsWithUnitPerWeek = 0 ;

    Object.values(data).forEach((school: any) => {
      Object.values(school.teachers).forEach((teacher: any) => {
        // Determine the icon to use for our percentage for each teacher
        let teacherMeetingPercent = (teacher.totalStudents === 0) ? 0 : Math.round((teacher.studentsWithUnitPerWeek / teacher.totalStudents) * 100) ;
        let teacherMeetingPercentDisplay = (teacherMeetingPercent === 0 && teacher.studentsWithUnitPerWeek > 0) ? '<1' : teacherMeetingPercent ;
        let teacherMeetingIcon = '' ;
        if (teacherMeetingPercent < 60)
        {
          teachersLow++ ;
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendRed.svg" class="icon" />' ;
        }
        else if (teacherMeetingPercent < 99)
        {
          teachersMedium++ ;
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendYellow.svg" class="icon" />' ;
        }
        else
        {
          teachersHigh++ ;
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendGreen.svg" class="icon" />' ;
        }

        teacherData.push({
          data: [
            teacher.firstName,
            teacher.lastName,
            teacher.grades,
            school.name,
            teacherMeetingPercent,
          ],
          display: [
            teacher.firstName,
            teacher.lastName,
            teacher.grades.join(', '),
            school.name,
            `<div class="icon-score">${teacherMeetingIcon} <div class="score">${teacherMeetingPercentDisplay}%</div></div>`,
          ],
        }) ;
      }) ;

      let schoolMeetingPercent = (school.totalStudents === 0) ? 0 : Math.round((school.totalStudentsWithUnitPerWeek / school.totalStudents) * 100) ;
      let schoolMeetingPercentDisplay = (schoolMeetingPercent === 0 && school.totalStudentsWithUnitPerWeek > 0) ? '<1' : schoolMeetingPercent ;
      let schoolMeetingIcon = '' ;
      if (schoolMeetingPercent <= 60)
      {
        schoolsLow++ ;
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendRed.svg" class="icon" />' ;
      }
      else if (schoolMeetingPercent <= 75)
      {
        schoolsMedium++ ;
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendYellow.svg" class="icon" />' ;
      }
      else
      {
        schoolsHigh++ ;
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendGreen.svg" class="icon" />' ;
      }

      totalStudents += school.totalStudents ;
      totalStudentsOnTrack += school.totalStudentsOnTrack ;
      totalStudentsWithUnitPerWeek += school.totalStudentsWithUnitPerWeek ;

      schoolData.push({
        data: [
          school.name,
          Object.keys(school.teachers).length,
          school.totalStudents,
          schoolMeetingPercent,
        ],
        display: [
          school.name,
          Object.keys(school.teachers).length,
          school.totalStudents,
          `<div class="icon-score">${schoolMeetingIcon} <div class="score">${schoolMeetingPercentDisplay}%</div></div>`,
        ],
      }) ;
    }) ;

    this.teacherAggregateData = teacherData ;
    this.schoolAggregateData = schoolData ;
    this.schoolData.datasets[0].data = [ schoolsHigh, schoolsMedium, schoolsLow ] ;
    this.teacherData.datasets[0].data = [ teachersHigh, teachersMedium, teachersLow ] ;
    this.predictedCompletionPercent = (totalStudents === 0) ? 0 : Math.round((totalStudentsOnTrack / totalStudents) * 100) ;
    this.predictedCompletionPercentDisplay = (this.predictedCompletionPercent === 0 && totalStudentsOnTrack > 0) ? '<1' : `${this.predictedCompletionPercent}` ;
    this.unitCompletionPercent = (totalStudents === 0) ? 0 : Math.round((totalStudentsWithUnitPerWeek / totalStudents) * 100) ;
    this.unitCompletionPercentDisplay = (this.unitCompletionPercent === 0 && totalStudentsWithUnitPerWeek > 0) ? '<1' : `${this.unitCompletionPercent}` ;

    this.changeDetector.detectChanges();
    this.predictedGauge?.calculateValues() ;
    this.unitGauge?.calculateValues() ;

    if (this.charts)
    {
      this.charts.forEach((chart) => {
        chart.update() ;
      }) ;
    }
  }

  printReport() {
    // Convert charts to images before printing
    let schoolCanvas = this.schoolChartCanvas.nativeElement;
    let schoolImg = this.schoolChartImg.nativeElement;
    let teacherCanvas = this.teacherChartCanvas.nativeElement;
    let teacherImg = this.teacherChartImg.nativeElement;
    this.reportsService.convertCanvasToImage(schoolCanvas, schoolImg);
    this.reportsService.convertCanvasToImage(teacherCanvas, teacherImg);

    let school = this.reportsService.getSelectedSchoolForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }
}
