<div class="students-filter-wrapper">
  <div>
    <label class="bold">Grade</label>
    <select class="form-select" [(ngModel)]="selectedGrade" (change)="updateStudentFilter()">
      <option value="all" *ngIf="showAllGradeOption">All Grades</option>
      <ng-container *ngIf="elementaryGrades.length > 0">
        <optgroup label="Grades 4 & Below">
          <option value="four_below_all">All 4th &amp; Below</option>
          <option *ngFor="let grade of elementaryGrades" [value]="grade">{{ grade }}</option>
        </optgroup>
      </ng-container>
      <ng-container *ngIf="secondaryGrades.length > 0">
        <optgroup label="Grades 5 & Up">
          <option value="five_up_all">All 5th &amp; Up</option>
          <option *ngFor="let grade of secondaryGrades" [value]="grade">{{ grade }}</option>
        </optgroup>
      </ng-container>
    </select>
  </div>
  <div>
    <label class="bold">Class</label>
    <select class="form-select" [(ngModel)]="selectedClass" (change)="updateStudentFilter()">
      <option *ngFor="let schoolClass of classes" [value]="schoolClass.schoolClassID">{{ schoolClass.name }}</option>
    </select>
  </div>
  <div *ngIf="filterExtraSupport">
    <label class="bold">Extra Support</label>
    <select class="form-select" [(ngModel)]="selectedExtraSupport" (change)="updateStudentFilter()">
      <option *ngFor="let supportOption of supportOptions" [value]="supportOption">{{ supportOption }}</option>
    </select>
  </div>
</div>
