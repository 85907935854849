import { FormGroup } from '@angular/forms';

export function ControlValuesMatchValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName] ;
    let matchingControl = formGroup.controls[matchingControlName] ;

    if (matchingControl.errors && !matchingControl.errors['mustMatch'])
    {
      return ;
    }

    if (control.value !== matchingControl.value)
    {
      matchingControl.setErrors({ mustMatch: true }) ;
    }
    else
    {
      matchingControl.setErrors(null) ;
    }
  }
}
