import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute}  from '@angular/router';

import { ChartConfiguration, ChartData, ChartType } from 'chart.js';

import { BaseChartDirective } from 'ng2-charts';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { faGauge, faPieChart } from '@fortawesome/free-solid-svg-icons';

import { SubscriptionTypes } from '../../../../core/models/subscription-types.model';
import { DashboardService } from '../shared/dashboard.service';
import { UtilityService } from '../../../../core/services/utility.service';
import { SessionInfo } from '../../../../core/models/session-info.model';
import { ManagementUser } from '../../../../core/models/management-user.model';
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { District } from '../../../../core/models/district.model';
import { School } from '../../../../core/models/school.model';
import { LoadingService } from '../../../../core/services/loading.service';


@Component({
  selector: 'wf-dashboard-school',
  templateUrl: './system-dashboard.component.html',
  styleUrls: ['./system-dashboard.component.css']
})
export class SystemDashboardComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  schoolFilterError: string = '' ;
  showSchoolFilter: boolean = false ;
  numStudentsEnrolled: number = -1 ;
  numStudentsNotLoggedIn: number = -1 ;
  percentMakingTimeGoal: number = -1 ;
  avgWeeklyUsageAll: number = -1 ;
  avgWeeklyUsagePrevious: number = -1 ;
  numPointsEarned: number = -1 ;
  tipOfTheWeek: string = '' ;
  mostCommonUnits: any[] = [] ;
  studentsWithSessionTimes: any[] = [] ;
  studentsWithNoLogins: any[] = [] ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  dashboardIcon = faGauge ;
  reportIcon = faPieChart ;
  fullProduct: string = SubscriptionTypes.FullProduct ;
  colors: string[] = [ '#0095DA', '#B2D235', '#949598', '#007236', '#80C342' ] ;
  chartType: ChartType = 'bar' ;
  chartData: ChartData<'bar'> = {
    labels: Array.from(Array(24).keys()).map(k => `${k + 1}`),
    datasets: [
      {
        data: [],
        backgroundColor: Array(24).fill(null).map((value, idx) => { return this.colors[idx % this.colors.length] ; }),
        hoverBackgroundColor: 'rgba(0, 0, 0, 0.5)',
      }
    ]
  } ;
  chartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Number of Students',
          font: {
            weight: '700',
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          }
        }
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Unit Number',
          font: {
            weight: '700',
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          },
        },
        ticks: {
          stepSize: 1,
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          }
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external:(context) => {
          // NOTE: This logic all assume we have a single dataset (all the array reads to the 0-th index)
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl)
          {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.classList.add('chartjs-tooltip') ;
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip ;
          if (tooltipModel.opacity === 0)
          {
            tooltipEl.style.opacity = '0' ;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign)
          {
            tooltipEl.classList.add(tooltipModel.yAlign);
          }
          else
          {
            tooltipEl.classList.add('no-transform');
          }

          // Set Text
          if (tooltipModel.dataPoints && tooltipModel.body[0].lines && tooltipModel.body[0].lines.length)
          {
            // construct our label tile
            let colors = tooltipModel.labelColors[0] ;
            let style = 'background-color: ' + colors.backgroundColor ;

            let innerHtml = '<div>' ;
            innerHtml += '  <p class="chartjs-tooltip-header">' ;
            innerHtml += '    <span class="chartjs-tooltip-key" style="' + style + '"></span>' ;
            innerHtml += '    Unit ' + tooltipModel.dataPoints[0].label
            innerHtml += '  </p>' ;
            innerHtml += '  <div>' ;
            innerHtml += '    <p>' + tooltipModel.dataPoints[0].formattedValue + (tooltipModel.dataPoints[0].formattedValue === '1' ? ' student' : ' students') + '</p>' ;

            // We only display the students name if the teacher is logged in
            if ([ 'ROLE_TEACHER', 'ROLE_TEACHER_CURRICULUM', 'ROLE_TEACHER_REVIEW' ].includes(this.currentUser!.userRole.toUpperCase()))
            {
              innerHtml += '    <ul class="unit-students">';
              this.mostCommonUnits[tooltipModel.dataPoints[0].dataIndex].forEach((student: any) => {
                innerHtml += '      <li>' + student + '</li>';
              }) ;
              innerHtml += '    </ul>';
            }

            innerHtml += '  <div>' ;
            innerHtml += '</div>' ;

            tooltipEl.innerHTML = innerHtml ;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.padding = tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      }
    },
  } ;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective ;
  @ViewChild('nologinmodal') noLoginsModal?: ElementRef ;
  @ViewChild('previousmodal') previousSessionsModal?: ElementRef ;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sessionStorageService: SessionStorageService,
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;
    if (this.route.snapshot.data['resolveData'].erred)
    {
      this.schoolFilterError = this.route.snapshot.data['resolveData'].message ;
    }
    else
    {
      this.numStudentsEnrolled = this.route.snapshot.data['resolveData'].studentsEnrolled ;
      this.numStudentsNotLoggedIn = this.route.snapshot.data['resolveData'].studentsNotStarted ;
      this.numPointsEarned = this.route.snapshot.data['resolveData'].totalPointsEarned ;
      this.avgWeeklyUsageAll = this.route.snapshot.data['resolveData'].avgWeeklyUsageAll ;
      this.avgWeeklyUsagePrevious = this.route.snapshot.data['resolveData'].avgWeeklyUsagePrevious ;
      this.mostCommonUnits = this.route.snapshot.data['resolveData'].mostCommonUnit ;
      this.chartData.datasets[0].data = this.route.snapshot.data['resolveData'].mostCommonUnit.map((value: any) => { return value.length ; }) ;
      this.tipOfTheWeek = this.route.snapshot.data['resolveData'].tipOfTheWeek ;
      this.studentsWithSessionTimes = this.route.snapshot.data['resolveData'].previousWeekSessions.sort(this.utilityService.sortStudentsTimeGoals.bind(this.utilityService)) ;
      this.percentMakingTimeGoal = this.getPercentMakingTimeGoal(this.studentsWithSessionTimes) ;
    }

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts ;
    this.schools = this.route.snapshot.data['filterData'].schools ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser())
    {
      // The FIL user and District user roles can select all schools
      this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    }

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() || this.currentUser!.isSchoolUser())
    {
      // The FIL user and District and School user roles can select all teachers
      this.teachers.unshift(ManagementUser.getGenericUser()) ;
    }
  }

  showStudentsWithNoLogins() {
    this.dashboardService.getStudentsNotLoggedInList().subscribe({
      next: (response: any) => {
        this.studentsWithNoLogins = response ;
        this.modalService.open(this.noLoginsModal, { animation: true, centered: true, scrollable: true, windowClass: 'dashboard-modal' }) ;
      }
    }) ;
  }

  showPreviousSessions() {
    this.modalService.open(this.previousSessionsModal, { animation: true, centered: true, scrollable: true, windowClass: 'dashboard-modal' }) ;
  }

  getDisplaySessionTime(sessionTime: number) {
    return this.utilityService.getDisplayedSessionTime(sessionTime) ;
  }

  filterSchoolTeacher(filterOptions: any) {
    this.dashboardService.getSystemDashboardData().subscribe({
      next: (data: any) => {
        if (data.erred)
        {
          this.schoolFilterError = data.message ;
        }
        else
        {
          this.schoolFilterError = '' ;
          this.numStudentsEnrolled = data['studentsEnrolled'];
          this.numStudentsNotLoggedIn = data['studentsNotStarted'];
          this.numPointsEarned = data['totalPointsEarned'];
          this.avgWeeklyUsageAll = data['avgWeeklyUsageAll'];
          this.avgWeeklyUsagePrevious = data['avgWeeklyUsagePrevious'];
          this.mostCommonUnits = data['mostCommonUnit'];
          this.chartData.datasets[0].data = data['mostCommonUnit'].map((value: any) => {
            return value.length;
          });
          this.tipOfTheWeek = data['tipOfTheWeek'];
          this.studentsWithSessionTimes = data['previousWeekSessions'].sort(this.utilityService.sortStudentsTimeGoals.bind(this.utilityService));
          this.percentMakingTimeGoal = this.getPercentMakingTimeGoal(this.studentsWithSessionTimes);

          this.chart?.update();
        }
      }
    })
  }

  refreshDashboardData() {
    this.loadingService.start() ;
    this.dashboardService.getSystemDashboardData().subscribe({
      next: (data: any) => {
        if (data.erred)
        {
          this.schoolFilterError = data.message ;
        }
        else
        {
          this.schoolFilterError = '';
          this.loadingService.finish();
          this.numStudentsEnrolled = data['studentsEnrolled'];
          this.numStudentsNotLoggedIn = data['studentsNotStarted'];
          this.numPointsEarned = data['totalPointsEarned'];
          this.avgWeeklyUsageAll = data['avgWeeklyUsageAll'];
          this.avgWeeklyUsagePrevious = data['avgWeeklyUsagePrevious'];
          this.mostCommonUnits = data['mostCommonUnit'];
          this.chartData.datasets[0].data = data['mostCommonUnit'].map((value: any) => {
            return value.length;
          });
          this.tipOfTheWeek = data['tipOfTheWeek'];
          this.studentsWithSessionTimes = data['previousWeekSessions'].sort(this.utilityService.sortStudentsTimeGoals.bind(this.utilityService));
          this.percentMakingTimeGoal = this.getPercentMakingTimeGoal(this.studentsWithSessionTimes);

          this.chart?.update();
        }
      }
    })
  }

  private getPercentMakingTimeGoal(studentSessions: any[]) {
    let countAboveTimeGoal = 0 ;
    let underFifthGradeGoal = SessionInfo.ElementaryTimeGoal ;
    let overFifthGradeGoal = SessionInfo.SecondaryTimeGoal ;

    studentSessions.forEach((studentSession) => {
      let student = studentSession.student ;
      let sessionTime = this.utilityService.getDisplayedSessionTime(studentSession.sessionTime) ;

      if ((student.grade < 6 && sessionTime >= underFifthGradeGoal) || (sessionTime >= overFifthGradeGoal))
      {
        countAboveTimeGoal++ ;
        student.metGoal = true ;
      }
    }) ;

    return (countAboveTimeGoal / studentSessions.length) * 100 ;
  }
}
