import { Injectable } from '@angular/core';

import { Observable, of, forkJoin } from 'rxjs';
import { EnrollmentService } from 'src/app/core/services/enrollment.service';

@Injectable({
  providedIn: 'root'
})

export class EnrollmentResolver  {
  constructor(
    private enrollmentService: EnrollmentService
  ) {}

  resolve(): Observable<any> {
    let remainingDiagnosticLicenesesCount = this.enrollmentService.getRemainingDiagnosticLicensesCount(null, null);
    let remainingInterventionLicensesCount = this.enrollmentService.getRemainingInterventionLicensesCount(null, null);
    let gradeOptions = this.enrollmentService.getGradeOptions();
    let genderOptions = this.enrollmentService.getGenderOptions();
    // NOTE: default to setting secondary curriculum
    let curriculumOptions = this.enrollmentService.getCurriculumOptions(7);
    let classOptions = this.enrollmentService.getClassOptions();
    let assessmentTypeOptions = this.enrollmentService.getAssessmentTypeOptions();

    // TODO: add .pipe to catchErr here
    let EnrollmentResolveData$ = forkJoin({
      remainingDiagnosticLicenesesCount,
      remainingInterventionLicensesCount,
      gradeOptions,
      genderOptions,
      curriculumOptions,
      classOptions,
      assessmentTypeOptions,
    });
    return EnrollmentResolveData$;
  }

}