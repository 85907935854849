import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticScheduleService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getFullProductStudentsForScheduling() {
    const reqOpts = {
      withCredentials: true
    };

    return this.httpClient.get(`${environment.dataServicesURL}manage/enroll/students/managesubscriptions/scheduling`, reqOpts);
  }

  editDiagnosticSchedule(scheduleData: any) {
    const reqOpts = {
      withCredentials: true
    };

    return this.httpClient.put(`${environment.dataServicesURL}manage/enroll/students/schedule/edit`, scheduleData, reqOpts);
  }
}
