<div>
  <div>
    <div class="header-controls">
      <h3><img src="/assets/images/iconEnroll.svg" /> Enroll </h3>
    </div>
    <div>
      <div class="report-section-header">
        <h5 class="results-report-header"><fa-icon [icon]="studentIcon"></fa-icon> Batch Enrollment</h5>
      </div>
      <div class="diagnosticproduct-management-text-button-bar">
        <div class="diagnosticproduct-management-button-bar pull-left" >
          <input style="margin-left: 8px" type="file" id="studentCSVUpload" #studentCSVUpload class="input-file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  (change)="handleFileInput($event)">
          <label style="margin-left: 8px" for="studentCSVUpload"><fa-icon [icon]="importIcon"></fa-icon> {{fileName}}</label>
          <button style="margin-left: 8px" type="button" class="btn btn-danger" (click)="removeFile()"><fa-icon [icon]="removeIcon"></fa-icon> Remove File</button>
          <button style="margin-left: 8px" type="button" class="btn undo-button" (click)="undoBatchEnrollment()"><fa-icon [icon]="undoIcon"></fa-icon> Undo</button>
          <button style="margin-left: 8px" type="button" class="btn btn-success" (click)="addStudents()" [disabled]="!enableEnrollButton"><fa-icon [icon]="enrollIcon"></fa-icon> Enroll</button>
        </div>
        <div id="batch-error" *ngIf="invalidBatch" class="alert message alert-danger">
          <div class="icon">
            <fa-icon [icon]="errorIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">Batch Enrollment Error</div>
            <ul>
              <li *ngFor="let errorMessage of errors">{{ errorMessage }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="!showCsvPreview">
        <table class="report-table small">
          <thead class="diagnosticproduct-management-table-header">
            <th>Teacher</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Grade</th>
            <th>Username</th>
            <th>Password</th>
            <th>Session Time</th>
            <th>Subscription Type</th>
            <th>Class</th>
            <th>Enrollment Date</th>
          </thead>
          <tbody>
            <tr *ngFor="let student of studentList">
              <td style="vertical-align: middle">{{student.teacherUsername}}</td>
              <td style="vertical-align: middle">{{student.firstName}}</td>
              <td style="vertical-align: middle">{{student.lastName}}</td>
              <td style="vertical-align: middle">{{student.grade | displayGrade}}</td>
              <td style="vertical-align: middle">{{student.username}}</td>
              <td style="vertical-align: middle">{{student.password}}</td>
              <td style="vertical-align: middle">{{student.sessionLength}}</td>
              <td style="vertical-align: middle">{{student.assessmentType}}</td>
              <td style="vertical-align: middle">{{student.schoolClass?.name || 'None'}}</td>
              <td style="vertical-align: middle;">{{student.enrollmentDate}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="showCsvPreview">
        <div class="alert alert-warning">{{previewNote}}</div>
        <table *ngIf="csvPreview.length > 0" class="report-table small">
          <thead class="diagnosticproduct-management-table-header">
            <th class="batch-enroll-preview-header" *ngFor="let header of csvHeaders">{{header}}</th>
          </thead>
          <tbody>
          <tr *ngFor="let row of csvRows">
            <td *ngFor="let item of row">{{item}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
