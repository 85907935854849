<div class="container-fluid center-content" *ngIf="displayForm">
  <div class="well-login col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-s-8 col-xs-offset-2">
    <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="row">
        <div class="col-xs-12">
          <img style="float:left; padding: 5px;" src="../../../assets/images/managementicon.svg"/>
          <h2 class="management-login">Management Login</h2>
        </div>
      </div>
      <div [hidden]="!returnedError">
        <div class="alert message" [ngClass]="{'alert-danger': returnedLoginError, 'alert-warning': returnedVersionError}" id="failedLoginDisplay">
          <div class="icon">
            <fa-icon [icon]="loginErrorIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">{{ returnTitle }}</div>
            {{ result }}
          </div>
        </div>
      </div>
      <div class="row" style="padding: 20px; row-gap: 10px ;">
        <div class="col-xs-12">
          <div class="inner-addon left-addon">
            <fa-icon [icon]="userIcon" class="input-icon"></fa-icon>
            <input type="text" id="username" name="username" class="form-control" aria-placeholder="Username" placeholder="Username" formControlName="username" required />
          </div>
        </div>
        <div class="col-xs-12 margin-bottom-25">
          <div class="inner-addon left-addon">
            <fa-icon [icon]="passwordIcon" class="input-icon"></fa-icon>
            <input type="password" id="password" class="form-control" name="password" aria-placeholder="Password"
              placeholder="Password" formControlName="password" required />
          </div>
        </div>
        <div style="padding-left: 10px ; padding-right: 10px ;">
          <div *ngIf="capsOn" class="caps-lock-warning">
            <fa-icon [icon]="capsLockIcon"></fa-icon>
            Caps-lock is enabled
          </div>
        </div>
        <div class="col-xs-12">
          <label class="checkbox" style="text-align: left; display: inline-block;">
            <input type="checkbox" id="remember-me" class="remember-me-checkbox" formControlName="rememberMe">
            Remember me
          </label>
          <a class="forgot-password-link" routerLink="/forgot-password">Forgot password?</a>
        </div>
      </div>

      <div class="row">
        <div class="d-grid">
          <button class="btn btn-primary btn-block btn-success" id="loginButton" type="submit" [disabled]="!loginForm.valid">
            <span *ngIf="loading; then loadingText else loginText"></span>
            <ng-template #loadingText><fa-icon [icon]="spinnerIcon" [spin]="true"></fa-icon></ng-template>
            <ng-template #loginText>Login</ng-template>
          </button>
        </div>
      </div>
    </form>
    <div class="login-clever" *ngIf=showProviderButtons>
      <a [href]="cleverOauth"><img src="/assets/images/LogInWithClever.png" /></a>
      <a [href]="classlinkOauth"><img src="/assets/images/LogInWithClassLink.png" style="width: 207px;" /></a>
    </div>
  </div>
</div>
<wf-footer></wf-footer>
