import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, of, switchMap } from 'rxjs';
import { LoginService } from '../../core/services/login.service';
import { CleverService } from '../../core/services/clever.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { ManagementUser } from '../../core/models/management-user.model';
import { Credentials, SSOCredentials } from '../../core/models/credentials.model';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'wf-clever',
  templateUrl: './clever.component.html',
})
export class CleverComponent implements OnInit {
  token: string | null = null;
  invalidToken: string | null = null;
  loadingToken: boolean = false;
  error: {message: string, details: string} | null = null;

  constructor(
    private loginService: LoginService,
    private cleverService: CleverService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loadingToken = true;

    // Get the token/invalidToken params from the route
    this.activatedRoute.queryParams
      .subscribe((params: any) => {
          this.token = params.token;
          this.invalidToken = params.invalidToken
        }
      );

    if (this.invalidToken) {
      this.error = {
        message: 'Could not communicate with Clever',
        details: 'We could not communicate with Clever to get a valid login and retrieve your user info'
      }
      this.loadingToken = false;

      return;
    }

    this.cleverService.getUserInfo(this.token || '').pipe(
      switchMap((userInfoObj: Credentials) => {
        // If the user exists, we will get back a credentials object with the wf username set
        if (userInfoObj.wf.username) {
          return of(userInfoObj);
        }

        // If we have not gotten that in the response, we need to create our user
        return this.cleverService.createNewUser(this.token || '');
      })
    ).subscribe({
      next: (userInfoObj: Credentials) => {
        // At this point, we either found our user (by IdP or email) OR we created our user, so login
        this.loadingToken = false;
        this.login(userInfoObj);
      },
      error: (error: any) => {
        this.sessionStorageService.setUserLoggedIn(false);
        this.error = error.error;
        this.loadingToken = false;

        Sentry.captureException(error, {
          tags: {
            section: 'clever-get-user-info',
          }
        });
      }
    });
  }


  // Login by making request via loginFactory to the web services
  // TODO: This is mostly duplicated code to facilitate the login and when we refactor
  //     : for the AngularIO implementation this should be better placed so it is reusable
  //     : in all login locations
  login(credentials: Credentials) {
    // Check to see if the website is out of date - check once every 24 hrs
    this.loginService.checkForUpdate().pipe(
      switchMap((updateRequired: boolean) => {
        if (updateRequired)
        {
          this.error = {
            message: 'A new version is available',
            details: 'A new version of WordFlight is available.'
          }
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        return of(updateRequired);
      }),
      filter((updateRequired: boolean) => !updateRequired),
      switchMap(() => this.loginService.login(credentials.wf.username, '', credentials.sso))
    ).subscribe({
      next: (response: any) => {
        this.loadingToken = false ;
        this.error = null;
        let managementUser = this.sessionStorageService.getUserData();

        if (response.isLoggedIn)
        {
          this.loginService.redirectAfterLogin(managementUser, response) ;
        }
      },
      error: (error: any) => {
        this.loadingToken = false ;
        this.error = {
          message: 'Login failed',
          details: 'An error occurred while trying to login to WordFlight'
        }

        Sentry.captureException(error, {
          tags: {
            section: 'clever-login',
          }
        }) ;
      }
    }) ;
  }
}
