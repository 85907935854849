import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core' ;

import ChartDataLabels from 'chartjs-plugin-datalabels' ;

import * as Sentry from '@sentry/angular';

import { faDiagramSuccessor, faPrint, faUserGraduate, faWarning } from '@fortawesome/free-solid-svg-icons' ;
import { faUncharted } from '@fortawesome/free-brands-svg-icons';

import { ManagementUser } from '../../../../core/models/management-user.model' ;
import { District } from '../../../../core/models/district.model' ;
import { School } from '../../../../core/models/school.model' ;
import { SubscriptionTypes } from '../../../../core/models/subscription-types.model' ;
import { Student } from '../../../../core/models/student.model' ;
import { ReportsService } from '../../../../core/services/reports.service' ;
import { PrintService } from '../../../../core/services/print.service' ;
import { UtilityService } from '../../../../core/services/utility.service' ;
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScoreCutoffs } from '../../../../core/models/score-cutoffs.model';
import { ChartConfiguration,
  ChartData,
  ChartType,
  Chart,
} from 'chart.js';
import { AssessmentScore } from '../../../../core/models/assessment-score.model';
import { AssessmentTypeNames } from '../../../../core/models/assessment-types.model';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'wf-student-summary',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentSummaryComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  schoolFilterError: string = '' ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  students: Student[] = [] ;
  selectedStudent: Student | undefined ;
  fullProduct: string = SubscriptionTypes.FullProduct ;
  currentTeam: string = '' ;
  totalPoints: number = 0 ;
  currentWeeklyPoints: number = 0 ;
  currentUnit: number = 0 ;
  totalUnits: number = 24 ;
  currentLevel: number = 0 ;
  totalLevels: number = 120 ;
  avgWeeklyUsage: number = 0 ;
  minutesThisWeek: number = 0 ;
  minWeeklyTimeGoal: number = 60 ;
  levelsThisWeek: number = 0 ;
  minWeeklyLevelsGoal: number = 8 ;
  minutesWeekPercent: number = 0 ;
  levelsWeekPercent: number = 0 ;
  screenerRange: string = '' ;
  screenerDate: string = '' ;
  screenerClass: string = '' ;
  diagnosticLink: string = '' ;
  diagnostics: any[] = [] ;
  objectives: any[] = [] ;
  orderedColumns: string[][]  =[
    [ 'Objective', '1', 'Short Vowels' ],
    [ 'Objective', '2', 'Long Vowels', 'with Silent e' ],
    [ 'Objective', '3', 'Short Vowels', 'and', 'Long Vowel', 'Digraphs' ],
    [ 'Objective', '4', 'Long Vowel', 'Digraphs,', 'and', 'R Controlled', 'Vowels' ],
    [ 'Objective', '5', 'Diphthongs,', 'Digraphs,', 'R Controlled Vowels' ],
    [ 'Objective', '6', 'Mixed Vowels' ],
  ] ;
  objectiveChartData: ChartData<'bar', { id: number, value: number, inProgress: boolean, notStarted: boolean }[]> = {
    labels: this.orderedColumns,
    datasets: [
      {
        label: 'Pretest',
        data: [
          { id: 1, value: 0, inProgress: false, notStarted: true },
          { id: 2, value: 0, inProgress: false, notStarted: true },
          { id: 3, value: 0, inProgress: false, notStarted: true },
          { id: 4, value: 0, inProgress: false, notStarted: true },
          { id: 5, value: 0, inProgress: false, notStarted: true },
          { id: 6, value: 0, inProgress: false, notStarted: true },
        ],
        backgroundColor: '#7E93EE',
        borderRadius: 4,
        barPercentage: 1.0,
      },
      {
        label: 'Posttest',
        data: [
          { id: 1, value: 0, inProgress: false, notStarted: true },
          { id: 2, value: 0, inProgress: false, notStarted: true },
          { id: 3, value: 0, inProgress: false, notStarted: true },
          { id: 4, value: 0, inProgress: false, notStarted: true },
          { id: 5, value: 0, inProgress: false, notStarted: true },
          { id: 6, value: 0, inProgress: false, notStarted: true },
        ],
        backgroundColor: '#1E3BB8',
        borderRadius: 4,
        barPercentage: 1.0,
      }
    ],
  } ;
  chartPlugins = [ ChartDataLabels ] ;
  chartType: ChartType = 'bar' ;
  chartOpts: ChartConfiguration['options'] = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
            size: 10,
            weight: 'bold',
          }
        },
        grid: {
          lineWidth: 0
        }
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
        },
        title: {
          display: true,
          text: '% Correct',
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
            weight: 'bold',
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        offset: 5,
        textAlign: 'center',
        font: {
          size: 24,
          weight: 'bold',
        },
        color: (context) => {
          const index = context.dataIndex ;
          const value: any = context.dataset.data[index] ;

          if (value.inProgress)
          {
             return '#8094E8' ;
          }
          else if (value.notStarted)
          {
           return '#CCCCCC' ;
          }

          return '#FFFFFF' ;
        },
        formatter: (value, context) => {
          // The only data labels we want to display on our bar chart are 'In Progress' or 'Not Started'
          // but because we have 2 datasets (one for pre and one for post), we *only* want to display one
          // datalabel if we are not started or in progress, so here we test the datasetindex we are iterating
          // through and only display a datalabel if we are in the first data set (pre)
          // NOTE: The whitespace is needed here so it appears centered over both datasets (hack)
          if (value.inProgress && context.datasetIndex === 0) {
            return '        In\n        Progress' ;
          }
          else if (value.notStarted && context.datasetIndex === 0) {
            return '        Not\n        Started' ;
          }

          return '' ;
        }
      }
    },
    parsing: {
      xAxisKey: 'id',
      yAxisKey: 'value',
    },
  } ;
  studentIcon = faUserGraduate ;
  diagnosticIcon = faUncharted ;
  objectiveIcon = faDiagramSuccessor ;
  warningIcon = faWarning ;
  printIcon = faPrint;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective ;
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;
  @ViewChild('objectiveChartCanvas') objectiveChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('objectiveChartImg') objectiveChartImg!: ElementRef<HTMLImageElement> ;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportsService: ReportsService,
    private sessionStorageService: SessionStorageService,
    private utilityService: UtilityService,
    private printService: PrintService,
  ) { }

  ngOnInit(): void {
    let resolvedData = this.route.snapshot.data['resolveData'] ;
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;

    if (resolvedData.erred)
    {
      this.schoolFilterError = resolvedData.message ;
    }
    else
    {
      this.students = resolvedData.students ;
      this.selectedStudent = this.students[0] ;
    }

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts ;
    this.schools = this.route.snapshot.data['filterData'].schools ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser())
    {
      // The FIL user and District user roles can select all schools
      this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    }

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() || this.currentUser!.isSchoolUser())
    {
      // The FIL user and District and School user roles can select all teachers
      this.teachers.unshift(ManagementUser.getGenericUser()) ;
    }

    this.updateStudentSummaryDetails(resolvedData.studentSummaryData) ;
  }

  updateStudentSummaryDetails(summaryData: any) {
    if (!this.selectedStudent) return ;

    let studentGroup = (this.selectedStudent.grade <= 5) ? 'elementary' : 'secondary' ;
    this.diagnosticLink = `/reports/system/diagnostic/${studentGroup}/detail/${this.selectedStudent.userID}` ;
    this.currentTeam = summaryData.summary.currentTeam || '' ;
    this.totalPoints = summaryData.summary.totalPoints ;
    this.currentWeeklyPoints = summaryData.summary.weeklyPoints ;
    this.currentUnit = summaryData.summary.currentUnit ;
    this.currentLevel = summaryData.summary.currentLevel ;
    this.levelsThisWeek = summaryData.summary.currentWeekLevelsCompleted ;
    this.minutesThisWeek = summaryData.summary.usageRangeTotalTime ;
    this.avgWeeklyUsage = summaryData.summary.avgWeeklyUsage ;
    this.minutesWeekPercent = ((this.minutesThisWeek / this.minWeeklyTimeGoal) * 100.0) ;
    this.levelsWeekPercent = ((this.levelsThisWeek / this.minWeeklyLevelsGoal) * 100.0) ;

    // Set our screener info, if exists
    let screener: AssessmentScore = summaryData.assessments.find((assessment: AssessmentScore) => {
      return assessment.assessmentType === AssessmentTypeNames.SCREENER && assessment.dateCompleted ;
    }) ;

    if (!screener)
    {
      this.screenerRange = 'N/A' ;
      this.screenerDate = 'N/A' ;
      this.screenerClass = 'none' ;
    }
    else
    {
      this.screenerRange = `${screener.screenerResultsLow} - ${screener.screenerResultsHigh}` ;
      this.screenerDate = this.utilityService.formatDateNum(screener.dateCompleted!) ;
      if (screener.systemScore < ScoreCutoffs[studentGroup]['automaticity']['some-risk'])
      {
        this.screenerClass = 'score-red' ;
      }
      else if (screener.systemScore < ScoreCutoffs[studentGroup]['automaticity']['proficient'])
      {
        this.screenerClass = 'score-yellow' ;
      }
      else
      {
        this.screenerClass = 'score-green' ;
      }
    }

    let preDiagnostic: AssessmentScore = summaryData.assessments.find((assessment: AssessmentScore) => {
      return assessment.assessmentType === AssessmentTypeNames.PREDIAGNOSTIC && assessment.dateCompleted ;
    }) ;
    let midDiagnostic: AssessmentScore = summaryData.assessments.find((assessment: AssessmentScore) => {
      return assessment.assessmentType === AssessmentTypeNames.MIDDIAGNOSTIC && assessment.dateCompleted ;
    }) ;
    let postDiagnostic: AssessmentScore = summaryData.assessments.find((assessment: AssessmentScore) => {
      return assessment.assessmentType === AssessmentTypeNames.POSTDIAGNOSTIC && assessment.dateCompleted ;
    }) ;

    this.diagnostics = [{
        'title' : 'Pre Diagnostic',
        'recognition' : preDiagnostic ? preDiagnostic.systemScore : '-',
        'decoding' : preDiagnostic ? preDiagnostic.decodingScore : '-',
        'completed' : preDiagnostic ? this.utilityService.formatDateNum(preDiagnostic.dateCompleted!) : 'Not Completed',
        'recognitionClass' : preDiagnostic ? this.reportsService.getDiagnosticClass(studentGroup, preDiagnostic.systemScore) : '',
        'decodingClass' : preDiagnostic ? this.reportsService.getDiagnosticClass(studentGroup, preDiagnostic.decodingScore) : '',
      },
      {
        'title' : 'Mid Diagnostic',
        'recognition' : midDiagnostic ? midDiagnostic.systemScore : '-',
        'decoding' : midDiagnostic ? midDiagnostic.decodingScore : '-',
        'completed' : midDiagnostic ? this.utilityService.formatDateNum(midDiagnostic.dateCompleted!) : 'Not Completed',
        'recognitionClass' : midDiagnostic ? this.reportsService.getDiagnosticClass(studentGroup, midDiagnostic.systemScore) : '',
        'decodingClass' : midDiagnostic ? this.reportsService.getDiagnosticClass(studentGroup, midDiagnostic.decodingScore) : '',
      },
      {
        'title' : 'Post Diagnostic',
        'recognition' : postDiagnostic ? postDiagnostic.systemScore : '-',
        'decoding' : postDiagnostic ? postDiagnostic.decodingScore : '-',
        'completed' : postDiagnostic ? this.utilityService.formatDateNum(postDiagnostic.dateCompleted!) : 'Not Completed',
        'recognitionClass' : postDiagnostic ? this.reportsService.getDiagnosticClass(studentGroup, postDiagnostic.systemScore) : '',
        'decodingClass' : postDiagnostic ? this.reportsService.getDiagnosticClass(studentGroup, postDiagnostic.decodingScore) : '',
      },
    ] ;

    // Determine our Objective data - the API will only return objectives that are complete
    this.objectives = [] ;
    let preScores: { id: number, value: number, inProgress: boolean, notStarted: boolean } [] = [] ;
    let postScores: { id: number, value: number, inProgress: boolean, notStarted: boolean } [] = [] ;
    Object.values(summaryData.objectives).filter((objective: any) => objective.objectiveNumber !== 0).forEach((objective: any, idx: number) => {
      // For each objective determine, for every task we average the pre/post values to get an overall value
      if (!objective.taskData) return ;

      // Hang onto this objective in a special data format to display dates
      // Might want to check on this, we are making the assuming that the start of the objective is when the pretest will be finished and when the
      // objective is completed that is when the posttest is finished
      this.objectives.push({
        isComplete: objective.complete,
        preComplete: objective.formattedStartDate,
        postComplete: objective.formattedEndDate,
      }) ;

      // If this objective is not complete, then it is in-progress
      if (!objective.complete) {
        preScores.push({ id: (idx + 1), value: 0, inProgress: true, notStarted: false }) ;
        postScores.push({ id: (idx + 1), value: 0, inProgress: true, notStarted: false }) ;

        return ;
      }

      let objScore: { [key: string]: number } = Object.values(objective.taskData).reduce((prev: { [key: string]: number }, curr: any) => {
        prev['pre'] += curr.pretest.score ;
        prev['post'] += curr.posttest.score ;

        return  prev ;
      }, { pre: 0, post: 0 }) ;

      preScores.push({ id: (idx + 1), value: Math.round(objScore['pre'] / (Object.keys(objective.taskData).length)), inProgress: false, notStarted: false }) ;
      postScores.push({ id: (idx + 1), value: Math.round(objScore['post'] / (Object.keys(objective.taskData).length)), inProgress: false, notStarted: false }) ;
    }) ;

    // Fill out any not started objectives
    while (preScores.length < 6)
    {
      preScores.push({ id: preScores.length + 1, value: 0, inProgress: false, notStarted: true }) ;
    }

    while (postScores.length < 6)
    {
      postScores.push({ id: postScores.length + 1, value: 0, inProgress: false, notStarted: true }) ;
    }

    this.objectiveChartData.datasets[0].data = preScores ;
    this.objectiveChartData.datasets[1].data = postScores ;
    this.chart?.update() ;
  }

  trackByStudentId(index: number, obj: Student) {
    return obj.userID ;
  }

  printSummary() {
    // Convert charts to images before print:
    this.reportsService.convertCanvasToImage(this.objectiveChartCanvas.nativeElement, this.objectiveChartImg.nativeElement);

    let school = this.reportsService.getSelectedSchoolForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }

  updateTeacherStudents() {
    this.reportsService.getStudentsForTeacher(this.reportsService.getSelectedTeacherForReports().userID).subscribe({
      next: (students) => {
        this.students = students ;
        this.selectedStudent = this.students[0] ;
        this.schoolFilterError = '' ;
        this.updateStudentSummaryData() ;
      },
      error: (error) => {
        this.schoolFilterError = error.message ;

        Sentry.captureException(error, {
          tags: {
            section: 'reports',
            report: 'system-student',
            action: 'update-teacher-students',
            teacherId: this.reportsService.getSelectedTeacherForReports().userID,
          }
        }) ;
      }
    }) ;
  }

  updateStudentSummaryData() {
    if (!this.selectedStudent || !this.selectedStudent.userID || !this.reportsService.getSelectedTeacherForReports()) return ;
    this.reportsService.getStudentSummaryReportData(this.reportsService.getSelectedTeacherForReports().userID, this.selectedStudent.userID).subscribe({
      next: (summaryData) => {
        this.schoolFilterError = '' ;
        this.updateStudentSummaryDetails(summaryData) ;
      },
      error: (error) => {
        this.schoolFilterError = error.message ;

        Sentry.captureException(error, {
          tags: {
            section: 'reports',
            report: 'system-student',
            action: 'update-student-summary-data',
            teacherId: this.reportsService.getSelectedTeacherForReports().userID,
            studentId: (this.selectedStudent) ? this.selectedStudent.userID : 'no-student-selected',
          }
        }) ;
      }
    }) ;
  }

  goToDiagnosticDetails() {
    if (!this.selectedStudent) return ;

    this.sessionStorageService.setDiagnosticDetailsStudent(this.selectedStudent!) ;
    // this.router.navigate([ '/reports/system/diagnostic', ((this.selectedStudent!.grade <= 5) ? 'elementary' : 'secondary'), 'detail', this.selectedStudent.userID]) ;
    this.router.navigate([ '/reports/system/diagnostic' ], { state: { studentId: this.selectedStudent.userID } }) ;
  }

  goToObjectiveDetails() {
    if (!this.selectedStudent) return ;

    this.router.navigate([ '/reports/system/objective' ], { state: { studentId: this.selectedStudent.userID } }) ;
  }

  get currentDate() {
    return this.utilityService.formatDateNum(new Date().getTime()) ;
  }
}
