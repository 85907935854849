import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { faCircleLeft, faCircleRight, faMagnifyingGlass, faPrint, faRotateLeft, faPieChart } from '@fortawesome/free-solid-svg-icons';

import { ChartConfiguration, ChartData, ChartType } from 'chart.js';

import { Student } from '../../../../core/models/student.model';
import { ReportsService } from '../../../../core/services/reports.service';
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { UtilityService } from '../../../../core/services/utility.service';
import { ScoreCutoffs } from '../../../../core/models/score-cutoffs.model';
import { PrintService } from 'src/app/core/services/print.service';

@Component({
  selector: 'wf-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagnosticDetailComponent implements OnInit {
  studentId: number = 0 ;
  studentIdx: number = -1 ;
  student: Student | null = null ;
  canMoveNext: boolean = false ;
  canMovePrev: boolean = false ;
  erred: boolean = false ;
  isElementary: boolean = false ;
  productRoute: string = '' ;
  category: string = '' ;
  wordTitle: string = '' ;
  automaticityReportTitle: string = '' ;
  chartType: ChartType = 'bar' ;
  decodingChartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: {
          autoSkip: false
        }
      },
      y: {
        suggestedMin: 0,
        max: 600,
        ticks: {
          stepSize: 120,
        }
      }
    },
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false,
      },
    }
  } ;
  generalizationChartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: {
          autoSkip: false
        }
      },
      y: {
        suggestedMin: 0,
        max: 100,
        ticks: {
          stepSize: 20,
        },
        title: {
          display: true,
          text: '% Correct'
        }
      }
    },
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false,
      },
    }
  } ;
  detailIcon = faMagnifyingGlass ;
  returnIcon = faRotateLeft ;
  nextIcon = faCircleRight ;
  previousIcon = faCircleLeft ;
  printIcon = faPrint ;
  reportIcon = faPieChart ;

  @ViewChild('printContent') printContent!: ElementRef<HTMLElement>;
  @ViewChildren('decodingBar') decodingBars!: QueryList<ElementRef<HTMLCanvasElement>> ;
  @ViewChildren('decodingImg') decodingImgs!: QueryList<ElementRef<HTMLImageElement>>;
  @ViewChildren('generalizationBar') generalizationBars!: QueryList<ElementRef<HTMLCanvasElement>> ;
  @ViewChildren('generalizationImg') generalizationImgs!: QueryList<ElementRef<HTMLImageElement>>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private sessionStorageService: SessionStorageService,
    private utilityService: UtilityService,
    private printService: PrintService,
  ) { }

  ngOnInit(): void {
    this.student = this.sessionStorageService.getDiagnosticDetailsStudent() ;
    this.studentIdx = this.sessionStorageService.getDiagnosticDetailsStudents().findIndex(student => student.userID === this.student?.userID) ;
    this.canMoveNext = (this.studentIdx !== (this.sessionStorageService.getDiagnosticDetailsStudents().length - 1)) ;
    this.canMovePrev = (this.studentIdx > 0) ;
    // Category defines the student grade category (elementary or secondary) and comes from the route
    this.category = this.route.snapshot.paramMap.get('category')! ;
    this.productRoute = this.route.snapshot.paramMap.get('product')! ;
    this.isElementary = this.category === 'elementary' ;
    if (this.isElementary)
    {
      this.wordTitle = 'Word Recognition' ;
      this.automaticityReportTitle = 'GENERALIZATION and AUTOMATICITY' ;
    }
    else
    {
      this.wordTitle = 'Automatic Word Recognition' ;
      this.automaticityReportTitle = 'GENERALIZATION and AUTOMATICITY' ;
    }

    // Create our instructional zone info
    this.student.assessmentScores?.forEach((assessmentScore) => {
      assessmentScore.instructionalPriorities = `${this.student?.firstName}` ;

      // Calculate instructional priorities
      if (assessmentScore.decodingScore < ScoreCutoffs[this.category]['decoding']['proficient'])
      {
        if (assessmentScore.systemScore < ScoreCutoffs[this.category]['automaticity']['proficient'])
        {
          if (assessmentScore.decodingConsonantOverall < ScoreCutoffs[this.category]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall < ScoreCutoffs[this.category]['vowel']['some-risk'])
          {
            assessmentScore.instructionalPriorities += " has deficiencies in decoding consonants and vowels and in generalization and automaticity." ;
          }
          else if (assessmentScore.decodingConsonantOverall >= ScoreCutoffs[this.category]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall < ScoreCutoffs[this.category]['vowel']['some-risk'])
          {
            assessmentScore.instructionalPriorities += " has deficiencies in decoding vowels and in generalization and automaticity.";
          }
          else if (assessmentScore.decodingConsonantOverall > ScoreCutoffs[this.category]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall > ScoreCutoffs[this.category]['vowel']['some-risk'])
          {
            assessmentScore.instructionalPriorities += " has borderline skills in decoding. Review decoding scores.";
          }
        }
        else
        {
          if (assessmentScore.decodingConsonantOverall < ScoreCutoffs[this.category]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall < ScoreCutoffs[this.category]['vowel']['some-risk'])
          {
            assessmentScore.instructionalPriorities += " has deficiencies in decoding consonants and vowels.";
          }
          else if (assessmentScore.decodingConsonantOverall >= ScoreCutoffs[this.category]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall < ScoreCutoffs[this.category]['vowel']['proficient'])
          {
            assessmentScore.instructionalPriorities += " has deficiencies in decoding vowels.";
          }
          else if (assessmentScore.decodingConsonantOverall < ScoreCutoffs[this.category]['consonant']['proficient'] && assessmentScore.decodingVowelOverall >= ScoreCutoffs[this.category]['vowel']['some-risk'])
          {
            assessmentScore.instructionalPriorities += " has deficiencies in decoding consonants.";
          }
        }
      }
      else
      {
        if (assessmentScore.systemScore < ScoreCutoffs[this.category]['automaticity']['proficient'])
        {
          if (assessmentScore.decodingConsonantOverall >= ScoreCutoffs[this.category]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall >= ScoreCutoffs[this.category]['vowel']['some-risk'])
          {
            assessmentScore.instructionalPriorities += " has deficiencies in automatic word recognition.";
          }
          else if (assessmentScore.decodingConsonantOverall < ScoreCutoffs[this.category]['consonant']['some-risk'] || assessmentScore.decodingVowelOverall < ScoreCutoffs[this.category]['vowel']['some-risk'])
          {
            assessmentScore.instructionalPriorities += " has borderline skills in decoding. Review decoding scores.";
          }
        }
        else
        {
          if (assessmentScore.decodingConsonantOverall >= ScoreCutoffs[this.category]['consonant']['proficient'] && assessmentScore.decodingVowelOverall >= ScoreCutoffs[this.category]['vowel']['proficient'])
          {
            assessmentScore.instructionalPriorities += " has proficiency in decoding, generalization and automaticity.";
          }
          else if (assessmentScore.decodingConsonantOverall < ScoreCutoffs[this.category]['consonant']['proficient'] && assessmentScore.decodingVowelOverall < ScoreCutoffs[this.category]['vowel']['proficient'])
          {
            assessmentScore.instructionalPriorities += " has overall decoding proficiency but has borderline skills in decoding consonants and vowels.";
          }
          else if (assessmentScore.decodingConsonantOverall >= ScoreCutoffs[this.category]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall >= ScoreCutoffs[this.category]['vowel']['proficient'])
          {
            assessmentScore.instructionalPriorities += " has overall decoding proficiency but has borderline skills in decoding consonants.";
          }
          else if (assessmentScore.decodingConsonantOverall >= ScoreCutoffs[this.category]['consonant']['proficient'] && assessmentScore.decodingVowelOverall > ScoreCutoffs[this.category]['vowel']['some-risk'])
          {
            assessmentScore.instructionalPriorities += " has overall decoding proficiency but has borderline skills in decoding vowels.";
          }
        }
      }
    }) ;
  }

  // Here, category is student grouping (elementary or secondary) and type is the score type to get the value for
  getReportIcon(score: number, type: string) {
    if (score < ScoreCutoffs[this.category][type]['some-risk']) return '/assets/images/reportIcons/iconScoreRed.svg' ;
    else if (score < ScoreCutoffs[this.category][type]['proficient']) return '/assets/images/reportIcons/iconScoreYellow.svg' ;
    else return '/assets/images/reportIcons/iconScoreGreen.svg' ;
  }

  formatDate(date: number | undefined) {
    if (!date) return '-' ;

    return this.utilityService.formatDateNum(date) ;
  }

  // Here, category is student grouping (elementary or secondary) and type is the score type to get the value for
  getReportHeaderClass(score: number, type: string) {
    if (score < ScoreCutoffs[this.category][type]['some-risk']) return `${type}-header-red` ;
    else if (score < ScoreCutoffs[this.category][type]['proficient']) return `${type}-header-yellow` ;
    else return `${type}-header-green` ;
  }

  // Here, category is student grouping (elementary or secondary) and type is the score type to get the value for
  getReportBorderClass(score: number, type: string) {
    if (score < ScoreCutoffs[this.category][type]['some-risk']) return `${type}-border-red` ;
    else if (score < ScoreCutoffs[this.category][type]['proficient']) return `${type}-border-yellow` ;
    else return `${type}-border-green` ;
  }

  // Here, category is student grouping (elementary or secondary) and type is the score type to get the value for
  getDecodingCategoryColor(score: number, type: string) {
    if (score < ScoreCutoffs[this.category][type]['some-risk']) return `${type}-red` ;
    else if (score >= ScoreCutoffs[this.category][type]['some-risk'] && score <= ScoreCutoffs[this.category][type]['proficient']) return `${type}-yellow` ;
    else return `${type}-green` ;
  }

  getDecodingDivChartClass() {
    if (window.devicePixelRatio > 1) return 'decodingDiv' ;
    return '' ;
  }

  getGeneralizationDivClass() {
    if (window.devicePixelRatio > 1) return 'generalizationDiv' ;
    return '' ;
  }

  generateDecodingChartData(data: any): ChartData<'bar'> {
    return {
      labels: ['Single', 'Digraph', 'Cluster', 'Single', 'Digraph', 'Cluster', 'Short', 'Dipth & R', 'Digraph', 'Long', 'Short', 'Digraph'],
      datasets: [
        {
          data: data,
          barPercentage: 0.8,
          backgroundColor: 'rgba(121,125,141,1)',
          borderColor: 'rgba(121,125,141,1)',
          hoverBackgroundColor: 'rgba(121, 125, 141, .2)',
          hoverBorderColor: 'rgba(121, 125, 141, .2)',
        }
      ]
    };
  }

  generateGeneralizationChartData(data: any): ChartData<'bar'> {
    return {
      labels: ['Word', 'Nonword', '1 Syl', '2 Syl', '3 Syl', 'Nonspeeded', 'Speeded'],
      datasets: [
        {
          data: data,
          barPercentage: 0.8,
          backgroundColor: 'rgba(121,125,141,1)',
          borderColor: 'rgba(121,125,141,1)',
          hoverBackgroundColor: 'rgba(121, 125, 141, .2)',
          hoverBorderColor: 'rgba(121, 125, 141, .2)',
        }
      ]
    };
  }

  goToSummary() {
    this.router.navigate([ '/reports/', this.productRoute.toLowerCase(), 'diagnostic' ]) ;
  }

  goToNext() {
    // Safety check
    if (this.canMoveNext)
    {
      let nextStudent = this.sessionStorageService.getDiagnosticDetailsStudents()[this.studentIdx + 1]
      this.sessionStorageService.setDiagnosticDetailsStudent(nextStudent) ;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
      this.router.onSameUrlNavigation = 'reload' ;
      this.router.navigateByUrl(`/reports/${this.productRoute.toLowerCase()}/diagnostic/${this.category}/detail/${nextStudent.userID}`) ;
    }
  }

  goToPrev() {
    // Safety Check
    if (this.canMovePrev)
    {
      let prevStudent = this.sessionStorageService.getDiagnosticDetailsStudents()[this.studentIdx - 1]
      this.sessionStorageService.setDiagnosticDetailsStudent(prevStudent) ;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
      this.router.onSameUrlNavigation = 'reload' ;
      this.router.navigateByUrl(`/reports/${this.productRoute.toLowerCase()}/diagnostic/${this.category}/detail/${prevStudent.userID}`) ;
    }
  }

  printDetail() {
    // We can have any number of assessment knowledge/use bar charts, so iterate over our collection and create the
    // img elements from the canvas elements
    this.decodingBars.forEach((decodingBar, idx) => {
      this.reportsService.convertCanvasToImage(decodingBar.nativeElement, this.decodingImgs.get(idx)!.nativeElement);
    }) ;

    this.generalizationBars.forEach((generalizationBar, idx) => {
      this.reportsService.convertCanvasToImage(generalizationBar.nativeElement, this.generalizationImgs.get(idx)!.nativeElement);
    }) ;

    let school = this.reportsService.getSelectedDistrictForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }
}
