import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { concatMap, map, Observable, of, switchMap } from 'rxjs';
import { ReportsService } from '../../../core/services/reports.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectiveResolver  {
  constructor(
    private reportsService: ReportsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.reportsService.getObjectiveData() ;
  }
}
