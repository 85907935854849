import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { ReportsService } from '../../../core/services/reports.service';
import {SubscriptionTypes} from "../../../core/models/subscription-types.model";

@Injectable({
  providedIn: 'root'
})
export class DiagnosticResolver  {
  constructor(
    private reportsService: ReportsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let isSystem = route.data['product'] === SubscriptionTypes.FullProduct ;

    // Diagnostic data needs at least a school selected
    if (schoolID === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      }) ;
    }

    return this.reportsService.getDiagnosticData(isSystem) ;
  }
}
