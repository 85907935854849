<div id="printContent">
  <div class="header-controls">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
  </div>
  <div class="print-hide" *ngIf="showSchoolFilter">
    <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools" [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" (filterChangeEvent)="filterSchoolTeacher($event)"></wf-school-teacher-filter>
  </div>
  <div  [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="report-section-header" style="justify-content: space-between;">
    <h5 class="results-report-header">
      <fa-icon [icon]="dashboardIcon"></fa-icon>
      {{ fullProduct }} Dashboard
    </h5>
    <refresh-button
    (click)="refreshDashboardData()"
    ></refresh-button>
  </div>
  <div class="dashboard-container">
    <div class="teacher-dashboard-grid">
      <div class="teacher-dashboard-box students-enrolled pointer" [routerLink]="'/students'">
        <div class="dashboard-box-label">Students Enrolled</div>
        <div class="dashboard-students-enrolled"></div>
        <div class="dashboard-box-number">{{ numStudentsEnrolled }}</div>
      </div>
      <div class="teacher-dashboard-box students-not-started pointer" (click)="showStudentsWithNoLogins()">
        <div class="dashboard-box-label">Students Not Started</div>
        <div class="dashboard-students-not-started"></div>
        <div class="dashboard-box-number">{{ numStudentsNotLoggedIn }}</div>
      </div>
      <div class="teacher-dashboard-box weekly-time-goal pointer" (click)="showPreviousSessions()">
        <div class="dashboard-box-label">Weekly Time Goal</div>
        <div class="dashboard-students-weekly-time-goal"></div>
        <div class="dashboard-box-number">{{ percentMakingTimeGoal | number: '1.0-0' }}%</div>
        <div class="dashboard-box-mini-goal-label">Previous Week</div>
      </div>
      <div class="teacher-dashboard-box average-weekly-usage">
        <div class="dashboard-box-label">Average Weekly Usage</div>
        <div class="dashboard-students-weekly-usage"></div>
        <div class="dashboard-box-number">{{ avgWeeklyUsageAll }}</div>
        <div class="dashboard-box-number">{{ avgWeeklyUsagePrevious }}</div>
        <div class="dashboard-box-mini-label">All Time</div>
        <div class="dashboard-box-mini-label">Previous Week</div>
      </div>
    </div>
    <div class="teacher-dashboard-middle-grid">
      <div class="teacher-dashboard-box-long total-points pointer" [routerLink]="'/reports/system/summary/group'">
        <div class="dashboard-box-points-label">
          Total Points Earned
          <div class="dashboard-students-points-earned"></div>
        </div>
        <div class="dashboard-box-points-number">{{ numPointsEarned | number: '1.0-0' }}</div>
      </div>
      <div class="teacher-dashboard-box-long weekly-tip">
        <div class="dashboard-box-tip-label">Tip of the Week</div>
        <div class="dashboard-tip">{{ tipOfTheWeek }}</div>
      </div>
    </div>
    <div class="teacher-dashboard-graph unit-graph">
      <div class="dashboard-graph-label">Most Common Current Unit</div>
      <div class="dashboard-bar-graph">
        <canvas baseChart
                [data]="chartData"
                [type]="chartType"
                [options]="chartOptions"></canvas>
      </div>
    </div>
    <ng-template #nologinmodal let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Students with No Logins</h5>
        <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
      </div>
      <div class="modal-body">
        <ul class="no-bullet">
          <li *ngFor="let student of studentsWithNoLogins">{{ student.firstName }} {{ student.lastName }}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn action-button" (click)="modal.dismiss()">Close</button>
      </div>
    </ng-template>
    <ng-template #previousmodal let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Previous Week Sessions</h5>
        <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
      </div>
      <div class="modal-body">
        <table class="table table-sm ac-dashboard weekly-usage-goal">
          <thead>
            <tr>
              <th scope="col">Student</th>
              <th scope="col">Weekly Usage (min)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let session of studentsWithSessionTimes">
              <td [class]="{ 'met-goal': session.student.metGoal, 'no-met-goal': !session.student.metGoal }">
                {{ session.student.firstName }} {{session.student.lastName }}
              </td>
              <td>
                {{ getDisplaySessionTime(session.sessionTime) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn action-button" (click)="modal.dismiss()">Close</button>
      </div>
    </ng-template>
  </div>
</div>
