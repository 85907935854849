import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';
import {
  faBan,
  faCalendarDay,
  faCalendarDays,
  faCalendarPlus,
  faRotateLeft,
  faShare,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { DiagnosticTypes } from '../../core/models/assessment-types.model';
import { DiagnosticScheduleService } from '../../core/services/diagnostic-schedule.service';
import { UtilityService } from '../../core/services/utility.service';
import { FormControl } from '@angular/forms';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'wf-diagnostic-schedule',
  templateUrl: './diagnostic-schedule.component.html',
})
export class DiagnosticScheduleComponent implements OnInit {

  allScheduleData: any = {};
  diagnosticOptions: string[] = ['Pre', 'Mid', 'Post'];
  disableButtons: boolean = true;
  showScheduleDialog: boolean = false;
  showError: boolean = false;
  orderByField: any | null = null;
  reverseSort: boolean = false;
  selectedDiagnosticType: string = '';
  scheduleList: any[] = [];
  errorMsg: string[] = [];
  select: boolean = false;
  selectedDate: FormControl = new FormControl({
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate(),
  });
  minDate: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate(),
  };
  maxDate: NgbDateStruct = {
    year: (new Date()).getFullYear() + 10,
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate(),
  }
  calendarIcon = faCalendarDays;
  goBackIcon = faRotateLeft ;
  skipIcon = faShare ;
  scheduleIcon = faCalendarPlus ;
  defaultIcon = faCalendarDay ;
  cancelIcon = faBan ;
  enrollIcon = faUserGroup ;

  constructor(
    private diagnosticScheduleService: DiagnosticScheduleService,
    private utilityService: UtilityService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // TODO: could maybe make a resolver to get the info here too
    // Get the access code student scheduling data to populate the table
    this.diagnosticScheduleService.getFullProductStudentsForScheduling().subscribe({
      next: (data: any) => {
        this.allScheduleData = data;
        this.insertDefaultScheduleNames(this.allScheduleData);
        this.convertStudentGrades(this.allScheduleData);
        this.selectedDiagnosticType = this.diagnosticOptions[0];
        this.scheduleList = data[this.selectedDiagnosticType];
      },
      error: (error: any) => {
        this.errorMsg.push("There was an error getting the assessment informaiton. Please try again later.");
        this.showError = true;

        Sentry.captureException(error, {
          tags: {
            section: 'subscriptions',
            action: 'get-students-for-scheduling',
          }
        }) ;
      }
    });
  }

  goBack() {
    this.router.navigateByUrl('/students/manage-subscriptions');
  }


  insertDefaultScheduleNames(allScheduleData: any) {
    let defaultScheduleString: string = '';
    Object.keys(allScheduleData).forEach((assessmentKey) => {
      if (assessmentKey == 'Pre') {
        defaultScheduleString = 'Before Objective 1';
      } else if (assessmentKey == 'Mid') {
        defaultScheduleString = 'After Objective 3';
      } else if (assessmentKey == 'Post') {
        defaultScheduleString = 'After Objective 6';
      }

      let assessmentScheduleData = allScheduleData[assessmentKey];
      for (let i = 0; i < assessmentScheduleData.length; i++) {
        let scheduleData = assessmentScheduleData[i];
        if (scheduleData.schedule == null) {
          scheduleData.schedule = (scheduleData.status !== 'Skipped') ? defaultScheduleString : '---';
        }
      }
    });
  }

  // Convert grade values from the backend (1-13) to student grades (K-12) for display
  convertStudentGrades(allScheduleData: any) {
    Object.keys(allScheduleData).forEach((assessmentKey) => {
      let assessmentScheduleData = allScheduleData[assessmentKey];
      for (let i = 0; i < assessmentScheduleData.length; i++) {
        let scheduleData = assessmentScheduleData[i];
        scheduleData.displayGrade = this.utilityService.getStudentGrade(scheduleData.grade);
      }
    });
  }

  // Creates the request data to be sent when changing the diagnostic schedule for a group of students
  createScheduleData(skipped: boolean, scheduledDate: Date | null) {
    let scheduleData: any = {};
    // Get all selected Student IDs:
    let studentIDs: number[] = [];
    let subscriptionIDs: number[] = [];
    for (let i = 0; i < this.scheduleList.length; i++) {
      let studentSchedule = this.scheduleList[i];
      if (studentSchedule.selected) {
        studentIDs.push(studentSchedule.userID);
        subscriptionIDs.push(studentSchedule.subscriptionID);
      }
    }

    scheduleData.studentIDs = studentIDs;
    scheduleData.subscriptionIDs = subscriptionIDs;
    scheduleData.scheduledDate = (scheduledDate) ? scheduledDate.getTime() : null;
    scheduleData.assessmentType = DiagnosticTypes[this.selectedDiagnosticType];
    scheduleData.skipped = skipped;
    return scheduleData;
  }

  // Updates the client to match the changes on the host on successful saving of diagnostic schedules
  updateScheduleTable(updatedScheduleData: any) {
    updatedScheduleData.studentIDs.forEach((studentID: number) => {
      this.scheduleList.forEach((studentSchedule: any) => {
        if (studentSchedule.userID == studentID) {
          // Update the schedule information
          studentSchedule.schedule = (updatedScheduleData.scheduledDate != null) ? this.utilityService.formatDate(updatedScheduleData.scheduledDate) : null;
          studentSchedule.status = (updatedScheduleData.skipped) ? 'Skipped' : 'Not Started';
        }
      });
    });
    this.insertDefaultScheduleNames(this.allScheduleData);
  }

  generateErrorMsg(errorText: string) {
    // TODO: Show different error messages based on statusCode
    this.errorMsg = [];
    if (errorText === '' || errorText == null) {
      this.errorMsg.push('An error occurred while saving a new diagnostic schedule.');
    }
    else {
      var errors = errorText.split('\n');
      errors.forEach((error) => {
        this.errorMsg.push(error);
      });
    }
    this.showError = true;
  }

  // Show different data when a new assessment type is selected from the dropdown
  loadDiagnosticScheduleData() {
    // Deselect any existing selections from the previous diagnostic type
    this.disableButtons = true;
    this.select = false;
    this.scheduleList.forEach((assessmentSchedule: any) => {
      assessmentSchedule.selected = false;
    });

    this.scheduleList = this.allScheduleData[this.selectedDiagnosticType];
  }

  openScheduleDialog() {
    this.showError = false;
    this.errorMsg = [];
    this.showScheduleDialog = true;
  }

  /**
    * Schedule or skip the selected diagnostic assessment for the group of selected students
    * @param {boolean} skipped Whether or not the diagnostic will be set to 'Skip'
    * @param {Date} scheduledDate [OPTIONAL] If specified, the Date to schedule the diagnostic
    */
  scheduleDiagnostic(skipped: boolean, scheduledDate?: NgbDateStruct) {
    this.showError = false;
    let scheduleData: any = {};
    if (scheduledDate) {
      let scheduledDateObj = new Date(scheduledDate.year, scheduledDate.month - 1, scheduledDate.day);
      scheduledDateObj.setHours(0);
      scheduledDateObj.setMinutes(0);
      scheduledDateObj.setSeconds(0);
      scheduledDateObj.setMilliseconds(0);
      scheduleData = this.createScheduleData(skipped, scheduledDateObj);
    }
    else {
      scheduleData = this.createScheduleData(skipped, null);
    }

    this.diagnosticScheduleService.editDiagnosticSchedule(scheduleData).subscribe({
      next: () => {
        this.updateScheduleTable(scheduleData);
        // Close the scheduling dialog if open
        if (this.showScheduleDialog) {
          this.showScheduleDialog = false;
        }
      },
      error: (error) => {
        this.generateErrorMsg(error.error.message);

        Sentry.captureException(error, {
          tags: {
            section: 'subscriptions',
            action: 'schedule-diagnostic',
            studentIds: scheduleData.studentIDs.join(','),
            subscriptionIds: scheduleData.subscriptionIDs.join(','),
            skipped: skipped,
            scheduledDate: scheduleData.scheduledDate,
          }
        }) ;
      }
    });
  }

  //
  // Select checkbox functionality
  selectAll(event: any) {
    this.select = event.target.checked;
    for (let i = 0; i < this.scheduleList.length; i++) {
      let selected = this.select;
      if (selected && (this.shouldHideCheckbox(this.scheduleList[i].status))) {
        selected = false;
      }
      this.scheduleList[i].selected = selected;
    }
    this.disableButtons = !this.select;
  }

  enableButtonsIfSelected(event: any) {
    if (event.target.checked) {
      this.disableButtons = false;
    }
    else {
      // Check to see if any other assessment schedules are still currently selected
      let assessmentsAreSelected = false;
      for (let i = 0; i < this.scheduleList.length; i++) {
        if (this.scheduleList[i].selected) {
          assessmentsAreSelected = true;
          break;
        }
      }
      this.disableButtons = !assessmentsAreSelected;

      // Uncheck the Select All checkbox if no assessments are currently selected
      if (!assessmentsAreSelected) {
        this.select = false;
      }
    }
  }

  shouldHideCheckbox(status: string) {
    return (status.toUpperCase() === 'COMPLETED' || status.toUpperCase() === 'SKIPPED (IN PROGRESS)') ;
  }

  sortTable(columnName: string) {
    this.reverseSort = (this.orderByField === columnName) ? !this.reverseSort : false;
    this.orderByField = columnName;
    this.scheduleList = (this.reverseSort) ? _.sortBy(this.scheduleList, columnName).reverse() : _.sortBy(this.scheduleList, columnName);
  }

  setScheduleDiagnostic() {
    if (this.selectedDate.valid) {
      this.scheduleDiagnostic(false, this.selectedDate.value);
    } else {
      this.errorMsg.push('There was an error validating your selected scheduled date');
      this.cancel();
    }
  }

  cancel() {
    this.showScheduleDialog = false;
    this.showError = false;
  }
}
