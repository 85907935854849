import { Injectable } from '@angular/core';

import { Adapter } from '../adapter';

export class Assessment {
  studentID: number ;
  subscriptionID: number ;
  dateStarted: number ;
  dateCompleted: number ;
  expirationDate: number | null ;
  curriculum: string | null ;
  subscriptionType: number ;
  assessmentType: number ;
  skipped: boolean ;
  numDiagnosticTasks: number ;
  numDiagnosticTasksCompleted: number ;
  isScreener: boolean ;
  isACInstructional: boolean
  isPreDiagnostic: boolean ;
  isMidDiagnostic: boolean ;
  isPostDiagnostic: boolean ;
  isDiagnostic: boolean ;
  state: string ;
  formattedDateStarted?: string ;
  formattedDateCompleted?: string ;
  testWindow?: string ;

  constructor(item: any) {
    this.studentID = item.studentID ;
    this.subscriptionID = item.subscriptionID ;
    this.dateStarted = item.dateStarted ;
    this.dateCompleted = item.dateCompleted ;
    this.expirationDate = item.expirationDate ;
    this.curriculum = item.curriculum ;
    this.subscriptionType = item.subscriptionType ;
    this.assessmentType = item.assessmentType ;
    this.skipped = item.skipped ;
    this.numDiagnosticTasks = item.numDiagnosticTasks ;
    this.numDiagnosticTasksCompleted = item.numDiagnosticTasksCompleted ;
    this.isScreener = item.isScreener ;
    this.isACInstructional = item.isACInstructional ;
    this.isPreDiagnostic = item.isPreDiagnostic ;
    this.isMidDiagnostic = item.isMidDiagnostic ;
    this.isPostDiagnostic = item.isPostDiagnostic ;
    this.isDiagnostic = item.isDiagnostic ;
    this.state = item.state ;
    if (item.formattedDateStarted) this.formattedDateStarted = item.formattedDateStarted ;
    if (item.formattedDateCompleted) this.formattedDateCompleted = item.formattedDateCompleted ;
    if (item.testWindow) this.testWindow = item.testWindow ;
  }

  isAssessmentExpired(): boolean {
    let pastExpirationDate = false;
    if (this.expirationDate != null) {
      let expirationDate = new Date(this.expirationDate);
      let currentDate = new Date();

      pastExpirationDate = currentDate.getTime() > expirationDate.getTime();
    }

    return pastExpirationDate;
  }

  getAssessmentType(): string {
    let assessmentType: string = '' ;

    if (this.isScreener)
    {
      assessmentType = 'Screener' ;
    }
    else if (this.isDiagnostic)
    {
      assessmentType = 'Diagnostic ' + this.testWindow ;
    }
    else if (this.isPreDiagnostic)
    {
      assessmentType = 'Pre Diagnostic' ;
    }
    else if (this.isMidDiagnostic)
    {
      assessmentType = 'Mid Diagnostic' ;
    }
    else if (this.isPostDiagnostic)
    {
      assessmentType = 'Post Diagnostic' ;
    }
    else if (this.isACInstructional)
    {
      assessmentType = 'Instruction' ;
    }

    return assessmentType ;
  }

  isCompletedInWindow(testWindow: string): boolean {
    return (this.state === 'Completed' && this.testWindow === testWindow) ;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AssessmentAdapter implements Adapter<Assessment> {
  adapt(item: any): Assessment {
    return new Assessment(item) ;
  }
}
