<div id="printContent" style="color: #333;">
  <div class="header-controls">
    <h3><fa-icon [icon]="supportIcon"></fa-icon> Feedback</h3>
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">
    {{errorMessage}}
  </div>
  <div *ngIf="noSurveys">
    <div class="alert alert-secondary message" style="background-color: rgba(211, 211, 211, .4) ;">
      <div class="icon">
        <fa-icon [icon]="infoIcon"></fa-icon>
      </div>
      <div>
        <div class="bold">No Surveys</div>
        It doesn't look like there are any Surveys in the system at this time. Thank you for your willingness to provide feedback,
        please check back later!
      </div>
    </div>
  </div>
  <div *ngIf="!noSurveys && !timeToTake">
    <div class="alert alert-secondary message" style="background-color: rgba(211, 211, 211, .4) ;">
      <div class="icon">
        <fa-icon [icon]="infoIcon"></fa-icon>
      </div>
      <div>
        <div class="bold">No Surveys to Take</div>
        It doesn't look like there are any Surveys that are available for you to take at this time. Thank you for your willingness to provide feedback,
        please check back later!
      </div>
    </div>
  </div>
  <div *ngIf="surveyError">
    <div class="alert alert-danger message">
      <div class="icon">
        <fa-icon [icon]="errorIcon"></fa-icon>
      </div>
      <div>
        <div class="bold">Problem Loading Survey</div>
        It looks like we are having trouble loading the Survey for you to take. Please contact us to let us know and we will fix the issue. Thank you!
      </div>
    </div>
  </div>
  <div *ngIf="surveyLoading">
    <fa-icon [icon]="loadingIcon" [spin]="true"></fa-icon> Loading survey...
  </div>
  <div *ngIf="timeToTake && !noSurveys && !submitted && !surveyError">
    <form #form="ngForm" (ngSubmit)="onSubmit(form)">
      <div *ngFor="let field of formFields" style="margin-bottom: 20px;">
        <label [for]="field.name" [innerHTML]="field.label" [ngStyle]="{ 'display' : (field.name === 'email') ? 'none' : 'inline-block' }"></label>
        <div [ngSwitch]="field.fieldType">
          <input *ngSwitchCase="'text'" type="text"
            [name]="field.name"
            [ngModel]="getDynamicValue(field.name)"
            (ngModelChange)="setDynamicValue(field.name, $event)"
            style="margin-bottom: 16px; width: 35%; display: none;">
          <input *ngSwitchCase="'phonenumber'" type="tel" [name]="field.name" [(ngModel)]="form.value[field.name]" style="margin-bottom: 16px;" required>
          <div *ngSwitchCase="'radio'">
            <div *ngFor="let option of field.options">
              <input type="radio" [name]="field.name" [value]="option.value" [(ngModel)]="form.value[field.name]" style="margin-bottom: 10px;" required> {{ option.label }}
            </div>
          </div>

          <textarea *ngSwitchCase="'textarea'"></textarea>
        </div>
        <ng-container *ngFor="let depFieldFilter of field.dependentFieldFilters">
          <ng-container *ngIf="depFieldFilter.filters.length && depFieldFilter.filters[0].strValues.includes(form.value[field.name])">
            <label [for]="depFieldFilter.dependentFormField.name" [innerHTML]="depFieldFilter.dependentFormField.label" [ngStyle]="{ 'display' : (depFieldFilter.dependentFormField.name === 'email') ? 'none' : 'inline-block' }"></label>
            <div [ngSwitch]="depFieldFilter.dependentFormField.fieldType">
              <input *ngSwitchCase="'text'" type="text"
                     [name]="depFieldFilter.dependentFormField.name"
                     style="margin-bottom: 16px; width: 35%; display: none;">
              <input *ngSwitchCase="'phonenumber'" type="tel" [name]="depFieldFilter.dependentFormField.name" [(ngModel)]="form.value[depFieldFilter.dependentFormField.name]" style="margin-bottom: 16px;" required>
              <div *ngSwitchCase="'radio'">
                <div *ngFor="let option of depFieldFilter.dependentFormField.options">
                  <input type="radio" [name]="depFieldFilter.dependentFormField.name" [value]="option.value" [(ngModel)]="form.value[depFieldFilter.dependentFormField.name]" style="margin-bottom: 10px;" required> {{ option.label }}
                </div>
              </div>
              <textarea *ngSwitchCase="'textarea'" [name]="depFieldFilter.dependentFormField.name" [(ngModel)]="form.value[depFieldFilter.dependentFormField.name]" style="width: 50% ; padding: 5px" rows="5"></textarea>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <button type="submit" class="btn action-button" [disabled]="form.invalid">
        <fa-icon [icon]="sendIcon"></fa-icon> Send
      </button>
    </form>
  </div>

  <div *ngIf="submitted" style="margin-top: 50px;">
    <div class="alert alert-success message">
      <div class="icon">
        <fa-icon [icon]="successIcon"></fa-icon>
      </div>
      <div>
        <div class="bold">Message Received!</div>
        <div [innerHTML]="responseMessage"></div>
      </div>
    </div>
  </div>
</div>
