<div class="kb-container">
  <ng-container *ngFor="let item of resources">
    <wf-knowledge-base-item
      (click)="goToItem(item)"
      [title]="item.title"
      [description]="item.description"
      [thumbnail]="item.icon">
    </wf-knowledge-base-item>
  </ng-container>
</div>
