import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.SENTRY_DSN,
  release: environment.versionNumber,
  environment: environment.production ? 'production' : 'dev',
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
