import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, catchError, map } from 'rxjs';

import { SessionStorageService } from './session-storage.service';
import { environment } from '../../../environments/environment';
import { Student, StudentEnrollData, StudentAdapter } from '../models/student.model';
import { SchoolClass } from '../models/school-class.model';
import { ManagementUser } from '../models/management-user.model';

const REQ_OPTS = {
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
}

@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {

  constructor(
    private httpClient: HttpClient,
    private studentAdapter: StudentAdapter,
    private sessionStorageService: SessionStorageService
  ) { }

  private getRemainingLicensesCount(teacherID?: number | null, schoolID?: number | null, subscriptionType?: string): Observable<number> {
    let currentUser: ManagementUser | null = this.sessionStorageService.getUserData();
    if (currentUser === null) return of(0) ;

    if (teacherID === null) {
      teacherID = currentUser.userID;
    }
    if (schoolID === null) {
      schoolID = currentUser.schoolID;
    }

    if (teacherID! > 0) {
      return this.httpClient.get<number>(environment.dataServicesURL + 'manage/enroll/teacher/' + teacherID + '/school/' + schoolID +'/NumberUnassignedSubscriptions/' + subscriptionType, REQ_OPTS);
    } else {
      return of(0);
    }
  }

  assignDiagnosticSubscriptionToStudents(studentIDs: number[]) {
    let teacherID = this.sessionStorageService.getUserData()!.userID ;
    let ids = studentIDs.join(',') ;

    return this.httpClient.put(`${environment.dataServicesURL}manage/enroll/students/assigndiagnostics/${teacherID}/${ids}`, {}, REQ_OPTS) ;
  }

  assignFullProductSubscriptionToStudents(studentIDs: number[]) {
    let teacherID = this.sessionStorageService.getUserData()!.userID ;
    let ids = studentIDs.join(',') ;

    return this.httpClient.put(`${environment.dataServicesURL}manage/enroll/students/assignintervention/${teacherID}/${ids}`, {}, REQ_OPTS) ;
  }

  getGradeOptions() {
    return this.httpClient.get(environment.dataServicesURL + 'manage/enroll/options/grade', REQ_OPTS);
  }

  getClassOptions(): Observable<SchoolClass[]> {
    return this.httpClient.get<SchoolClass[]>(environment.dataServicesURL + 'manage/enroll/options/class', REQ_OPTS);
  }

  getGenderOptions() {
    return this.httpClient.get(environment.dataServicesURL + 'manage/enroll/options/gender', REQ_OPTS);
  }

  getAssessmentTypeOptions() {
    return this.httpClient.get(environment.dataServicesURL + 'manage/enroll/options/assessmentTypes', REQ_OPTS);
  }

  getCurriculumOptions(gradeEnum: number) {
    return this.httpClient.get(environment.dataServicesURL + 'manage/enroll/options/curriculum/' + gradeEnum, REQ_OPTS)
  }

  getRemainingDiagnosticLicensesCount(teacherID?: number | null, schoolID?: number | null): Observable<number> {
    return this.getRemainingLicensesCount(teacherID, schoolID, 'Diagnostic');
  }

  getRemainingInterventionLicensesCount(teacherID: number | null, schoolID: number | null): Observable<number> {
    return this.getRemainingLicensesCount(teacherID, schoolID, 'Intervention');
  }

  getStudents(): Observable<Student[]> {
    return this.httpClient.get<Student[]>(`${environment.dataServicesURL}manage/enroll/students/managesubscriptions`, REQ_OPTS).pipe(
      map((data: any) => {
        let students: Student[] = [] ;

        data.forEach((student: any) => {
          students.push(this.studentAdapter.adapt(student)) ;
        }) ;

        return students ;
      })
    ) ;
  }

  sendEnrollmentInfo(studentData: StudentEnrollData): any {
    return this.httpClient.post(environment.dataServicesURL + 'manage/enroll/student/add', studentData, REQ_OPTS)
      .pipe(
        catchError((err: any) => {
          return of(err);
        }),
        map((res: any) => {
          return res;
        })
      );
  }

  updateStudentInfo(studentData: Student): Observable<any> {
    return this.httpClient.put(environment.dataServicesURL + 'manage/enroll/student/' + studentData.userID, studentData, REQ_OPTS)
      .pipe(
        catchError((err: any) => {
          return of(err);
        })
      );
  }

  addStudentList(csvFile: FormData) {
    return this.httpClient.post(environment.dataServicesURL + 'manage/enroll/student/add/list', csvFile, REQ_OPTS)
  }

  undoBatchEnrollment() {
    return this.httpClient.post(environment.dataServicesURL + 'manage/enroll/student/undo/list', {}, REQ_OPTS);
  }
}
