import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wf-report-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.css']
})
export class GaugeComponent implements OnInit {
  @Input() percent: number = 0 ;
  @Input() color: string = '' ;
  x: number = 1000 ;
  y: number = 480 ;
  gaugeEnd: number = 50 ;
  gaugeStart: number = this.x - this.gaugeEnd ;
  radius: number = 450 ;
  startAngle: number = -90 ;
  angle: number = 0 ;
  angleRadians: number = 0 ;
  startAngleRadians: number = 0 ;
  startX: number = 0 ;
  startY: number = 0 ;
  endX: number = 0 ;
  endY: number = 0 ;
  gaugeFiller: string = '' ;
  gaugeComplete: string = '' ;

  constructor() { }

  ngOnInit(): void {
    this.calculateValues() ;
  }

  calculateValues()
  {
    this.angle = (this.percent / 100) * 180 - 90 ;
    this.angleRadians = (this.angle - 90) * Math.PI / 180.0;
    this.startAngleRadians = (this.startAngle - 90) * Math.PI / 180.0;
    this.startX = Math.round(((this.x / 2) + (this.radius * Math.cos(this.angleRadians))) * 100) / 100 ;
    this.startY = Math.round((this.y + (this.radius * Math.sin(this.angleRadians))) * 100) / 100 ;
    this.endX = Math.round(((this.x / 2) + (this.radius * Math.cos(this.startAngleRadians))) * 100) / 100 ;
    this.endY = Math.round((this.y + (this.radius * Math.sin(this.startAngleRadians))) * 100) / 100 ;
    this.gaugeFiller = `M ${this.gaugeStart} ${this.y} A ${this.radius} ${this.radius} 0 0 0 ${this.gaugeEnd} ${this.y}` ;
    this.gaugeComplete = `M ${this.startX} ${this.startY} A ${this.radius} ${this.radius} 0 0 0 ${this.endX} ${this.endY}` ;
  }
}
