import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { faArrowLeft, faCircleCheck, faCircleExclamation, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { ControlValuesMatchValidator } from '../../core/validators/control-values-match.directive';
import { LoginService } from '../../core/services/login.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  showSaveControls: boolean = true ;
  showNewPassword: boolean = false ;
  showConfirmPassword: boolean = false ;
  resetError: boolean = false ;
  resetSuccess: boolean = false ;
  token: string | null = '' ;
  error: string = '' ;
  resetIcon = faRotateLeft ;
  resetSuccessIcon = faCircleCheck ;
  resetErrorIcon = faCircleExclamation ;
  backIcon = faArrowLeft ;

  resetPasswordForm = this.fb.group({
    newPassword: ['', Validators.compose([
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(20),
      Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9_!?]){8,20}$')
    ])],
    confirmPassword: [ '', Validators.required ]
  }, {
    validator: ControlValuesMatchValidator('newPassword', 'confirmPassword'),
  }) ;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token') ;
  }

  changePassword() {
    if (this.newPassword?.value.trim() === '' || this.confirmPassword?.value.trim() === '' || this.newPassword?.value !== this.confirmPassword?.value)
    {
      // Sanity check, make sure our passwords match
      return ;
    }

    this.resetSuccess = false ;
    this.resetError = false ;
    this.error = '' ;

    this.loginService.changePasswordViaToken(this.token!, this.newPassword!.value).subscribe({
      next: (status) => {
        if (status === 200)
        {
          this.resetError = false ;
          this.resetSuccess = true ;
        }
        else
        {
          this.resetError = true ;
          this.resetSuccess = false ;

          if (status >= 500)
          {
            this.error = 'The application is temporarily unavailable. Please wait a few moments and try again.' ;
          }
          else if (status === 400)
          {
            this.error = 'The new password does not meet password requirements and is invalid.' ;
          }
          else if (status === 401)
          {
            this.error = 'The password reset request is invalid or has expired.'
          }
        }
      }
    }) ;
  }

  // Utility methods to get our form controls
  get newPassword() {
    return this.resetPasswordForm.get('newPassword')
  }

  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword')
  }
}
