<div class="video-background" [hidden]="!viewResourceVideo"></div>
<div class="videogular-container" [hidden]="!viewResourceVideo">
  <div class="exit-video" (click)="onCompleteVideo()"></div>
  <vg-player (onPlayerReady)="onPlayerReady($event)" class="video-container">
    <vg-controls>
      <vg-play-pause></vg-play-pause>
      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
      </vg-scrub-bar>
      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
      <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video
      [src]="videoSource"
      [vgMedia]="$any(videoPlayerElement)"
      #videoPlayerElement
      preload="auto"
      playsinline
      autoplay
    >
    </video>
  </vg-player>
</div>
<div>
  <div [innerHTML]="resourceContent | safeHtml"></div>
</div>
