<div #printContent id="printContent">
  <div *ngIf="!isSchoolReportsUser">
    <div class="print-hide">
      <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools" [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" [showTeacher]="false" (filterChangeEvent)="filterSchoolTeacher($event)"></wf-school-teacher-filter>
    </div>
  </div>
  <div [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="district-report-content">
    <div class="district-report-header">
      <div class="district-report-header-group">
        <span class="report">Diagnostic</span>
        <div class="print-hide">
          <input type="radio" value="automaticity" id="automaticity-type" [(ngModel)]="diagnosticType" (change)="setDiagnosticTypeValues()" />
          <label for="automaticity-type" style="margin-left: 5px ;">(Automatic) Word Recognition</label>
        </div>
        <div class="print-hide">
          <input type="radio" value="decoding" id="diagnostic-type" [(ngModel)]="diagnosticType" (change)="setDiagnosticTypeValues()" />
          <label for="diagnostic-type" style="margin-left: 5px ;">Decoding (Grades 5 and Up Only)</label>
        </div>
        <div class="print-show" style="text-transform: capitalize ;">{{ diagnosticType }}</div>
      </div>
      <button id="printButton" class="btn print-summary-button" (click)="printReport()" *ngIf="!currentUser!.isTeacherReviewUser()"><fa-icon [icon]="printIcon"></fa-icon> Print</button>
    </div>
    <div *ngIf="loadError" class="alert message alert-danger">{{ loadError }}</div>
    <div class="district-report-row">
      <div class="district-report-column width-100">
        <div class="district-report-panel" id="district-report-diagnostic-progress">
          <div class="title split-flex">
            <div>Student Progress</div>
            <div class="print-hide">
              Compare Tests
              <select [(ngModel)]="comparisonGroup" (change)="setDiagnosticTypeValues()">
                <option *ngFor="let cgroup of comparisonGroups; trackBy: trackByComparisonId" [ngValue]="cgroup">{{ cgroup.display }}</option>
              </select>
            </div>
            <div class="print-show">
              Compare Tests: {{ comparisonGroup.display }}
            </div>
          </div>
          <div class="district-report-row margin-top-10">
            <div class="district-report-column width-50 diagnostic-progress-column">
              <div class="diagnostic-progress-panel">
                <div>High Risk Moving to Some Risk</div>
                <div class="diagnostic-progress-info">
                  <div class="display">
                    <img src="/assets/images/reportIcons/iconManagement-ProgressHighToSome.svg"/>
                  </div>
                  <div class="stat-tile">
                    <div class="stat">
                      <div class="stat-value">{{ highToSomePercentDisplay }}%</div>
                      <div class="stat-label">{{ highToSomeCount }} Students</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="diagnostic-progress-panel">
                <div>High Risk Moving to Proficient</div>
                <div class="diagnostic-progress-info">
                  <div class="display">
                    <img src="/assets/images/reportIcons/iconManagement-ProgressHighToProficient.svg"/>
                  </div>
                  <div class="stat-tile">
                    <div class="stat">
                      <div class="stat-value">{{ highToProficientPercentDisplay }}%</div>
                      <div class="stat-label">{{ highToProficientCount }} Students</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="district-report-column width-50 diagnostic-progress-column">
              <div class="diagnostic-progress-panel">
                <div>Some Risk Moving to Proficient</div>
                <div class="diagnostic-progress-info">
                  <div class="display">
                    <img src="/assets/images/reportIcons/iconManagement-ProgressSomeToProficient.svg"/>
                  </div>
                  <div class="stat-tile">
                    <div class="stat">
                      <div class="stat-value">{{ someToProficientPercentDisplay }}%</div>
                      <div class="stat-label">{{ someToProficientCount }} Students</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="diagnostic-progress-panel">
                <div>Remained in Same Category</div>
                <div class="diagnostic-progress-info">
                  <div class="stat-tile">
                    <div class="stat">
                      <div class="stat-value">{{ samePercentDisplay }}%</div>
                      <div class="stat-label">{{ sameCount }} Students</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="district-report-row page-break-after">
      <div class="district-report-column width-100">
        <div class="district-report-panel" id="district-report-diagnostic-performance">
          <div class="title">Performance</div>
          <div class="district-report-row">
            <div class="district-report-column width-100 diagnostic-performance-panel">
              <div class="labels">
                <div class="perf-label high-risk">
                  <div class="icon image-label">High Risk</div>
                </div>
                <div class="perf-label some-risk">
                  <div class="icon image-label">Some Risk</div>
                </div>
                <div class="perf-label proficient">
                  <div class="icon image-label">Proficient</div>
                </div>
              </div>
              <div class="district-report-row performance-row">
                <div class="district-report-column performance-row-label-group width-20">
                  <div class="performance-row-label">Pre-test</div>
                  <div class="performance-row-value">({{ preTotal }} students)</div>
                </div>
                <div class="performance-row-bars">
                  <div *ngIf="preHighPercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : preHighPercentage + '%' }">
                    <div class="performance-row-bar high-risk">{{ preHighPercentDisplay }}%</div>
                    <div class="performance-row-value">({{ preHighTotal }})</div>
                  </div>
                  <div *ngIf="preSomePercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : preSomePercentage + '%' }">
                    <div class="performance-row-bar some-risk">{{ preSomePercentDisplay }}%</div>
                    <div class="performance-row-value">({{ preSomeTotal }})</div>
                  </div>
                  <div *ngIf="preProficientPercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : preProficientPercentage + '%' }">
                    <div class="performance-row-bar proficient">{{ preProficientPercentDisplay }}%</div>
                    <div class="performance-row-value">({{ preProficientTotal }})</div>
                  </div>
                </div>
              </div>
              <div class="district-report-row performance-row">
                <div class="district-report-column performance-row-label-group width-20">
                  <div class="performance-row-label">Mid-test</div>
                  <div class="performance-row-value">({{ midTotal }})</div>
                </div>
                <div class="performance-row-bars">
                  <div *ngIf="midHighPercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : midHighPercentage + '%' }">
                    <div class="performance-row-bar high-risk">{{ midHighPercentDisplay }}%</div>
                    <div class="performance-row-value">({{ midHighTotal }})</div>
                  </div>
                  <div *ngIf="midSomePercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : midSomePercentage + '%' }">
                    <div class="performance-row-bar some-risk">{{ midSomePercentDisplay }}%</div>
                    <div class="performance-row-value">({{ midSomeTotal }})</div>
                  </div>
                  <div *ngIf="midProficientPercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : midProficientPercentage + '%' }">
                    <div class="performance-row-bar proficient">{{ midProficientPercentDisplay }}%</div>
                    <div class="performance-row-value">({{ midProficientTotal }})</div>
                  </div>
                </div>
              </div>
              <div class="district-report-row performance-row">
                <div class="district-report-column performance-row-label-group width-20">
                  <div class="performance-row-label">Post-test</div>
                  <div class="performance-row-value">({{ postTotal }})</div>
                </div>
                <div class="performance-row-bars">
                  <div *ngIf="postHighPercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : postHighPercentage + '%' }">
                    <div class="performance-row-bar high-risk">{{ postHighPercentDisplay }}%</div>
                    <div class="performance-row-value">({{ postHighTotal }})</div>
                  </div>
                  <div *ngIf="postSomePercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : postSomePercentage + '%' }">
                    <div class="performance-row-bar some-risk">{{ postSomePercentDisplay }}%</div>
                    <div class="performance-row-value">({{ postSomeTotal }})</div>
                  </div>
                  <div *ngIf="postProficientPercentage > 0" class="district-report-column performance-row-label-group" [ngStyle]="{ 'width' : postProficientPercentage + '%' }">
                    <div class="performance-row-bar proficient">{{ postProficientPercentDisplay }}%</div>
                    <div class="performance-row-value">({{ postProficientTotal }})</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="district-report-row">
            <div class="district-report-column width-50 diagnostic-performance-panel left">
              <div class="title">Students Making Gains</div>
              <div class="print-hide">
                <canvas baseChart
                        #gainsChartCanvas
                        class="chart chart-bar"
                        id="diagnostic-gains-chart"
                        [data]="gainsData"
                        [type]="chartType"
                        [options]="gainOpts"></canvas>
              </div>
              <img id="diagnostic-gains-chart-img" class="chart-img" #gainsChartImg />
            </div>
            <div class="district-report-column width-50 diagnostic-performance-panel right">
              <div class="title split-flex">
                <div>Improvement</div>
                <div class="print-hide">
                  <div style="margin-right: 5px ;">Compare Tests</div>
                  <select [(ngModel)]="comparisonGroupScoreImprovement" (change)="setChangeData()">
                    <option *ngFor="let cgroup of comparisonGroups; trackBy: trackByComparisonId" [ngValue]="cgroup">{{ cgroup.display }}</option>
                  </select>
                </div>
                <div class="print-show" style="font-size: 1rem ;">
                  Compare Tests: {{ comparisonGroupScoreImprovement.display }}
                </div>
              </div>
              <div class="print-hide">
                <canvas baseChart
                        #improvementChartCanvas
                        class="chart chart-bar"
                        id="diagnostic-avg-gains-chart"
                        [data]="changeData"
                        [type]="chartType"
                        [options]="improvementOpts"></canvas>
              </div>
              <img id="diagnostic-avg-gains-chart-img" class="chart-img" #improvementChartImg />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
