import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { SessionStorageService } from "../services/session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    let isUnauthorizedRoute: boolean = childRoute.data['needs_auth'] === false;
    if (!this.sessionStorageService.isUserLoggedIn() && !isUnauthorizedRoute)
    {
      return this.router.createUrlTree([ '/login' ]) ;
    }

    return true ;
  }
}
