import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'underscore';

import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { ManagementUser } from '../../../core/models/management-user.model';
import { District } from '../../../core/models/district.model';
import { School } from '../../../core/models/school.model';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ReportsService } from '../../../core/services/reports.service';
import { GaugeComponent } from '../../../shared/components/gauge/gauge.component';
import { PrintService } from '../../../core/services/print.service';
import { ScoreCutoffs } from '../../../core/models/score-cutoffs.model';

@Component({
  selector: 'app-screener',
  templateUrl: './screener.component.html',
  styleUrls: ['./screener.component.css']
})
export class DistrictScreenerComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  schoolFilterError: string = '' ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  reportData: any ;
  selectedDistrictId: number = -1 ;
  enrolled: number = 0 ;
  completed: number = 0 ;
  completedPercent: number = 0 ;
  completedPercentDisplay: string = '' ;
  proficientStudents: number = 0 ;
  atRiskStudents: number = 0 ;
  highRiskStudents: number = 0 ;
  proficientPercent: number = 0 ;
  proficientPercentDisplay: string = '' ;
  atRiskPercent: number = 0 ;
  atRiskPercentDisplay: string = '' ;
  highRiskPercent: number = 0 ;
  highRiskPercentDisplay: string = '' ;
  teacherTableSwitch: string = '+' ;
  schoolTableSwitch: string = '+' ;
  showTeacherTable: boolean = false ;
  showSchoolTable: boolean = false ;
  isSchoolReportsUser: boolean = false ;
  schoolCols: string[] = [
    'School',
    'Number of Teachers',
    'WordFlight Students',
    '% Students Complete'
  ] ;
  teacherCols: string[] = [
    'First Name',
    'Last Name',
    'Grade',
    'School',
    '% Students Complete'
  ] ;
  teacherAggregateData: any[] = [] ;
  schoolAggregateData: any[] = [] ;
  chartType: ChartType = 'pie' ;
  chartData: ChartData<'pie', number[], string | string[]> = {
    labels: [ 'Proficient', 'Some Risk', 'High Risk' ],
    datasets: [{
      data: [ this.proficientStudents, this.atRiskStudents, this.highRiskStudents ],
      backgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
      hoverBackgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
    }]
  } ;
  chartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        position: 'bottom',
        font: {
          family: '"Gotham Rounded SSm A", "Gotham Rounded SSm B", "Arial", "Helvetica", sans-serif'
        },
        text: `${this.completed} Students`,
      }
    },
  } ;
  printIcon = faPrint ;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective ;
  @ViewChild('performanceGauge') performanceGauge!: GaugeComponent ;
  @ViewChild('screenerPerformanceChart') screenerPerformanceCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('screenerPerformanceImg') screenerPerformanceImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private sessionStorageService: SessionStorageService,
    private printService: PrintService,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;
    this.isSchoolReportsUser = this.currentUser ? this.currentUser?.isSchoolUser() : false;

    // Resolve data
    this.reportData = this.route.snapshot.data['resolveData'] ;

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts.filter((district: District) => district.name !== 'Clever District') ;
    this.schools = this.route.snapshot.data['filterData'].schools.filter((school: School) => school.name !== 'Clever School') ;
    this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;
    this.selectedDistrictId = this.reportsService.getSelectedDistrictForReports().districtID ;

    // Filter our data on our initially selected district and school and calculate report values
    this.filterSchoolTeacher({ 'district' : this.reportsService.getSelectedDistrictForReports(), 'school' : this.reportsService.getSelectedSchoolForReports() }) ;
  }

  filterSchoolTeacher(filterOpts: any) {
    if (filterOpts.district.districtID !== this.selectedDistrictId)
    {
      // The district was changed, so we need to get new report data
      this.selectedDistrictId = filterOpts.district.districtID ;
      this.reportsService.getDistrictScreenerData().subscribe({
        next: (reportData: any) => {
          this.reportData = reportData ;
          this.calculateReportValues(reportData) ;
        }
      }) ;
    }
    else
    {
      // The school was changed, so filter out our data
      let filteredData: any = {} ;
      if (filterOpts.school.schoolID === 0)
      {
        filteredData = this.reportData ;
      }
      else
      {
        Object.values(this.reportData).forEach((schoolData: any) => {
          if (schoolData.id === filterOpts.school.schoolID)
          {
            filteredData[schoolData.id] = schoolData ;
          }
        }) ;
      }
      this.calculateReportValues(filteredData) ;
    }
  }

  sortSchoolTable(sortOpts: any){
    this.schoolAggregateData = (sortOpts.sortReverse) ? _.sortBy(this.schoolAggregateData, this.reportsService.sortSchoolFunction, sortOpts).reverse() : _.sortBy(this.schoolAggregateData, this.reportsService.sortSchoolFunction, sortOpts) ;
  }

  sortTeacherTable(sortOpts: any) {
    this.teacherAggregateData = (sortOpts.sortReverse) ? _.sortBy(this.teacherAggregateData, this.reportsService.sortTeacherFunction, sortOpts).reverse() : _.sortBy(this.teacherAggregateData, this.reportsService.sortTeacherFunction, sortOpts) ;
  }

  toggleTeacherTableSwitch() {
    this.teacherTableSwitch = (this.teacherTableSwitch === '+') ? '-' : '+' ;
    this.showTeacherTable = !this.showTeacherTable ;
  }

  toggleSchoolTableSwitch() {
    this.schoolTableSwitch = (this.schoolTableSwitch === '+') ? '-' : '+' ;
    this.showSchoolTable = !this.showSchoolTable ;
  }

  calculateReportValues(reportData: any) {
    this.enrolled = 0 ;
    this.completed = 0 ;
    this.completedPercent = 0 ;
    this.completedPercentDisplay = '' ;
    this.proficientStudents = 0 ;
    this.atRiskStudents = 0 ;
    this.highRiskStudents = 0 ;
    this.proficientPercent = 0 ;
    this.proficientPercentDisplay = '' ;
    this.atRiskPercent = 0 ;
    this.atRiskPercentDisplay = '' ;
    this.highRiskPercent = 0 ;
    this.highRiskPercentDisplay = '' ;
    this.schoolAggregateData = [] ;
    this.teacherAggregateData = [] ;

    Object.values(reportData).forEach((school: any) => {
      this.enrolled += school.totalScreeners ;
      this.completed += school.completedScreeners ;

      // Generate our school aggregate data to display
      let schoolMeetingPercent = Math.round((school.completedScreeners / school.totalScreeners) * 100) ;
      let schoolMeetingPercentDisplay = (schoolMeetingPercent === 0 && school.completedScreeners > 0) ? '<1' : schoolMeetingPercent ;
      let schoolMeetingIcon = '' ;
      if (schoolMeetingPercent < 60)
      {
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendRed.svg" class="icon" />' ;
      }
      else if (schoolMeetingPercent < 75)
      {
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendYellow.svg" class="icon" />' ;
      }
      else
      {
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendGreen.svg" class="icon" />' ;
      }

      this.schoolAggregateData.push({
        data: [
          school.name,
          Object.values(school.teachers).length,
          school.totalScreeners,
          schoolMeetingPercent,
        ],
        display: [
          school.name,
          Object.values(school.teachers).length,
          school.totalScreeners,
          `<div class="icon-score">${schoolMeetingIcon} <div class="score">${schoolMeetingPercentDisplay}%</div></div>`
        ]
      }) ;

      Object.values(school.teachers).forEach((teacher: any) => {
        // Generate our teacher aggregate data to display
        let teacherMeetingPercent = Math.round((teacher.completedScreeners / teacher.totalScreeners) * 100) ;
        let teacherMeetingPercentDisplay = (teacherMeetingPercent === 0 && teacher.completedScreeners > 0) ? '<1' : teacherMeetingPercent ;
        let teacherMeetingIcon = '' ;
        if (teacherMeetingPercent <= 60)
        {
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendRed.svg" class="icon" />' ;
        }
        else if (teacherMeetingPercent <= 75)
        {
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendYellow.svg" class="icon" />' ;
        }
        else
        {
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendGreen.svg" class="icon" />' ;
        }

        this.teacherAggregateData.push({
          data: [
            teacher.firstName,
            teacher.lastName,
            teacher.grades,
            school.name,
            teacherMeetingPercent,
          ],
          display: [
          teacher.firstName,
          teacher.lastName,
          teacher.grades.join(','),
          school.name,
          `<div class="icon-score">${teacherMeetingIcon} <div class="score">${teacherMeetingPercentDisplay}%</div></div>`
          ]
        }) ;

        // Gather student proficiencies
        Object.values(teacher.students).forEach((student: any) => {
          if (!student.dateCompleted) return ;

          // NOTE: As described in feature-3759 breakdowns of performance are now based on grade,
          //     : student groups 4th and below (internal grade value of <= 5) have one set of cutoffs,
          //     : while 5th and above (internal grade value of >5) has another. What performance
          //     : category they contribute to depends on the student grade
          let studentGroup: string = (student.grade <= 5) ? 'elementary' : 'secondary' ;
          if (student.systemScore >= ScoreCutoffs[studentGroup]['automaticity']['proficient']) this.proficientStudents++ ;
          else if (student.systemScore >= ScoreCutoffs[studentGroup]['automaticity']['some-risk']) this.atRiskStudents++ ;
          else this.highRiskStudents++ ;
        }) ;
      }) ;
    }) ;

    if (this.enrolled > 0)
    {
      this.completedPercent = Math.round((this.completed / this.enrolled) * 100) ;
      this.completedPercentDisplay = (this.completedPercent === 0 && this.completed > 0) ? '<1' : `${this.completedPercent}` ;
    }

    if (this.proficientStudents > 0)
    {
      this.proficientPercent = Math.round((this.proficientStudents / this.completed) * 100) ;
      this.proficientPercentDisplay = (this.proficientPercent === 0 && this.proficientStudents > 0) ? '<1' : `${this.proficientPercent}` ;
    }
    else
    {
      this.proficientPercentDisplay = '-' ;
    }

    if (this.atRiskStudents > 0)
    {
      this.atRiskPercent = Math.round((this.atRiskStudents / this.completed) * 100) ;
      this.atRiskPercentDisplay = (this.atRiskPercent === 0 && this.atRiskStudents > 0) ? '<1' : `${this.atRiskPercent}` ;
    }
    else
    {
      this.atRiskPercentDisplay = '-' ;
    }

    if (this.highRiskStudents > 0)
    {
      this.highRiskPercent = Math.round((this.highRiskStudents / this.completed) * 100) ;
      this.highRiskPercentDisplay = (this.highRiskPercent === 0 && this.highRiskStudents > 0) ? '<1' : `${this.highRiskPercent}` ;
    }
    else
    {
      this.highRiskPercentDisplay = '-' ;
    }

    this.chartOptions!.plugins!.title!.text = `${this.completed} Students` ;
    this.chartOptions = {...this.chartOptions} ;
    this.chartData.datasets[0].data = [ this.proficientStudents, this.atRiskStudents, this.highRiskStudents ] ;
    this.chart?.update() ;
    this.changeDetector.detectChanges();
    this.performanceGauge?.calculateValues() ;
  }

  printReport() {
    // Convert charts to images before printing
    let performanceCanvas = this.screenerPerformanceCanvas.nativeElement;
    let performanceImg = this.screenerPerformanceImg.nativeElement;
    this.reportsService.convertCanvasToImage(performanceCanvas, performanceImg);
    let school = this.reportsService.getSelectedSchoolForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }
}
