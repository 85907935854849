import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from "underscore";

import {
  faPrint,
  faRankingStar,
  faPieChart,
  faCheckCircle,
  faCircleMinus,
  faFileExcel,
  faSpinner,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';

import { SchoolClass } from '../../../../core/models/school-class.model';
import { District } from '../../../../core/models/district.model';
import { School } from '../../../../core/models/school.model';
import { ManagementUser } from '../../../../core/models/management-user.model';
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { SubscriptionTypes } from '../../../../core/models/subscription-types.model';
import { StudentSession } from '../../../../core/models/student-session.model';
import { UtilityService } from '../../../../core/services/utility.service';
import { StudentsService } from '../../../../core/services/students.service';
import { ReportsService } from '../../../../core/services/reports.service';
import { PrintService } from '../../../../core/services/print.service';
import { LoadingService } from '../../../../core/services/loading.service';
import { DownloadService } from '../../../../core/services/download.service';
import { Objective } from 'src/app/core/models/objective.model';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'wf-group-summary',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupSummaryComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  reportError: string = '' ;
  schoolFilterError: string = '' ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  sessions: StudentSession[] = [] ;
  reportType: string = 'usage' ;
  reportTypeDisplay: string = 'Usage and Progress' ;
  usageColumns: { [key: string]: string }[] = [
    {
      title: 'Student Name',
      property: 'student.lastName'
    },
    {
      title: 'Grade',
      property: 'student.grade'
    },
    {
      title: 'Date Started',
      property: 'startDate'
    },
    {
      title: 'Last Usage Date',
      property: 'lastUsageDate'
    },
    {
      title: 'Current Placement',
      property: 'currentUnit'
    },
    {
      title: 'Current Level',
      property: 'currentLevel'
    },
    {
      title: 'Avg Weekly Usage (min)',
      property: 'avgWeeklyUsage'
    },
    {
      title: 'Previous Week Usage (min)',
      property: 'prevUsageRangeTotalTime'
    },
    {
      title: 'Current Week Usage (min)',
      property: 'usageRangeTotalTime'
    },
    {
      title: 'Current Week Tasks Completed',
      property: 'currentWeekCompletedTaskCounts'
    },
    {
      title: 'Previous Week Tasks Completed',
      property: 'previousWeekCompletedTaskCounts'
    }
  ] ;
  engagementColumns: { [key: string]: string }[] = [
    {
      title: 'Student Name',
      property: 'student.lastName'
    },
    {
      title: 'Grade',
      property: 'student.grade'
    },
    {
      title: 'Current Team',
      property: 'student.currentTeam'
    },
    {
      title: 'Total Points',
      property: 'totalPoints'
    },
    {
      title: 'Previous Week Points',
      property: 'previousWeeksPoints'
    },
    {
      title: 'Total Characters Earned',
      property: 'student.avatarsEarned'
    },
    {
      title: 'Current Week Levels Completed',
      property: 'currentWeekLevelsCompleted'
    },
    {
      title: 'Previous Week Levels Completed',
      property: 'previousWeekLevelsCompleted'
    },
    {
      title: 'Met Previous Week Level Goal',
      property: 'previousWeekLevelsGoal'
    }
  ] ;
  selectedColumns: { [key: string]: string }[] = [] ;
  filteredSessions: StudentSession[] = [] ;
  grades: string[] = [] ;
  classes: SchoolClass[] = [] ;
  sortColumn: string = '' ;
  sortReverse: boolean = false ;
  fullProduct: string = SubscriptionTypes.FullProduct ;
  exportingReport: boolean = false ;
  printIcon = faPrint ;
  summaryIcon = faRankingStar ;
  loadingIcon = faSpinner ;
  goalMet = faCheckCircle ;
  goalNotMet = faCircleMinus ;
  excelIcon = faFileExcel ;
  errorIcon = faCircleExclamation ;

  @ViewChild('printContent') printContent!: ElementRef<HTMLElement>;

  constructor(
    private route: ActivatedRoute,
    private studentService: StudentsService,
    private reportService: ReportsService,
    private sessionStorageService: SessionStorageService,
    private utilityService: UtilityService,
    private printService: PrintService,
    private loadingService: LoadingService,
    private downloadService: DownloadService,
  ) { }

  // FIXME: Sometimes the Grade and Extra Support filters are not set (usually when a school user changes schools)
  ngOnInit(): void {
    let resolvedData = this.route.snapshot.data['resolveData'] ;
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;

    if (resolvedData.erred)
    {
      this.schoolFilterError = resolvedData.message ;
    }
    else
    {
      this.filteredSessions = resolvedData.sessionData ;
      this.sessions = resolvedData.sessionData ;
      this.grades = resolvedData.grades ;
      this.classes = resolvedData.classes ;
    }

    // Filter data
    this.selectedColumns = this.usageColumns ;
    this.districts = this.route.snapshot.data['filterData'].districts ;
    this.schools = this.route.snapshot.data['filterData'].schools ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser())
    {
      // The FIL user and District user roles can select all schools
      this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    }

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() || this.currentUser!.isSchoolUser())
    {
      // The FIL user and District and School user roles can select all teachers
      this.teachers.unshift(ManagementUser.getGenericUser()) ;
    }
  }

  filterSessions(filterOpts: any) {
    this.filteredSessions = this.studentService.filterStudentSessions(this.sessions, filterOpts) ;
  }

  filterSchoolTeacher() {
    this.loadingService.start() ;
    this.reportService.getGroupSummaryReportData().subscribe({
      next: (data: any) => {
        this.loadingService.finish() ;

        if (data.erred)
        {
          this.schoolFilterError = data.message ;
        }
        else
        {
          this.schoolFilterError = '' ;
          this.sessions = data.sessionData ;
          this.filteredSessions = data.sessionData ;
          this.grades = data.grades ;
          this.classes = data.classes ;
        }
      },
      error: (err: any) => {
        Sentry.captureException(err, {
          tags: {
            section: 'reports',
            report: 'system-summary-group',
          }
        }) ;
      }
    }) ;
  }

  displayCurrentObjectives(objectives: Objective[]){
    // Since there is no direct way to determine the current objective they're at, we check for objective completion then
    // pick a task and see which category is not completed
    let currentObjectives = '';
    Object.values(objectives).forEach(function(objective) {
      if (!objective.complete)
      {
        let aTask:any = Object.values(objective.taskData)[0];
        let suffix:string = '';
        if (!aTask) return ;

        if (aTask.pretest.score === 0){
          suffix = 'Pre'
        }

        /*if (aTask.posttest.score != 0){
          suffix = 'Post'
        }*/
        currentObjectives += 'Objective ' + objective.objectiveNumber + ' ' + suffix;
      }
    });

    return currentObjectives;
  }

  getDisplayValue(session: StudentSession, value: any) {
    const daysBetweenLastUsage = session.lastUsageDate ? Math.floor(((new Date()).getTime() - (new Date(session.lastUsageDate)).getTime()) / (1000 * 3600 * 24)) : 0 ;
    return (session.isCompleted && daysBetweenLastUsage > 6) ? '—' : value;
  }

  getUnitStatus(session: StudentSession) {
    if (session.isCompleted) {
      return 'Completed';
    } else if (session.currentUnit === 0) {
      return 'Not Started';
    } else {
      return 'Unit ' + session.currentUnit;
    }
  }

  formatDate(dateString: string) {
    return this.utilityService.formatDate(dateString) ;
  }

  sortTable(col: string) {
    if (this.sortColumn === col)
    {
      this.sortReverse = !this.sortReverse ;
    }
    this.sortColumn = col ;

    this.filteredSessions = (this.sortReverse) ? _.sortBy(this.filteredSessions, this.sortFunction, this).reverse() : _.sortBy(this.filteredSessions, this.sortFunction, this) ;
  }

  setReportType() {
    if (this.reportType === 'usage')
    {
      this.selectedColumns = this.usageColumns ;
      this.reportTypeDisplay = 'Usage and Progress' ;
    }
    else
    {
      this.selectedColumns = this.engagementColumns ;
      this.reportTypeDisplay = 'Engagement' ;
    }
  }

  printSummary() {
    let school = this.reportService.getSelectedSchoolForReports().name;
    let teacher = this.reportService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }

  refreshTableContent(){
    this.loadingService.start();
    this.reportService.getGroupSummaryReportData().subscribe({
      next: (data: any) => {
        this.loadingService.finish() ;

        if (data.erred)
        {
          this.schoolFilterError = data.message ;
        }
        else
        {
          this.schoolFilterError = '' ;
          this.sessions = data.sessionData ;
          this.filteredSessions = data.sessionData ;
          this.grades = data.grades ;
          this.classes = data.classes ;
        }
      }
    }) ;
  }

  exportGroupSummary() {
    this.reportError = '' ;
    this.exportingReport = true ;
    this.reportService.downloadGroupSummary(this.reportService.getSelectedSchoolForReports().schoolID, this.reportService.getSelectedTeacherForReports().userID)
      .subscribe({
        next: (blob: Blob) => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = 'group_summary.xlsx';
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.exportingReport = false ;
        },
        error: (err: any) => {
          this.exportingReport = false ;
          this.reportError = `There was an error trying to export the Group Summary report. Please contact support for assistance.` ;

          Sentry.captureException(err, {
            tags: {
              section: 'reports',
              report: 'system-summary-group',
              action: 'export',
              districtId: this.reportService.getSelectedDistrictForReports().districtID,
              schoolId: this.reportService.getSelectedSchoolForReports().schoolID,
              teacherId: this.reportService.getSelectedTeacherForReports().userID,
            }
          }) ;
        }
      }) ;
  }

  private sortFunction(val: any) {
    if (this.sortColumn === 'student.lastName')
    {
      return val.student.lastName ;
    }

    if (this.sortColumn === 'student.grade')
    {
      return val.student.grade ;
    }

    if (this.sortColumn === 'student.avatarsEarned')
    {
      return val.student.avatarsEarned ;
    }

    if (this.sortColumn === 'student.currentTeam')
    {
      return val.student.currentTeam ;
    }

    return val[this.sortColumn] ;
  }
}
