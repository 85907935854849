<div>
  <div id="printContent" #printContent>
    <div class="header-controls split-flex">
      <h3><img src="/assets/images/iconEnroll.svg" /> Enroll </h3>
      <div class="print-hide filter-options">
        <wf-students-filter [grades]="grades" [classes]="classes" [filterExtraSupport]="false" (filterChangeEvent)="filterStudents($event)"></wf-students-filter>
      </div>
    </div>
    <div>
      <div class="report-section-header split-flex">
        <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="studentIcon"></fa-icon> Student List</h5>
        <button id="print-button" class="btn print-summary-button print-hide" (click)=printStudents() *ngIf="userRole !== 'ROLE_TEACHER_REVIEW'"><fa-icon [icon]="printIcon"></fa-icon> Print Student List</button>
      </div>
      <div class="diagnosticproduct-management-text-button-bar">
        <div id="student-list-message" class="alert message" *ngIf="message" [ngClass]="{ 'alert-danger': messageStatus === 'error', 'alert-success' : messageStatus === 'success' }">
          <div class="icon">
            <fa-icon [icon]="successIcon" *ngIf="messageStatus === 'success'"></fa-icon>
            <fa-icon [icon]="errorIcon" *ngIf="messageStatus === 'error'"></fa-icon>
          </div>
          <div>
            <div class="bold">{{ messageTitle }}</div>
            {{ message }}
          </div>
        </div>
        <div class="print-hide diagnosticproduct-management-button-bar">
          <button type="button" class="btn btn-success" (click)="addStudent()" *ngIf="!isCleverTeacher && !canPurchase && !isClasslinkTeacher && userRole !== 'ROLE_TEACHER_REVIEW'">
            <fa-icon [icon]="addStudentIcon"></fa-icon> Add
          </button>
          <button type="button" class="btn btn-success" (click)="showPurchaseDialog = true" *ngIf="canPurchase">
            <fa-icon [icon]="purchaseIcon"></fa-icon> Purchase
          </button>
          <button type="button" class="btn btn-clever" *ngIf="isCleverTeacher" (click)="goToAddCleverSections()">
            <img src="/assets/images/clever.svg" width="20" height="20"/> Add Clever Sections
          </button>
          <button type="button" class="btn btn-clever" *ngIf="isClasslinkTeacher" (click)="goToAddClassLinkSections()">
            <img src="/assets/images/classlink-logo.png" width="20" height="20"/> Add ClassLink Sections
          </button>
          <button type="button" class="btn btn-danger" [disabled]="!studentsSelected || isDeleting" (click)="archiveStudents()" *ngIf="!isParentUser && userRole !== 'ROLE_TEACHER_REVIEW'">
            <fa-icon [icon]="deleteStudentIcon" *ngIf="!isDeleting"></fa-icon>
            <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="isDeleting"></fa-icon>
            Delete
          </button>
          <button type="button" class="btn btn-danger" [disabled]="!studentsSelected" (click)="showCancelDialog = true" *ngIf="isParentUser">
            <fa-icon [icon]="cancelIcon" *ngIf="!isDeleting"></fa-icon>
            <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="isDeleting"></fa-icon>
            Cancel Subscription
          </button>
        </div>
      </div>
      <table class="report-table">
        <thead>
          <tr>
            <th class="print-hide"></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Grade</th>
            <th>Class</th>
            <th>Username</th>
            <th>Password</th>
            <th>Session Time</th>
            <th>Subscription Type</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let student of filteredStudents">
            <td class="print-hide select-edit-cell">
              <input type="checkbox" (change)="studentToggled($event)" [value]="student.userID">
              <button type="button" class="btn btn-sm" title="Edit Student" (click)="editStudent(student.userID)"><fa-icon [icon]="editStudentIcon"></fa-icon></button>
            </td>
            <td>{{ student.firstName }}</td>
            <td>{{ student.lastName }}</td>
            <td>{{ student.grade | displayGrade }}</td>
            <td>{{ student.schoolClass?.name || 'None' }}</td>
            <td>{{ student.username }}</td>
            <td>{{ student.password }}</td>
            <td>{{ student.sessionLength }}</td>
            <td>{{ student.assessmentType }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Confirmation Dialog for purchasing new subscriptions -->
<div class="assign-confirm-dialog-background" *ngIf="showPurchaseDialog"></div>
<div class="modal-dialog" role="document" style="z-index: 1001;" *ngIf="showPurchaseDialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="alert alert-success message">
          <div class="icon">
            <fa-icon [icon]="purchaseIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">Purchase Subscription</div>
            Add additional subscriptions to your account
          </div>
        </div>
        <div>
          Additional students may be added by purchasing another subscription.
        </div>
        <div style="margin-top: 25px ;">
          Please contact <a href="mailto:customerservice@foundationsinlearning.com">Foundations in Learning</a> to discuss
          adding more subscriptions to your account.
        </div>
      </div>
      <div class="confirm-dialog-bottom-container modal-footer">
        <button class="btn" (click)="showPurchaseDialog = false">
          <fa-icon [icon]="returnIcon"></fa-icon> Back
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation Dialog for canceling subscriptions -->
<div class="assign-confirm-dialog-background" *ngIf="showCancelDialog"></div>
<div class="modal-dialog" role="document" style="z-index: 1001;" *ngIf="showCancelDialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="alert alert-danger message">
          <div class="icon">
            <fa-icon [icon]="cancelIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">Cancel Subscription</div>
            Be sure to print out the Diagnostic Report before cancelling your subscription if would like to retain a copy.
          </div>
        </div>
        <div>
          You are about to cancel subscriptions for the selected students. This will remove them from WordFlight, including
          any data and reports.
        </div>
        <div class="margin-top-10">
          <em>If you need to keep a copy of any reports, please print them before cancelling.</em>
        </div>
        <div class="margin-top-10">
          Would you like to go ahead and cancel your subscription?
        </div>
      </div>
      <div class="confirm-dialog-bottom-container modal-footer">
        <button class="btn btn-success" (click)="cancelStudentSubscriptions()">
          <fa-icon [icon]="successIcon"></fa-icon> Yes
        </button>
        <button class="btn" (click)="showCancelDialog = false">
          <fa-icon [icon]="returnIcon"></fa-icon> No
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Dialog after adding a clever section with students that already exist-->
<div class="assign-confirm-dialog-background" *ngIf="showSectionAddMessage"></div>
<div class="modal-dialog" style="z-index: 1001;" role="document" *ngIf="showSectionAddMessage">
    <div class="modal-content">
        <div class="modal-body">
        <button type="button" class="close" (click)="closeSectionAddNotification()">
          <div class="icon">
            <fa-icon [icon]="closeIcon"></fa-icon>
          </div>
        </button>
            <label class="assign-confirm-text">
              {{sectionAddMessage.message}}
            </label>
            <ul class="assign-confirm-student-list">
              <li *ngFor="let student of sectionAddMessage.studentList">
                {{student}}
              </li>
            </ul>
        </div>
        <div class="confirm-dialog-bottom-container modal-footer">
        <button class="btn yes-dialog-btn" (click)="closeSectionAddNotification()">
            <fa-icon [icon]="successIcon"></fa-icon> Ok
        </button>
        </div>
    </div>
</div>
