import { Injectable } from '@angular/core';
import { ManagementUser } from '../models/management-user.model';
import { SSOCredentials } from '../models/credentials.model';
import { School } from '../models/school.model';
import { ReportTeacher } from "../models/user.model";
import {District} from "../models/district.model";
import {keys} from "underscore";
import {Student} from "../models/student.model";

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  clear() {
    sessionStorage.clear();
  }

  setLoginTime(login: number) {
    this.setNumber('loginTime', login) ;
  }

  getLoginTime() {
    return this.getNumber('loginTime') ;
  }

  setCurrentRoute(url: string) {
    sessionStorage.setItem('currentRoute', url) ;
  }

  getCurrentRoute(): string | null {
    return sessionStorage.getItem('currentRoute') ;
  }

  setLastAccess(time: number) {
    this.setNumber('lastAccess', time) ;
  }

  getLastAccess(): number | null {
    return this.getNumber('lastAccess') ;
  }

  getUsername(): string | null {
    return sessionStorage.getItem('username') ;
  }

  setUsername(username: string) {
    sessionStorage.setItem('username', username) ;
  }

  getPassword(): string | null {
    return sessionStorage.getItem('password') ;
  }

  setPassword(password: string) {
    sessionStorage.setItem('password', password) ;
  }

  getUserData(): ManagementUser | null {
    if (!this.isUserLoggedIn()) return null ;

    return new ManagementUser(this.getSessionObject('user')) ;
  }

  setUserData(userData: ManagementUser) {
    this.setSessionObject('user', userData) ;
  }

  setUserLoggedIn(isLoggedIn: boolean) {
    this.setBoolean('isLoggedIn', isLoggedIn) ;
  }

  isUserLoggedIn(): boolean {
    return this.getBoolean('isLoggedIn') ;
  }

  setSSOInfo(sso: SSOCredentials) {
    this.setSessionObject('sso', sso);
  }

  getSSOInfo(): SSOCredentials | null {
    return this.getSessionObject('sso');
  }

  removeSSOInfo() {
    this.removeSessionObject('sso');
  }

  setSessionExpirationTime(expirationTime: number | null) {
    if (expirationTime == null)
    {
      sessionStorage.removeItem('sessionExpirationTime') ;
    }
    else
    {
      this.setNumber('sessionExpirationTime', expirationTime) ;
    }
  }

  getSessionExpirationTime(): number | null {
    return this.getNumber('sessionExpirationTime') ;
  }

  // Reports related data
  getSelectedDistrict(): District | null {
    return this.getSessionObject('selectedDistrict') ;
  }

  setSelectedDistrict(district: District) {
    this.setSessionObject('selectedDistrict', district) ;
  }

  clearSelectedDistrict() {
    this.removeSessionObject('selectedDistrict') ;
  }

  getSelectedSchool(): School | null {
    return this.getSessionObject('selectedSchool') ;
  }

  setSelectedSchool(school: School) {
    this.setSessionObject('selectedSchool', school) ;
  }

  clearSelectedSchool() {
    this.removeSessionObject('selectedSchool') ;
  }

  getSelectedTeacher(): ManagementUser | null {
    return this.getSessionObject('selectedTeacher') ;
  }

  setSelectedTeacher(teacher: ManagementUser) {
    this.setSessionObject('selectedTeacher', teacher) ;
  }

  clearSelectedTeacher() {
    this.removeSessionObject('selectedTeacher') ;
  }

  getDistricts(): District[] | null {
    return this.getSessionObject('districts') ;
  }

  setDistricts(districts: District[]) {
    this.setSessionObject('districts', districts) ;
  }

  getSchools(): School[] | null {
    return this.getSessionObject('schools') ;
  }

  setSchools(schools: School[]) {
    this.setSessionObject('schools', schools) ;
  }

  getTeachers(): ManagementUser[] | null {
    return this.getSessionObject('teachers') ;
  }

  setTeachers(teachers: ManagementUser[]) {
    this.setSessionObject('teachers', teachers) ;
  }

  getDiagnosticOpts(): any {
    return this.getSessionObject('diagnosticOpts') ;
  }

  setDiagnosticOpts(opts: any) {
    this.setSessionObject('diagnosticOpts', opts) ;
  }

  getDiagnosticStudents(): Student[] {
    return this.getSessionObject('diagnosticStudents') ;
  }

  setDiagnosticStudents(students: Student[]) {
    this.setSessionObject('diagnosticStudents', students) ;
  }

  getDiagnosticDetailsStudents(): Student[] {
    return this.getSessionObject('diagnosticDetailsStudents') ;
  }

  setDiagnosticDetailsStudents(students: Student[]) {
    this.setSessionObject('diagnosticDetailsStudents', students) ;
  }

  getDiagnosticDetailsStudent(): Student {
    return this.getSessionObject('diagnosticDetailsStudent') ;
  }

  setDiagnosticDetailsStudent(student: Student) {
    this.setSessionObject('diagnosticDetailsStudent', student) ;
  }

  getCopyOfSessionData(): string {
    return JSON.stringify(sessionStorage);
  }

  restoreSessionFromCopy(sessionCopy: string) {
    // clear session storage, and then restore from the string
    sessionStorage.clear();
    let sessionCopyObject: Object = JSON.parse(sessionCopy)
    Object.keys(sessionCopyObject).forEach(key => {
      sessionStorage.setItem(key, Object.getOwnPropertyDescriptors(sessionCopyObject)[key].value);
    });
  }

  setResourceCategory(category: string) {
    sessionStorage.setItem('resourceCategory', category)
  };

  getResourceCategory(): string | null{
    return sessionStorage.getItem('resourceCategory')
  }

  setLogoutMessage(msg: string) {
    sessionStorage.setItem('logoutMessage', msg) ;
  }

  getLogoutMessage(): string | null {
    return sessionStorage.getItem('logoutMessage') ;
  }

  private getBoolean(key: string): boolean {
    return sessionStorage.getItem(key) === 'true';
  }

  private setBoolean(key: string, value: boolean) {
    sessionStorage.setItem(key, value ? 'true' : 'false');
  }

  private getNumber(key: string): number | null {
    let numString = sessionStorage.getItem(key);
    return (numString !== null) ? Number(numString) : null;
  }

  private setNumber(key: string, value: number) {
    sessionStorage.setItem(key, value.toString());
  }

  private getSessionObject(key: string): any {
    let sessionObject = null;
    let sessionObjectString: string | null = sessionStorage.getItem(key);

    if (sessionObjectString && sessionObjectString !== 'undefined')
    {
      sessionObject = JSON.parse(sessionObjectString);
    }

    return sessionObject;
  }

  private setSessionObject(key: string, object: any) {
    let sessionObjectString = JSON.stringify(object);
    sessionStorage.setItem(key, sessionObjectString);
  }

  private removeSessionObject(key: string) {
    sessionStorage.removeItem(key);
  }
}
