<div class="container-fluid center-content">
  <div class="well-login col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-s-8 col-xs-offset-2" style="margin-top: 200px;">
    <form id="forgotPasswordForm" [formGroup]="forgotPasswordForm" (ngSubmit)="sendResetLink()">
      <div class="row reset-header">
        <div class="col-xs-12">
          <div class="management-login"><fa-icon [icon]="resetIcon"></fa-icon> Reset Your Password</div>
        </div>
      </div>
      <div [hidden]="!showResetFields">
        <div class="row" style="padding-top: 20px; padding-bottom: 20px;" [hidden]="!showResetFields">
          <div class="col-xs-12">
            <div class="inner-addon left-addon">
              <fa-icon [icon]="emailIcon" class="input-icon"></fa-icon>
              <input type="email"
                     id="email"
                     class="form-control"
                     name="email"
                     placeholder="E-mail"
                     style="margin-bottom: 10px;"
                     formControlName="emailAddress"
                     required />
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="!resetError">
        <div class="alert alert-danger message" id="failedDisplay" [hidden]="!resetError">
          <div class="icon">
            <fa-icon [icon]="resetErrorIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">Password Reset Failed</div>
            {{ error }}
          </div>
        </div>
      </div>
      <div [hidden]="!resetSuccess">
        <div class="alert alert-success message" [hidden]="!resetSuccess">
          <div class="icon">
            <fa-icon [icon]="resetSuccessIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">Request Received</div>
            If a matching account is found, an email will be sent to <span class="bold">{{ enteredEmail }}</span> with a link to reset the password.
          </div>
        </div>
      </div>
      <div class="reset-actions split-flex">
        <div>
          <a class="back-to-login-link" routerLink="/login"><fa-icon [icon]="backIcon"></fa-icon> Back to login page</a>
        </div>
        <div>
          <button type="submit" class="btn btn-primary btn-block btn-success" id="resetButton" [disabled]="!forgotPasswordForm.valid">Reset Password</button>
        </div>
      </div>
    </form>
  </div>
</div>
