import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingCount: number = 0 ;
  private loadingStatusSource = new BehaviorSubject<boolean>(false) ;
  loadingStatus = this.loadingStatusSource.asObservable() ;

  constructor() { }

  // We keep a loading count to determine we are still loading data or not, because theoretically multiple
  // components/services/resolvers could be loading data at the same time, and we only want to initial loading events
  // if we were not loading anything prior (count = 0) and only end if we are now done (count = 0)
  start() {
    if (this.loadingCount === 0) {
      this.loadingStatusSource.next(true) ;
    }
    this.loadingCount++ ;
  }

  finish() {
    this.loadingCount-- ;
    // Safety check
    if (this.loadingCount < 0) this.loadingCount = 0 ;
    
    if (this.loadingCount === 0) {
      this.loadingStatusSource.next(false) ;
    }
  }
}
