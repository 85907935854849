<div #printContent id="printContent">
  <div class="print-hide" *ngIf="showSchoolFilter">
    <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools" [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" (filterChangeEvent)="filterSchoolTeacher()"></wf-school-teacher-filter>
  </div>
  <div [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="header-controls">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
  </div>
  <div class="report-section-header" style="justify-content: space-between;">
    <h5 class="results-report-header">
      <fa-icon [classes]="['print-hide']" [icon]="detailIcon"></fa-icon>
      {{ fullProduct }}: Unit Detail
    </h5>
    <div>
      <refresh-button
      (click)="refreshUnitDetails()">
      </refresh-button>
      <button id="print-button" class="btn print-summary-button print-hide" (click)="printSummary()" *ngIf="!currentUser!.isTeacherReviewUser()"><fa-icon [icon]="printIcon"></fa-icon> Print Results</button>
    </div>
  </div>
  <div class="student-dropdown-row flex column-gap-10" style="align-items: flex-end ;">
    <div class="width-25">
      <label for="student-dropdown">Student</label>
      <select id="student-dropdown" class="form-select print-hide" [(ngModel)]="selectedStudent" (change)="getInterventionTaskDetails()">
        <option *ngFor="let student of students; trackBy: trackByStudentId" [ngValue]="student">{{ student.firstName }} {{ student.lastName }}</option>
      </select>
      <div class="print-show">{{selectedStudent?.firstName || ''}} {{selectedStudent?.lastName || ''}}</div>
    </div>
    <div class="width-25">
      <label for="unit-dropdown">Unit</label>
      <select id="unit-dropdown" class="form-select print-hide" [(ngModel)]="selectedUnit" (change)="filterTaskDetailsList()">
        <option *ngFor="let unit of units" [ngValue]="unit">{{ unit.summary }}</option>
      </select>
      <div class="print-show">{{selectedUnit?.summary || ''}}</div>
    </div>
    <div class="width-25">
      <label for="task-dropdown">Category</label>
      <select id="task-dropdown" class="form-select print-hide" [(ngModel)]="selectedTaskCategory" (change)="filterTaskDetailsList()">
        <option [ngValue]="defaultTaskCategory"></option>
        <option *ngFor="let category of taskCategories" [ngValue]="category">{{ category.name }}</option>
      </select>
    </div>
    <div *ngIf="!selectedStudent" class="school-teacher-filter-error-label" style="margin-bottom: 5px ;">Please select a Student</div>
    <div *ngIf="selectedStudent && !selectedUnit" class="school-teacher-filter-error-label" style="margin-bottom: 5px ;">Please select a Unit</div>
  </div>
  <table class="table table-borderless student-table">
    <thead class="student-table-header">
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Grade</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{selectedStudent?.firstName}}</td>
        <td>{{selectedStudent?.lastName}}</td>
        <td>{{selectedStudent?.grade | displayGrade }}</td>
      </tr>
    </tbody>
  </table>
  <table id="unit-detail-table" class="table unit-detail-table accordion">
    <thead class="student-table-header">
    <tr>
      <th>Task</th>
      <th>Wordlist</th>
      <th (click)="sortTable('dateCompleted')">
        <div class="sortable-report-table-header" style="justify-content: left;">
          Date
          <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 'dateCompleted') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th>Percent Correct</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngIf="taskRetrievalError"><td colspan="5" style="text-align: center; color: red;">There was an error retrieving the student's task data.  If the problem persists, please contact support.</td></tr>
      <ng-container *ngFor="let task of filteredStudentTasks">
        <tr *ngIf="task.taskTitle ; else taskWithTrialData" class="intervention-task">
          <td>
            <div>{{ task.taskName }}</div>
            <div class="task-title"><fa-icon [icon]="titleIcon" class="print-hide"></fa-icon> {{ task.taskTitle }}</div>
          </td>
          <td>{{ task.wordListString }}</td>
          <td>{{ task.dateCompletedString }}</td>
          <td *ngIf="task.numberOfTrials === 0">&mdash;</td>
          <td *ngIf="task.numberOfTrials !== 0">{{ (task.correctTrials/task.numberOfTrials*100) | number: '1.0-0' }}%</td>
        </tr>
        <ng-template #taskWithTrialData>
          <tr class="intervention-task">
            <td>{{ task.taskName }}</td>
            <td>{{ task.wordListString }}</td>
            <td>{{ task.dateCompletedString }}</td>
            <td *ngIf="task.numberOfTrials === 0">&mdash;</td>
            <td *ngIf="task.numberOfTrials !== 0">
              <span style="display: inline-block; width: 20%;">{{ (task.correctTrials/task.numberOfTrials*100) | number: '1.0-0' }}%</span>
              <a [href]="'#trials-' + task.studentInterventionTaskId" role="button" data-bs-toggle="collapse" class="collapsed" (click)="toggleTaskTrials(task.studentInterventionTaskId)" >
                <fa-icon [icon]="(expandedTaskId === task.studentInterventionTaskId) ? collapseIcon : expandIcon"></fa-icon>
              </a>
            </td>
          </tr>
          <tr class="intervention-task-trials accordion-item">
            <td colspan="5" style="padding: 0 ; border-collapse: collapse ;">
              <div class="collapse accordion-collapse" [id]="'trials-' + task.studentInterventionTaskId" data-bs-parent="#unit-detail-table">
                <table>
                  <thead>
                  <tr>
                    <th>Trial #</th>
                    <th>Trial Target</th>
                    <th>Student Response</th>
                    <th>Correct?</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let trial of task.trialData; index as trialIdx" [ngClass]="{ 'trial-incorrect': !trial.isCorrect }">
                    <td>{{ trialIdx + 1 }}</td>
                    <td>{{ trial.target }}</td>
                    <td>{{ trial.response }}</td>
                    <td *ngIf="trial.isCorrect" class="trial-correct"><fa-icon class="print-icon green-color" [icon]="correctIcon"></fa-icon></td>
                    <td *ngIf="!trial.isCorrect"><fa-icon class="print-icon red-color" [icon]="incorrectIcon"></fa-icon></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </ng-template>
      </ng-container>
    </tbody>
  </table>
</div>
