import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'wf-report-aggregate-panel',
  templateUrl: './report-aggregate-panel.component.html',
  styleUrls: ['./report-aggregate-panel.component.css']
})
export class ReportAggregatePanelComponent implements OnInit {
  @Input() data: any = [] ;
  @Input() cols: string[] = [] ;
  @Input() showTable: boolean = false ;
  @Output() sortTableEvent = new EventEmitter<Object>() ;
  sortColumn: number = 0 ;
  sortReverse: boolean = false ;

  constructor() { }

  ngOnInit(): void {
  }

  sortTable(colIdx: number) {
    if (this.sortColumn === colIdx)
    {
      this.sortReverse = !this.sortReverse ;
    }
    this.sortColumn = colIdx ;

    this.sortTableEvent.emit({
      sortReverse: this.sortReverse,
      sortColumn: this.sortColumn,
    }) ;
  }
}
