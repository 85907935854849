import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service' ;
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { PageSession } from '../models/page-session.model';
import { environment } from '../../../environments/environment';

const SESSION_INTERVAL_MILLIS : number = 3 * 1000; // 3 seconds
const SLEEP_TIMEOUT_MILLIS : number = 4 * 60 * 1000; // 4 minutes

const SESSION_TIMEOUT_MILLIS : number = 6 * 60 * 60 * 1000; // 6 hours
const TEACHER_REVIEW_SESSION_TIMEOUT_MILLIS : number = 60 * 60 * (environment.teacherReviewTimeoutHour || 1) * 1000; // 1 hour


@Injectable({
  providedIn: 'root'
})
export class SessionTimerService {
  private inactivityTimer: any = null ;
  private sleepInterval: any = null ;
  private lastTime: number = Date.now() ;
  private logoutTrigger$: Subject<boolean> = new Subject<boolean>() ;
  private clickInactivityResetListener$: Observable<Event> ;
  private touchInactivityResetListener$: Observable<Event> ;
  private clickResetSubscription: Subscription | null = null ;
  private touchResetSubscription: Subscription | null = null ;
  private pageSessions: PageSession[] = [];

  /**
   * We have two timing events in the timing service that can cause a logout:
   * (1) one that will look for inactivity and if the user has not been active in the last 6 hours, logout
   * (2) one that checks every 3 seconds to see if we haven't done a check for 4 minutes (indicating we fell asleep) , logout
   **/
  constructor(
    private sessionStorageService: SessionStorageService,
  ) {
    this.clickInactivityResetListener$ = fromEvent(document, 'click') ;
    this.touchInactivityResetListener$ = fromEvent(document, 'touchStart') ;
  }

  getTimeoutThreshold(): number {
    const sessionTimeout = this.sessionStorageService.getUserData()?.isTeacherReviewUser() ? TEACHER_REVIEW_SESSION_TIMEOUT_MILLIS : SESSION_TIMEOUT_MILLIS;

    return sessionTimeout;
  }

  private startSleepTimer() {
    if (this.sleepInterval == null)
    {
      this.lastTime = (new Date()).getTime() ;
    }

    this.sleepInterval = setInterval(() => {
      let currentTime = (new Date()).getTime() ;
      if (currentTime > (this.lastTime + SLEEP_TIMEOUT_MILLIS))
      {
        this.triggerLogout() ;

        return ;
      }

      this.lastTime = currentTime ;
    }, SESSION_INTERVAL_MILLIS) ;
  }

  private cancelSleepTimer() {
    if (this.sleepInterval != null)
    {
      clearInterval(this.sleepInterval);
      this.sleepInterval = null;
    }
  }

  private startInactivityTimer() {
    // Reset and start the inactivity timer
    this.resetInactivityTimer() ;

    // Add event listeners to the document to reset the inactivity timer when someone clicks
    this.clickResetSubscription = this.clickInactivityResetListener$.subscribe(() => {
      if (this.sessionStorageService.isUserLoggedIn())
      {
        this.resetInactivityTimer() ;
      }
    }) ;

    this.touchResetSubscription = this.touchInactivityResetListener$.subscribe(() => {
      if (this.sessionStorageService.isUserLoggedIn())
      {
        this.resetInactivityTimer() ;
      }
    }) ;
  }

  private resetInactivityTimer() {
    this.cancelInactivityTimers() ;
    const sessionTimeout = this.sessionStorageService.getUserData()?.isTeacherReviewUser() ? TEACHER_REVIEW_SESSION_TIMEOUT_MILLIS : SESSION_TIMEOUT_MILLIS;

    this.inactivityTimer = setTimeout(() => {
      this.triggerLogout() ;
    }, sessionTimeout) ;
  }

  private cancelInactivityTimers() {
    if (this.inactivityTimer != null)
    {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = null;
    }
  }

  private triggerLogout() {
    this.logoutTrigger$.next(true) ;
  }

  private startTimers() {
    // When starting timers, first check to see if we are coming back and have exceeded our session time
    let currentTime: number = (new Date()).getTime() ;
    let sessionExpiration: number | null = this.sessionStorageService.getSessionExpirationTime() ;

    // If we just started the sleep timer and we have been asleep for too long, logout
    if (sessionExpiration !== null && currentTime > sessionExpiration)
    {
      this.triggerLogout() ;

      return ;
    }

    this.startInactivityTimer() ;
    this.cancelSleepTimer() ;
    this.startSleepTimer() ;
  }

  // Public methods that our callers can use to start/stop timers because of events
  getLogoutTrigger(): Subject<boolean> {
    return this.logoutTrigger$ ;
  }

  startSleepAndInactivityTimers() {
    // session length reduced for teacher review
    const sessionTimeout = this.sessionStorageService.getUserData()?.isTeacherReviewUser() ? TEACHER_REVIEW_SESSION_TIMEOUT_MILLIS : SESSION_TIMEOUT_MILLIS;

    // On login, set when our session will expire
    this.sessionStorageService.setSessionExpirationTime((new Date().getTime()) + sessionTimeout) ;

    // Start our timers
    this.startTimers() ;
  }

  cancelTimers() {
    this.cancelInactivityTimers() ;
    this.cancelSleepTimer() ;

    this.clickResetSubscription?.unsubscribe() ;
    this.touchResetSubscription?.unsubscribe() ;
  }

  startPageSession(currentPage: string, previousPage: string | null = null) {
    const newSession: PageSession = {
      startTime: Date.now(),
      currentPage,
      previousPage
    };
    this.pageSessions.push(newSession);
  }

  endPageSession(currentPage: string) {
    const session = this.pageSessions.find(s => s.currentPage === currentPage && !s.endTime);
    if (session) {
      session.endTime = Date.now();
    }
  }

  getPageSessions(): PageSession[] {
    return this.pageSessions;
  }

  clearPageSessions() {
    this.pageSessions = [];
  }
}
