import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { forkJoin, map, Observable } from 'rxjs';

import { EnrollmentService } from '../../core/services/enrollment.service';
import { Student } from '../../core/models/student.model';
import { UtilityService } from '../../core/services/utility.service';
import {SchoolClass} from "../../core/models/school-class.model";

@Injectable({
  providedIn: 'root'
})
export class AssignDiagnosticsResolver  {
  constructor(
    private enrollService: EnrollmentService,
    private utilityService: UtilityService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      this.enrollService.getStudents(),
      this.enrollService.getRemainingDiagnosticLicensesCount(null, null),
      this.enrollService.getRemainingInterventionLicensesCount(null, null)
    ]).pipe(
      map((results) => {
        let students: Student[] = results[0] ;
        let diagnosticLicenseCount: number = results[1] ;
        let interventionLicenseCount: number = results[2] ;
        let gradesSet: Set<string> = new Set() ;
        let grades: string[] = [] ;
        let classes: SchoolClass[] = [] ;

        // Add in all our current grade options
        let foundInterventionSubscription: boolean = false ;
        students.forEach((student: Student) => {
          gradesSet.add(this.utilityService.getStudentGrade(student.grade));

          // Process our assessments to add extra data used in the component
          let showAddTestButton: boolean = true ;
          let showRemoveACButton: boolean = true ;
          student.assessments?.forEach((assessment) => {
            if (assessment.dateStarted)
            {
              assessment.formattedDateStarted = this.utilityService.formatDateNum(assessment.dateStarted) ;
            }

            if (assessment.isDiagnostic)
            {
              assessment.testWindow = this.utilityService.getTestWindow(assessment.dateStarted) ;
            }

            if (assessment.isAssessmentExpired())
            {
              assessment.formattedDateCompleted = 'Expired' ;
            }
            else if (assessment.dateCompleted !== null)
            {
              assessment.formattedDateCompleted = this.utilityService.formatDateNum(assessment.dateCompleted) ;
            }
            else if (assessment.state.toLowerCase() === 'started')
            {
              assessment.formattedDateCompleted = 'In Progress' ;

              if (assessment.isDiagnostic || assessment.isPreDiagnostic || assessment.isMidDiagnostic || assessment.isPostDiagnostic)
              {
                let percentComplete = (assessment.numDiagnosticTasks === 0) ? 0 : Math.round((assessment.numDiagnosticTasksCompleted / assessment.numDiagnosticTasks) * 100) ;
                if (assessment.skipped)
                {
                  assessment.formattedDateCompleted = `Completed - ${percentComplete}%` ;
                }
                else
                {
                  assessment.formattedDateCompleted += ` - ${percentComplete}%` ;
                }
              }
            }
            else if (assessment.state.toLowerCase() === 'assigned')
            {
              assessment.formattedDateCompleted = '' ;
            }

            if (!foundInterventionSubscription)
            {
              // Determine if any student has an intervention subscription
              foundInterventionSubscription = (assessment.subscriptionType === this.utilityService.SUBSCRIPTION_TYPE_INTERVENTION) ;
            }

            if (showAddTestButton)
            {
              if (!assessment.skipped && !assessment.isAssessmentExpired() && (assessment.dateCompleted === null))
              {
                showAddTestButton = false ;
              }
              else if (assessment.isDiagnostic && assessment.isCompletedInWindow(this.utilityService.getTestWindow((new Date()).getTime())))
              {
                showAddTestButton = false ;
              }
            }

            if (showRemoveACButton && !assessment.isDiagnostic && !assessment.isScreener && assessment.dateStarted !== null)
            {
              showRemoveACButton = false ;
            }

            student.showAddTestButton = showAddTestButton ;
            student.showRemoveACButton = showRemoveACButton ;
          });
        }) ;

        // Return sorted grades
        grades = [ 'All' ].concat(this.utilityService.sortGrades(Array.from(gradesSet))) ;

        // Return sorted school classes
        classes = [{
          name: 'All',
          schoolClassID: 0,
          teacherID: 0,
          createDate: (new Date()),
          status: ''
        }].concat(this.utilityService.sortClasses(this.utilityService.getUniqueSchoolClasses(students))) ;

        return {
          students: students,
          grades: grades,
          classes: classes,
          diagnosticLicenseCount: diagnosticLicenseCount,
          interventionLicenseCount: interventionLicenseCount,
        } ;
      })
    ) ;
  }
}
