export interface TaskCategory {
  type: number,
  name: string,
  tasks: string[],
}

export const TaskCategoryTypes: any = {
  ALL: 0,
  TEXT_COMP: 1,
  VOCAB: 2,
  DECODING: 3,
  RECOG: 4,
  SPELLING: 5,
  SYLLABLE: 6,
  SPEEDED: 7,
}

export const TaskCategories: TaskCategory[] = [
  {
    type: TaskCategoryTypes.ALL,
    name: 'All',
    tasks: [

    ],
  },
  {
    type: TaskCategoryTypes.SPEEDED,
    name: 'Speeded',
    tasks: [
      'RHYM',
      'PICM',
      'FSM',
    ],
  },
  {
    type: TaskCategoryTypes.TEXT_COMP,
    name: 'Text Comprehension',
    tasks: [
      'PASS',
      'PAQ',
      'FTP',
    ],
  },
  {
    type: TaskCategoryTypes.VOCAB,
    name: 'Vocabulary',
    tasks: [
      'PAP',
      'PIC',
    ],
  },
  {
    type: TaskCategoryTypes.DECODING,
    name: 'Decoding',
    tasks: [
      'FB',
      'MFB',
      'CHW',
    ],
  },
  {
    type: TaskCategoryTypes.RECOG,
    name: 'Word Recognition',
    tasks: [
      'FTW',
      'RHY',
      'FWF',
      'MTP',
    ],
  },
  {
    type: TaskCategoryTypes.SPELLING,
    name: 'Spelling',
    tasks: [
      'STW',
      'SPELL',
    ],
  },
  {
    type: TaskCategoryTypes.SYLLABLE,
    name: 'Syllable',
    tasks: [
      'MTW',
      'FSU',
      'FSA',
      'FSB',
    ],
  },
]
