import { Injectable } from '@angular/core';

import { Adapter } from '../adapter';
import {User, UserRoles} from './user.model';

export class ManagementUser implements User {
  userID: number ;
  userRole: string ;
  effectiveUserRole: string ;
  firstName: string ;
  middleName: string | null ;
  lastName: string ;
  username: string ;
  password: string | null ;
  enabled: boolean ;
  externalIdentities: { [key: string] : string } ;
  email: string | null ;
  school: string ;
  schoolID: number ;
  timeZoneId: string | null ;
  numberOfUserLogins: number | null ;
  districtID: number ;
  districtName: string | null ;
  diagnosticProductEnabled: boolean ;
  fullProductEnabled: boolean ;
  fullProductSubscriptionAssigned: boolean ;
  territory: string | null ;
  canEnableBetaTasks: boolean | null ;

  constructor(item: any) {
    this.userID = item.userID ;
    this.userRole = item.userRole ;
    this.firstName = item.firstName ;
    this.middleName = item.middleName ;
    this.lastName = item.lastName ;
    this.username = item.username ;
    this.password = item.password ;
    this.enabled = item.enabled ;
    this.externalIdentities = item.externalIdentities ;
    this.school = item.school ;
    this.schoolID = item.schoolID ;
    this.email = item.email ;
    this.timeZoneId = item.timeZoneId ;
    this.numberOfUserLogins = item.numberOfUserLogins ;
    this.districtID = item.districtID ;
    this.districtName = item.districtName ;
    this.diagnosticProductEnabled = item.diagnosticProductEnabled ;
    this.fullProductEnabled = item.fullProductEnabled ;
    this.fullProductSubscriptionAssigned = item.fullProductSubscriptionAssigned ;
    this.territory = item.territory ;
    this.canEnableBetaTasks = item.canEnableBetaTasks ;

    // The role 'TEACHER_SCHOOL_REPORTS' is a multi-function role that is essentially combining the abilities
    // of the 'TEACHER' and 'SCHOOL_REPORTS' roles. In the UI, we allow the user to switch between these two roles.
    // We store which role is chosen for that user group in the 'effectiveUserRole' field, so we need to set that
    // properly. When our user object is created, we will default it to the 'TEACHER' role for these users
    this.effectiveUserRole = item.effectiveUserRole || ((this.userRole === UserRoles.TEACHER_SCHOOL_REPORTS) ? UserRoles.TEACHER : '') ;
  }

  public isTeacherUser(): boolean {
    return (
      this.userRole === UserRoles.TEACHER ||
      this.userRole === UserRoles.TEACHER_CURRICULUM ||
      this.userRole === UserRoles.TEACHER_REVIEW ||
      this.effectiveUserRole === UserRoles.TEACHER
    ) ;
  }

  public isSchoolUser(): boolean {
    return (this.userRole === UserRoles.SCHOOL_REPORTS || this.effectiveUserRole === UserRoles.SCHOOL_REPORTS) ;
  }

  public isTeacherSchoolReportsUser(): boolean {
    return (this.userRole === UserRoles.TEACHER_SCHOOL_REPORTS) ;
  }

  public isDistrictUser(): boolean {
    return (this.userRole === UserRoles.DISTRICT_REPORTS) ;
  }

  public isSalesRepUser(): boolean {
    return (this.userRole === UserRoles.SALES_REP) ;
  }

  public isTeacherReviewUser(): boolean {
    return (this.userRole === UserRoles.TEACHER_REVIEW) ;
  }

  public isCustomerServiceUser(): boolean {
    return (this.userRole === UserRoles.CUSTOMER_SERVICE) ;
  }

  public isParentUser(): boolean {
    return (this.userRole === UserRoles.PARENT) ;
  }

  public isFILUser(): boolean {
    return (this.userRole === UserRoles.SALES_REP) || (this.userRole.toUpperCase() === UserRoles.FOUNDATIONS_REPORTS) ;
  }

  // This 'generic' user is used mostly in dropdowns for filters (report filters)
  public static getGenericUser(): ManagementUser {
    return new ManagementUser({
     userID: 0,
     userRole: UserRoles.TEACHER,
     firstName: 'All',
     middleName: '',
     lastName: '',
     username: 'all',
     password: '',
     enabled: true,
     externalIdentities: {},
     school: '',
     schoolID: 0,
     email: '',
     timeZoneId: '',
     numberOfUserLogins: 0,
     districtID: 0,
     territory: '',
     districtName: '',
     diagnosticProductEnabled: false,
     fullProductEnabled: false,
     fullProductSubscriptionAssigned: false,
    }) ;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ManagementUserAdapter implements Adapter<ManagementUser> {
  adapt(item: any): ManagementUser {
    return new ManagementUser(item) ;
  }
}
