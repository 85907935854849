import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  downloadFile(url: string): Observable<Blob> {
    return this.httpClient.get<Blob>(url, {
      responseType: 'blob' as 'json',
      withCredentials: true,
    }) ;
  }
}
