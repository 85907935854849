import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { concatMap, map, Observable, of } from 'rxjs';

import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ReportsService } from '../../../core/services/reports.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolTeacherResolver  {
  constructor(
    private sessionStorageService: SessionStorageService,
    private reportsService: ReportsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // This resolver will load the necessary data for any page (report) needing to use
    // the district-school-teacher filter. What we load depends on the user that is currently
    // logged in. If we are a:
    // * FIL User - load all districts, and all schools for the first returned district
    // * District User - load all schools
    // * School User - load all teachers
    let currentUser = this.sessionStorageService.getUserData() ;

    if (currentUser!.isFILUser())
    {
      return this.reportsService.getDistricts(currentUser!).pipe(
        concatMap((districts) => {
          return this.reportsService.getSchools(currentUser!, this.reportsService.getSelectedDistrictForReports().districtID).pipe(
            map((schools) => {
              return {
                districts: districts,
                schools: schools,
                teachers: this.sessionStorageService.getTeachers() ?? []
              }
            })
          ) ;
        })
      ) ;
    }

    if (currentUser!.isDistrictUser())
    {
      return this.reportsService.getSchools(currentUser!, currentUser!.districtID).pipe(
        map((schools) => {
          return {
            districts: [{ districtID: currentUser!.districtID, name: currentUser!.districtName, enabled: true }],
            schools: schools,
            teachers: this.sessionStorageService.getTeachers() ?? []
          }
        })
      ) ;
    }

    if (currentUser!.isSchoolUser())
    {
      return this.reportsService.getTeachers(currentUser!, currentUser!.schoolID).pipe(
        map((teachers) => {
          return {
            districts: [{ districtID: currentUser!.districtID, name: currentUser!.districtName, enabled: true }],
            schools: [{ schoolID: currentUser!.schoolID, districtID: currentUser!.districtID, name: currentUser!.school, enabled: true }],
            teachers: teachers
          }
        })
      ) ;
    }

    return of({
      districts: [],
      schools: [],
      teachers: [],
    })
  }
}
