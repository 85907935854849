import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { concatMap, map, Observable, of } from 'rxjs';
import { DashboardService } from '../shared/dashboard.service';
import { StudentsService } from '../../../../core/services/students.service';

@Injectable({
  providedIn: 'root'
})
export class ParentDashboardResolver  {
  constructor(
    private studentsService: StudentsService,
    private dashboardService: DashboardService,
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.studentsService.getStudents().pipe(
      concatMap((students) => {
        if (students.length === 0)
        {
          return of({
            students: [],
            studentData: {}
          }) ;
        }
        else
        {
          return this.dashboardService.getParentDashboardDataForStudent(students[0].userID).pipe(
            map((studentData) => {
              return {
                students: students,
                studentData: studentData
              }
            })
          ) ;
        }
      })
    ) ;
  }
}
