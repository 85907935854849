<div class="header">
  <div class="fil-brand-wrapper">
    <div class="drawerWrapper" [hidden]="!userLoggedIn">
      <!-- TODO: Hide/Expand on smaller screens
        <button
        [ngClass]="{'exit-menu' : sidebarService.drawerIsOpen, 'hamburger-menu' : !sidebarService.drawerIsOpen }"
        (click)="sidebarService.toggleDrawer()">
      </button> -->
    </div>
    <div class="fil-logo"><a href="https://www.wordflight.com/" target="_blank"><img src="/assets/images/wordflight_hrz_color_pos_rgb.svg"></a></div>
  </div>
  <div class="user-info-wrapper" *ngIf="userLoggedIn">
    <div *ngIf="isDistrictUser ; then districtWelcome else userWelcome"></div>
    <ng-template #districtWelcome>
      <div class="district-welcome"><span class="grow">{{ userData?.districtName }}</span></div>
    </ng-template>
    <ng-template #userWelcome>
      <div class="user-welcome">
        <div class="grade-level-text-management">{{ userData?.school }}</div>
        <div class="welcome-username-management">{{ greeting }} {{ userData?.username }}</div>
      </div>
    </ng-template>
    <div class="header-tools">
      <a id="student-app-login" class="btn"
         *ngIf="studentAppLink"
         [href]="studentAppLink">
        <fa-icon [icon]="demoIcon"></fa-icon> Student Demo
      </a>
      <button style="background-color: rgba(113, 189, 113, 0.4); position: relative;" id="feedback-button" class="btn" (click)="goToFeedback()" *ngIf="showFeedbackButton && userRole !== 'ROLE_TEACHER_REVIEW'">
        <fa-icon [icon]="feedbackIcon"></fa-icon> Feedback
        <span style="position: absolute; top: -10px; right: -10px; padding:1px 8px; border-radius: 50%; background-color: red; color: white; font-weight: bold; font-size: 0.8em;">!</span>
      </button>
      <button id="logout-button" class="btn" (click)="logout()"><fa-icon [icon]="logoutIcon"></fa-icon> Logout</button>
      <button id="settings-button" routerLink="/settings"><fa-icon [icon]="settingsIcon"></fa-icon></button>
    </div>
  </div>
</div>
