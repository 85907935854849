import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core' ;

import { Resources } from 'src/app/core/models/resource.model' ;

@Component({
  selector: 'wf-knowledge-base',
  templateUrl: './knowledge-base.component.html',
  styleUrls: ['./knowledge-base.component.css']
})
export class KnowledgeBaseComponent implements OnInit {
  @Input() resources: Resources[] = [] ;
  @Output() itemClicked = new EventEmitter<Resources>() ;

  constructor() { }

  ngOnInit(): void {
  }

  goToItem(resource: Resources) {
    this.itemClicked.emit(resource) ;
  }
}
