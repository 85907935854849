import { Injectable } from '@angular/core';

import { Adapter } from '../adapter';

export class AssessmentScore {
  assessmentID: number ;
  assessmentType: number ;
  dateStarted?: number ;
  dateCompleted?: number ;
  formattedDateStarted?: string;
  formattedDateCompleted?: string;
  state?: string;
  decodingScore: number ;
  systemScore: number ;
  automatacityScore: number ;
  zone: number ;
  decodingShortVowels: number ;
  decodingLongVowels: number ;
  decodingVowelDigraphs: number ;
  decodingDipthongRVowels: number ;
  decodingIrregularShortVowels: number ;
  decodingIrregularVowelSecondary: number ;
  decodingInitialConsonantSingle: number ;
  decodingInitialConsonantDigraph: number ;
  decodingInitialConsonantCluster: number ;
  decodingSecondaryConsonantSingle: number ;
  decodingSecondaryConsonantDigraph: number ;
  decodingSecondaryConsonantCluster: number ;
  decodingConsonantOverall: number ;
  decodingVowelOverall: number ;
  generalizationWord: number ;
  generalizationNonWord: number ;
  generalizationOneSyl: number ;
  generalizationTwoSyl: number ;
  generalizationThreeSyl: number ;
  systemSpeeded: number ;
  systemNonSpeeded: number ;
  unscaledDecodingScore: number ;
  isActive?: boolean ;
  skipped?: boolean;
  testWindow?: string ;
  dataDecoding?: any ;
  dataGeneralization?: any ;
  instructionalPriorities?: string ;
  numDiagnosticTasksCompleted: number;
  numDiagnosticTasks: number;
  subscriptionType: number;

  constructor(item: any) {
    this.assessmentID = item.assessmentID ;
    this.assessmentType = item.assessmentType ;
    this.dateStarted = item.dateStarted ;
    this.decodingScore = item.decodingScore ;
    this.systemScore = item.systemScore ;
    this.automatacityScore = item.automatacityScore ;
    this.zone = item.zone ;
    this.decodingShortVowels = item.decodingShortVowels ;
    this.decodingLongVowels = item.decodingLongVowels ;
    this.decodingVowelDigraphs = item.decodingVowelDigraphs ;
    this.decodingDipthongRVowels = item.decodingDipthongRVowels ;
    this.decodingIrregularShortVowels = item.decodingIrregularShortVowels ;
    this.decodingIrregularVowelSecondary = item.decodingIrregularVowelSecondary ;
    this.decodingInitialConsonantSingle = item.decodingInitialConsonantSingle ;
    this.decodingInitialConsonantDigraph = item.decodingInitialConsonantDigraph ;
    this.decodingInitialConsonantCluster = item.decodingInitialConsonantCluster ;
    this.decodingSecondaryConsonantSingle = item.decodingSecondaryConsonantSingle ;
    this.decodingSecondaryConsonantDigraph = item.decodingSecondaryConsonantDigraph ;
    this.decodingSecondaryConsonantCluster = item.decodingSecondaryConsonantCluster ;
    this.decodingConsonantOverall = item.decodingConsonantOverall ;
    this.decodingVowelOverall = item.decodingVowelOverall ;
    this.generalizationWord = item.generalizationWord ;
    this.generalizationNonWord = item.generalizationNonWord ;
    this.generalizationOneSyl = item.generalizationOneSyl ;
    this.generalizationTwoSyl = item.generalizationTwoSyl ;
    this.generalizationThreeSyl = item.generalizationThreeSyl ;
    this.systemSpeeded = item.systemSpeeded ;
    this.systemNonSpeeded = item.systemNonSpeeded ;
    this.unscaledDecodingScore = item.unscaledDecodingScore ;
    this.numDiagnosticTasksCompleted = item.numDiagnosticTasksCompleted;
    this.numDiagnosticTasks = item.numDiagnosticTasks;
    this.subscriptionType = item.subscriptionType;

    if (item.dateStarted) this.dateStarted = item.dateStarted ;
    if (item.dateCompleted) this.dateCompleted = item.dateCompleted ;
    if (item.isActive !== null && item.isActive !== undefined) this.isActive = item.isActive ;
    if (item.testWindow) this.testWindow = item.testWindow ;

    if (item.formattedDateStarted) this.formattedDateStarted = item.formattedDateStarted ;
    if (item.formattedDateCompleted) this.formattedDateCompleted = item.formattedDateCompleted ;
    if (item.state) this.state = item.state ;
    if (item.skipped) this.skipped = item.skipped ;

  }

  public get screenerResultsLow() {
    let lowRange = this.systemScore - 30 ;

    return (lowRange < 0) ? 0 : Math.round(lowRange) ;
  }

  public get screenerResultsHigh() {
    return Math.round(this.systemScore + 30) ;
  }

  public limitScores() {
    this.decodingScore = this.limitScore(this.decodingScore);
    this.systemScore = this.limitScore(this.systemScore);
    this.decodingShortVowels = this.limitScore(this.decodingShortVowels);
    this.decodingLongVowels = this.limitScore(this.decodingLongVowels);
    this.decodingVowelDigraphs = this.limitScore(this.decodingVowelDigraphs);
    this.decodingDipthongRVowels = this.limitScore(this.decodingDipthongRVowels);
    this.decodingIrregularShortVowels = this.limitScore(this.decodingIrregularShortVowels);
    this.decodingIrregularVowelSecondary = this.limitScore(this.decodingIrregularVowelSecondary);
    this.decodingInitialConsonantSingle = this.limitScore(this.decodingInitialConsonantSingle);
    this.decodingInitialConsonantDigraph = this.limitScore(this.decodingInitialConsonantDigraph);
    this.decodingInitialConsonantCluster = this.limitScore(this.decodingInitialConsonantCluster);
    this.decodingSecondaryConsonantSingle = this.limitScore(this.decodingSecondaryConsonantSingle);
    this.decodingSecondaryConsonantDigraph = this.limitScore(this.decodingSecondaryConsonantDigraph);
    this.decodingSecondaryConsonantCluster = this.limitScore(this.decodingSecondaryConsonantCluster);
    this.decodingConsonantOverall = this.limitScore(this.decodingConsonantOverall);
    this.decodingVowelOverall = this.limitScore(this.decodingVowelOverall);
    this.generalizationWord = this.limitScore(this.generalizationWord);
    this.generalizationNonWord = this.limitScore(this.generalizationNonWord);
    this.generalizationOneSyl = this.limitScore(this.generalizationOneSyl);
    this.generalizationTwoSyl = this.limitScore(this.generalizationTwoSyl);
    this.generalizationThreeSyl = this.limitScore(this.generalizationThreeSyl);
    this.systemSpeeded = this.limitScore(this.systemSpeeded);
    this.systemNonSpeeded = this.limitScore(this.systemNonSpeeded);
  }

  private limitScore(score: number) {
    if (score <= 0) return 0 ;
    else if (score > 600) return 600 ;
    else return Math.round(score) ;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AssessmentScoreAdapter implements Adapter<AssessmentScore> {
  adapt(item: any): AssessmentScore {
    return new AssessmentScore(item) ;
  }
}
