import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  private baseUrl = environment.dataServicesURL+'manage/surveys';
  private reqOpts = {
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  } ;

  private surveySubmissionSource = new Subject<boolean>() ;
  surveySubmission$ = this.surveySubmissionSource.asObservable() ;

  constructor(private http: HttpClient) { }

  getAvailableSurveys() {
    return this.http.get<number[]>(`${this.baseUrl}/list`, this.reqOpts) ;
  }

  //Gets the form token for the requested survey
  getFormId(surveyId: number): Observable<string> {
    return this.http.get(`${this.baseUrl}/${surveyId}/formId`, { ...this.reqOpts, responseType: 'text' });
  }

  //Gets the form from the backend
  getFormFromBackend(surveyId: number, formId: string, token: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${surveyId}/formId/${formId}/token/${token}/form`, this.reqOpts);
  }

  //Saves the user survey to the database and submits it to HubSpot
  saveUserSurvey(surveyId: number, userId: number): Observable<any> {
    const dateTaken = new Date();
    const data = {
      userId: userId,
      surveyId: surveyId,
      dateTaken: dateTaken,
    };

    return this.http.post(`${this.baseUrl}/surveyId/${surveyId}/userId/${userId}/dateTaken/${dateTaken}/userSurvey`, data, this.reqOpts).pipe(
      map((response) => {
        this.surveySubmissionSource.next(true) ;

        return response ;
      })
    );
  }

  //Gets the past surveys for the requested user
  getUserSurveys(userId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/userId/${userId}/userSurveys`, this.reqOpts);
  }

  //Submits the form to HubSpot
  submitForm(portalId: number, formId: string, token: string, formData: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/portalId/${portalId}/formId/${formId}/token/${token}/submit`, formData, this.reqOpts);
  }
}
