<div #printContent id="printContent">
  <div *ngIf="!isSchoolReportsUser">
    <div class="print-hide">
      <wf-school-teacher-filter
        [error]="schoolFilterError"
        [districts]="districts"
        [schools]="schools"
        [teachers]="teachers"
        [showDistrict]="currentUser!.isFILUser()"
        [showTeacher]="false"
        (filterChangeEvent)="filterSchoolTeacher($event)">
      </wf-school-teacher-filter>
    </div>
  </div>
  <div [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="district-report-content">
    <div class="district-report-header">
      <span class="report">Screener</span>
      <button id="printButton" class="btn print-summary-button" (click)="printReport()"><fa-icon [icon]="printIcon" *ngIf="!currentUser!.isTeacherReviewUser()"></fa-icon> Print</button>
    </div>
    <div class="district-report-row">
      <div class="district-report-column width-35">
        <div class="district-report-panel" id="district-report-screener-enrollment">
          <div class="title">Enrollment</div>
          <div class="stat-tile">
            <div class="icon">
              <img src="/assets/images/reportIcons/iconManagement-EnrolledGreen.svg" />
            </div>
            <div class="stat">
              <div class="stat-value">{{ enrolled }}</div>
              <div class="stat-label">Students Enrolled in Screener</div>
            </div>
          </div>
        </div>
        <div class="district-report-panel gauge" id="district-report-screener-completion">
          <div class="title">Completion</div>
          <wf-report-gauge [percent]="completedPercent" [color]="'#0095DA'" #performanceGauge></wf-report-gauge>
          <div class="gauge-detail">
            <div class="graph center-text">
              <div class="stat">
                <div class="stat-value">{{ completedPercentDisplay }}%</div>
                <div class="stat-label">Students with Completed Screener</div>
              </div>
            </div>
            <div class="summary center-text">
              {{ completed }} / {{ enrolled }}
            </div>
          </div>
        </div>
      </div>
      <div class="district-report-column width-65">
        <div class="district-report-panel" id="district-report-screener-performance">
          <div class="title">Performance Results</div>
          <div id="performance-data">
            <!--div>WordFlight is designed for these students</div-->
            <div id="categories">
              <div id="proficient" class="stat-tile">
                <div class="icon image-label">
                  Proficient
                </div>
                <div class="stat">
                  <div class="stat-value">{{ proficientPercentDisplay }}%</div>
                  <div class="stat-label">{{ proficientStudents }} Students</div>
                </div>
              </div>
              <div id="some-risk" class="stat-tile">
                <div class="icon image-label">
                  Some Risk
                </div>
                <div class="stat">
                  <div class="stat-value">{{ atRiskPercentDisplay }}%</div>
                  <div class="stat-label">{{ atRiskStudents }} Students</div>
                </div>
              </div>
              <div id="high-risk" class="stat-tile">
                <div class="icon image-label">
                  High Risk
                </div>
                <div class="stat">
                  <div class="stat-value">{{ highRiskPercentDisplay }}%</div>
                  <div class="stat-label">{{ highRiskStudents }} Students</div>
                </div>
              </div>
            </div>
            <div id="chart" class="print-hide" style="width: 50% ;">
              <canvas baseChart
                      #screenerPerformanceChart
                      class="chart-pie"
                      id="performance-chart"
                      [data]="chartData"
                      [type]="chartType"
                      [options]="chartOptions"></canvas>
            </div>
            <img id="performance-chart-img" class="chart-img" #screenerPerformanceImg />
          </div>
        </div>
      </div>
    </div>
    <div class="district-report-row page-break-after" *ngIf="!isSchoolReportsUser">
      <div class="district-report-column width-100">
        <wf-report-aggregate-panel [cols]="schoolCols"
                                   [data]="schoolAggregateData"
                                   [showTable]="showSchoolTable"
                                   (sortTableEvent)="sortSchoolTable($event)">
          <div class="title" header>
            <span>School Report</span>
            <span class="display-toggle print-hide" (click)="toggleSchoolTableSwitch()">{{ schoolTableSwitch }}</span>
          </div>
        </wf-report-aggregate-panel>
      </div>
    </div>
    <div class="district-report-row">
      <div class="district-report-column width-100">
        <wf-report-aggregate-panel [cols]="teacherCols"
                                   [data]="teacherAggregateData"
                                   [showTable]="showTeacherTable"
                                   (sortTableEvent)="sortTeacherTable($event)">
          <div class="title" header>
            <span>Teacher Report</span>
            <span class="display-toggle print-hide" (click)="toggleTeacherTableSwitch()">{{ teacherTableSwitch }}</span>
          </div>
        </wf-report-aggregate-panel>
      </div>
    </div>
  </div>
</div>
