<div class="container-fluid center-content">
  <div class="well-login col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-s-8 col-xs-offset-2">
    <form id="resetPasswordForm" name="passwordForm" [formGroup]="resetPasswordForm" (ngSubmit)="changePassword()">
      <div class="row">
        <div class="col-xs-12">
          <div class="management-login"><fa-icon [icon]="resetIcon"></fa-icon> Reset Your Password</div>
        </div>
      </div>
      <div [hidden]="!resetError">
        <div class="alert alert-danger message" id="failedDisplay">
          <div class="icon">
            <fa-icon [icon]="resetErrorIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">Password Reset Failed</div>
            {{ error }}
          </div>
        </div>
      </div>
      <div [hidden]="!resetSuccess">
        <div class="alert alert-success message">
          <div class="icon">
            <fa-icon [icon]="resetSuccessIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">Password Reset Success</div>
            Your new password was successfully changed
          </div>
        </div>
      </div>
      <div [hidden]="!showSaveControls">
        <div class="form-group required">
          <label class="control-label" for="new-password">New Password</label>
          <input style="display: inline-block;" class="form-control" [type]="showNewPassword ? 'text' : 'password'" id="new-password" name="new-password" formControlName="newPassword" placeholder="New Password" required>
          <button type="button" style="float: right; left:0px; top:-24px;" class="show-password-button" (click)="showNewPassword=!showNewPassword" tabindex="-1"></button>
          <div *ngIf="newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)" class="help-block small-text">
            <div *ngIf="newPassword?.errors?.['required']">
              <fa-icon [icon]="resetErrorIcon"></fa-icon> The new password is required
            </div>
            <div *ngIf="newPassword?.errors?.['pattern']">
              <fa-icon [icon]="resetErrorIcon"></fa-icon> The new password must be between 8 and 20 alphanumeric characters, including at least one letter and one number
            </div>
          </div>
        </div>
        <div class="form-group required">
          <label class="control-label" for="confirm-password">Confirm New Password</label>
          <input style="display: inline-block;" class="form-control" [type]="showConfirmPassword ? 'text' : 'password'" id="confirm-password" name="confirm-password" formControlName="confirmPassword" placeholder="Confirm Password" required>
          <button type="button" style="float:right; left:0px; top:-24px;" class="show-password-button" (click)="showConfirmPassword=!showConfirmPassword" tabindex="-1"></button>
          <div *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)" class="help-block small-text">
            <div *ngIf="confirmPassword?.errors?.['required']">
              <fa-icon [icon]="resetErrorIcon"></fa-icon> You must re-enter your new password
            </div>
            <div *ngIf="confirmPassword?.errors?.['mustMatch']">
              <fa-icon [icon]="resetErrorIcon"></fa-icon> The confirm password does not match
            </div>
          </div>
        </div>
      </div>
      <div class="reset-actions split-flex">
        <div>
          <a class="back-to-login-link" routerLink="/login"><fa-icon [icon]="backIcon"></fa-icon> Back to login page</a>
        </div>
        <div>
          <button type="submit" class="btn btn-primary btn-block btn-success" id="resetButton" [disabled]="!resetPasswordForm.valid">Save Password</button>
        </div>
      </div>
    </form>
  </div>
</div>
