export const ScoreCutoffs: any = {
  'elementary' : {
    'decoding' : {
      'proficient': 400,
      'some-risk': 266.67,
    },
    'automaticity' : {
      'proficient': 400,
      'some-risk': 265,
    },
    'consonant' : {
      'proficient' : 417,
      'some-risk' : 324,
    },
    'vowel' : {
      'proficient' : 313,
      'some-risk' : 240,
    },
    'zones': [
      { 'bottom' : 0, 'top' : 199, 'label' : 'Zone 1' },
      { 'bottom' : 200, 'top' : 265, 'label' : 'Zone 2' },
      { 'bottom' : 266, 'top' : 399, 'label' : 'Zone 3' },
      // This top zone is 400+, so no top range
      { 'bottom' : 400, 'top' : 999, 'label' : 'Zone 4' },
    ],
    'labels' : {
      'topTier' : { 'label' : 'Proficient', 'range': '400 and above' },
      'middleTier' : { 'label' : 'Some Risk', 'range': '266 - 399' },
      'bottomTier' : { 'label' : 'High Risk', 'range': '0 - 265' },
    },
  },
  'secondary' : {
    'decoding' : {
      'proficient' : 300,
      'some-risk': 200,
    },
    'automaticity' : {
      'proficient' : 300,
      'some-risk': 200,
    },
    'consonant' : {
      'proficient' : 325,
      'some-risk' : 225,
    },
    'vowel' : {
      'proficient' : 300,
      'some-risk' : 200,
    },
    'zones': [
      { 'bottom' : 0, 'top' : 99, 'label' : 'Zone 1' },
      { 'bottom' : 100, 'top' : 199, 'label' : 'Zone 2' },
      { 'bottom' : 200, 'top' : 299, 'label' : 'Zone 3' },
      // This top zone is 300+, so no top range
      { 'bottom' : 300, 'top' : 999, 'label' : 'Zone 4' },
    ],
    'labels' : {
      'topTier' : { 'label' : 'Proficient', 'range': '300 and above' },
      'middleTier' : { 'label' : 'Some Risk', 'range': '200 - 299' },
      'bottomTier' : { 'label' : 'High Risk', 'range': '0 - 199' },
    },
  }
}
