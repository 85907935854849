import { Component, Input, OnInit } from '@angular/core';
import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ResourcesService } from '../../core/services/resources.service';

@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.css']
})
export class PdfViewerDialogComponent implements OnInit {
  @Input()
  pdfUrl: string = '';
  @Input()
  type: string = 'pdf' ;
  @Input()
  data: any = {} ;
  imageUrl: SafeUrl | null = null ;
  isLoading: boolean = false ;
  page: number = 1 ;
  prevIcon = faChevronLeft ;
  nextIcon = faChevronRight ;
  loadingIcon = faSpinner ;

  constructor(
    private resourceService: ResourcesService,
    private sanitizer: DomSanitizer,
    private modalRef: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.loadImage() ;
  }

  nextPage() {
    this.page++ ;

    this.loadImage() ;
  }

  previousPage() {
    if (this.page > 1)
    {
      this.page-- ;
      this.loadImage() ;
    }
  }

  onCloseClick() {
    if (this.modalRef)
    {
      this.modalRef.close() ;
    }
  }

  disableContextMenu($event: any) {
    $event.preventDefault() ;
    return false ;
  }

  loadImage() {
    this.isLoading = true ;

    let loader$ ;
    if (this.type === 'pdf')
    {
      loader$ = this.resourceService.getImageSource(`${this.pdfUrl}/page/${this.page}`) ;
    }
    else if (this.type === 'lesson')
    {
      loader$ = this.resourceService.getLessonImage(`${this.pdfUrl}/page/${this.page}`, this.data) ;
    }

    if (loader$)
    {
      loader$.subscribe((blob) => {
        const objUrl = URL.createObjectURL(blob) ;
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(objUrl) ;
        this.isLoading = false ;
      }) ;
    }
  }
}
