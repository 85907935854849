import { Injectable } from '@angular/core';

import { User } from './user.model';
import { SchoolClass } from './school-class.model';
import { Adapter } from '../adapter';
import { Assessment, AssessmentAdapter } from './assessment.model';
import { AssessmentTypes } from './assessment-types.model';
import { AssessmentScore, AssessmentScoreAdapter } from './assessment-score.model';

export class Student implements User {
  userID: number ;
  userRole: string ;
  firstName: string ;
  middleName: string | null ;
  lastName: string ;
  username: string ;
  password: string | null ;
  school: string ;
  schoolId: number ;
  enabled: boolean ;
  externalIdentities: { [key: string] : string } ;
  gender: number ;
  grade: number ;
  englishLanguageLearner: boolean ;
  extraSupportNeeded: boolean ;
  timerEnabled: boolean ;
  curriculum: string ;
  sessionLength: number ;
  teacherID: number ;
  teacherUsername: string ;
  enrolledSubscription: number ;
  currentAssessment: number ;
  subscriptionExpired: boolean ;
  canBeAssignedToTeam: boolean ;
  betaTasksEnabled: boolean ;
  weeklySessionTime: number ;
  avatarsEarned: number ;
  cleverLogin: boolean ;
  assessmentType: string ;
  currentTeam?: string ;
  schoolClass?: SchoolClass ;
  assessments?: Assessment[] ;
  assessmentScores?: AssessmentScore[] ;
  firstAssessmentScore?: AssessmentScore ;
  assessmentDataForSorting?: any ;
  showAddTestButton?: boolean;
  showRemoveACButton?: boolean;
  enrollmentDate?: Date;

  constructor(item: any) {
    this.userID = item.userID ;
    this.userRole = item.userRole ;
    this.firstName = item.firstName ;
    this.middleName = item.middleName ;
    this.lastName = item.lastName ;
    this.username = item.username ;
    this.password = item.password ;
    this.enabled = item.enabled ;
    this.externalIdentities = item.externalIdentities ;
    // Deprecated: As noted in Redmine issue 5566, we are no longer specifying gender
    // we are leaving support in the codebase for now in case we want to bring it back
    // if this is decided to be permanently removed we can remove gender code in a future release
    this.gender = item.gender ;
    this.grade = item.grade ;
    this.englishLanguageLearner = item.englishLanguageLearner ;
    this.extraSupportNeeded = item.extraSupportNeeded ;
    this.timerEnabled = item.timerEnabled ;
    this.curriculum = item.curriculum ;
    this.sessionLength = item.sessionLength ;
    this.teacherID = item.timerEnabled ;
    this.teacherUsername = item.teacherUsername ;
    this.school = item.school ;
    this.schoolId = item.schoolId ;
    this.schoolClass = item.schoolClass || null;
    this.enrolledSubscription = item.enrolledSubscription ;
    this.subscriptionExpired = item.subscriptionExpired ;
    this.currentAssessment = item.curriculum ;
    this.canBeAssignedToTeam = item.canBeAssignedToTeam ;
    this.betaTasksEnabled = item.betaTasksEnabled ;
    this.weeklySessionTime = item.weeklySessionTime ;
    this.avatarsEarned = item.avatarsEarned ;
    this.cleverLogin = (this.externalIdentities && Object.keys(this.externalIdentities).length > 0) ;
    this.assessmentType = AssessmentTypes[`${this.enrolledSubscription}`] ;
    if (this.subscriptionExpired) this.assessmentType += ' (Expired)' ;

    if (item.enrollmentDate) this.enrollmentDate = item.enrollmentDate ;
    if (item.assessmentScores) this.assessmentScores = item.assessmentScores ;
    if (item.firstAssessmentScore) this.firstAssessmentScore = item.firstAssessmentScore ;
    if (item.assessments) this.assessments = item.assessments ;
    if (item.currentTeam) this.currentTeam = item.currentTeam ;
    if (item.showAddTestButton) this.showAddTestButton = item.showAddTestButton;
    if (item.showRemoveACButton) this.showRemoveACButton = item.showRemoveACButton;
  }
}

export interface StudentEnrollData {
  firstName: string,
  middleName: string | null,
  lastName: string,
  username: string,
  password: string,
  gender: number,
  grade: number,
  englishLanguageLearner: boolean,
  extraSupportNeeded: boolean,
  timerEnabled: boolean,
  enabled: boolean,
  curriculum: string,
  sessionLength: number,
  teacherID: number | undefined,
  userRole: string,
  enrolledSubscription: number,
  canBeAssignedToTeam: boolean,
  betaTasksEnabled: boolean | undefined,
  betaSpeechTasksEnabled: boolean | undefined,
  // TODO: if the enrollment form gets updated to properly to handle SchoolClass type, we
  // should change the type here
  schoolClass: any,
}

@Injectable({
  providedIn: 'root'
})
export class StudentAdapter implements Adapter<Student> {
  constructor(
    private assessmentScoreAdapter: AssessmentScoreAdapter,
    private assessmentAdapter: AssessmentAdapter,
  ) {}

  adapt(item: any): Student {
    // In some cases, our Student object can come back with AssessmentScores set, if we have those, adapt those first
    if (item.assessmentScores)
    {
      let assessmentScores: AssessmentScore[] = [] ;
      item.assessmentScores.forEach((assessmentScore: any) => {
        assessmentScores.push(this.assessmentScoreAdapter.adapt(assessmentScore)) ;
      }) ;

      item['assessmentScores'] = assessmentScores ;
    }

    if (item.firstAssessmentScore)
    {
      item['firstAssessmentScore'] = this.assessmentScoreAdapter.adapt(item.firstAssessmentScore) ;
    }

    if (item.assessments)
    {
      let assessments: Assessment[] = [] ;
      item.assessments.forEach((assessment: any) => {
        assessments.push(this.assessmentAdapter.adapt(assessment)) ;
      }) ;

      item['assessments'] = assessments ;
    }

    return new Student(item) ;
  }
}
