import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';

import { ReportsService } from '../../../../core/services/reports.service';
import { LoadingService } from '../../../../core/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class GroupSummaryResolver  {
  constructor(
    private reportsService: ReportsService,
    private loadingService: LoadingService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.loadingService.start() ;
    return this.reportsService.getGroupSummaryReportData().pipe(
      map((data) => {
        this.loadingService.finish() ;

        return data ;
      }),
      catchError((error) => {
        this.loadingService.finish() ;

        return of(error.status) ;
      })
    ) ;
  }
}
