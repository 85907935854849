import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';

import { DashboardService } from '../shared/dashboard.service';
import { LoadingService } from '../../../../core/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class SystemDashboardResolver  {
  constructor(
    private dashboardService: DashboardService,
    private loadingService: LoadingService,
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.loadingService.start() ;

    return this.dashboardService.getSystemDashboardData().pipe(
      map((data) => {
        this.loadingService.finish() ;

        return data ;
      }),
      catchError((error) => {
        this.loadingService.finish() ;

        return of(error.status) ;
      })
    ) ;
  }
}
