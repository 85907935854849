import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { SessionStorageService } from '../services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleGuard  {
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    let userData = this.sessionStorageService.getUserData() ;

    if (!childRoute.data['rolesAllowed'])
    {
      // No allowed roles specified, so ALL roles allowed
      return  true;
    }

    return (
      userData &&
      (
        childRoute.data['rolesAllowed'].includes(userData.userRole.toUpperCase()) ||
        childRoute.data['rolesAllowed'].includes(userData.effectiveUserRole.toUpperCase())
      )
    ) ;
  }

}
