import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import {
  faBookBookmark,
  faChartLine,
  faConciergeBell,
  faMagnifyingGlassChart,
  faSchool, faUsers
} from '@fortawesome/free-solid-svg-icons';

import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LoginService } from '../../../core/services/login.service';
import { SidebarService } from '../../../core/services/sidebar.service';
import { SubscriptionTypes } from '../../../core/models/subscription-types.model';
import { ManagementUser } from '../../../core/models/management-user.model';

@Component({
  selector: 'wf-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [
  ]
})
export class SidebarComponent implements OnInit, OnDestroy {
  loginStatusSubscription: Subscription | undefined ;
  routeChangeSubscription: Subscription | undefined ;

  chartIcon = faChartLine ;
  detailedReportsIcon = faMagnifyingGlassChart ;
  districtReportsIcon = faSchool ;
  resourcesIcon = faBookBookmark ;
  supportIcon = faConciergeBell ;
  enrollIcon = faUsers ;

  managementUser: ManagementUser | null = null ;
  userRole: string = '' ;
  shouldShow: boolean = true ;
  isUserLoggedInInternal: boolean = false ;
  isUserLoggedIn: boolean = false ;
  isDistrictUser: boolean = false ;
  isFoundationsUser: boolean = false ;
  isCustomerServiceUser: boolean = false ;
  isSchoolOrDistrictUser: boolean = false ;
  isTeacherSchoolReportsUser: boolean = false ;
  isParentUser: boolean = false ;
  isTeacherUser: boolean = false ;
  isTeacherReviewUser: boolean = false ;
  customerServiceOrParent: boolean = false ;
  isSchoolUser: boolean = false ;

  hasFullProductLicense: boolean | undefined = false ;
  hasDiagnosticProductLicense: boolean | undefined = false ;

  canViewEnroll: boolean = false ;
  canViewEnrollSection: boolean = false ;
  canManageSubscriptions: boolean = false ;

  canViewScreenerReports: boolean = false ;
  canViewDiagnosticReports: boolean | undefined = false ;
  canViewSystemReports: boolean | undefined = false ;
  canViewSystemDashboardReport: boolean | undefined = false ;
  canViewHomeDashboardReport: boolean = false ;
  canViewHomeSystemDiagnosticReport: boolean = false ;
  canViewAllOtherSystemReports: boolean | undefined = false ;
  canViewLessonGenerator: boolean | undefined = false ;

  canViewResources: boolean | undefined = false ;
  canViewDiagnosticResources: boolean | undefined = false ;
  canViewSystemResources: boolean | undefined = false ;

  freeProduct: string = SubscriptionTypes.FreeProduct ;
  fullProduct: string = SubscriptionTypes.FullProduct ;
  diagnosticProduct: string = SubscriptionTypes.DiagnosticProduct ;

  constructor(
    public loginService: LoginService,
    public sessionStorageService: SessionStorageService,
    public sidebarService: SidebarService,
    private router: Router,
  ) {
    // NOTE: This is a bit of a hack, because we are listening to the login event, which is emitted
    //     : immediately on the login, it causes a weird bug if we immediately reflect that to our `isUserLoggedIn`
    //     : variable (which causes the sidebar to show up), and on login this is a problem because our route
    //     : might not load immediately (so the sidebar appears before the login box is removed). So instead,
    //     : we handle the emitted login value to an internal cached value and set it when our route change ends
    this.routeChangeSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd)
      {
        this.isUserLoggedIn = this.isUserLoggedInInternal ;

        // When we are on the diagnostic details page, we should not show the sidebar
        this.shouldShow = !event.url.includes('detail') ;
      }
    })
  }

  ngOnInit(): void {
    this.loginStatusSubscription = this.loginService.loginStatus$.subscribe((loggedIn) => {
      this.managementUser = this.sessionStorageService.getUserData() ;
      this.setUserPermissions() ;

      this.isUserLoggedInInternal = loggedIn ;
    }) ;
  }

  ngOnDestroy() {
    this.loginStatusSubscription?.unsubscribe() ;
    this.routeChangeSubscription?.unsubscribe() ;
  }

  setUserPermissions() {
    if (this.managementUser)
    {
      this.isDistrictUser = this.managementUser.isDistrictUser() ;
      this.isFoundationsUser = this.managementUser.isFILUser() ;
      this.isCustomerServiceUser = this.managementUser.isCustomerServiceUser() ;
      this.isSchoolOrDistrictUser = this.managementUser.isSchoolUser() || this.managementUser.isDistrictUser() ;
      this.isTeacherSchoolReportsUser = this.managementUser.isTeacherSchoolReportsUser() ;
      this.isParentUser = this.managementUser.isParentUser() ;
      this.isTeacherUser = this.managementUser.isTeacherUser() ;
      this.customerServiceOrParent = this.managementUser.isCustomerServiceUser() || this.managementUser.isParentUser() ;
      this.isSchoolUser = this.managementUser.isSchoolUser() ;
      this.isTeacherReviewUser = this.managementUser.isTeacherReviewUser() ;
      this.userRole = this.managementUser.userRole ;
    }

    this.hasFullProductLicense = this.sessionStorageService.getUserData()?.fullProductEnabled ;
    this.hasDiagnosticProductLicense = this.sessionStorageService.getUserData()?.diagnosticProductEnabled ;

    this.canViewEnroll = (!this.isSchoolOrDistrictUser && !this.isCustomerServiceUser) ;
    this.canViewEnrollSection = (this.isTeacherUser || this.isParentUser || this.isCustomerServiceUser) ;
    this.canManageSubscriptions = this.isTeacherUser ;

    this.canViewResources = !this.isCustomerServiceUser ;
    this.canViewDiagnosticResources = this.hasDiagnosticProductLicense && !this.customerServiceOrParent ;
    this.canViewSystemResources = (this.hasFullProductLicense && !this.isCustomerServiceUser) || this.isTeacherReviewUser ;

    this.canViewScreenerReports = !this.customerServiceOrParent ;
    this.canViewDiagnosticReports = this.hasDiagnosticProductLicense && !this.customerServiceOrParent ;
    this.canViewSystemReports = this.hasFullProductLicense && !this.isCustomerServiceUser ;
    this.canViewSystemDashboardReport = this.canViewSystemReports && !this.isParentUser ;
    this.canViewHomeSystemDiagnosticReport = this.isParentUser ;
    this.canViewLessonGenerator = (this.hasFullProductLicense && !this.isCustomerServiceUser) || this.isTeacherReviewUser ;
    this.canViewHomeDashboardReport = this.isParentUser ;
    this.canViewAllOtherSystemReports = this.canViewSystemReports && !this.isParentUser ;
  }

  updateEffectiveUserRole()
  {
    if (this.managementUser)
    {
      // Save our management user back to state for user in other parts of the app
      this.sessionStorageService.setUserData(this.managementUser) ;
      this.setUserPermissions() ;

      if (this.managementUser.effectiveUserRole === 'ROLE_SCHOOL_REPORTS')
      {
        this.router.navigateByUrl('/reports/district/screener') ;
      }
      else if (this.managementUser.effectiveUserRole === 'ROLE_TEACHER')
      {
        this.router.navigateByUrl('/students') ;
      }
    }
  }
}
