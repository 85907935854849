<div class="district-report-panel">
  <ng-content class="report-header" select="[header]"></ng-content>
  <div class="category-report-info">
    <div [hidden]="!showTable" class="district-report-table-wrapper">
      <ng-content select="[summary]"></ng-content>
      <table class="district-report-table">
        <thead>
        <tr>
          <th *ngFor="let column of cols ; index as idx" (click)="sortTable(idx)">
            <div class="sortable-report-table-header">
              <div>{{ column }}</div>
              <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== idx) ? 'hidden' : 'visible'"></div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of data">
          <td *ngFor="let col of row.display" [innerHtml]="col | safeHtml"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
