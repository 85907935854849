import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Credentials } from '../models/credentials.model';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClasslinkService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  getUserInfo(bearerToken: string) {
    let reqOptions = {
      headers: { 'Access-Control-Allow-Origin' : '*' },
    }

    return  this.httpClient.post<Credentials>(environment.dataServicesURL + 'classlink/info', { bearerToken }, reqOptions);
  }

  createNewUser(token: string) {
    // Build request with correct headers, base64 encode username and password
    const reqOpts = {
      headers: {
        'Access-Control-Allow-Origin' : "*"
      }
    };

    // Make the request to the server
    return this.httpClient.post<Credentials>(environment.dataServicesURL + 'manage/classlink/teacher/add',
      { bearerToken: token }, reqOpts);
  }

  getClasses() {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Content-Type': 'application/json',
      }
    } ;

    return this.httpClient.get(environment.dataServicesURL + 'manage/classlink/classes', reqOpts);
  }

  rosterClasses(classes: any) {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin' : '*',
      }
    } ;

    return this.httpClient.post(environment.dataServicesURL + 'manage/classlink/classes/add', { classData: classes }, reqOpts);
  }
}
