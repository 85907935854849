import { Component, OnInit } from '@angular/core';
import { faPersonCircleQuestion, faUser, faComments, faHeadset, faCogs, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { ManagementUser } from '../../core/models/management-user.model';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { LoginService } from '../../core/services/login.service';
import { SidebarService } from '../../core/services/sidebar.service';
import { ContactSupportService } from '../../core/services/contact-support.service';
import { Subscription } from 'rxjs';
import { switchMap, flatMap, of } from 'rxjs';
import { LoadingService } from '../../core/services/loading.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  supportIcon = faPersonCircleQuestion;
  userIcon = faUser;
  topicIcon = faCogs;
  messageIcon = faComments;
  contactIcon = faHeadset;
  sendIcon = faPaperPlane;
  userData: ManagementUser | null = null ;
  contactPreference: string = 'email';
  firstName!: string;
  lastName!: string;
  fullName!: string;
  topic!: string;
  message!: string;
  email!: string;
  phone!: string;
  isOtherSelected: boolean = false;
  otherTopic!: string;
  userLoggedIn: boolean = false ;
  loginStatusSubscription: Subscription | undefined ;
  errorMessage: string | null = null;
  submitted: boolean = false;

  topicOptions = [
    {value: 'enrollment', name: 'Enrollment'},
    {value: 'subscriptions', name: 'Subscriptions'},
    {value: 'reports', name: 'Reports'},
    {value: 'student-acc-issue', name: 'Student Account Issue'},
  ];

  constructor(
    public loginService: LoginService,
    public sessionStorageService: SessionStorageService,
    public sidebarService: SidebarService,
    public contactSupportService: ContactSupportService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.loginStatusSubscription = this.loginService.loginStatus$.subscribe((loggedIn) => {
      this.userLoggedIn = loggedIn ;
      if (loggedIn)
      {
        this.userData = this.sessionStorageService.getUserData() ;
        this.firstName = this.userData?.firstName || '';
        this.lastName = this.userData?.lastName || '';
        this.fullName = this.firstName + ' ' + this.lastName ;
        this.email = this.userData?.email || '';
      }
    }) ;
  }

  onTopicChange(value: string) {
    this.isOtherSelected = value === 'other';
  }

  onSubmit(form: NgForm) {
    if (this.isOtherSelected) {
      this.topic = this.otherTopic;
    }
  
    let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (this.contactPreference === 'email' && !emailPattern.test(this.email)) {
      this.errorMessage = 'Please provide a valid email address.';
      return;
    }
  
    let phoneNumberPattern = /^(\+\d{1,2}\s?)?1?\-?\.?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (this.contactPreference === 'phone' && !phoneNumberPattern.test(this.phone)) {
      this.errorMessage = 'Please provide a valid phone number.';
      return;
    }
  
    this.errorMessage = null;
    this.loadingService.start();
    this.contactSupportService.sendEmail(form.value)
      .subscribe({
        next: (response: any) => {
          this.loadingService.finish();
          if(response.status === 'success'){
            this.submitted = true;
          }else if(response.status === 'fail'){
            this.errorMessage = "Error submitting support request"
          }
        },
        error: (errRes: any) => {
          this.loadingService.finish();
          this.errorMessage = "Error submitting support request"
        }
      });
  }
  
}
