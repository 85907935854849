<div #printContent id="printContent">
  <div class="print-hide" *ngIf="showSchoolFilter">
    <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools" [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" (filterChangeEvent)="updateTeacherStudents()"></wf-school-teacher-filter>
  </div>
  <div  [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
  </div>
  <div class="report-section-header split-flex">
    <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="studentIcon"></fa-icon> {{ fullProduct }}: Student Session Summary</h5>
    <div>
      <button id="print-button" class="btn print-summary-button print-hide" (click)="printSummary()" *ngIf="!currentUser!.isTeacherReviewUser()"><fa-icon [icon]="printIcon"></fa-icon> Print Results</button>
    </div>
  </div>
  <div class="report-container">
    <div class="report-row flex split-flex" style="align-items: flex-end ;">
      <div class="width-25">
        <label for="student-dropdown">Student</label>
        <select id="student-dropdown" class="form-select print-hide" [(ngModel)]="selectedStudent" (change)="updateStudentSummaryData()">
          <option *ngFor="let student of students; trackBy: trackByStudentId" [ngValue]="student">{{ student.firstName }} {{ student.lastName }}</option>
        </select>
        <div class="print-show">{{selectedStudent?.firstName || ''}} {{selectedStudent?.lastName || ''}}</div>
      </div>
      <div class="large-label">
        Report Generated: {{ currentDate }}
      </div>
    </div>
    <div class="report-row print-flex-column">
      <div id="student-engagement-wrapper" class="report-column width-25 print-flex-100">
        <div class="report-panel">
          <div class="title">Engagement</div>
          <div class="content print-text-center">
            <div class="report-row">
              <div class="vertical-stat">
                <div class="label bold padding-left-30">Current Team</div>
                <div class="value-wrapper"><div class="value width-75">{{ currentTeam ? currentTeam : '-' }}</div></div>
              </div>
            </div>
            <div class="report-row">
              <div class="vertical-stat">
                <div class="label bold padding-left-30">Total Points</div>
                <div class="value-wrapper"><div class="value width-75">{{ totalPoints | number }}</div></div>
              </div>
            </div>
            <div class="report-row">
              <div class="vertical-stat">
                <div class="label bold padding-left-30">Current Weekly Points</div>
                <div class="value-wrapper"><div class="value width-75">{{ currentWeeklyPoints | number }}</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="student-usage-progress-wrapper" class="report-column width-75 print-flex-100 page-break-after">
        <div class="report-panel">
          <div class="title">Usage and Progress</div>
          <div class="content">
            <div class="report-row center-flex">
              <div class="report-column width-20 padding-15">
                <div class="report-row margin-bottom-0">
                  <div class="vertical-stat center-text center-flex">
                    <div class="label bold">Current Unit</div>
                    <div class="stat width-80">
                      <div class="stat-value">{{ currentUnit }}</div>
                      <div class="stat-label">out of {{ totalUnits }}</div>
                    </div>
                  </div>
                </div>
                <div class="report-row">
                  <div class="vertical-stat center-text center-flex">
                    <div class="label bold">Current Level</div>
                    <div class="stat width-80">
                      <div class="stat-value">{{ currentLevel }}</div>
                      <div class="stat-label">out of {{ totalLevels }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="report-column width-60 padding-top-10" style="row-gap: 5px ;">
                <div class="report-column" style="row-gap: 5px ;">
                  <div class="large-stat-label">Minutes This Week: <span>{{ minutesThisWeek | number: '1.0-0' }}</span></div>
                  <div class="flex center-flex column-gap-10">
                    <div class="width-90 horizontal-gauge">
                      <div class="filled-gauge" [ngStyle]="{ 'background': 'linear-gradient(to right, transparent 0% ' + minutesWeekPercent + '%, #D8D8D8 ' + minutesWeekPercent + '% 100%)' }"></div>
                    </div>
                    <div class="width-10">
                      <fa-icon class="print-icon" [classes]="['fa-2x']" [ngClass]="{ 'warn-color' : minutesWeekPercent >= 60, 'alert-color' : minutesWeekPercent < 60 }" [icon]="warningIcon" *ngIf="minutesWeekPercent < 80"></fa-icon>
                    </div>
                  </div>
                  <div class="text-center small-instruction">Minimum weekly time goal ({{ minWeeklyTimeGoal }} min)</div>
                </div>
                <div class="report-column" style="row-gap: 5px ;">
                  <div class="large-stat-label">Levels This Week: <span>{{ levelsThisWeek }}</span></div>
                  <div class="flex center-flex column-gap-10">
                    <div class="width-90 horizontal-gauge" [ngClass]="{ 'warn' : (levelsThisWeek < minWeeklyLevelsGoal), 'acceptable' : (levelsThisWeek >= minWeeklyLevelsGoal)}">
                      <div class="filled-gauge" [ngStyle]="{ 'background': 'linear-gradient(to right, transparent 0% ' + levelsWeekPercent + '%, #D8D8D8 ' + levelsWeekPercent + '% 100%)' }"></div>
                    </div>
                    <div class="width-10">
                      <fa-icon class="print-icon" [classes]="['fa-2x']" [ngClass]="{ 'warn-color' : (levelsThisWeek < minWeeklyLevelsGoal) }" [icon]="warningIcon" *ngIf="levelsThisWeek < minWeeklyLevelsGoal"></fa-icon>
                    </div>
                  </div>
                  <div class="text-center small-instruction">Minimum levels per week ({{ minWeeklyLevelsGoal }} levels)</div>
                </div>
              </div>
              <div class="report-column width-20 padding-15">
                <div class="report-row">
                  <div class="vertical-stat center-text center-flex">
                    <div class="label bold">Average<br/>Weekly Usage</div>
                    <div class="stat width-80">
                      <div class="stat-value inline">{{ (avgWeeklyUsage > 0) ? (avgWeeklyUsage | number: '1.0-0') : "0" }}</div>
                      <div class="stat-label inline margin-left-3">min</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="report-row">
      <div id="student-performance-wrapper" class="report-column width-100">
        <div class="report-panel">
          <div class="title">Performance</div>
          <div class="content">
            <div class="report-row print-flex-column">
              <div class="report-column width-35 print-flex-100">
                <div class="report-panel padding-10">
                  <div class="section-title">Screener Results</div>
                  <div class="report-row center-flex">
                    <div class="report-column width-50 padding-10 row-gap-0">
                      <div class="legend-green"><div class="pie-legend">Proficient</div></div>
                      <div class="legend-yellow"><div class="pie-legend">Some Risk</div></div>
                      <div class="legend-red"><div class="pie-legend">High Risk</div></div>
                    </div>
                    <div class="report-column width-50 row-gap-5 center-text padding-15 bg-light-gray">
                      <div class="bold dark-gray">Results</div>
                      <div class="screener-results-score" [ngClass]="screenerClass"> {{ screenerRange }} </div>
                      <div class="bold margin-top-15 dark-gray">Date Completed</div>
                      <div>{{ screenerDate }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="report-column width-65 print-flex-100">
                <div class="report-panel padding-10">
                  <div class="section-title flex split-flex">
                    <div>
                      Diagnostic Results
                    </div>
                    <div>
                      <button id="diagnostic-button" class="btn action-button print-hide" (click)="goToDiagnosticDetails()">
                        <fa-icon [icon]="diagnosticIcon"></fa-icon> Student Diagnostic Detail Report
                      </button>
                    </div>
                  </div>
                  <div class="report-row margin-top-15">
                    <table class="center-text flex-grow-1">
                      <thead>
                        <tr>
                          <th>Word Recognition</th>
                          <th>Decoding</th>
                          <th>Test Type</th>
                          <th>Date Completed</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let diagnostic of diagnostics">
                          <td class="padding-top-5 padding-bottom-5"><div [ngClass]="diagnostic.recognitionClass">{{ diagnostic.recognition }}</div></td>
                          <td class="padding-top-5 padding-bottom-5"><div [ngClass]="diagnostic.decodingClass">{{ diagnostic.decoding }}</div></td>
                          <td class="padding-top-5 padding-bottom-5">{{ diagnostic.title }}</td>
                          <td class="padding-top-5 padding-bottom-5">{{ diagnostic.completed }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="report-row">
              <div class="report-column width-100">
                <div class="report-panel padding-10">
                  <div class="section-title flex split-flex">
                    <div>
                      Objective Results
                    </div>
                    <div>
                      <button id="assessments-button" class="btn action-button print-hide" (click)="goToObjectiveDetails()">
                        <fa-icon [icon]="objectiveIcon"></fa-icon> Objective Assessment Report
                      </button>
                    </div>
                  </div>
                  <div class="report-row margin-top-25 center-space-flex">
                    <div class="report-column width-75">
                      <div class="objective-assessments-charts-container">
                        <div class="print-hide">
                          <canvas #objectiveChartCanvas
                                  id="objective-assessments-chart"
                                  baseChart
                                  [data]="objectiveChartData"
                                  [type]="chartType"
                                  [plugins]="chartPlugins"
                                  [options]="chartOpts"></canvas>
                        </div>
                        <img #objectiveChartImg id="cumulative-objective-chart-img" class="chart-img print-flex-100" />
                      </div>
                      <div class="report-row column-gap-15">
                        <div class="report-column gap-0 align-self-end text-center objective-dates" style="margin-left: -20px ;">
                          <div>Completed</div>
                          <div class="pre">Pretest:</div>
                          <div class="post">Posttest:</div>
                        </div>
                        <ng-container *ngFor="let objective of objectives">
                          <div *ngIf="objective.isComplete" class="report-column gap-0 align-self-end text-center objective-dates width-14">
                            <div class="pre">{{ objective.preComplete }}</div>
                            <div class="post">{{ objective.postComplete }}</div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="report-column width-10">
                      <div class="objective-assessments-chart-legend">
                        <div><div class="legend-box pretest-objective"></div><div class="legend-label">Pretest</div></div>
                        <div><div class="legend-box posttest-objective"></div><div class="legend-label">Posttest</div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
