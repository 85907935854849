import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { forkJoin, map, of, from, switchMap } from 'rxjs';

import {
  faBan,
  faCheckCircle,
  faCircleExclamation,
  faDollarSign,
  faPrint,
  faRotateLeft,
  faSpinner,
  faUserGraduate,
  faUserPen,
  faUserPlus,
  faUserXmark,
  faCircleXmark,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons';

import { StudentsService } from '../../core/services/students.service';
import { UtilityService } from '../../core/services/utility.service';

import { SchoolClass } from '../../core/models/school-class.model';
import { Student } from '../../core/models/student.model';
import { LoginService } from '../../core/services/login.service';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { ManagementUser } from '../../core/models/management-user.model';
import { DialogService } from 'src/app/core/services/dialog.service';
import { PrintService } from 'src/app/core/services/print.service';

@Component({
  selector: 'wf-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.css']
})
export class StudentsListComponent implements OnInit {
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement>;
  managementUser: ManagementUser | null = null ;
  isCleverTeacher: boolean = false ;
  isClasslinkTeacher: boolean = false ;
  isParentUser: boolean = false ;
  userRole: string = '' ;
  isDeleting: boolean = false ;
  canPurchase: boolean = false ;
  showCancelDialog: boolean = false;
  showPurchaseDialog: boolean = false ;
  showSectionAddMessage: boolean = false ;
  messageTitle: string = '' ;
  message: string = '' ;
  messageStatus: string = '' ;
  students: Student[] = [] ;
  filteredStudents: Student[] =[] ;
  grades: string[] = [] ;
  classes: SchoolClass[] = [] ;
  selectedStudents: Set<number> = new Set<number>();
  studentsSelected: boolean = false ;
  sectionAddMessage: any = {};
  printIcon = faPrint ;
  studentIcon = faUserGraduate ;
  addStudentIcon = faUserPlus ;
  deleteStudentIcon = faUserXmark ;
  editStudentIcon = faUserPen ;
  loadingIcon = faSpinner ;
  successIcon = faCheckCircle ;
  errorIcon = faCircleExclamation ;
  purchaseIcon = faDollarSign ;
  cancelIcon = faBan ;
  returnIcon = faRotateLeft ;
  closeIcon = faCircleXmark ;
  enrollIcon = faUserGroup ;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentsService,
    private utilityService: UtilityService,
    private loginService: LoginService,
    private sessionService: SessionStorageService,
    private dialogService: DialogService,
    private printService: PrintService
  ) { }

  ngOnInit(): void {
    this.managementUser = this.sessionService.getUserData() ;
    if (this.managementUser)
    {
      this.isParentUser = this.managementUser.isParentUser() ;
      this.userRole = this.managementUser.userRole ;
    }

    this.students = this.route.snapshot.data['resolveData'].students ;
    this.filteredStudents = this.students ;
    this.grades = this.utilityService.sortGrades(this.route.snapshot.data['resolveData'].grades) ;
    this.classes = this.utilityService.sortClasses(this.route.snapshot.data['resolveData'].classes) ;
    this.canPurchase = this.isParentUser && this.route.snapshot.data['resolveData'].licenseCount === 0 ;
    this.isCleverTeacher = this.sessionService.getSSOInfo()?.provider === 'clever' ;
    this.isClasslinkTeacher = this.sessionService.getSSOInfo()?.provider === 'classlink' ;
    this.sectionAddMessage = this.dialogService.getSectionAddMessage();
    if (this.sectionAddMessage.showMessage) {
      this.showSectionAddMessage = true;
    }
  }


  studentToggled(e: any) {
    if (e.target.checked)
    {
      this.selectedStudents.add(e.target.value) ;
    }
    else
    {
      this.selectedStudents.delete(e.target.value) ;
    }
    this.studentsSelected = this.selectedStudents.size > 0 ;
  }

  filterStudents(filterOpts: any) {
    this.filteredStudents = this.studentService.filterStudents(this.students, filterOpts) ;
    this.selectedStudents = new Set<number>() ;
  }

  archiveStudents() {
    this.isDeleting = true ;
    this.studentService.archiveStudents(Array.from(this.selectedStudents)).pipe(
      map((deleteStatuses) => {
        let failedCount = 0 ;
        let successCount = 0 ;

        deleteStatuses.forEach((status: number) => {
          if (status === 200) successCount++ ;
          else failedCount++ ;
        })
        return {
          failedCount: failedCount,
          successCount: successCount,
        } ;
      }),
      switchMap((statusCount) => {
        return forkJoin({
          statusCount: of(statusCount),
          students: this.studentService.getStudents()
        }) ;
      })
    ).subscribe({
      next: (results: any) => {
        let failedCount: number = results['statusCount']['failedCount'] ;
        let successCount: number = results['statusCount']['successCount'] ;
        this.students = results['students'] ;
        this.filteredStudents = results['students'] ;
        this.selectedStudents = new Set<number>() ;
        this.isDeleting = false ;

        this.messageTitle = 'Student Deletion' ;
        if (failedCount > 0)
        {
          // We had some delete requests return a non-200 status, this is an error
          this.messageStatus = 'error' ;
          this.message = `There was a problem deleting ${failedCount} ${failedCount > 1 ? 'students' : 'student'}` ;
        }
        else
        {
          // All requests returned a 200, so we were successful
          this.messageStatus = 'success' ;
          this.message = `${successCount} student${successCount > 1 ? 's were' : ' was'} successfully deleted` ;
        }
      }
    }) ;
  }

  cancelStudentSubscriptions() {
    this.studentService.cancelStudentSubscriptions(Array.from(this.selectedStudents)).pipe(
      map((cancelStatuses) => {
        return cancelStatuses.filter((status: number) => status !== 200).length ;
      }),
      switchMap((failedCount: any) => {
        this.messageTitle = 'Subscription Cancellation' ;
        if (failedCount > 0) {
          // We had some delete requests return a non-200 status, this is an error
          this.messageStatus = 'error';
          this.message = `There was a problem cancelling your ${failedCount} ${failedCount > 1 ? 'subscriptions' : 'subscription'}`;
        } else {
          // All requests returned a 200, so we were successful
          this.messageStatus = 'success';
          if (this.selectedStudents.size === 1) {
            this.message = `${this.selectedStudents.size} subscription was successfully cancelled`;
          } else {
            this.message = `${this.selectedStudents.size} subscriptions were successfully cancelled`;
          }
        }

        // Now that we have just cancelled a students subscription, check to see if this Parent user has
        // any valid active subscriptions left, if not then logout
        return this.studentService.isParentEnabled(this.sessionService.getUserData()?.userID)
      }),
      switchMap((parentEnabled: boolean) => {
        if (!parentEnabled)
        {
          // We are no longer enabled, so logout
          this.loginService.logout() ;
          return of([]) ;
        }
        else
        {
          // Still an active, enabled parent user so get updated students list
          return this.studentService.getStudents();
        }
      })
    ).subscribe({
      next: (students) => {
        this.students = students ;
        this.selectedStudents = new Set<number>() ;
        this.showCancelDialog = false ;
      }
    }) ;
  }

  addStudent() {
    this.router.navigateByUrl('student/add') ;
  }

  editStudent(studentId: number) {
    this.router.navigateByUrl(`student/edit/${studentId}`) ;
  }

  goToAddCleverSections() {
    this.router.navigateByUrl('clever/roster');
  }

  goToAddClassLinkSections() {
    this.router.navigateByUrl('classlink/roster');
  }

  closeSectionAddNotification() {
    this.showSectionAddMessage = false;
    this.dialogService.clearSectionAddMessage();
  }

  printStudents() {
    const userData = this.sessionService.getUserData();
    const teacher = `${userData?.firstName} ${userData?.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, userData?.school || '', teacher);
  }
}
