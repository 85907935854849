<div class="announcement" *ngIf="showAnnouncement && loggedIn">
  <fa-icon [icon]="announcementIcon"></fa-icon> Have questions about WordFlight? Join office hours. Register to get the Zoom link.
  <a href="https://learn.wordflight.com/office-hours" target="_blank">https://learn.wordflight.com/office-hours</a>
</div>
<wf-header></wf-header>
<wf-loading-mask></wf-loading-mask>
<div class="content-wrapper" [ngClass]="{ 'dark-background' : districtsBackground }" [ngStyle]="{'max-height' : (showAnnouncement && loggedIn) ? 'calc(100vh - 112px)' : 'calc(100vh - 80px)'}">
  <wf-sidebar class="sidebar-content" [ngClass]="{ 'hidden' : (!loggedIn || !shouldShowSidebar) }"></wf-sidebar>
  <div class="main-content" [ngClass]="{ 'sidebar-revert' : !shouldShowSidebar }">
    <router-outlet></router-outlet>
  </div>
</div>
