import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SchoolClass } from '../../../core/models/school-class.model';

@Component({
  selector: 'wf-students-filter',
  templateUrl: './students-filter.component.html',
  styleUrls: ['./students-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentsFilterComponent implements OnInit {
  @Input() classes: SchoolClass[] = [];
  @Input() filterExtraSupport: boolean = false;
  @Input() showAllGradeOption: boolean = true ;
  @Output() filterChangeEvent = new EventEmitter<Object>();
  supportOptions: string[] = ['All', 'Yes', 'No'];
  selectedGrade: string = '' ;
  selectedClass: number = 0 ;
  selectedExtraSupport: string = '' ;
  elementaryGrades: string[] = [] ;
  secondaryGrades: string[] = [] ;

  constructor() {}

  @Input() set grades(grades: string[]) {
    this.elementaryGrades = [] ;
    this.secondaryGrades = [] ;

    grades.forEach((grade) => {
      let gradeNum = parseInt(grade) ;
      if (isNaN(gradeNum)) return ;

      if (gradeNum < 5) this.elementaryGrades.push(grade) ;
      else this.secondaryGrades.push(grade) ;
    }) ;

    if (this.showAllGradeOption) this.selectedGrade = 'all' ;
    else if (this.elementaryGrades.length) this.selectedGrade = 'four_below_all' ;
    else if (this.secondaryGrades.length) this.selectedGrade = 'five_up_all' ;
  }

  ngOnInit(): void {
    this.selectedClass = (this.classes.length > 0) ? this.classes[0].schoolClassID : 0 ;
    this.selectedExtraSupport = this.supportOptions[0] ;

    if (this.showAllGradeOption) this.selectedGrade = 'all' ;
    else if (this.elementaryGrades.length) this.selectedGrade = 'four_below_all' ;
    else if (this.secondaryGrades.length) this.selectedGrade = 'five_up_all' ;
  }

  updateStudentFilter() {
    this.filterChangeEvent.emit(this.getFilterSettings()) ;
  }

  getFilterSettings() {
    return {
      grade: this.selectedGrade,
      class: this.selectedClass,
      extraSupport: this.filterExtraSupport ? this.selectedExtraSupport : 'all'
    }
  }

  resetFilterSettings() {
    if (this.showAllGradeOption) this.selectedGrade = 'all' ;
    else if (this.elementaryGrades.length) this.selectedGrade = 'four_below_all' ;
    else if (this.secondaryGrades.length) this.selectedGrade = 'five_up_all' ;

    this.selectedClass = (this.classes.length > 0) ? this.classes[0].schoolClassID : 0 ;
    this.selectedExtraSupport = this.supportOptions[0] ;
  }
}
