import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingService } from '../../../core/services/loading.service';

@Component({
  selector: 'wf-loading-mask',
  templateUrl: './loading-mask.component.html',
  styleUrls: ['./loading-mask.component.css']
})
export class LoadingMaskComponent implements OnInit, OnDestroy {
  loadingStatusSubscription: Subscription | undefined ;
  loading: boolean = false ;

  constructor(
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.loadingStatusSubscription = this.loadingService.loadingStatus.subscribe({
      next: (loading: boolean) => {
        this.loading = loading ;
      }
    }) ;
  }

  ngOnDestroy() {
    this.loadingStatusSubscription?.unsubscribe() ;
  }
}
