<!-- Container fluid makes the container use the full width of the parent element-->
<div class="container-fluid center-content">
  <div class="well-login col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-s-8 col-xs-offset-2">
    <div class="row">
      <div class="col-xs-12">
        <img style="float:left; padding: 5px;" src="/assets/images/managementicon.svg"/>
        <h2 class="management-login">Management Login</h2>
      </div>
    </div>
    <div *ngIf="!loadingToken && error">
      <div class="alert alert-danger" id="failedLoginDisplay">
        <h4>{{ error.message }}</h4>
        <p>{{ error.details }}</p><br>
        <p>Please try again later...</p>
      </div>
    </div>
    <div class="row" style="text-align: center ; font-size: 1.5em ; margin: 20px 0 ;" *ngIf="loadingToken">
      Please Wait...
    </div>
  </div>
</div>
