import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { concatMap, Observable, of, switchMap } from 'rxjs';
import { SurveyService } from '../../core/services/surveys.service';
import { SessionStorageService } from '../../core/services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyResolver  {
  constructor(
    private surveyService: SurveyService,
    private sessionStorageService: SessionStorageService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.surveyService.getAvailableSurveys().pipe(
      concatMap((surveyIds) => {
        return this.surveyService.getUserSurveys(this.sessionStorageService.getUserData()!.userID).pipe(
          switchMap((surveys) => {
            return of({
              surveyIds,
              surveys,
            }) ;
          })
        );
      })
    )
  }
}
