import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PageSession } from '../models/page-session.model';
import {SessionStorageService} from "./session-storage.service";

@Injectable({
  providedIn: 'root',
})
export class ManagementSessionService {
  private baseUrl = environment.dataServicesURL + 'manage/sessions';

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
  ) {}

  // Saves the management user session info to the database
  saveManagementSession(pageSessions: PageSession[]): Observable<any> {
    let reqOptions = {
      withCredentials: true,
      observe: 'response',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    return this.http.post(`${this.baseUrl}/pageSessions`, {
      pageSessions,
      login: this.sessionStorageService.getLoginTime()!,
    }, reqOptions as any);
  }
}
