export const environment = {
  production: true,
  dataServicesURL: 'https://ptwfservices.wordflight.com/',
  assetServerURL: 'https://ptstudent.wordflight.com',
  studentSiteURL: 'https://ptstudent.wordflight.com',
  SENTRY_DSN: '',
  CLEVER_REDIRECT_URI: 'https://ptwfservices.wordflight.com/clever/login',
  CLEVER_CLIENT_ID: '6c6d4dfbf1c99e1e747e',
  CLASSLINK_REDIRECT_URI: '',
  CLASSLINK_CLIENT_ID: '',
  TYPOGRAPHY_URL: 'https://cloud.typography.com/7034538/7531232/css/fonts.css',
  versionNumber: '5.015',
  hubspotPrivateAppToken: 'pat-na1-01e3b1f6-ca95-4623-8a90-1c7670c32b0b',
  hubspotPortalId: 24309339,
  showAnnouncement: false,
  teacherReviewTimeoutHour: 1,
};
