import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { District } from '../../../core/models/district.model';
import { School } from '../../../core/models/school.model';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ManagementUser } from '../../../core/models/management-user.model';
import { ReportsService } from '../../../core/services/reports.service';

@Component({
  selector: 'wf-school-teacher-filter',
  templateUrl: './school-teacher-filter.component.html',
  styleUrls: ['./school-teacher-filter.component.css']
})
export class SchoolTeacherFilterComponent implements OnInit {
  @Input() districts: District[] = [] ;
  @Input() schools: School[] = [] ;
  @Input() teachers: ManagementUser[] = [] ;
  @Input() showDistrict: boolean = true ;
  @Input() showTeacher?: boolean = true ;
  @Input() error: string = '' ;
  @Output() filterChangeEvent = new EventEmitter<Object>() ;
  selectedDistrict: District | null = null ;
  selectedSchool: School | null = null ;
  selectedTeacher: ManagementUser | null = null ;
  currentUser: ManagementUser | null = null ;

  constructor(
    private sessionService: SessionStorageService,
    private reportsService: ReportsService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionService.getUserData() ;
    this.showDistrict = this.currentUser!.isFILUser() ;

    // Check if we already have district, school or teacher set
    let serviceDistrict = this.reportsService.getSelectedDistrictForReports() ;
    this.districts.forEach((district) => {
      if (district.districtID === serviceDistrict.districtID)
      {
        this.selectedDistrict = district ;
      }
    }) ;


    let serviceSchool = this.reportsService.getSelectedSchoolForReports() ;
    this.schools.forEach((school) => {
      if (school.schoolID === serviceSchool.schoolID)
      {
        this.selectedSchool = school ;
      }
    }) ;

    let serviceTeacher = this.reportsService.getSelectedTeacherForReports() ;
    this.teachers.forEach((teacher) => {
      if (teacher.userID === serviceTeacher.userID)
      {
        this.selectedTeacher = teacher ;
      }
    }) ;
  }

  changeDistrict() {
    this.reportsService.getSchools(this.currentUser!, this.selectedDistrict!.districtID).subscribe({
      next: (schools) => {
        this.schools = schools ;

        this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
        this.selectedSchool = schools[0] ;

        this.teachers = [] ;
        this.teachers.unshift(ManagementUser.getGenericUser()) ;
        this.selectedTeacher = this.teachers[0] ;

        this.updateFilter() ;
      }
    }) ;
  }

  changeSchool() {
    this.reportsService.getTeachers(this.currentUser!, this.selectedSchool!.schoolID).subscribe({
      next: (teachers) => {
        this.teachers = teachers ;

        // Whenever a new school is selected, make our teachers include and 'All' and set it as selected
        this.teachers.unshift(ManagementUser.getGenericUser()) ;
        this.selectedTeacher = teachers[0] ;

        this.updateFilter() ;
      }
    }) ;
  }

  updateFilter() {
    let filterOptions = this.getFilterSettings() ;
    this.sessionService.setSelectedDistrict(filterOptions.district!) ;
    this.sessionService.setSelectedSchool(filterOptions.school!) ;
    this.sessionService.setSelectedTeacher(filterOptions.teacher!) ;

    this.filterChangeEvent.emit(filterOptions) ;
  }

  getFilterSettings() {
    return {
      district: this.selectedDistrict,
      school: this.selectedSchool,
      teacher: this.selectedTeacher,
    } ;
  }

  trackByDistrictId(index: number, obj: District) {
    return obj.districtID ;
  }

  trackBySchoolId(index: number, obj: School) {
    return obj.schoolID ;
  }

  trackByTeacherId(index: number, obj: ManagementUser) {
    return obj.userID ;
  }
}
