import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  faCheckCircle,
  faCircleExclamation,
  faDiagramSuccessor,
  faPrint,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';

import { ManagementUser } from '../../../core/models/management-user.model';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { District } from '../../../core/models/district.model';
import { School } from '../../../core/models/school.model';
import { SubscriptionTypes } from '../../../core/models/subscription-types.model';
import { Student } from '../../../core/models/student.model';
import { Objective } from '../../../core/models/objective.model';
import { ReportsService } from '../../../core/services/reports.service';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { PrintService } from 'src/app/core/services/print.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'wf-objective-prepost',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.css']
})
export class ObjectivePrePostComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  showCumulativeDates: boolean = false ;
  schoolFilterError: string = '' ;
  messageTitle: string = '' ;
  message: string = '' ;
  messageStatus: string = '' ;
  fullProduct: string = SubscriptionTypes.FullProduct ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  students: Student[] = [] ;
  selectedStudent: Student | null = null ;
  navigateStudentId: number = -1 ;
  objectives: { [ k: string ]: Objective } = {} ;
  selectedObjective: Objective | null = null ;
  cumulativeObjectives: Objective | null = null ;
  printIcon = faPrint ;
  objectiveIcon = faDiagramSuccessor ;
  warningIcon = faTriangleExclamation ;
  errorIcon = faCircleExclamation ;
  orderedColumns: string[][] = [
    [ 'Decoding',  'Words'],
    [ 'Using', 'Syllables' ],
    [ 'Recognizing', 'Words', '(untimed)' ],
    [ 'Recognizing', 'Words', '(timed)' ],
  ] ;
  objectiveChartData: ChartData<'bar'> = {
    labels: this.orderedColumns,
    datasets: [
      {
        label: 'Pretest',
        data: [],
        backgroundColor: '#7E93EE',
      },
      {
        label: 'Posttest',
        data: [],
        backgroundColor: '#1E3BB8',
      }
    ]
  } ;
  cumulativeChartData: ChartData<'bar'> = {
    labels: this.orderedColumns,
    datasets: [
      {
        label: 'Pretest',
        data: [],
        backgroundColor: '#995895',
      },
      {
        label: 'Posttest',
        data: [],
        backgroundColor: '#491446',
      }
    ]
  } ;
  chartType: ChartType = 'bar' ;
  chartOpts: ChartConfiguration['options'] = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          lineWidth: 0
        }
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
        },
        title: {
          display: true,
          text: '% Correct',
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    }
  } ;

  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;
  @ViewChild('objectiveChartCanvas') objectiveChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('cumulativeChartCanvas') cumulativeChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('objectiveChartImg') objectiveChartImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('cumulativeChartImg') cumulativeChartImg!: ElementRef<HTMLImageElement> ;
  @ViewChildren(BaseChartDirective) charts!: QueryList<BaseChartDirective> ;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportsService: ReportsService,
    private sessionStorageService: SessionStorageService,
    private printService: PrintService
  ) {
    this.navigateStudentId = parseInt(this.router.getCurrentNavigation()?.extras?.state?.['studentId']) ;
    if (isNaN(this.navigateStudentId)) this.navigateStudentId = -1 ;
  }

  ngOnInit(): void {
    let resolvedData = this.route.snapshot.data['resolveData'] ;
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;

    if (resolvedData.erred)
    {
      this.schoolFilterError = resolvedData.message ;
    }
    else
    {
      this.students = resolvedData.students ;

      // If we had a student ID passed to us in nav, attempt to find the student and if we find them, get data and display
      if (this.navigateStudentId > 0)
      {
        this.selectedStudent = this.students.find((student) => student.userID === this.navigateStudentId) || null ;
        if (this.selectedStudent)
        {
          this.updateStudent() ;
          return ;
        }
        else
        {
          this.message = 'The Objective data for the requested Student could not be loaded, showing the first available Student' ;
          this.messageTitle = 'Requested Student Not Found' ;
          this.messageStatus = 'warning' ;
        }
      }

      // If we get here, we did not have a student passed to us on navigation, so select the first student and display
      this.selectedStudent = this.students[0] ;
      this.processObjectiveData(resolvedData.objectiveData) ;
    }

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts ;
    this.schools = this.route.snapshot.data['filterData'].schools ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser())
    {
      // The FIL user and District user roles can select all schools
      this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    }

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() || this.currentUser!.isSchoolUser())
    {
      // The FIL user and District and School user roles can select all teachers
      this.teachers.unshift(ManagementUser.getGenericUser()) ;
    }
  }

  filterSchoolTeacher() {
    this.reportsService.getObjectiveData().subscribe({
      next: (data: any) => {
        if (data.erred)
        {
          this.schoolFilterError = data.message ;
          this.selectedObjective = null ;
        }
        else
        {
          this.schoolFilterError = '' ;
          this.students = data.students ;

          if (this.students.length)
          {
            this.selectedStudent = this.students[0] ;
            this.processObjectiveData(data.objectiveData) ;
          }
          else
          {
            this.selectedStudent = null ;
            this.selectedObjective = null ;
          }
        }
      },
      error: (err: any) => {
        Sentry.captureException(err, {
          tags: {
            section: 'reports',
            report: 'system-objective',
            action: 'get-objective-data',
          }
        }) ;
      }
    }) ;
  }

  updateStudent() {
    let schoolId = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let teacherId = this.reportsService.getSelectedTeacherForReports().userID ;

    // Clear out any old loading messages/warnings
    this.message = '' ;
    this.messageTitle = '' ;
    this.messageStatus = '' ;
    this.reportsService.getObjectivePrePostPerformanceData(schoolId, teacherId, this.selectedStudent!.userID).subscribe({
      next: (objectiveData) => {
        this.processObjectiveData(objectiveData) ;
      },
      error: (err) => {
        this.message = 'There was an error trying to load the Objective data for the selected Student, try again later or ask us for help' ;
        this.messageTitle = 'Error Loading Data' ;
        this.messageStatus = 'error' ;

        Sentry.captureException(err, {
          tags: {
            section: 'reports',
            report: 'system-objective',
            schoolId: schoolId,
            teacherId: teacherId,
            studentId: (this.selectedStudent) ? this.selectedStudent.userID : 'no-student-selected',
          }
        }) ;
      }
    }) ;
  }

  updateObjectiveChart() {
    let objData = this.createDataSet(this.selectedObjective?.taskData) ;
    this.objectiveChartData.datasets[0].data = objData['pretest'] ;
    this.objectiveChartData.datasets[1].data = objData['posttest'] ;
    if (this.charts && this.charts.first) this.charts.first.update() ;
  }

  trackByStudentId(index: number, obj: Student) {
    return obj.userID ;
  }

  printReport() {
    // Convert charts to images before print:
    this.reportsService.convertCanvasToImage(this.objectiveChartCanvas.nativeElement, this.objectiveChartImg.nativeElement);
    this.reportsService.convertCanvasToImage(this.cumulativeChartCanvas.nativeElement, this.cumulativeChartImg.nativeElement);

    let school = this.reportsService.getSelectedSchoolForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }

  private processObjectiveData(objectiveData: { [ k: string ]: Objective }) {
    if (Object.keys(objectiveData).length === 0) return ;

    let objectiveCnt = 0 ;
    this.objectives = {} ;

    Object.keys(objectiveData).forEach((objectiveNum) => {
      if (objectiveNum !== 'cumulative')
      {
        this.objectives[objectiveNum] = objectiveData[objectiveNum] ;
        objectiveCnt++ ;
      }
    }) ;

    this.selectedObjective = this.objectives[`${objectiveCnt}`] ;
    this.cumulativeObjectives = objectiveData['cumulative'] ;
    this.showCumulativeDates = (this.cumulativeObjectives.taskData !== null && Object.keys(this.cumulativeObjectives.taskData).length !== 0) ;

    // Now, load our datasets
    if (this.selectedObjective)
    {
      this.updateObjectiveChart() ;
    }

    if (this.cumulativeObjectives.taskData && Object.keys(this.cumulativeObjectives.taskData).length)
    {
      let cumulativeData = this.createDataSet(this.cumulativeObjectives.taskData) ;
      this.cumulativeChartData.datasets[0].data = cumulativeData['pretest'] ;
      this.cumulativeChartData.datasets[1].data = cumulativeData['posttest'] ;
      if (this.charts && this.charts.last) this.charts.last.update() ;
    }
    else
    {
      this.cumulativeChartData.datasets[0].data = [] ;
      this.cumulativeChartData.datasets[1].data = [] ;
      if (this.charts && this.charts.last) this.charts.last.update() ;
    }
  }

  private createDataSet(taskData: any) {
    let predata: number[] = [] ;
    let postdata: number[] = [] ;

    // Create our datasets in order
    this.orderedColumns.forEach((area) => {
      let tag = area.join(' ') ;
      if (taskData[tag])
      {
        predata.push(taskData[tag].pretest.score)
        postdata.push(taskData[tag].posttest.score) ;
      }
    }) ;

    return {
      pretest: predata,
      posttest: postdata,
    } ;
  }

  protected readonly faCircleExclamation = faCircleExclamation;
  protected readonly faCheckCircle = faCheckCircle;
}
