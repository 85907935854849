import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';

import { ResourcesService } from '../../core/services/resources.service';

@Injectable({
  providedIn: 'root'
})
export class HtmlResourceResolver  {

  constructor(
    private router: Router,
    private resourcesService: ResourcesService,
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let link = this.router.getCurrentNavigation()?.extras?.state?.['resourceUrl'] ;
    let videoUrl = this.router.getCurrentNavigation()?.extras?.state?.['videoUrl'] ;

    if (!link)
    {
      // If we did not have a link to html resource, return an error
      return from(
        'Uh-oh! It looks like the resource link was not set, please contact us so we can fix the problem.'
      ) ;
    }

    return this.resourcesService.getHtmlResource(link).pipe(
      map((response: any) => {
        return {
          content: response,
          videoUrl: videoUrl,
        }
      }),
      catchError((error) => {
        return from(
          `The resource you requested (${link}) was not found. Please contact us and let us know so we can fix the problem.`
        ) ;
      })
    ) ;
  }
}
