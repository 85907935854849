export class Resources {
    isHtml: boolean ;
    isVideo: boolean ;
    grade: string ;
    category: string ;
    link: string ;
    icon: string ;
    title: string ;
    product: string ;
    gradeDisplayName?: string ;
    resourceUrl?: string ;
    lessonType?: string;
    activity?: string;
    unit?: string;
    description?: string ;

    constructor(item: any) {
        this.isHtml = item.isHtml || false ;
        this.isVideo = item.isVideo || false ;
        this.grade = item.grade ;
        this.category = item.category ;
        this.link = item.link ;
        this.icon = item.icon ;
        this.title = item.title ;
        this.product = item.product ;
        this.gradeDisplayName = this.grade === "EL" ? "Elementary" :
                                this.grade === "MS" ? "Secondary"  :
                                this.grade ;
        if (item.resourceUrl) this.resourceUrl = item.resourceUrl ;
        if (item.lessonType) this.lessonType = item.lessonType ;
        if (item.activity) this.activity = item.activity ;
        if (item.unit) this.unit = item.unit ;
        if (item.description) this.description = item.description ;

    }
}
