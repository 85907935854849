import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from "underscore";

import * as Sentry from "@sentry/angular";

import {
  faMagnifyingGlassChart,
  faPrint,
  faBook,
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';

import {faMinusSquare, faPlusSquare} from '@fortawesome/free-regular-svg-icons' ;

import { ManagementUser } from '../../../../core/models/management-user.model';
import { District } from '../../../../core/models/district.model';
import { School } from '../../../../core/models/school.model';
import { SubscriptionTypes } from '../../../../core/models/subscription-types.model';
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { Student } from '../../../../core/models/student.model';
import { Unit } from '../../../../core/models/unit.model';
import { InterventionTaskStatus } from '../../../../core/models/intervention-task-status.model';
import {ReportsService} from "../../../../core/services/reports.service";
import { PrintService } from 'src/app/core/services/print.service';
import {TaskCategories, TaskCategory, TaskCategoryTypes} from '../../../../core/models/task-category.model';
import { LoadingService } from '../../../../core/services/loading.service';

@Component({
  selector: 'wf-unit-detail',
  templateUrl: './unit-detail.component.html',
  styleUrls: ['./unit-detail.component.css']
})
export class UnitDetailComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  taskRetrievalError: boolean = false ;
  showSchoolFilter: boolean = false ;
  schoolFilterError: string = '' ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  students: Student[] = [] ;
  selectedStudent: Student | undefined ;
  units: Unit[] = [] ;
  selectedUnit: Unit | undefined ;
  taskCategories: Array<TaskCategory> = TaskCategories ;
  defaultTaskCategory: TaskCategory = TaskCategories[0] ;
  selectedTaskCategory: TaskCategory = this.defaultTaskCategory ;
  studentTasks: InterventionTaskStatus[] = [] ;
  filteredStudentTasks: InterventionTaskStatus[] = [] ;
  sortColumn: string = '' ;
  sortReverse: boolean = false ;
  fullProduct: string = SubscriptionTypes.FullProduct ;
  detailIcon = faMagnifyingGlassChart ;
  printIcon = faPrint ;
  titleIcon = faBook ;
  correctIcon = faCircleCheck ;
  incorrectIcon = faCircleXmark ;
  expandIcon = faPlusSquare ;
  collapseIcon = faMinusSquare ;
  expandedTaskId: number = -1 ;

  @ViewChild('printContent') printContent!: ElementRef<HTMLElement>;

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private sessionStorageService: SessionStorageService,
    private printService: PrintService,
    private loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;

    if (this.route.snapshot.data['resolveData'].erred)
    {
      this.schoolFilterError = this.route.snapshot.data['resolveData'].message ;
    }
    else
    {
      this.students = this.route.snapshot.data['resolveData'] ;
    }

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts ;
    this.schools = this.route.snapshot.data['filterData'].schools ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser())
    {
      // The FIL user and District user roles can select all schools
      this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    }

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() || this.currentUser!.isSchoolUser())
    {
      // The FIL user and District and School user roles can select all teachers
      this.teachers.unshift(ManagementUser.getGenericUser()) ;
    }
  }

  filterSchoolTeacher() {
    let teacherId = this.reportsService.getSelectedTeacherForReports().userID ;

    // We need to make sure we have a teacher selected
    if (teacherId === 0)
    {
      this.schoolFilterError = 'Please select a Teacher' ;

      return ;
    }

    this.reportsService.getStudentsForTeacher(teacherId).subscribe({
      next: (students) => {
        this.students = students ;
        this.selectedStudent = undefined ;
        this.studentTasks = [] ;
        this.filteredStudentTasks = [] ;
        this.schoolFilterError = '' ;
      },
      error: (error) => {
        this.schoolFilterError = error ;
      }
    })
  }

  filterTaskDetailsList() {
    this.filteredStudentTasks = [] ;
    this.studentTasks.forEach((task) => {
      if (task.unitNumber === this.selectedUnit!.number && (this.selectedTaskCategory.type === TaskCategoryTypes.ALL || (task.taskCategory && this.selectedTaskCategory.type === task.taskCategory)))
      {
        this.filteredStudentTasks.push(task) ;
      }
    }) ;
  }

  getInterventionTaskDetails() {
    // When a student is selected, we retrieve the unit details for that student
    let schoolId = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let teacherId = this.reportsService.getSelectedTeacherForReports().userID ;

    this.schoolFilterError = '' ;
    this.studentTasks = [] ;
    this.filteredStudentTasks = [] ;
    this.taskRetrievalError = false ;
    this.loadingService.start() ;
    this.reportsService.getUnitDetailReportData(schoolId, teacherId, this.selectedStudent!.userID).subscribe({
      next: (tasks) => {
        this.loadingService.finish() ;
        this.studentTasks = tasks ;

        // Now, create our units from the returned tasks. To do this, we need to
        // go through all our task details and get the unit it was associated with,
        // building up a unique list of unit info
        this.units = [] ;
        tasks.forEach((task) => {
          // Adjust some of our properties for these tasks
          if (!task.taskName.includes('Masked') && (task.taskID.charAt(task.taskID.length - 2) === 'M' || task.taskID.charAt(task.taskID.length - 3) === 'M'))
          {
            task.taskName += ' Masked' ;
          }

          if (task.taskID.startsWith("PASS") ||
              task.taskID.startsWith("PAP") ||
              task.taskID.startsWith("PAQ") ||
              task.taskID.startsWith("FTP") ||
              task.taskID.startsWith("MTP"))
          {
            task.wordListString = 'Single Wordlist' ;
          }

          let addedBefore = this.units.filter((unit) => unit.number === task.unitNumber);
          if (!addedBefore.length)
          {
            this.units.push({
              number: task.unitNumber,
              summary: `${task.unitNumber} - ${task.unitDescription}`,
              description: task.unitDescription,
              dateCompleted: task.dateCompleted,
            }) ;
          }else{
            // item has already been added before so we need to update dateCompleted
            if(addedBefore[0].dateCompleted < task.dateCompleted){
              let indexBefore = this.units.indexOf(addedBefore[0]);
              this.units[indexBefore].dateCompleted = task.dateCompleted;
            }
          }
        }) ;

        // Sort units based on task completion date and set selected unit to first one
        if(this.units.length > 0){
          this.units.sort((task1, task2) => task1.dateCompleted > task2.dateCompleted ? -1 : task1.dateCompleted < task2.dateCompleted ? 1 : 0);
          this.selectedUnit = this.units[0]
          // Trigger filtering tasks to update values in the table initially
          this.filterTaskDetailsList()
        }
      },
      error: (err: any) => {
        this.taskRetrievalError = true ;
        this.loadingService.finish() ;

        Sentry.captureException(err, {
          tags: {
            section: 'reports',
            report: 'unit-detail',
            schoolId: schoolId,
            teacherId: teacherId,
            studentId: (this.selectedStudent) ? this.selectedStudent.userID : 'no-student-selected',
          }
        }) ;
      }
    })
  }

  refreshUnitDetails(){
    if(this.selectedStudent !== undefined ){
      this.getInterventionTaskDetails() ;
    }
  }

  sortTable(col: string) {
    if (this.sortColumn === col)
    {
      this.sortReverse = !this.sortReverse ;
    }
    this.sortColumn = col ;

    this.filteredStudentTasks = (this.sortReverse) ? _.sortBy(this.filteredStudentTasks, col).reverse() : _.sortBy(this.filteredStudentTasks, col) ;
  }

  toggleTaskTrials(taskId: number) {
    this.expandedTaskId = (taskId === this.expandedTaskId) ? -1 : taskId ;
  }

  trackByStudentId(index: number, obj: Student) {
    return obj.userID ;
  }

  printSummary() {
    let school = this.reportsService.getSelectedSchoolForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }
}
