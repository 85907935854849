import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { faGauge, faPieChart } from '@fortawesome/free-solid-svg-icons';

import { Student } from '../../../../core/models/student.model';
import { DashboardService } from '../shared/dashboard.service';

@Component({
  selector: 'wf-parent-dashboard',
  templateUrl: './parent-dashboard.component.html',
  styleUrls: ['./parent-dashboard.component.css']
})
export class ParentDashboardComponent implements OnInit {
  dashboardIcon = faGauge ;
  minutesGoal: number = 80 ;
  levelsGoal: number = 8 ;
  totalLevels: number = 120 ;
  students: Student[] = [] ;
  selectedStudent: Student | null = null ;
  minutesThisWeek: number = 0 ;
  levelsCompleted: number = 0 ;
  todaysPoints: number = 0 ;
  pointsThisWeek: number = 0 ;
  totalPoints: number = 0 ;
  minutesGoalAchievedImage: string = '' ;
  levelGoalAchievedImage: string = '' ;
  dateLastLogin: string = '-' ;
  currentLevel: number = 0 ;
  currentLevelPercent: number = 0 ;
  reportIcon = faPieChart ;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
    this.students = this.route.snapshot.data['resolveData'].students ;
    this.selectedStudent = (this.students.length > 0) ? this.students[0] : null ;

    this.loadDashboardData(this.route.snapshot.data['resolveData'].studentData) ;
  }

  loadDashboardData(studentData: any) {
    if (Object.keys(studentData).length === 0) return ;

    this.todaysPoints = studentData.studentDailyPoints ;
    this.pointsThisWeek = studentData.studentWeeklyPoints ;
    this.totalPoints = studentData.studentTotalPoints ;
    this.dateLastLogin = studentData.lastUsageDate ;
    this.currentLevel = studentData.currentLevel ;
    this.currentLevelPercent = Math.round(this.currentLevel / 120 * 100) ;

    this.minutesThisWeek = studentData.weeklySessionTime ;
    if (this.minutesThisWeek >= this.minutesGoal)
    {
      this.minutesGoalAchievedImage = '../assets/images/parentDashboard/checkbox_goalAchievedGreen_bc.svg' ;
    }
    else
    {
      this.minutesGoalAchievedImage = '../assets/images/parentDashboard/checkbox_goalAchievedGreen_bu.svg' ;
    }

    this.levelsCompleted = studentData.weeklyLevelsCompleted ;
    if (this.levelsCompleted >= this.levelsGoal)
    {
      this.levelGoalAchievedImage = '../assets/images/parentDashboard/checkbox_goalAchievedPurple_bc.svg' ;
    }
    else
    {
      this.levelGoalAchievedImage = '../assets/images/parentDashboard/checkbox_goalAchievedPurple_bu.svg' ;
    }
  }

  changeStudent() {
    this.dashboardService.getParentDashboardDataForStudent(this.selectedStudent!.userID).subscribe({
      next: (studentData) => {
        this.loadDashboardData(studentData) ;
      }
    }) ;
  }

  trackByStudentId(index: number, obj: Student) {
    return obj.userID ;
  }
}
