import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';

let PROVIDER_IDENTIFIER = "clever";

@Injectable()
export class CleverAuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private sessionStorageService: SessionStorageService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let ssoInfo = this.sessionStorageService.getSSOInfo();

    if (request.withCredentials && ssoInfo?.provider.toLowerCase() === PROVIDER_IDENTIFIER)
    {
      let authHeader = `Bearer ${ssoInfo.bearerToken}`;
      request = request.clone({
        setHeaders: {
          Authorization: authHeader,
          'WF-SSO-Provider': PROVIDER_IDENTIFIER,
        }
      });
    }

    return next.handle(request);
  }
}
