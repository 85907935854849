<div>
    <h3>Settings</h3>
    <div>
      <div class="report-section-header">
        <h4 class="results-report-header"><fa-icon [icon]="settingsIcon"></fa-icon> Change Password</h4>
      </div>
      </div>
      <div style="margin-left: 0px" class="diagnosticproduct-management-table padding-15">
        <div class="alert alert-success message" *ngIf="successMessage">
          <div class="icon">
            <fa-icon [icon]="successIcon"></fa-icon>
          </div>
          <div>
            <div class="bold">Change Password</div>
            {{ successMessage }}
          </div>
        </div>
        <form [formGroup]="changePasswordForm" id="password-form" (ngSubmit)="changePassword()" novalidate>
          <div class="form-group-no-margin-bottom required">
            <label>Current Password</label>
            <div>
              <input class="form-control settings-input-text" [type]="showCurrentPassword ? 'text' : 'password'" name="currentPassword" formControlName="currentPassword" placeholder="Enter your current password" required>
              <button type="button" style="display: inline-block" class="show-password-button" (click)="currentPasswordToggle()" tabindex="-1"></button>
            </div>
            <div *ngIf="currentPassword?.invalid && (currentPassword?.dirty || currentPassword?.touched)" class="help-block small-text">
              <div *ngIf="currentPassword?.errors?.['required']">
                <fa-icon [icon]="errorIcon"></fa-icon> Your current password is required
              </div>
              <div *ngIf="currentPassword?.errors?.['incorrect']">
                <fa-icon [icon]="errorIcon"></fa-icon> Your current password is not correct
              </div>
            </div>
          </div>
          <div class="form-group-no-margin-bottom required">
            <label>New Password</label>
            <div>
              <input class="form-control settings-input-text" [type]="showNewPassword ? 'text' : 'password'" name="newPassword" formControlName="newPassword" placeholder="Enter your desired new password" required>
              <button type="button" style="display: inline-block" class="show-password-button" (click)="newPasswordToggle()" tabindex="-1"></button>
            </div>
            <div *ngIf="newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)" class="help-block small-text">
              <div *ngIf="newPassword?.errors?.['required']">
                <fa-icon [icon]="errorIcon"></fa-icon> The new password is required
              </div>
              <div *ngIf="newPassword?.errors?.['pattern']">
                <fa-icon [icon]="errorIcon"></fa-icon> The new password must be between 8 and 20 alphanumeric characters in length and include at least one letter and one number
              </div>
            </div>
          </div>
          <div class="form-group-no-margin-bottom required">
            <label>Confirm Password</label>
            <div>
              <input class="form-control settings-input-text" [type]="showConfirmPassword ? 'text' : 'password'" name="confirmPassword" formControlName="confirmPassword" placeholder="Re-enter your desired new password" required>
              <button type="button" style="display: inline-block" class="show-password-button" (click)="confirmPasswordToggle()" tabindex="-1"></button>
            </div>
            <div *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)" class="help-block small-text">
              <div *ngIf="confirmPassword?.errors?.['required']">
                <fa-icon [icon]="errorIcon"></fa-icon> You must re-enter your new password
              </div>
              <div *ngIf="confirmPassword?.errors?.['mustMatch']">
                <fa-icon [icon]="errorIcon"></fa-icon> The re-entered password does not match
              </div>
            </div>
          </div>
          <div>
            <button style="display: inline-block;" class="btn action-button" id="password-submit" type="submit" [disabled]="changePasswordForm.invalid || isChangingPassword">
              <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="isChangingPassword"></fa-icon>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>


