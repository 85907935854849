import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';

import {
  faCircleExclamation,
  faCircleInfo,
  faComments,
  faPaperPlane,
  faSpinner,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';

import { ManagementUser } from '../../core/models/management-user.model';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { LoginService } from '../../core/services/login.service';
import { SidebarService } from '../../core/services/sidebar.service';
import { SurveyService } from 'src/app/core/services/surveys.service';
import { environment } from '../../../environments/environment';
import { FormResponse } from './survey.model';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})

export class SurveyComponent implements OnInit {
  formFields: any[] = [];
  formId!: string ;
  token: string = environment.hubspotPrivateAppToken;
  portalId: number = environment.hubspotPortalId;
  supportIcon = faComments;
  sendIcon = faPaperPlane;
  userData: ManagementUser | null = null ;
  firstName!: string;
  lastName!: string;
  fullName!: string;
  topic!: string;
  message!: string;
  email!: string;
  userLoggedIn: boolean = false ;
  loginStatusSubscription: Subscription | undefined ;
  submitted: boolean = false ;
  surveyError: boolean = false;
  errorMessage: string = '' ;
  responseMessage: string = '' ;
  surveyID: number = 1;
  userSurveys: any[] = [];
  submitData: any = {};
  noSurveys: boolean = false ;
  timeToTake: boolean = false ;
  surveyLoading: boolean = false ;
  infoIcon = faCircleInfo ;
  errorIcon = faCircleExclamation ;
  loadingIcon = faSpinner ;
  successIcon = faThumbsUp ;

  constructor(
    private route: ActivatedRoute,
    public loginService: LoginService,
    public sessionStorageService: SessionStorageService,
    public sidebarService: SidebarService,
    public surveyService: SurveyService
  ) {}

  ngOnInit(): void {
    let resolvedData = this.route.snapshot.data['resolveData'] ;
    let surveyIds = resolvedData.surveyIds ;
    this.userSurveys = resolvedData.surveys ;

    this.loginStatusSubscription = this.loginService.loginStatus$.subscribe((loggedIn) => {
      this.userLoggedIn = loggedIn ;
      if (loggedIn)
      {
        this.userData = this.sessionStorageService.getUserData() ;
        this.firstName = this.userData?.firstName || '';
        this.lastName = this.userData?.lastName || '';
        this.email = this.userData?.email || '';
      }
    }) ;

    if (!surveyIds.length)
    {
      this.noSurveys = true ;
    }
    else
    {
      this.surveyID = surveyIds[surveyIds.length - 1] ;
      const now = new Date();
      const startWindow = new Date(now.getFullYear(), 3, 15);
      const endWindow = new Date(now.getFullYear(), 11, 15);
      const isNowInWindow = now >= startWindow && now <= endWindow;
      const lastSurveyResponses = (this.userSurveys || []).filter((userSurvey) => userSurvey.SurveyID === this.surveyID) ;

      if (!lastSurveyResponses.length && ((this.userData?.numberOfUserLogins ?? 0) >= 5))
      {
        this.timeToTake = true ;
      }
      else if (lastSurveyResponses.length)
      {
        const latestSurvey = lastSurveyResponses[lastSurveyResponses.length - 1] ;
        const latestSurveyDate = new Date(latestSurvey.DateTaken[0], latestSurvey.DateTaken[1] - 1, latestSurvey.DateTaken[2], latestSurvey.DateTaken[3], latestSurvey.DateTaken[4], latestSurvey.DateTaken[5]);
        const isLatestSurveyInWindow = latestSurveyDate >= startWindow && latestSurveyDate <= endWindow;
        this.timeToTake = isNowInWindow !== isLatestSurveyInWindow ;
      }
    }

    if (this.timeToTake)
    {
      this.getFormId();
    }
  }

  getForm(): void {
    this.surveyLoading = true ;
    this.surveyService.getFormFromBackend(this.surveyID, this.formId, this.token)
      .subscribe({
        next: (response: FormResponse) => {
          response.formFieldGroups.forEach(group => {
            group.fields.forEach(field => {
              this.formFields.push(field);
            });
          });

          this.surveyLoading = false ;
        },
        error: (error) => {
          this.surveyError = true;
          this.surveyLoading = false ;

          Sentry.captureException(error, {
            tags: {
              section: 'survey',
              action: 'get-form',
            }
          }) ;
        }
      });
  }

  getDynamicValue(fieldName: string): any {
    switch (fieldName) {
      case 'firstname':
        return this.firstName;
      case 'lastname':
        return this.lastName;
      case 'email':
        return this.email;
      default:
        return '';
    }
  }

  setDynamicValue(fieldName: string, value: any): void {
    switch (fieldName) {
      case 'firstname':
        this.firstName = value;
        break;
      case 'lastname':
        this.lastName = value;
        break;
      case 'email':
        this.email = value;
        break;
      default:
        break;
    }
  }

  saveUserSurvey(surveyId: number) {
    const userId = this.userData ? this.userData.userID : null;
    if (userId) {
      this.surveyService.saveUserSurvey(surveyId, userId).subscribe();
    }
  }

  onSubmit(form: NgForm) {
    this.submitData = {
      fields: Object.keys(form.value).map(key => ({ name: key, value: form.value[key] })),
    };

    this.surveyService.submitForm(this.portalId, this.formId, this.token, this.submitData).subscribe({
      next: (response) => {
        this.errorMessage = "" ;
        this.submitted = true ;

        if (response && response.inlineMessage)
        {
          this.responseMessage = response.inlineMessage ;
        }
        else
        {
          this.responseMessage = 'Thanks for your feedback!' ;
        }

        this.saveUserSurvey(this.surveyID);
      },
      error: (err: any) => {
        this.errorMessage = "Something went wrong. Please try again later." ;

        Sentry.captureException(err, {
          tags: {
            section: 'surveys',
            action: 'submit',
          }
        }) ;
      }
    });
  }


  getFormId(): void {
    this.surveyService.getFormId(this.surveyID)
      .subscribe({
        next: (data) => {
          this.formId = data;
          this.getForm();
        },
        error: (error) => {
          this.surveyError = true;

          Sentry.captureException(error, {
            tags: {
              section: 'surveys',
              action: 'get-form-id',
              surveyId: this.surveyID,
            }
          }) ;
        }
      });
  }
}
