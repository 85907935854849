<div id="printContent">
  <div class="header-controls">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
  </div>
  <div class="report-section-header">
    <h5 class="results-report-header">
      <fa-icon [icon]="dashboardIcon"></fa-icon> Dashboard
    </h5>
  </div>
  <div class="dashboard-container" style="min-width: 100%;">
    <div class="parent-dashboard-white-box">
      <div class="student-achievements">STUDENT ACHIEVEMENTS</div>
      <div class="print-hide right-text student-select-wrapper">
        <label for="student-select">Student</label>
        <select class="form-select" id="student-select" name="students" [(ngModel)]="selectedStudent" [disabled]="students.length === 0" (change)="changeStudent()">
          <option *ngFor="let student of students ; trackBy: trackByStudentId" [ngValue]="student">{{ student.firstName }} {{ student.lastName }}</option>
        </select>
      </div>

      <div class="parent-dashboard-grey-box">
        <div class="parent-dashboard-minutes-area">
          <div class="parent-dashboard-green-star hide-on-mobile"></div>
          <div class="hide-outside-mobile">
            <div class=parent-dashboard-label-wrapper>
              <div class="parent-dashboard-green-star"></div>
              <div class="parent-dashboard-white-label-large">MINUTES</div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-goal-achieved-checkbox" [ngStyle]="{ 'background-image' : 'url(' + minutesGoalAchievedImage + ')' }"></div>
              <div class="parent-dashboard-goal-achieved-text">Goal<br/>Achieved</div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-black-label">Minutes This Week</div>
              <div class="parent-dashboard-white-number-box" style="width: 90%;">
                {{minutesThisWeek | number: '1.0-0'}}
              </div>
              <div class="parent-dashboard-white-label">Goal: 80 min. per week</div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-black-label">Date of Last Login</div>
              <div class="parent-dashboard-narrow-number-box " style="width: 90%;">
                {{dateLastLogin}}
              </div>
            </div>
          </div>


          <div class="parent-dashboard-row-grid">
            <div class="parent-dashboard-flex-column">
              <div class="parent-dashboard-white-label-large">MINUTES</div>
              <div class="parent-dashboard-goal-achieved-checkbox" [ngStyle]="{ 'background-image' : 'url(' + minutesGoalAchievedImage + ')' }"></div>
              <div class="parent-dashboard-goal-achieved-text">Goal</div>
              <div class="parent-dashboard-goal-achieved-text" style="margin-bottom:10px;">Achieved</div>
            </div>
            <div class="parent-dashboard-flex-column">
              <div class="parent-dashboard-black-label">Minutes This Week</div>
              <div class="parent-dashboard-white-number-box" style="width: 90%;">
                {{minutesThisWeek | number: '1.0-0'}}
              </div>
              <div class="parent-dashboard-white-label" style="margin-bottom:10px;">Goal: 80 min. per week</div>
            </div>
            <div class="parent-dashboard-flex-column">
              <div class="parent-dashboard-black-label">Date of Last Login</div>
              <div class="parent-dashboard-narrow-number-box " style="width: 90%;">
                {{dateLastLogin}}
              </div>
            </div>
          </div>
        </div>
        <div class="parent-dashboard-level-area">
          <div class="parent-dashboard-purple-star hide-on-mobile"></div>
          <div class="hide-outside-mobile">
            <div class=parent-dashboard-label-wrapper>
              <div class="parent-dashboard-purple-star"></div>
              <div class="parent-dashboard-white-label-large">LEVEL</div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-goal-achieved-checkbox" [ngStyle]="{ 'background-image' : 'url(' + levelGoalAchievedImage + ')' }"></div>
              <div class="parent-dashboard-goal-achieved-text" style="margin-bottom:10px;">Goal<br/>Achieved</div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-black-label">Levels Completed This Week</div>
              <div class="parent-dashboard-white-number-box" style="width: 90%; border: 2px solid #53568D;">
                {{levelsCompleted | number: '1.0-0'}}
              </div>
              <div class="parent-dashboard-white-label" style="margin-bottom:10px;">Goal: 8 levels per week</div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-black-label">Percent Complete</div>
              <div class="parent-dashboard-white-number-box" style="width: 90%; border: 2px solid #53568D; background-color: rgb(255, 255, 255, 0.5); color: #4A4A4A">
                {{currentLevelPercent}}%
              </div>
              <div class="parent-dashboard-white-label" style="margin-bottom:10px;">&nbsp;</div>
            </div>
          </div>
          <div class="parent-dashboard-row-grid">
            <div class="parent-dashboard-flex-column">
              <div class="parent-dashboard-white-label-large">LEVEL</div>
              <div class="parent-dashboard-goal-achieved-checkbox" [ngStyle]="{ 'background-image' : 'url(' + levelGoalAchievedImage + ')' }"></div>
              <div class="parent-dashboard-goal-achieved-text">Goal</div>
              <div class="parent-dashboard-goal-achieved-text" style="margin-bottom:10px;">Achieved</div>
            </div>
            <div class="parent-dashboard-flex-column">
              <div class="parent-dashboard-black-label">Levels Completed This Week</div>
              <div class="parent-dashboard-white-number-box" style="width: 90%; border: 2px solid #53568D;">
                {{levelsCompleted | number: '1.0-0'}}
              </div>
              <div class="parent-dashboard-white-label" style="margin-bottom:10px;">Goal: 8 levels per week</div>
            </div>
            <div class="parent-dashboard-flex-column" ng-show="currentLevel > 0">
              <div class="parent-dashboard-black-label">Percent Complete</div>
              <div class="parent-dashboard-white-number-box" style="width: 90%; border: 2px solid #53568D; background-color: rgb(255, 255, 255, 0.5); color: #4A4A4A">
                {{currentLevelPercent}}%
              </div>
              <div class="parent-dashboard-white-label" style="margin-bottom:10px;">&nbsp;</div>
            </div>
          </div>
        </div>
        <div class="parent-dashboard-points-area">
          <div class="parent-dashboard-blue-star hide-on-mobile"></div>

          <div class="hide-outside-mobile">
            <div class=parent-dashboard-label-wrapper>
              <div class="parent-dashboard-blue-star"></div>
              <div class="parent-dashboard-white-label-large">POINTS</div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-black-label">Today's Points</div>
              <div class="parent-dashboard-white-number-box" style="width: 90%; border: 2px solid #4E779A;">
                {{todaysPoints | number: '1.0-0'}}
              </div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-black-label">This Week</div>
              <div class="parent-dashboard-narrow-number-box " style="width: 90%; border: 2px solid #4E779A;">
                {{pointsThisWeek | number: '1.0-0'}}
              </div>
            </div>
            <div class="parent-dashboard-item">
              <div class="parent-dashboard-black-label" style="margin-top: 10px;">Total Points</div>
              <div class="parent-dashboard-narrow-number-box " style="width: 90%; border: 2px solid #4E779A;">
                {{totalPoints | number: '1.0-0'}}
              </div>
            </div>
          </div>

          <div class="parent-dashboard-row-grid">
            <div class="parent-dashboard-flex-column" style="justify-content: flex-start;">
              <div class="parent-dashboard-white-label-large" style="text-align: center;">POINTS</div>
            </div>
            <div class="parent-dashboard-flex-column">
              <div class="parent-dashboard-black-label">Today's Points</div>
              <div class="parent-dashboard-white-number-box" style="width: 90%; border: 2px solid #4E779A;">
                {{todaysPoints | number: '1.0-0'}}
              </div>
            </div>
            <div class="parent-dashboard-flex-column">
              <div class="parent-dashboard-black-label">This Week</div>
              <div class="parent-dashboard-narrow-number-box " style="width: 90%; border: 2px solid #4E779A;">
                {{pointsThisWeek | number: '1.0-0'}}
              </div>
              <div class="parent-dashboard-black-label" style="margin-top: 10px;">Total Points</div>
              <div class="parent-dashboard-narrow-number-box " style="width: 90%; border: 2px solid #4E779A;">
                {{totalPoints | number: '1.0-0'}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
