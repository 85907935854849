import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { ReportsService } from '../../../core/services/reports.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { PrintService } from '../../../core/services/print.service';
import { ManagementUser } from '../../../core/models/management-user.model';
import { District } from '../../../core/models/district.model';
import { School } from '../../../core/models/school.model';
import { Student } from '../../../core/models/student.model';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'wf-district-diagnostic',
  templateUrl: './diagnostic.component.html',
  styleUrls: ['./diagnostic.component.css']
})
export class DistrictDiagnosticComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  isSchoolReportsUser: boolean = false ;
  schoolFilterError: string = '' ;
  loadError: string = '' ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  diagnosticData: any ;
  filteredData: any ;
  selectedDistrictId: number = -1 ;
  selectedSchoolId: number = 0 ;
  diagnosticType: string = 'automaticity' ;
  highToSomeCount: number = 0 ;
  highToSomePercentage: number = 0 ;
  highToSomePercentDisplay: string = '' ;
  someToProficientCount: number = 0 ;
  someToProficientPercentage: number = 0 ;
  someToProficientPercentDisplay: string = '' ;
  highToProficientCount: number = 0 ;
  highToProficientPercentage: number = 0 ;
  highToProficientPercentDisplay: string = '' ;
  sameCount: number = 0 ;
  samePercentage: number = 0 ;
  samePercentDisplay: string = '' ;
  preHighTotal: number = 0 ;
  preSomeTotal: number = 0 ;
  preProficientTotal: number = 0 ;
  preTotal: number = 0 ;
  preHighPercentage: number = 0 ;
  preHighPercentDisplay: string = '' ;
  preSomePercentage: number = 0 ;
  preSomePercentDisplay: string = '' ;
  preProficientPercentage: number = 0 ;
  preProficientPercentDisplay: string = '' ;
  midHighTotal: number = 0 ;
  midSomeTotal: number = 0 ;
  midProficientTotal: number = 0 ;
  midTotal: number = 0 ;
  midHighPercentage: number = 0 ;
  midHighPercentDisplay: string = '' ;
  midSomePercentage: number = 0 ;
  midSomePercentDisplay: string = '' ;
  midProficientPercentage: number = 0 ;
  midProficientPercentDisplay: string = '' ;
  postHighTotal: number = 0 ;
  postSomeTotal: number = 0 ;
  postProficientTotal: number = 0 ;
  postTotal: number = 0 ;
  postHighPercentage: number = 0 ;
  postHighPercentDisplay: string = '' ;
  postSomePercentage: number = 0 ;
  postSomePercentDisplay: string = '' ;
  postProficientPercentage: number = 0 ;
  postProficientPercentDisplay: string = '' ;
  comparisonGroups: any[] = [
    { id: 1, value: 'pre-mid', display: 'Pre - Mid' },
    { id: 2, value: 'mid-post', display: 'Mid - Post' },
    { id: 3, value: 'pre-post', display: 'Pre - Post' },
  ] ;
  comparisonGroup: any = this.comparisonGroups[0] ;
  comparisonGroupScoreImprovement: any = this.comparisonGroups[0] ;
  chartType: ChartType = 'bar' ;
  gainOpts: ChartConfiguration['options'] = {
    maintainAspectRatio: true,
    responsive: true,
    font: {
      family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Diagnostic Administrations'
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          },
        },
      },
      y: {
        title: {
          display: true,
          text: '% of Students Showing an Increase'
        },
        ticks: {
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          },
        },
        beginAtZero: true,
        min: 0,
        max: 100,
      },
    },
    events: []
  } ;
  gainsData: ChartData<'bar'> = {
    labels: [ 'Pre - Mid', 'Mid - Post', 'Pre - Post' ],
    datasets: [
      {
        data: [],
        backgroundColor: [ '#0095DA', '#B2D235', '#949598', '#007236', '#80C342' ],
      }
    ]
  } ;
  improvementOpts: ChartConfiguration['options'] = {
    maintainAspectRatio: true,
    responsive: true,
    font: {
      family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Scale Score Improvement'
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Students'
        },
        ticks: {
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          },
        },
        beginAtZero: true,
        min: 0,
      },
    },
    events: []
  } ;
  changeData: ChartData<'bar'> = {
    labels: [ '0-24', '25-49', '50-74', '75-99', '100+' ],
    datasets: [
      {
        data: [],
        backgroundColor: [ '#0095DA', '#B2D235', '#949598', '#007236', '#80C342' ],
      }
    ]
  } ;
  printIcon = faPrint ;

  @ViewChildren(BaseChartDirective) charts!: QueryList<BaseChartDirective> ;
  @ViewChild('gainsChartCanvas') gainsChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('gainsChartImg') gainsChartImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('improvementChartCanvas') improvementChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('improvementChartImg') improvementChartImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private sessionStorageService: SessionStorageService,
    private printService: PrintService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;
    this.isSchoolReportsUser = this.currentUser ? this.currentUser?.isSchoolUser() : false;

    // Get progress data
    this.diagnosticData = this.route.snapshot.data['resolveData'] ;

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts.filter((district: District) => district.name !== 'Clever District') ;
    this.schools = this.route.snapshot.data['filterData'].schools.filter((school: School) => school.name !== 'Clever School') ;
    this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;
    this.selectedDistrictId = this.reportsService.getSelectedDistrictForReports().districtID ;
    this.selectedSchoolId = this.reportsService.getSelectedSchoolForReports().schoolID ;

    // We call filter initially on Init because we might have a school previously selected from other reports and we might
    // have to then filter based on that. The Resolver ensures that our district data is correct (it uses the selected district to load)
    this.filterSchoolTeacher({ district: this.reportsService.getSelectedDistrictForReports(), school: this.reportsService.getSelectedSchoolForReports() }) ;
  }

  filterSchoolTeacher(filterOpts: any) {
    if (filterOpts.district.districtID !== this.selectedDistrictId)
    {
      // District was changed, so we need to load new report data
      this.selectedDistrictId = filterOpts.district.districtID ;
      this.selectedSchoolId = 0 ;
      this.reportsService.getDistrictDiagnosticData().subscribe({
        next: (data: any) => {
          this.diagnosticData = data ;
          this.filteredData = this.diagnosticData.schools ;

          // Now update our data on the report
          this.setDiagnosticTypeValues() ;
        }
      }) ;
    }
    else
    {
      // School updated
      this.selectedSchoolId = filterOpts.school.schoolID ;

      this.filteredData = this.reportsService.filterDistrictReportData(this.diagnosticData.schools, this.selectedSchoolId) ;
      this.setDiagnosticTypeValues() ;
    }
  }

  setDiagnosticTypeValues() {
    let highSomeCount = 0 ;
    let someToProficientCount = 0 ;
    let highToProficientCount = 0 ;
    let sameCount = 0 ;
    let preHighTotal = 0 ;
    let preSomeTotal = 0 ;
    let preProficientTotal = 0 ;
    let midHighTotal = 0 ;
    let midSomeTotal = 0 ;
    let midProficientTotal = 0 ;
    let postHighTotal = 0 ;
    let postSomeTotal = 0 ;
    let postProficientTotal = 0 ;
    let preMidCount = 0 ;
    let midPostCount = 0 ;
    let prePostCount = 0 ;
    let preMidImpCount = 0 ;
    let midPostImpCount = 0 ;
    let prePostImpCount = 0 ;
    let diagnosticTypeStudents = 0 ;

    Object.values(this.filteredData).forEach((school: any) => {
      let diagnosticTypeValues = school[this.diagnosticType.toLowerCase()][this.comparisonGroup.value] ;
      let diagnosticTypeTotals = school[this.diagnosticType.toLowerCase() + 'Totals'] ;

      // Our values are stored in the data-structure by progress groups (high-some, some-proficient, etc). So when
      // we are trying to tally the total students we have, we ignore the 'decrease' group because this could contain
      // already counted students -- decrease is a sum of all students with a score that went down, maybe not enough to
      // change categories, but if they did they would be tallied in a performance group (that has already been counted)
      diagnosticTypeStudents += Object.keys(diagnosticTypeValues).reduce((prev, category) => {
        return (category !== 'decrease') ? prev + diagnosticTypeValues[category] : prev ;
      }, 0) ;

      highSomeCount += diagnosticTypeValues['high-some'] ;
      someToProficientCount += diagnosticTypeValues['some-proficient'] ;
      highToProficientCount += diagnosticTypeValues['high-proficient'] ;
      sameCount += diagnosticTypeValues['same'] ;

      preHighTotal += diagnosticTypeTotals.pre.high ;
      preSomeTotal += diagnosticTypeTotals.pre.some ;
      preProficientTotal += diagnosticTypeTotals.pre.proficient ;

      midHighTotal += diagnosticTypeTotals.mid.high ;
      midSomeTotal += diagnosticTypeTotals.mid.some ;
      midProficientTotal += diagnosticTypeTotals.mid.proficient ;

      postHighTotal += diagnosticTypeTotals.post.high ;
      postSomeTotal += diagnosticTypeTotals.post.some ;
      postProficientTotal += diagnosticTypeTotals.post.proficient ;

      // @ts-ignore
      preMidCount += Object.values(school[this.diagnosticType.toLowerCase()]['pre-mid']).reduce((prev: number, curr: number) => prev + curr, 0) ;
      // @ts-ignore
      midPostCount += Object.values(school[this.diagnosticType.toLowerCase()]['mid-post']).reduce((prev: number, curr: number) => prev + curr, 0) ;
      // @ts-ignore
      prePostCount += Object.values(school[this.diagnosticType.toLowerCase()]['pre-post']).reduce((prev: number, curr: number) => prev + curr, 0) ;

      preMidImpCount += school.improvements[this.diagnosticType.toLowerCase()]['pre-mid'].length ;
      midPostImpCount += school.improvements[this.diagnosticType.toLowerCase()]['mid-post'].length ;
      prePostImpCount += school.improvements[this.diagnosticType.toLowerCase()]['pre-post'].length ;
    }) ;

    // Calculate and set our group comparison numbers AND our totals
    // These next large blocks determine any performance category changes
    this.preHighTotal = preHighTotal ;
    this.preSomeTotal = preSomeTotal ;
    this.preProficientTotal = preProficientTotal ;
    this.preTotal = this.preHighTotal + this.preSomeTotal + this.preProficientTotal ;
    this.midHighTotal = midHighTotal ;
    this.midSomeTotal = midSomeTotal ;
    this.midProficientTotal = midProficientTotal ;
    this.midTotal = this.midHighTotal + this.midSomeTotal + this.midProficientTotal ;
    this.postHighTotal = postHighTotal ;
    this.postSomeTotal = postSomeTotal ;
    this.postProficientTotal = postProficientTotal ;
    this.postTotal = this.postHighTotal + this.postSomeTotal + this.postProficientTotal ;

    let highToSomeDenominator = (this.comparisonGroup.value.startsWith('pre')) ? this.preHighTotal : this.midHighTotal ;
    let someToProficientDenominator = (this.comparisonGroup.value.startsWith('pre')) ? this.preSomeTotal : this.midSomeTotal ;
    let highToProficientDenominator = (this.comparisonGroup.value.startsWith('pre')) ? this.preHighTotal : this.midHighTotal ;
    this.highToSomeCount = highSomeCount ;
    this.highToSomePercentage = (highToSomeDenominator === 0) ? 0 : Math.round((this.highToSomeCount / highToSomeDenominator) * 100) ;
    this.highToSomePercentDisplay = (this.highToSomePercentage === 0 && this.highToSomeCount > 0) ? '<1' : `${this.highToSomePercentage}` ;
    this.someToProficientCount = someToProficientCount ;
    this.someToProficientPercentage = (someToProficientDenominator === 0) ? 0 : Math.round((this.someToProficientCount / someToProficientDenominator) * 100) ;
    this.someToProficientPercentDisplay = (this.someToProficientPercentage === 0 && this.someToProficientCount > 0) ? '<1' : `${this.someToProficientPercentage}` ;
    this.highToProficientCount = highToProficientCount ;
    this.highToProficientPercentage = (highToProficientDenominator === 0) ? 0 : Math.round((this.highToProficientCount / highToProficientDenominator) * 100) ;
    this.highToProficientPercentDisplay = (this.highToProficientPercentage === 0 && this.highToProficientCount > 0) ? '<1' : `${this.highToProficientPercentage}` ;
    this.sameCount = sameCount ;
    this.samePercentage = (diagnosticTypeStudents === 0) ? 0 : Math.round((this.sameCount / diagnosticTypeStudents) * 100) ;
    this.samePercentDisplay = (this.samePercentage === 0 && this.sameCount > 0) ? '<1' : `${this.samePercentage}` ;

    this.preHighPercentage = (this.preTotal === 0) ? 0 : Math.round((this.preHighTotal / this.preTotal) * 100) ;
    this.preHighPercentDisplay = (this.preHighPercentage === 0 && this.preHighTotal > 0) ? '<1' : `${this.preHighPercentage}` ;
    this.preSomePercentage = (this.preTotal === 0) ? 0 : Math.round((this.preSomeTotal / this.preTotal) * 100) ;
    this.preSomePercentDisplay = (this.preSomePercentage === 0 && this.preSomeTotal > 0) ? '<1' : `${this.preSomePercentage}` ;
    this.preProficientPercentage = (this.preTotal === 0) ? 0 : Math.round((this.preProficientTotal / this.preTotal) * 100) ;
    this.preProficientPercentDisplay = (this.preProficientPercentage === 0 && this.preProficientTotal > 0) ? '<1' : `${this.preProficientPercentage}` ;

    this.midHighPercentage = (this.midTotal === 0) ? 0 : Math.round((this.midHighTotal / this.midTotal) * 100) ;
    this.midHighPercentDisplay = (this.midProficientPercentage === 0 && this.midHighTotal > 0) ? '<1' : `${this.midHighPercentage}` ;
    this.midSomePercentage = (this.midTotal === 0) ? 0 : Math.round((this.midSomeTotal / this.midTotal) * 100) ;
    this.midSomePercentDisplay = (this.midSomePercentage === 0 && this.midSomeTotal > 0) ? '<1' : `${this.midSomePercentage}` ;
    this.midProficientPercentage = (this.midTotal === 0) ? 0 : Math.round((this.midProficientTotal / this.midTotal) * 100) ;
    this.midProficientPercentDisplay = (this.midProficientPercentage === 0 && this.midProficientTotal > 0) ? '<1' : `${this.midProficientPercentage}` ;

    this.postHighPercentage = (this.postTotal === 0) ? 0 : Math.round((this.postHighTotal / this.postTotal) * 100) ;
    this.postHighPercentDisplay = (this.postHighPercentage === 0 && this.postHighTotal > 0) ? '<1' : `${this.postHighPercentage}` ;
    this.postSomePercentage = (this.postTotal === 0) ? 0 : Math.round((this.postSomeTotal / this.postTotal) * 100) ;
    this.postSomePercentDisplay = (this.postSomePercentage === 0 && this.postSomeTotal > 0) ? '<1' : `${this.postSomePercentage}` ;
    this.postProficientPercentage = (this.postTotal === 0) ? 0 : Math.round((this.postProficientTotal / this.postTotal) * 100) ;
    this.postProficientPercentDisplay = (this.postProficientPercentage === 0 && this.postProficientTotal > 0) ? '<1' : `${this.postProficientPercentage}` ;

    // Determine our over gains per category
    this.gainsData.datasets[0].data = [
      Math.round((preMidImpCount / preMidCount) * 100),
      Math.round((midPostImpCount / midPostCount) * 100),
      Math.round((prePostImpCount / prePostCount) * 100),
    ] ;

    if (this.charts)
    {
      this.charts.forEach((chart) => {
        chart.update() ;
      })
    }

    // Determine our average gains per category
    this.setChangeData() ;
  }

  setChangeData() {
    let changeData = [ 0, 0, 0, 0, 0 ] ;
    Object.values(this.filteredData).forEach((school: any) => {
      school.improvements[this.diagnosticType.toLowerCase()][this.comparisonGroupScoreImprovement.value].forEach((improvement: any) => {
        if (improvement < 25) changeData[0]++ ;
        else if (improvement < 50) changeData[1]++ ;
        else if (improvement < 75) changeData[2]++ ;
        else if (improvement < 100) changeData[3]++ ;
        else if (improvement >= 100) changeData[4]++ ;
      }) ;
    }) ;

    this.changeData.datasets[0].data = changeData ;
    if (this.charts) this.charts.last.update() ;
  }

  trackByComparisonId(index: number, obj: any) {
    return obj.id ;
  }

  printReport() {
    // Convert charts to images before printing
    let gainsCanvas = this.gainsChartCanvas.nativeElement;
    let gainsImg = this.gainsChartImg.nativeElement;
    let improvementCanvas = this.improvementChartCanvas.nativeElement;
    let improvementImg = this.improvementChartImg.nativeElement;
    this.reportsService.convertCanvasToImage(gainsCanvas, gainsImg);
    this.reportsService.convertCanvasToImage(improvementCanvas, improvementImg);

    let school = this.reportsService.getSelectedSchoolForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }
}
