<div #printContent id="printContent">
  <div *ngIf="!isSchoolReportsUser">
    <div class="print-hide">
      <wf-school-teacher-filter
        [error]="schoolFilterError"
        [districts]="districts"
        [schools]="schools"
        [teachers]="teachers"
        [showDistrict]="currentUser!.isFILUser()"
        [showTeacher]="false"
        (filterChangeEvent)="filterSchoolTeacher($event)">
      </wf-school-teacher-filter>
    </div>
  </div>

  <div [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="district-report-content">
    <div class="district-report-header">
      <span class="report">Unit Progress</span>
      <button id="printButton" class="btn print-summary-button" (click)="printReport()"><fa-icon [icon]="printIcon" *ngIf="!currentUser!.isTeacherReviewUser()"></fa-icon> Print</button>
    </div>
    <div *ngIf="loadError" class="alert message alert-danger">{{ loadError }}</div>
    <div class="district-report-row">
      <div id="progress-predicted-wrapper" class="district-report-column width-35">
        <div class="district-report-panel gauge" id="district-progress-predicted-completion">
          <div class="title">Predicted Completion</div>
          <wf-report-gauge [percent]="predictedCompletionPercent" [color]="'#B2D235'" #predictedCompletionGauge></wf-report-gauge>
          <div class="gauge-detail">
            <div class="graph center-text">
              <div class="stat">
                <div class="stat-value">{{ predictedCompletionPercentDisplay }}%</div>
                <div class="stat-label">Estimated number of students likely to complete WordFlight by May 15th</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="progress-units-wrapper" class="district-report-column width-35">
        <div class="district-report-panel gauge" id="district-progress-units">
          <div class="title">Units</div>
          <wf-report-gauge [percent]="unitCompletionPercent" [color]="'#0095DA'" #unitCompletionGauge></wf-report-gauge>
          <div class="gauge-detail">
            <div class="graph center-text">
              <div class="stat">
                <div class="stat-value">{{ unitCompletionPercentDisplay }}%</div>
                <div class="stat-label">Students completing at least one unit per week</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="district-report-row page-break-after" *ngIf="!isSchoolReportsUser">
      <div class="district-report-column width-100">
        <wf-report-aggregate-panel [cols]="schoolCols"
                                   [data]="schoolAggregateData"
                                   [showTable]="showSchoolTable"
                                   (sortTableEvent)="sortSchoolTable($event)">
          <div class="title" header>
            <span>Schools</span>
            <span class="display-toggle print-hide" (click)="toggleSchoolTableSwitch()">{{ schoolTableSwitch }}</span>
          </div>
          <div class="category-report-summary chart-summary" summary>
            <div class="chart" ng-show="showSchoolsTable">
              <div class="chart-display print-hide">
                <canvas baseChart
                        #schoolChartCanvas
                        class="chart chart-pie"
                        id="school-progress-chart"
                        [data]="schoolData"
                        [type]="chartType"
                        [options]="chartOptions"></canvas>
              </div>
              <img id="school-progress-chart-img" class="chart-img" #schoolChartImg />
              <div class="chart-labels">
                <div class="chart-label">
                  <div class="icon image-label top"> 75-100%</div>
                  <div class="chart-value">({{ schoolData.datasets[0].data[0] }})</div>
                </div>
                <div class="chart-label">
                  <div class="icon image-label middle"> 60-74%</div>
                  <div class="chart-value">({{ schoolData.datasets[0].data[1] }})</div>
                </div>
                <div class="chart-label">
                  <div class="icon image-label low"> 0-59%</div>
                  <div class="chart-value">({{ schoolData.datasets[0].data[2] }})</div>
                </div>
              </div>
            </div>
          </div>
        </wf-report-aggregate-panel>
      </div>
    </div>
    <div class="district-report-row">
      <div class="district-report-column width-100">
        <wf-report-aggregate-panel [cols]="teacherCols"
                                   [data]="teacherAggregateData"
                                   [showTable]="showTeacherTable"
                                   (sortTableEvent)="sortTeacherTable($event)">
          <div class="title" header>
            <span>Teachers</span>
            <span class="display-toggle print-hide" (click)="toggleTeacherTableSwitch()">{{ teacherTableSwitch }}</span>
          </div>
          <div class="category-report-summary chart-summary" summary>
            <div class="chart" ng-show="showTeachersTable">
              <div class="chart-display print-hide">
                <canvas baseChart
                        #teacherChartCanvas
                        class="chart chart-pie"
                        id="teacher-progress-chart"
                        [data]="teacherData"
                        [type]="chartType"
                        [options]="chartOptions"></canvas>
              </div>
              <img id="teacher-progress-chart-img" class="chart-img" #teacherChartImg />
              <div class="chart-labels">
                <div class="chart-label">
                  <div class="icon image-label top"> 75-100%</div>
                  <div class="chart-value">({{ teacherData.datasets[0].data[0] }})</div>
                </div>
                <div class="chart-label">
                  <div class="icon image-label middle"> 60-74%</div>
                  <div class="chart-value">({{ teacherData.datasets[0].data[1] }})</div>
                </div>
                <div class="chart-label">
                  <div class="icon image-label low"> 0-59%</div>
                  <div class="chart-value">({{ teacherData.datasets[0].data[2] }})</div>
                </div>
              </div>
            </div>
          </div>
        </wf-report-aggregate-panel>
      </div>
    </div>
  </div>
</div>
