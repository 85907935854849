import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

  export class LocalStorageService{

    getLastUsername(): string | null {
        return localStorage.getItem('lastUsername')
      }
    
      setLastUsername(username: string) {
        localStorage.setItem('lastUsername', username)
      }
    
      removeLastUsername() {
        localStorage.removeItem('lastUsername')
      }

      getCheckForUpdateTime(): number | null {
        return this.getNumber('checkForUpdateTime');
      }
    
      setCheckForUpdateTime(checkForUpdateTime: number) {
        this.setNumber('checkForUpdateTime', checkForUpdateTime);
      }

      // pre-emptive private methods 
    private getBoolean(key: string): boolean {
        return localStorage.getItem(key) === 'true';
      }
    
      private setBoolean(key: string, value: boolean) {
        localStorage.setItem(key, value ? 'true' : 'false');
      }
    
      private getNumber(key: string): number | null {
        let numString = localStorage.getItem(key);
        return (numString !== null) ? Number(numString) : null;
      }
    
      private setNumber(key: string, value: number) {
        localStorage.setItem(key, value.toString());
      }
    
      private getLocalObject(key: string): any {
        let localObject = null;
        let localObjectString: string | null = localStorage.getItem(key);
    
        if (localObjectString && localObjectString !== 'undefined')
        {
          localObject = JSON.parse(localObjectString);
        }
    
        return localObject;
      }
    
      private setLocalObject(key: string, object: any) {
        let localObjectString = JSON.stringify(object);
        localStorage.setItem(key, localObjectString);
      }
    
      private removeLocalObject(key: string) {
        localStorage.removeItem(key);
      }
  };