import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {Observable, map, of, forkJoin, catchError} from 'rxjs';

import { environment } from '../../../environments/environment';

import { SessionStorageService } from './session-storage.service';
import { Student, StudentAdapter } from '../models/student.model';
import { UtilityService } from './utility.service';
import { StudentSession } from '../models/student-session.model';
import { StudentSessionSummary } from '../models/student-session-summary.model';

@Injectable({
  providedIn: 'root'
})
export class StudentsService {

  constructor(
    private studentAdapter: StudentAdapter,
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    private utilityService: UtilityService,
  ) {}

  getStudentInformation(userID: number) {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(environment.dataServicesURL + 'manage/enroll/student/' + userID, reqOpts);
  }

  getStudents(): Observable<Student[]> {
    const reqOpts = {
      withCredentials: true,
    };

    return this.httpClient.get<Student[]>(environment.dataServicesURL + 'manage/enroll/student/list', reqOpts).pipe(
      map((data: any) => {
        let students: Student[] = [];

        data.forEach((student: any) => {
          students.push(this.studentAdapter.adapt(student));
        })

        return students;
      })
    );
  }

  archiveStudents(studentIds: number[]): Observable<any> {
    const reqOpts = {
      withCredentials: true,
      observe: 'response',
    };

    // Transform our student IDs into observables that can be called
    let requests = studentIds.map((studentId) => {
      return this.httpClient.put(`${environment.dataServicesURL}manage/enroll/student/${studentId}/archive`, null, reqOpts as any).pipe(
        map((data: any) => {
          return data.status ;
        }),
        catchError(error => of(error.status))
      ) ;
    }) ;

    return forkJoin(requests) ;
  }

  cancelStudentSubscriptions(studentIds: number[]): Observable<any> {
    const reqOpts = {
      withCredentials: true,
      observe: 'response',
    };

    // Transform our student IDs into observables that can be called
    let requests = studentIds.map((studentId) => {
      return this.httpClient.post(`${environment.dataServicesURL}manage/enroll/student/${studentId}/cancel-subscription`, null, reqOpts as any).pipe(
        map((data: any) => {
          return data.status ;
        }),
        catchError(error => of(error.status))
      ) ;
    }) ;

    return forkJoin(requests) ;
  }

  filterStudents(students: Student[], filters: any) : Student[] {
    return students.filter((student) => this.studentMeetsCriteria(student, filters)) ;
  }

  filterStudentSessions(sessions: StudentSession[], filters: any): StudentSession[] {
    return sessions.filter((session) => this.studentMeetsCriteria(session.student, filters)) ;
  }

  filterStudentSummarySessions(sessions: StudentSessionSummary[], filters: any): StudentSessionSummary[] {
    return sessions.filter((session) => this.studentMeetsCriteria(session, filters)) ;
  }

  isParentEnabled(userId: number | undefined): Observable<boolean> {
    if (!userId) return of(false) ;

    const reqOpts = {
      withCredentials: true,
    };

    return this.httpClient.get<boolean>(`${environment.dataServicesURL}manage/enroll/parent/${userId}/enabled`, reqOpts).pipe(
      catchError((error) => {
        console.log(error) ;
        return of(false) ;
      })
    ) ;
  }

  private studentMeetsCriteria(student: any, filters: any): boolean {
    let gradeFilter = filters.grade.toLowerCase().trim() ;
    let classFilter = parseInt(filters.class) ;
    let extraSupportFilter = (filters.extraSupport) ? filters.extraSupport.toLowerCase().trim() : 'all' ;

    if (gradeFilter === 'all' && classFilter === 0 && extraSupportFilter === 'all')
    {
      return true ;
    }

    // Special grade filters for 4th & below and 5th & up
    if ((gradeFilter === 'four_below_all' && student.grade <= 5) || (gradeFilter === 'five_up_all' && student.grade > 5))
    {
      return true ;
    }

    let gradeAllowed = (gradeFilter === 'all' || gradeFilter === this.utilityService.getStudentGrade(student.grade))
    let classAllowed = (classFilter === 0 || classFilter === student.schoolClass?.schoolClassID) ;
    let extraSupportAllowed = (extraSupportFilter === 'all' || (extraSupportFilter === 'no' && !student.extraSupportNeeded) || (extraSupportFilter === 'yes' && student.extraSupportNeeded))

    return gradeAllowed && classAllowed && extraSupportAllowed ;
  }
}
