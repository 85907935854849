import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component' ;
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CleverComponent } from './clever/clever.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ClasslinkComponent } from './classlink/classlink.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    CleverComponent,
    ResetPasswordComponent,
    ClasslinkComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
  ]
})
export class LoginsModule { }
