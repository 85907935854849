import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {catchError, Observable, of} from 'rxjs';

import { ReportsService } from '../../../../core/services/reports.service';

@Injectable({
  providedIn: 'root'
})
export class UnitDetailResolver  {
  constructor(
    private reportsService: ReportsService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // On Unit Detail load, we might be a district user or fil user, so if our selected
    // school is 'All' (ID of 0), then we cannot load students because we do not have
    // any teachers loaded
    if (this.reportsService.getSelectedSchoolForReports().schoolID === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      }) ;
    }

    if (this.reportsService.getSelectedTeacherForReports().userID === 0)
    {
      return of({
        erred: true,
        message: 'Please select a Teacher',
      }) ;
    }

    return this.reportsService.getStudentsForTeacher(this.reportsService.getSelectedTeacherForReports().userID).pipe(
      catchError((err, caught) => {
        return of({
          erred: true,
          message: err,
        }) ;
      })
    ) ;
  }
}
