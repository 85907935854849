import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'underscore';

import * as Sentry from '@sentry/angular';

import {
  faCalendarDays,
  faPrint,
  faRefresh,
  faSquarePollHorizontal,
  faPieChart,
  faFileExcel,
  faSpinner,
  faCircleExclamation
} from '@fortawesome/free-solid-svg-icons';

import { StudentsService } from '../../../../core/services/students.service';
import { ReportsService } from '../../../../core/services/reports.service';
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { ManagementUser } from '../../../../core/models/management-user.model';
import { District } from '../../../../core/models/district.model';
import { School } from '../../../../core/models/school.model';
import { SchoolClass } from '../../../../core/models/school-class.model';
import { SubscriptionTypes } from '../../../../core/models/subscription-types.model';
import { StudentSessionSummary } from '../../../../core/models/student-session-summary.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '../../../../core/services/utility.service';
import { PrintService } from 'src/app/core/services/print.service';
import { LoadingService } from '../../../../core/services/loading.service';

@Component({
  selector: 'wf-session-summary',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css']
})
export class SessionSummaryComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  schoolFilterError: string = '' ;
  reportError: string = '' ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  grades: string[] = [] ;
  classes: SchoolClass[] = [] ;
  sessions: StudentSessionSummary[] = [] ;
  filteredSessions: StudentSessionSummary[] = [] ;
  filteredSessionsWithSeconds: (StudentSessionSummary & { seconds: number, dateRange: { startDate: string, endDate: string } })[] = [] ;
  startDate: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate(),
  } ;
  endDate: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate(),
  } ;
  sortColumn: string = '' ;
  sortReverse: boolean = false ;
  fullProduct: string = SubscriptionTypes.FullProduct ;
  exportingReport: boolean = false ;
  printIcon = faPrint ;
  summaryIcon = faSquarePollHorizontal ;
  refreshIcon = faRefresh;
  calendarIcon = faCalendarDays ;
  reportIcon = faPieChart ;
  excelIcon = faFileExcel ;
  loadingIcon = faSpinner;
  errorIcon = faCircleExclamation ;
  viewType: string = 'sessions' ;

  minDate: NgbDateStruct = {
    year: (new Date()).getFullYear() - 5,
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate(),
  } ;

  maxDate: NgbDateStruct = {
    year: (new Date()).getFullYear() ,
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate(),
  } ;

  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;

  constructor(
    private route: ActivatedRoute,
    private studentService: StudentsService,
    private reportService: ReportsService,
    private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService,
    private printService: PrintService,
    private loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    let resolvedData = this.route.snapshot.data['resolveData'] ;
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;

    this.sessions = resolvedData.studentSessions ;
    this.filteredSessions = resolvedData.studentSessions ;
    this.grades = resolvedData.grades ;
    this.classes = resolvedData.classes ;

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts ;
    this.schools = this.route.snapshot.data['filterData'].schools ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser())
    {
      // The FIL user and District user roles can select all schools
      this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    }

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() || this.currentUser!.isSchoolUser())
    {
      // The FIL user and District and School user roles can select all teachers
      this.teachers.unshift(ManagementUser.getGenericUser()) ;
    }

  }

  filterSessions(filterOpts: any) {
    this.filteredSessions = this.studentService.filterStudentSummarySessions(this.sessions, filterOpts) ;
  }

  updateStudentSessions() {
    let startDateObj = new Date(this.startDate.year, this.startDate.month -1, this.startDate.day, 0, 0, 1) ;
    let endDateObj = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day, 23, 59, 59) ;

    this.reportService.getSessionSummaryData([ startDateObj.getTime(), endDateObj.getTime() ]).subscribe({
      next: (data) => {
        if (data.erred)
        {
          this.schoolFilterError = data.message ;
        }
        else
        {
          this.schoolFilterError = '' ;
          this.grades = data.grades;
          this.classes = data.classes;
          this.sessions = data.studentSessions;
          this.filteredSessions = data.studentSessions;
          if (this.viewType === 'minutes') {
            this.handleViewChange(undefined, 'minutes');
          }
        }
      },
      error: (err: any) => {
        Sentry.captureException(err, {
          tags: {
            section: 'reports',
            report: 'system-summary-session',
            startDate: startDateObj.toLocaleDateString('en-US'),
            endDate: endDateObj.toLocaleDateString('en-US'),
          }
        }) ;
      }
    })
  }

  refreshingTableData(){
    this.loadingService.start() ;
    this.viewType = 'sessions' ;
    let startDateObj = new Date(this.startDate.year, this.startDate.month -1, this.startDate.day, 0, 0, 1) ;
    let endDateObj = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day, 23, 59, 59) ;

    this.reportService.getSessionSummaryData([ startDateObj.getTime(), endDateObj.getTime() ]).subscribe({
      next: (data) => {
        if (data.erred)
        {
          this.schoolFilterError = data.message ;
        }
        else
        {
          this.schoolFilterError = '' ;
          this.grades = data.grades;
          this.classes = data.classes;
          this.sessions = data.studentSessions;
          this.filteredSessions = data.studentSessions;
          this.loadingService.finish();
        }
      },
      error: (err: any) => {
        Sentry.captureException(err, {
          tags: {
            section: 'reports',
            report: 'system-summary-session',
            startDate: startDateObj.toLocaleDateString('en-US'),
            endDate: endDateObj.toLocaleDateString('en-US'),
          }
        }) ;
      }
    })
  }

  sortTable(col: string) {
    if (this.sortColumn === col)
    {
      this.sortReverse = !this.sortReverse ;
    }
    this.sortColumn = col ;

    this.filteredSessions = (this.sortReverse) ? _.sortBy(this.filteredSessions, col).reverse() : _.sortBy(this.filteredSessions, col) ;
  }

  formatDate(dateString: string) {
    return this.utilityService.formatDate(dateString) ;
  }

  formatTime(timestamp: string) {
    return this.utilityService.formatTime(timestamp) ;
  }

  getSecondsBetweenDates(startDate: string, endDate: string) {
    let startDateObj = new Date(startDate) ;
    let endDateObj = new Date(endDate) ;
    let diff = (endDateObj.getTime() - startDateObj.getTime()) / 1000 ;
    return Math.floor(diff) ;
  }

  handleViewChange(event?: Event, value?: string): void {
    let option;
    if (event) {
      option = (event.target as HTMLSelectElement).value;
    } else if (value) {
      option = value;
    } else {
      return;
    }
    if (option === 'minutes') {
      this.filteredSessionsWithSeconds = this.filteredSessions.map((session: StudentSessionSummary) => ({
        ...session,
        seconds: 0,
        dateRange: { startDate: session.startDate, endDate: session.endDate }
      }));

      for (let session of this.filteredSessions) {
        let studentName = session.firstName + " " + session.lastName;
        let totalSeconds = this.getSecondsBetweenDates(session.startDate, session.endDate);
        for (let i = 0; i < this.filteredSessionsWithSeconds.length; i++) {
          if (this.filteredSessionsWithSeconds[i].firstName + " " + this.filteredSessionsWithSeconds[i].lastName === studentName) {
            this.filteredSessionsWithSeconds[i].seconds = this.filteredSessionsWithSeconds[i].seconds + totalSeconds;

            // Update the date range
            if (session.startDate < this.filteredSessionsWithSeconds[i].dateRange.startDate) {
              this.filteredSessionsWithSeconds[i].dateRange.startDate = session.startDate;
            }
            if (session.endDate > this.filteredSessionsWithSeconds[i].dateRange.endDate) {
              this.filteredSessionsWithSeconds[i].dateRange.endDate = session.endDate;
            }
          }
        }

        this.filteredSessionsWithSeconds = this.filteredSessionsWithSeconds.filter((session: StudentSessionSummary, index: number) => {
          return index === this.filteredSessionsWithSeconds.findIndex((s: StudentSessionSummary) => s.firstName + " " + s.lastName === session.firstName + " " + session.lastName);
        });
      }
      this.viewType = "minutes";
    } else {
      this.filteredSessionsWithSeconds = [];
      this.viewType = "sessions";
    }
  }

  roundMinutes(minutes: number) {
    return Math.ceil(minutes);
  }

  printSummary() {
    let school = this.reportService.getSelectedSchoolForReports().name;
    let teacher = this.reportService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }

  exportSessionSummary() {
    let startDateObj = new Date(this.startDate.year, this.startDate.month -1, this.startDate.day, 0, 0, 1) ;
    let endDateObj = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day, 23, 59, 59) ;

    this.reportError = '' ;
    this.exportingReport = true ;
    this.reportService.downloadSessionSummary(this.reportService.getSelectedDistrictForReports().districtID,
      this.reportService.getSelectedSchoolForReports().schoolID,
      this.reportService.getSelectedTeacherForReports().userID,
      [ startDateObj.getTime(), endDateObj.getTime() ])
    .subscribe({
      next: (blob: Blob) => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = 'session_summary.xlsx';
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.exportingReport = false ;
      },
      error: (err: any) => {
        this.exportingReport = false ;
        this.reportError = `There was an error trying to export the Student Session Summary report. Please contact support for assistance.` ;

        Sentry.captureException(err, {
          tags: {
            section: 'reports',
            report: 'system-summary-session',
            action: 'export',
            districtId: this.reportService.getSelectedDistrictForReports().districtID,
            schoolId: this.reportService.getSelectedSchoolForReports().schoolID,
            teacherId: this.reportService.getSelectedTeacherForReports().userID,
            startDate: startDateObj.toLocaleDateString('en-US'),
            endDate: endDateObj.toLocaleDateString('en-US'),
          }
        }) ;
      }
    }) ;
  }
}
