<div #printContent id="printContent">
  <div class="print-hide" *ngIf="showSchoolFilter">
    <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools" [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" (filterChangeEvent)="filterSchoolTeacher()"></wf-school-teacher-filter>
  </div>
  <div  [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
    <div class="print-hide filter-options">
      <wf-students-filter [grades]="grades" [classes]="classes" [filterExtraSupport]="true" [showAllGradeOption]="false" (filterChangeEvent)="filterResults($event)"></wf-students-filter>
    </div>
  </div>
  <div>
    <div class="report-section-header split-flex">
      <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="diagnosticIcon"></fa-icon> {{ selectedProduct }}: Diagnostic Results</h5>
      <button id="print-button" class="btn print-summary-button print-hide" (click)="printSummary()" *ngIf="!currentUser!.isTeacherReviewUser()"><fa-icon [icon]="printIcon"></fa-icon> Print Results</button>
    </div>
  </div>
  <div class="flex column-gap-10 margin-top-10 margin-bottom-10 print-hide">
    <div class="width-20">
      <label class="margin-bottom-min">Test Type</label>
      <select class="form-select print-hide" [(ngModel)]="selectedTestWindow" (change)="testWindowChanged()">
        <option *ngFor="let window of testWindows" [ngValue]="window">{{ window }}</option>
      </select>
      <div class="print-show">{{selectedTestWindow}}</div>
      <div class="form-check">
        <input id="archive-checkbox" class="form-check-input" type="checkbox" [(ngModel)]="showArchived" (change)="testWindowChanged()">
        <label class="form-check-label" for="archive-checkbox">Show Archived Students</label>
      </div>
    </div>
    <div class="width-20">
      <div class="form-check">
        <input id="compare-checkbox" class="form-check-input" type="checkbox" [(ngModel)]="compareTestWindows" [disabled]="selectedTestWindow === 'All Tests'" (change)="testWindowChanged()">
        <label class="form-check-label" for="compare-checkbox">Compare With:</label>
      </div>
      <select class="form-select print-hide" [disabled]="!compareTestWindows" [(ngModel)]="selectedCompareTestWindow" (change)="testWindowChanged()">
        <option *ngFor="let cWindow of testWindowsCompare" [ngValue]="cWindow">{{ cWindow }}</option>
      </select>
      <div class="print-show">{{selectedCompareTestWindow}}</div>
    </div>
  </div>
  <div class="flex column-gap-10">
    <div class="width-60 zone-explanation">
      <h4 class="bold">Instructional Zone</h4>
      <ul class="no-bullet">
        <li style="margin-bottom: 10px;">Zone 4:  Proficient in decoding knowledge and automaticity</li>
        <li style="margin-bottom: 10px;">Zone 3:  At some risk because of insufficient decoding knowledge and/or automaticity</li>
        <li style="margin-bottom: 10px;">Zone 2:  At moderate risk because of insufficient decoding knowledge and/or automaticity</li>
        <li style="margin-bottom: 10px;">Zone 1:  At high risk because of insufficient decoding knowledge and automaticity</li>
      </ul>
    </div>
    <div class="flex flex-column" class="width-40">
      <div><h4 class="bold">{{ automaticityTitle }}</h4></div>
      <div class="flex flex-row split-flex">
        <div class="report-chart-wrapper" *ngIf="selectedTestWindow !== 'All Tests'" [ngClass]=" { 'margin-top-45': compareTestWindows, 'compare-wrapper': compareTestWindows } ">
          <div class="report-chart-legend" style="width: 100% ;">
            <div class="legend-wrapper">
              <div class="legend-key legend-green">
                <div class="pie-legend">Proficient</div>
                <img class="legend-img" src="/assets/images/reportIcons/iconScoreGreen.svg" />
                <div class="legend-green-range">{{ tierLabels['proficient'] }}</div>
              </div>
              <div *ngIf="!compareTestWindows" class="report-chart-value">({{ automaticityData[0] }})</div>
            </div>
            <div class="legend-wrapper">
              <div class="legend-key legend-yellow">
                <div class="pie-legend">Some Risk</div>
                <img class="legend-img" src="/assets/images/reportIcons/iconScoreYellow.svg" />
                <div class="legend-yellow-range">{{ tierLabels['some-risk'] }}</div>
              </div>
              <div *ngIf="!compareTestWindows" class="report-chart-value">({{ automaticityData[1] }})</div>
            </div>
            <div class="legend-wrapper">
              <div class="legend-key legend-red">
                <div class="pie-legend">High Risk</div>
                <img class="legend-img" src="/assets/images/reportIcons/iconScoreRed.svg" />
                <div class="legend-red-range">{{ tierLabels['high-risk'] }}</div>
              </div>
              <div *ngIf="!compareTestWindows" class="report-chart-value">({{ automaticityData[2] }})</div>
            </div>
          </div>
        </div>
        <div class="width-70">
          <div *ngIf="compareTestWindows ; else testWindowChart">
            <table class="report-table">
              <thead>
              <tr>
                <th>{{ selectedTestWindow }}</th>
                <th>{{ selectedCompareTestWindow }}</th>
              </tr>
              </thead>
              <tbody>
              <tr class="compare-table-cell">
                <td>{{ scoreTotalsWindow1.automaticityProficient }}</td>
                <td>{{ scoreTotalsWindow2.automaticityProficient }}</td>
              </tr>
              <tr class="compare-table-cell">
                <td>{{ scoreTotalsWindow1.automaticitySomeRisk }}</td>
                <td>{{ scoreTotalsWindow2.automaticitySomeRisk }}</td>
              </tr>
              <tr class="compare-table-cell">
                <td>{{ scoreTotalsWindow1.automaticityHighRisk }}</td>
                <td>{{ scoreTotalsWindow2.automaticityHighRisk }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <ng-template #testWindowChart>
            <div *ngIf="selectedTestWindow !== 'All Tests'">
              <img #automaticitySummaryImg id="automaticitySummaryImg" class="pie-img print-show" />
              <div class="chart">
                <div class="diagnosticproduct-management-div-pie print-hide">
                  <canvas #automaticityPieCanvas
                          baseChart
                          [data]="chartData"
                          [type]="chartType"
                          [options]="chartOptions"></canvas>
                </div>
              </div>
            </div>
            <div *ngIf="selectedTestWindow === 'All Tests'">
              Select a Test Window...
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <table class="report-table" id="student-data-table">
    <thead>
    <tr>
      <th></th>
      <th (click)="sortTable('lastName')">
        <div class="sortable-report-table-header">
          <div>Student</div>
          <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 'lastName') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th (click)="sortTable('grade')">
        <div class="sortable-report-table-header">
          <div>Grade</div>
          <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 'grade') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th (click)="sortTable('systemScore')">
        <div [ngClass]="{ 'sortable-report-table-header' : !compareTestWindows && (selectedTestWindow !== 'All Tests') }">
          <div>{{ automaticityTitle }}</div>
          <div *ngIf="!compareTestWindows && (selectedTestWindow !== 'All Tests')" [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 'systemScore') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th (click)="sortTable('decodingScore')" *ngIf="!isElementary">
        <div [ngClass]="{ 'sortable-report-table-header' : !compareTestWindows && (selectedTestWindow !== 'All Tests') }">
          <div>Decoding</div>
          <div *ngIf="!compareTestWindows && (selectedTestWindow !== 'All Tests')" [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 'decodingScore') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th (click)="sortTable('zone')">
        <div [ngClass]="{ 'sortable-report-table-header' : !compareTestWindows && (selectedTestWindow !== 'All Tests') }">
          <div>Instructional Zone</div>
          <div *ngIf="!compareTestWindows && (selectedTestWindow !== 'All Tests')" [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 'zone') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th>Test Type</th>
      <th>Date Completed</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let student of filteredStudents">
      <td><button type="button" class="btn view-detail-button" (click)="goToDiagnosticDetails(student.userID)">View</button></td>
      <td>{{ student.firstName }} {{ student.lastName }}</td>
      <td>{{ student.grade | displayGrade }}</td>
      <td>
        <div *ngFor="let assessmentScore of student.assessmentScores">
          <div [ngClass]="getDiagnosticClass((student.grade <= 5), assessmentScore.systemScore)">
            {{ assessmentScore.systemScore }}
            <img class="icon-img" alt="score image" [src]="getDiagnosticImage((student.grade <= 5), assessmentScore.systemScore)" />
          </div>
        </div>
      </td>
      <td *ngIf="!isElementary">
        <div *ngFor="let assessmentScore of student.assessmentScores">
          <div [ngClass]="getDiagnosticClass((student.grade <= 5), assessmentScore.decodingScore)" *ngIf="student.grade > 5">
            {{ assessmentScore.decodingScore }}
            <img class="icon-img" alt="score image" [src]="getDiagnosticImage((student.grade <= 5), assessmentScore.decodingScore)" />
          </div>
        </div>
      </td>
      <td>
        <div *ngFor="let assessmentScore of student.assessmentScores" class="zone">{{ assessmentScore.zone }}</div>
      </td>
      <td>
        <div *ngFor="let assessmentScore of student.assessmentScores">
          <div>{{ assessmentScore.testWindow }}</div>
        </div>
      </td>
      <td>
        <div *ngFor="let assessmentScore of student.assessmentScores">
          <div>{{ assessmentScore.formattedDateCompleted }}</div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
