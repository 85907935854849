<div class="diagnosticproduct-management-content">
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconEnroll.svg" /> Enroll </h3>
    <button class="go-back-button" (click)="goBack()"><fa-icon [icon]="goBackIcon"></fa-icon> Go Back</button>
  </div>
  <div class="report-section-header">
    <h5 class="manage-subs-header"><fa-icon [classes]="['print-hide']" [icon]="calendarIcon"></fa-icon> Manage Subscriptions: Schedule Diagnostic</h5>
  </div>
  <div class="diagnosticproduct-management-text-button-bar flex split-flex">
    <div>
      <button class="btn schedule-button schedule" (click)="openScheduleDialog()" [disabled]="disableButtons || (selectedDiagnosticType=='Pre')"><fa-icon [icon]="scheduleIcon"></fa-icon> Schedule</button>
      <button class="btn schedule-button default-schedule" (click)="scheduleDiagnostic(false)" [disabled]="disableButtons"><fa-icon [icon]="defaultIcon"></fa-icon> Use Default Schedule</button>
      <button class="btn schedule-button skip" (click)="scheduleDiagnostic(true)" [disabled]="disableButtons"><fa-icon [icon]="skipIcon"></fa-icon> Skip Diagnostic</button>
    </div>
    <div>
      <table>
        <td>
          <span><strong>Diagnostic</strong></span>
        </td>
        <td style="padding-left: 10px">
          <select class="form-select" name="diagnostics" id="diagnostic-dropdown" (change)="loadDiagnosticScheduleData()" [(ngModel)]="selectedDiagnosticType">
            <option *ngFor="let diagnosticType of diagnosticOptions">{{diagnosticType}}</option>
          </select>
        </td>
      </table>
    </div>
  </div>
  <div class="alert alert-danger schedule-error-msg" *ngIf="showError && !showScheduleDialog">
    <ul>
      <li *ngFor="let error of errorMsg">{{error}}</li>
    </ul>
  </div>
  <table class="report-table" id="scheduleTable">
    <thead class="diagnosticproduct-management-table-header">
      <th>
        <input type="checkbox" (click)="selectAll($event)" [(ngModel)]="select" (change)="enableButtonsIfSelected($event)">
      </th>
      <th id="firstNameColumn" class="table-header-sortable" (click)="sortTable('firstName')">
        <div class="sortable-report-table-header">
          <div>First Name</div>
          <div [ngClass]="{ 'arrow-up' : !reverseSort, 'arrow-down': reverseSort }" [style.visibility]="(orderByField !== 'firstName') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th id="lastNameColumn" class="table-header-sortable" (click)="sortTable('lastName')">
        <div class="sortable-report-table-header">
          <div>Last Name</div>
          <div [ngClass]="{ 'arrow-up' : !reverseSort, 'arrow-down': reverseSort }" [style.visibility]="(orderByField !== 'lastName') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th id="gradeColumn" class="table-header-sortable" (click)="sortTable('grade')">
        <div class="sortable-report-table-header">
          <div>Grade</div>
          <div [ngClass]="{ 'arrow-up' : !reverseSort, 'arrow-down': reverseSort }" [style.visibility]="(orderByField !== 'grade') ? 'hidden' : 'visible'"></div>
        </div>
      </th>
      <th>Schedule</th>
      <th>Status</th>
    </thead>
    <tr *ngFor="let assessmentSchedule of scheduleList">
      <td>
        <div class="schedule-table-cell"><input type="checkbox" [(ngModel)]="assessmentSchedule.selected" *ngIf="!shouldHideCheckbox(assessmentSchedule.status)"
            (change)="enableButtonsIfSelected($event)"></div>
      </td>
      <td>
        <div class="schedule-table-cell">{{assessmentSchedule.firstName}}</div>
      </td>
      <td>
        <div class="schedule-table-cell">{{assessmentSchedule.lastName}}</div>
      </td>
      <td>
        <div class="schedule-table-cell">{{assessmentSchedule.displayGrade}}</div>
      </td>
      <td>
        <div class="schedule-table-cell">{{assessmentSchedule.schedule}}</div>
      </td>
      <td>
        <div class="schedule-table-cell">{{assessmentSchedule.status}}</div>
      </td>
    </tr>
  </table>
</div>

<!-- Scheduling Dialog -->
<!--OPTIMIZE: This used to be controlled by a different controller
  We potentially could make this into a separate component too-->
<div class="assign-confirm-dialog-background" *ngIf="showScheduleDialog"></div>
<div class="modal-dialog schedule-dialog" style="z-index: 1001;" *ngIf="showScheduleDialog">
  <div class="modal-content">
    <div class="modal-header schedule-header-container">
      <div class="modal-title">Schedule Selected</div>
    </div>
    <div class="modal-body">
        <label class="schedule-dialog-text">When would you like to reschedule this diagnostic?</label>
        <div class="input-group">
          <input class="form-control" [formControl]="selectedDate" placeholder="yyyy-mm-dd" ngbDatepicker #scheduleDate="ngbDatepicker" [minDate]="minDate" [maxDate]="maxDate" [startDate]="minDate">
          <button class="btn btn-outline-secondary calendar" (click)="scheduleDate.toggle()" type="button"><fa-icon [icon]="calendarIcon"></fa-icon></button>
        </div>
        <div style="color: rgb(221,44,0); padding-left: 10px; display: inline-block;" *ngIf="selectedDate.invalid">
          <div *ngIf="selectedDate.invalid">Invalid date format</div>
          <div *ngIf="selectedDate.errors?.['ngbDate'].minDate">Date in the past</div>
          <div *ngIf="selectedDate.errors?.['ngbDate'].maxDate">Date is greater than 10 years from now</div>
        </div>
      <div class="alert alert-danger schedule-error-msg" style="min-height: 80px" *ngIf="showError">
        <ul>
          <li *ngFor="let error of errorMsg">{{error}}</li>
        </ul>
      </div>
      <div style="min-height: 100px" ng-show="!showError">
      </div>
    </div>
    <div class="confirm-dialog-bottom-container modal-footer">
      <button class="btn schedule-dialog-cancel-btn" (click)="cancel()"><fa-icon [icon]="cancelIcon"></fa-icon> Cancel</button>
      <button type="submit" class="btn schedule-dialog-confirm-btn" (click)="setScheduleDiagnostic()" [disabled]="selectedDate.invalid || !selectedDate"><fa-icon [icon]="scheduleIcon"></fa-icon> Schedule</button>
    </div>
  </div>
</div>
