import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RefreshButton } from './components/refresh-button/refresh-button.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StudentsFilterComponent } from './components/students-filter/students-filter.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DisplayGradePipe } from '../core/pipes/display-grade.pipe';
import { SafeHtmlPipe } from '../core/pipes/safe-html.pipe';
import { SafeUrlPipe } from '../core/pipes/safe-url.pipe';
import { SchoolTeacherFilterComponent } from './components/school-teacher-filter/school-teacher-filter.component';
import { GaugeComponent } from './components/gauge/gauge.component';
import { ReportAggregatePanelComponent } from './components/report-aggregate-panel/report-aggregate-panel.component';
import { LoadingMaskComponent } from './components/loading-mask/loading-mask.component';

@NgModule({
  declarations: [
    HeaderComponent,
    RefreshButton,
    FooterComponent,
    SidebarComponent,
    StudentsFilterComponent,
    DisplayGradePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    SchoolTeacherFilterComponent,
    GaugeComponent,
    ReportAggregatePanelComponent,
    LoadingMaskComponent,
  ],
    imports: [
      CommonModule,
      RouterModule,
      FontAwesomeModule,
      FormsModule,
      NgSelectModule,
    ],
    exports: [
        HeaderComponent,
        RefreshButton,
        FooterComponent,
        SidebarComponent,
        StudentsFilterComponent,
        DisplayGradePipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        SchoolTeacherFilterComponent,
        GaugeComponent,
        ReportAggregatePanelComponent,
        LoadingMaskComponent,
    ]
})
export class SharedModule { }
