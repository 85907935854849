<div id="printContent" #printContent>
  <div class="print-hide" *ngIf="showSchoolFilter">
    <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools" [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" (filterChangeEvent)="filterSchoolTeacher()"></wf-school-teacher-filter>
  </div>
  <div [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
  </div>
  <div>
    <div class="report-section-header split-flex">
      <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="objectiveIcon"></fa-icon> {{ fullProduct }}: Objective Pre/Post</h5>
      <button id="print-button" class="btn print-summary-button print-hide" (click)="printReport()" *ngIf="!currentUser!.isTeacherReviewUser()"><fa-icon [icon]="printIcon"></fa-icon> Print Results</button>
    </div>
    <div id="student-message" class="alert message" *ngIf="message" [ngClass]="{ 'alert-danger': messageStatus === 'error', 'alert-warning' : messageStatus === 'warning' }">
      <div class="icon">
        <fa-icon [icon]="warningIcon" *ngIf="messageStatus === 'warning'"></fa-icon>
        <fa-icon [icon]="errorIcon" *ngIf="messageStatus === 'error'"></fa-icon>
      </div>
      <div>
        <div class="bold">{{ messageTitle }}</div>
        {{ message }}
      </div>
    </div>
    <div class="student-dropdown-row">
      <label for="student-dropdown">Student</label>
      <select id="student-dropdown" class="form-select print-hide" [(ngModel)]="selectedStudent" (change)="updateStudent()">
        <option *ngFor="let student of students; trackBy: trackByStudentId" [ngValue]="student">{{ student.firstName }} {{ student.lastName }}</option>
      </select>
      <div class="print-show">{{selectedStudent?.firstName || ''}} {{selectedStudent?.lastName || ''}}</div>
    </div>
    <table class="table table-borderless student-table">
      <thead class="student-table-header">
        <th>First Name</th>
        <th>Last Name</th>
        <th>Grade</th>
      </thead>
      <tbody>
        <tr>
          <td>{{selectedStudent?.firstName}}</td>
          <td>{{selectedStudent?.lastName}}</td>
          <td>{{selectedStudent?.grade | displayGrade }}</td>
        </tr>
      </tbody>
    </table>
    <div class="no-data-label" *ngIf="!selectedObjective ; else noObjective">
      No Data for this Student...
    </div>
    <ng-template #noObjective>
      <div class="objective-prepost-chart-container">
        <div class="objective-chart-container">
          <div class="objective-header-div">
            <div class="objective-chart-header">Objective</div>
            <select class="form-select print-hide" id="objective-dropdown" [(ngModel)]="selectedObjective" (change)="updateObjectiveChart()">
              <option *ngFor="let objective of objectives | keyvalue" [ngValue]="objective.value">{{ objective.value.objectiveText }}</option>
            </select>
            <div class="print-show">{{selectedObjective?.objectiveText || ''}}</div>
          </div>
          <div class="objective-chart-area">
            <div class="chart-header-label">Objective Performance</div>
            <div class="date-label">Dates: {{ selectedObjective?.formattedStartDate }} to {{ selectedObjective?.formattedEndDate }}</div>
            <div class="objective-chart-legend">
              <div><div class="legend-box pretest-objective"></div><div class="legend-label">Pretest</div></div>
              <div><div class="legend-box posttest-objective"></div><div class="legend-label">Posttest</div></div>
            </div>
            <div class="print-hide">
              <canvas #objectiveChartCanvas
                      id="objective-chart"
                      baseChart
                      [data]="objectiveChartData"
                      [type]="chartType"
                      [options]="chartOpts"></canvas>
            </div>
            <img #objectiveChartImg id="objective-chart-img" class="chart-img" />
            <table class="weekly-usage-table">
              <tbody>
                <tr>
                  <td>
                    <div>Expected Weekly Usage</div>
                    <div class="weekly-usage-stat"><span>{{ selectedObjective?.expectedWeeklyUsage }} min</span></div>
                  </td>
                  <td>
                    <div>Average Weekly Usage</div>
                    <div class="weekly-usage-stat"><span>{{ selectedObjective?.averageWeeklyUsage }} min</span></div>
                  </td>
                  <td>
                    <div>Weekly Time Goal</div>
                    <div class="weekly-usage-stat"><span>{{ selectedObjective?.weeklyTimeGoal }}%</span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="cumulative-objective-chart-container">
          <div class="cumulative-objective-header-div">
            <div class="objective-chart-header">Cumulative Objectives</div>
            <div class="cumulative-objectives-label">{{ cumulativeObjectives?.objectiveText }}</div>
          </div>
          <div class="objective-chart-area">
            <div class="chart-header-label">Cumulative Performance</div>
            <div class="date-label" [ngClass]="{ 'hide-label': !showCumulativeDates }">Dates: {{ cumulativeObjectives?.formattedStartDate }} to {{ cumulativeObjectives?.formattedEndDate }}</div>
            <div class="objective-chart-legend">
              <div><div class="legend-box pretest-cumulative"></div><div class="legend-label">Pretest</div></div>
              <div><div class="legend-box posttest-cumulative"></div><div class="legend-label">Posttest</div></div>
            </div>
            <div class="print-hide">
              <canvas #cumulativeChartCanvas
                      id="cumulative-objective-chart"
                      baseChart
                      [data]="cumulativeChartData"
                      [type]="chartType"
                      [options]="chartOpts"></canvas>
            </div>
            <img #cumulativeChartImg id="cumulative-objective-chart-img" class="chart-img" />
            <table class="weekly-usage-table">
              <tbody>
              <tr>
                <td>
                  <div>Expected Weekly Usage</div>
                  <div class="weekly-usage-stat"><span>{{ cumulativeObjectives?.expectedWeeklyUsage }} min</span></div>
                </td>
                <td>
                  <div>Average Weekly Usage</div>
                  <div class="weekly-usage-stat"><span>{{ cumulativeObjectives?.averageWeeklyUsage }} min</span></div>
                </td>
                <td>
                  <div>Weekly Time Goal</div>
                  <div class="weekly-usage-stat"><span>{{ cumulativeObjectives?.weeklyTimeGoal }}%</span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
