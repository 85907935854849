import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'wf-footer',
  templateUrl: './footer.component.html',
  styles: [
  ]
})
export class FooterComponent implements OnInit {

  copyrightYear: number = new Date().getFullYear();
  versionNumber: string = environment.versionNumber;

  constructor() { }

  ngOnInit(): void {
  }

}
