import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { concatMap, forkJoin, from, map, Observable, of, switchMap, throwError } from 'rxjs';

import { School } from '../models/school.model';
import { SessionStorageService } from './session-storage.service';
import { environment } from '../../../environments/environment';
import { Student, StudentAdapter } from '../models/student.model';
import { SchoolClass } from '../models/school-class.model';
import { EnrollmentService } from './enrollment.service';
import { UtilityService } from './utility.service';
import { ManagementUser } from '../models/management-user.model';
import { District } from '../models/district.model';
import { StudentSession, StudentSessionAdapter } from '../models/student-session.model';
import { StudentSessionSummary } from '../models/student-session-summary.model';
import { InterventionTaskStatus } from '../models/intervention-task-status.model';
import { SubscriptionTypes } from '../models/subscription-types.model';
import { AssessmentScore, AssessmentScoreAdapter } from '../models/assessment-score.model';
import { ScoreCutoffs } from '../models/score-cutoffs.model';
import { DownloadService } from './download.service';
import { TaskCategories, TaskCategory } from '../models/task-category.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private sessionService: SessionStorageService,
    private enrollService: EnrollmentService,
    private utilityService: UtilityService,
    private studentAdapter: StudentAdapter,
    private assessmentScoreAdapter: AssessmentScoreAdapter,
    private studentSessionAdapter: StudentSessionAdapter,
    private downloadService: DownloadService,
    private httpClient: HttpClient,
  ) { }

  getSelectedDistrictForReports(): District {
    let managementUser = this.sessionService.getUserData() ;
    let selectedDistrict = this.sessionService.getSelectedDistrict() ;

    if (!selectedDistrict)
    {
      selectedDistrict = {
        districtID: managementUser!.districtID,
        name: managementUser!.districtName || '',
        enabled: true,
      }

      this.sessionService.setSelectedDistrict(selectedDistrict) ;
    }

    return selectedDistrict ;
  }

  getSelectedSchoolForReports(): School {
    let managementUser = this.sessionService.getUserData() ;
    let selectedSchool = this.sessionService.getSelectedSchool() ;

    if (!selectedSchool)
    {
      if (managementUser!.isFILUser() || managementUser!.isDistrictUser())
      {
        selectedSchool = {
          schoolID: 0,
          districtID: 0,
          name: 'All',
          enabled: true,
        } ;
      }
      else
      {
        selectedSchool = {
          schoolID: managementUser!.schoolID,
          districtID: managementUser!.districtID,
          name: managementUser!.school,
          enabled: true,
        } ;
      }

      this.sessionService.setSelectedSchool(selectedSchool) ;
    }

    return selectedSchool ;
  }

  getSelectedTeacherForReports(): ManagementUser {
    let managementUser = this.sessionService.getUserData() ;
    let selectedReportTeacher = this.sessionService.getSelectedTeacher() ;

    if (!selectedReportTeacher)
    {
      if (managementUser!.isFILUser() || managementUser!.isDistrictUser() || managementUser!.isSchoolUser())
      {
        selectedReportTeacher = ManagementUser.getGenericUser() ;
      }
      else
      {
        selectedReportTeacher = managementUser! ;
      }

      this.sessionService.setSelectedTeacher(selectedReportTeacher) ;
    }

    return selectedReportTeacher ;
  }

  getScreenerStudentsWithAssessments(schoolID: number, teacherID: number): Observable<any> {
    let managementUser = this.sessionService.getUserData() ;
    const reqOpts = {
      withCredentials: true,
    };

    if (schoolID === null)
    {
      schoolID = managementUser!.schoolID ;
    }

    if (teacherID === null)
    {
      teacherID = managementUser!.userID ;
    }

    if (schoolID === 0)
    {
      return of([]) ;
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/enroll/school/${schoolID}/teacher/${teacherID}/student/reports/screenerscores`, reqOpts) ;
  }

  getScreenerReportData(schoolID: number, teacherID: number): Observable<any> {
    // As detailed in Redmine 5504, when the detailed reports are being created for Clever,
    // we want to force the user to select an individual teacher to avoid the large teacher
    // in the default Clever school
    const selectedDistrict = this.getSelectedDistrictForReports() ;
    const cleverReqs = this.checkCleverRequirements(selectedDistrict, schoolID, teacherID) ;
    if (cleverReqs !== null)
    {
      return cleverReqs ;
    }

    return forkJoin([
      this.enrollService.getRemainingDiagnosticLicensesCount(teacherID, schoolID),
      this.enrollService.getRemainingInterventionLicensesCount(teacherID, schoolID),
      this.getScreenerStudentsWithAssessments(schoolID, teacherID),
    ]).pipe(
      map((results) => {
        let diagnosticLicenses = results[0] ;
        let interventionLicenses = results[1] ;
        let students: Student[] = [] ;
        let classes: SchoolClass[] = [] ;
        let grades: string[] = [] ;
        let gradesSet: Set<string> = new Set<string>() ;

        results[2].forEach((rawStudent: any) => {
          let student = this.studentAdapter.adapt(rawStudent) ;

          students.push(student) ;
          gradesSet.add(this.utilityService.getStudentGrade(student.grade)) ;
        }) ;

        // Set our unique set of grades, sorted
        grades = [ 'All' ].concat(this.utilityService.sortGrades(Array.from(gradesSet))) ;

        // Get a list of unique classes for our returned students
        classes = [ {
          name: 'All',
          schoolClassID: 0,
          teacherID: 0,
          createDate: (new Date()),
          status: ''
        }].concat(this.utilityService.sortClasses(this.utilityService.getUniqueSchoolClasses(students))) ;

        return {
          grades: grades,
          diagnosticLicenses: diagnosticLicenses,
          interventionLicenses: interventionLicenses,
          classes: classes,
          students: students,
        } ;
      })
    );
  }

  /**
   * These generalized get<X>Data() methods are used by various components to get report data that might include
   * other necessary metadata for the Component to load properly. Internally, they call more specific methods
   * that will make calls to get necessary data from the dataservices
   */
  getGroupSummaryReportData(): Observable<any> {
    const schoolId = this.getSelectedSchoolForReports().schoolID ;
    const teacherId = this.getSelectedTeacherForReports().userID ;
    const selectedDistrictID = this.getSelectedDistrictForReports()?.districtID ;
    const selectedDistrict = this.getSelectedDistrictForReports() ;
    const cleverReqs = this.checkCleverRequirements(selectedDistrict, schoolId, teacherId) ;
    if (cleverReqs !== null)
    {
      return cleverReqs ;
    }

    const reqOpts = {
      withCredentials: true,
    };

    // We cannot load summary results if our schoolID is 0 ('All' schools)
    if (schoolId === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      }) ;
    }

    return this.httpClient.get<StudentSession[]>(`${environment.dataServicesURL}manage/reports/district/${selectedDistrictID}/school/${schoolId}/teacher/${teacherId}/acGroupSummary`, reqOpts).pipe(
      map((sessionData) => {
        let students: Student[] = [] ;
        let classes: SchoolClass[] = [] ;
        let grades: string[] = [] ;
        let gradesSet: Set<string> = new Set<string>() ;
        let sessions: StudentSession[] = [] ;

        sessionData.forEach((session) => {
          // ignore students that haven't started the mid-diagnostic for the group summary report
          if (session.currentUnit === 0) return ;

          students.push(session.student) ;
          sessions.push(this.studentSessionAdapter.adapt(session)) ;
          gradesSet.add(this.utilityService.getStudentGrade(session.student.grade)) ;
        }) ;

        // Set our unique set of grades, sorted
        grades = [ 'All' ].concat(this.utilityService.sortGrades(Array.from(gradesSet))) ;

        // Get a list of unique classes for our returned students
        classes = [ {
          name: 'All',
          schoolClassID: 0,
          teacherID: 0,
          createDate: (new Date()),
          status: ''
        }].concat(this.utilityService.sortClasses(this.utilityService.getUniqueSchoolClasses(students))) ;

        return {
          sessionData: sessions,
          grades: grades,
          classes: classes,
        }
      })
    ) ;
  }

  getObjectiveData(): Observable<any> {
    let schoolId = this.getSelectedSchoolForReports().schoolID ;
    let teacherId = this.getSelectedTeacherForReports().userID ;

    // We cannot load objective performance data if our schoolID is 0 ('All' schools)
    if (schoolId === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      });
    }

    // We cannot load summary results if our teacherId is 0 ('All' teachers)
    if (teacherId === 0)
    {
      return of({
        erred: true,
        message: 'Please select a Teacher',
      });
    }

    // Get all the students for our selected teacher
    return this.getStudentsForTeacher(teacherId).pipe(
      concatMap((students) => {
        if (students.length === 0)
        {
          return of({
            students: [],
            objectiveData: {},
          }) ;
        }

        return this.getObjectivePrePostPerformanceData(schoolId, teacherId, students[0].userID).pipe(
          switchMap((objectiveData) => {
            return of({
              students: students,
              objectiveData: objectiveData,
            }) ;
          })
        ) ;
      })
    ) ;
  }

  getSessionSummaryData(dateRange: number[]): Observable<any> {
    const districtId = this.getSelectedDistrictForReports().districtID ;
    const schoolId = this.getSelectedSchoolForReports().schoolID ;
    const teacherId = this.getSelectedTeacherForReports().userID ;
    const selectedDistrict = this.getSelectedDistrictForReports() ;
    const cleverReqs = this.checkCleverRequirements(selectedDistrict, schoolId, teacherId) ;
    if (cleverReqs !== null)
    {
      return cleverReqs ;
    }

    return this.getSessionSummaryReportData(districtId, schoolId, teacherId, dateRange).pipe(
      map((results) => {
        let classes: SchoolClass[] = [] ;
        let grades: string[] = [] ;
        let gradesSet: Set<string> = new Set<string>() ;

        results.forEach((session) => {
          gradesSet.add(this.utilityService.getStudentGrade(session.grade)) ;
        }) ;
        grades = [ 'All' ].concat(this.utilityService.sortGrades(Array.from(gradesSet))) ;

        classes = [ {
          name: 'All',
          schoolClassID: 0,
          teacherID: 0,
          createDate: (new Date()),
          status: ''
        }].concat(this.utilityService.sortClasses(this.utilityService.getUniqueSchoolClasses(results))) ;

        return {
          grades: grades,
          classes: classes,
          studentSessions: results,
        } ;
      })
    ) ;
  }

  getSessionSummaryReportData(districtId: number, schoolId: number, teacherId: number, dateRange: number[]): Observable<StudentSessionSummary[]> {
    const reqOpts = {
      withCredentials: true,
    } ;

    return this.httpClient.get<StudentSessionSummary[]>(`${environment.dataServicesURL}manage/reports/district/${districtId}/school/${schoolId}/teacher/${teacherId}/acStudentSessionSummary/startTime/${dateRange[0]}/endTime/${dateRange[1]}`, reqOpts) ;
  }

  getStudentSummaryReportData(teacherId: number, studentId: number): Observable<any> {
    const reqOpts = {
      withCredentials: true,
    };

    return this.httpClient.get<any>(`${environment.dataServicesURL}manage/reports/teacher/${teacherId}/student/${studentId}/summary`, reqOpts).pipe(
      map(summaryObj => {
        let assessments: AssessmentScore[] = summaryObj.assessments.map((assessment: any) => this.assessmentScoreAdapter.adapt(assessment)) ;
        assessments.forEach((assessment) => assessment.limitScores()) ;
        summaryObj.assessments =  assessments ;

        return summaryObj ;
      })
    ) ; ;
  }

  getObjectivePrePostPerformanceData(schoolId: number, teacherId: number, studentId: number): Observable<any> {
    let selectedDistrictID = this.getSelectedDistrictForReports()?.districtID ;
    const reqOpts = {
      withCredentials: true,
    };

    // We cannot load objective performance data if our schoolID is 0 ('All' schools)
    if (schoolId === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      });
    }

    // We cannot load summary results if our teacherId is 0 ('All' teachers)
    if (teacherId === 0)
    {
      return of({
        erred: true,
        message: 'Please select a Teacher',
      });
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${selectedDistrictID}/school/${schoolId}/teacher/${teacherId}/student/${studentId}/objectivePrePostPerformance`, reqOpts) ;
  }

  getUnitDetailReportData(schoolId: number, teacherId: number, studentId: number): Observable<InterventionTaskStatus[]> {
    let selectedDistrictID = this.getSelectedDistrictForReports()?.districtID ;
    const reqOpts = {
      withCredentials: true,
    };

    return this.httpClient.get<InterventionTaskStatus[]>(`${environment.dataServicesURL}manage/reports/district/${selectedDistrictID}/school/${schoolId}/teacher/${teacherId}/student/${studentId}/unitDetails`, reqOpts).pipe(
      map((data: any) => {
        let tasks: InterventionTaskStatus[] = [] ;

        // Try to add a task category if we can find one
        data.forEach((task: any) => {
          let category = TaskCategories.find((category: TaskCategory) => {
            // NOTE: This only works because our TaskCategories array has 'SPEEDED' (masked variants - RHYM),
            //     : first, so that 'RHYM' will match SPEEDED instead of TEXT_COMP (which has 'RHY'). A more
            //     : complete solution might be to have the back end identify the BaseTaskID and send that back
            //     : but that is a complex query
            return category.tasks.filter((t: string) => task.taskID.startsWith(t)).length ;
          }) ;

          if (category)
          {
            task.taskCategory = category.type ;
          }

          tasks.push(task) ;
        }) ;

        return tasks ;
      })
    ) ;
  }

  getDiagnosticData(system: boolean): Observable<any> {
    let schoolId = this.getSelectedSchoolForReports().schoolID ;
    let teacherId = this.getSelectedTeacherForReports().userID ;
    const selectedDistrict = this.getSelectedDistrictForReports() ;
    const cleverReqs = this.checkCleverRequirements(selectedDistrict, schoolId, teacherId) ;
    if (cleverReqs !== null)
    {
      return cleverReqs ;
    }

    // We cannot load objective performance data if our schoolID is 0 ('All' schools)
    if (schoolId === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      });
    }

    return this.getStudentsWithAssessments(schoolId, teacherId, system).pipe(
      map((results) => {
        let classes: SchoolClass[] = [];
        let grades: string[] = [];
        let gradesSet: Set<string> = new Set<string>();
        let testWindows: string[] = [] ;
        let compareTestWindows: string[] = [] ;
        let testWindowsSet: Set<string> = new Set<string>() ;
        let product = (system ? SubscriptionTypes.FullProduct : SubscriptionTypes.DiagnosticProduct) ;

        results.forEach((student) => {
          gradesSet.add(this.utilityService.getStudentGrade(student.grade));

          student.assessmentScores?.forEach((assessmentScore) => {
            assessmentScore.testWindow = this.utilityService.getTestWindow(assessmentScore.dateStarted, product, (system ? assessmentScore.assessmentType : null)) ;
            assessmentScore.limitScores() ;
            testWindowsSet.add(assessmentScore.testWindow) ;
          }) ;
        });
        grades = ['All'].concat(this.utilityService.sortGrades(Array.from(gradesSet)));
        testWindows = [ 'All Tests' ].concat(Array.from(testWindowsSet)) ;
        compareTestWindows = Array.from(testWindowsSet) ;

        classes = [{
          name: 'All',
          schoolClassID: 0,
          teacherID: 0,
          createDate: (new Date()),
          status: ''
        }].concat(this.utilityService.sortClasses(this.utilityService.getUniqueSchoolClasses(results)));

        return {
          grades: grades,
          classes: classes,
          students: results,
          testWindows: testWindows,
          compareTestWindows: compareTestWindows,
        };
      })
    ) ;
  }

  getDistrictScreenerData(): Observable<any> {
    let districtId = this.getSelectedDistrictForReports().districtID ;

    return this.getDistrictScreenerReportData(districtId) ;
  }

  getDistrictScreenerReportData(districtId: number): Observable<any> {
    let reqOpts = {
      withCredentials: true,
    } ;

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtId}/screener`, reqOpts) ;
  }

  getDistrictUsageData(): Observable<any> {
    let districtId = this.getSelectedDistrictForReports().districtID ;

    return this.getDistrictUsageReportData(districtId).pipe(
      switchMap((usageData: any) => {
        let trendLabels: any = [] ;

        usageData.weeklyUsage.forEach((val: any, idx: number) => {
          trendLabels.push(idx + 1) ;
        }) ;
        usageData.trendLabels = trendLabels ;
        usageData.rangeData ??= { schools: {} } ;

        return of(usageData) ;
      })
    ) ;
  }

  getDistrictUsageReportData(districtId: number): Observable<any> {
    let reqOpts = {
      withCredentials: true,
    } ;

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtId}/usage`, reqOpts) ;
  }

  getDistrictUsageRangeData(districtId: number, start: number, end: number): Observable<any> {
    let reqOpts = {
      withCredentials: true,
    } ;

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtId}/usage/start/${start}/end/${end}`, reqOpts) ;
  }

  getDistrictProgressData(): Observable<any> {
    let districtId = this.getSelectedDistrictForReports().districtID ;

    return this.getDistrictProgressReportData(districtId) ;
  }

  getDistrictProgressReportData(districtId: number): Observable<any> {
    let reqOpts = {
      withCredentials: true,
    } ;

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtId}/progress`, reqOpts) ;
  }

  getDistrictDiagnosticData(): Observable<any> {
    let districtId = this.getSelectedDistrictForReports().districtID ;

    return this.getDistrictDiagnosticReportData(districtId) ;
  }

  getDistrictDiagnosticReportData(districtId: number): Observable<any> {
    let reqOpts = {
      withCredentials: true,
    } ;

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtId}/diagnostic`, reqOpts) ;
  }

  filterDistrictReportData(data: any, schoolId: number) {
    // If our school ID is 0 (get all schools), just return our data
    if (schoolId === 0) return data ;

    let filteredData: any = {} ;
    Object.values(data).forEach((schoolData: any) => {
      if (schoolData.id === schoolId)
      {
        filteredData[schoolData.id] = schoolData ;
      }
    }) ;

    return filteredData ;
  }

  getDistricts(user: ManagementUser): Observable<District[]> {
    if (!user.isFILUser())
    {
      return of([{
        districtID: user.districtID,
        name: user.districtName || '',
        enabled: true,
      }]) ;
    }

    // If we are a Sales Rep, we need to get all districts in our territory.
    if (user.isSalesRepUser()) {
      let reqOpts = {
        withCredentials: true,
        territory: user.territory,
      } ;
      return this.httpClient.get<District[]>(`${environment.dataServicesURL}manage/reports/district/list/${user.territory}/territory`, reqOpts).pipe(
        map((districts) => {
          this.sessionService.setDistricts(districts) ;

          return districts ;
        })
      ) ;
    }

    // We are the FIL Superuser, and once we login the first time our districts will never change. So first check to see
    // if we have districts in the session, if so return those otherwise ask dataservices for our districts
    if (this.sessionService.getDistricts() !== null)
    {
      return of(this.sessionService.getDistricts()!) ;
    }
    else
    {
      let reqOpts = {
        params: { includeArchived: false },
        withCredentials: true,
      } ;

      return this.httpClient.get<District[]>(`${environment.dataServicesURL}manage/reports/district/list`, reqOpts).pipe(
        map((districts) => {
          this.sessionService.setDistricts(districts) ;

          return districts ;
        })
      ) ;
    }
  }

  getSchools(user: ManagementUser, districtId: number): Observable<School[]> {
    if (districtId > 0)
    {
      // We have selected an individual district, so gather the schools
      if (!user.isFILUser() && !user.isDistrictUser())
      {
        return of([{
          schoolID: user.schoolID,
          districtID: user.districtID,
          name: user.school,
          enabled: true,
        }]) ;
      }

      let reqOpts = {
        params: { includeArchived: false },
        withCredentials: true,
      } ;

      return this.httpClient.get<School[]>(`${environment.dataServicesURL}manage/reports/district/${districtId}/school/list`, reqOpts).pipe(
        map((schools) => {
          this.sessionService.setSchools(schools) ;

          return schools ;
        })
      ) ;
    }
    else
    {
      // We selected all so return the previous set of schools
      let schools = this.sessionService.getSchools() || [] ;
      return of(schools) ;
    }
  }

  getTeachers(user: ManagementUser, schoolId: number): Observable<ManagementUser[]> {
    if (!user.isFILUser() && !user.isDistrictUser() && !user.isSchoolUser())
    {
      return of([
        user
      ]) ;
    }

    let reqOpts = {
      params: { includeArchived: false },
      withCredentials: true,
    } ;

    return this.httpClient.get<ManagementUser[]>(`${environment.dataServicesURL}manage/reports/school/${schoolId}/teacher/list`, reqOpts).pipe(
      map((teachers) => {
        this.sessionService.setTeachers(teachers) ;

        return teachers ;
      })
    ) ;
  }

  getStudentsForTeacher(teacherId: number): Observable<Student[]> {
    if (teacherId === 0)
    {
      return throwError(() => new Error('Please select a Teacher')) ;
    }

    const reqOpts = {
      withCredentials: true,
    } ;

    return this.httpClient.get<Student[]>(`${environment.dataServicesURL}manage/reports/teacher/${teacherId}/student/list`, reqOpts) ;
  }

  getStudentsWithAssessments(schoolId: number, teacherId: number, system: boolean): Observable<Student[]> {
    let currentUser = this.sessionService.getUserData() ;

    if (schoolId === null)
    {
      schoolId = currentUser!.schoolID ;
    }

    if (teacherId === null)
    {
      teacherId = currentUser!.userID ;
    }

    if (schoolId > 0)
    {
      const reqOpts = {
        withCredentials: true,
      };

      let url = `${environment.dataServicesURL}manage/enroll/school/${schoolId}/teacher/${teacherId}/student/reports/` ;
      if (system) url += 'accesscodescores' ;
      else url += 'diagnosticscores' ;

      return this.httpClient.get<Student[]>(url, reqOpts).pipe(
        map(studentObjs => {
          let students: Student[] = [] ;

          studentObjs.forEach((studentObj) => {
            students.push(this.studentAdapter.adapt(studentObj)) ;
          }) ;

          return students ;
        })
      ) ;
    }
    else
    {
      return of([]) ;
    }
  }

  // Category defines the student grade category (elementary or secondary)
  getDiagnosticClass(category: string, score: number) {
    if (score < ScoreCutoffs[category]['automaticity']['some-risk']) return 'score-red' ;
    else if (score < ScoreCutoffs[category]['automaticity']['proficient']) return 'score-yellow' ;
    else return 'score-green' ;
  }

  // Category defines the student grade category (elementary or secondary)
  getDiagnosticImage(category: string, score: number) {
    if (score < ScoreCutoffs[category]['automaticity']['some-risk']) return '/assets/images/reportIcons/iconScoreRed.svg' ;
    else if (score < ScoreCutoffs[category]['automaticity']['proficient']) return '/assets/images/reportIcons/iconScoreYellow.svg' ;
    else return '/assets/images/reportIcons/iconScoreGreen.svg' ;
  }

  getAllSchoolClasses(students: Student[]){
    let allSchoolClasses: SchoolClass[] = [];

    for (let studentIndex in students) {
      let student = students[studentIndex];
      if (student.schoolClass && student.schoolClass.schoolClassID) {
        let included = allSchoolClasses.some((schoolClass) => {
          return schoolClass.schoolClassID === student.schoolClass?.schoolClassID;
        });

        if (!included) allSchoolClasses.push(student.schoolClass);
      }
    }
    return allSchoolClasses;
  }

  downloadGroupSummary(schoolId: number, teacherId: number): Observable<Blob> {
    let selectedDistrictID = this.getSelectedDistrictForReports()?.districtID ;
    return this.downloadService.downloadFile(`${environment.dataServicesURL}manage/reports/district/${selectedDistrictID}/school/${schoolId}/teacher/${teacherId}/acGroupSummary?export=xlsx`) ;
  }

  downloadSessionSummary(districtId: number, schoolId: number, teacherId: number, dateRange: number[]): Observable<Blob> {
    return this.downloadService.downloadFile(
      `${environment.dataServicesURL}manage/reports/district/${districtId}/school/${schoolId}/teacher/${teacherId}/acStudentSessionSummary/startTime/${dateRange[0]}/endTime/${dateRange[1]}?export=xlsx`
    ) ;
  }

  refreshTable(): Observable<any> {
   return of([]) ;
  }


  processStudentsAssessmentScores(students: Student[], scoreTotals: any, scoreTotalsWindow1: any, scoreTotalsWindow2: any) {
    this.initScoreTotalsProperties(scoreTotals) ;
    this.initScoreTotalsProperties(scoreTotalsWindow1) ;
    this.initScoreTotalsProperties(scoreTotalsWindow2) ;

    students.forEach((student) => {
      student.assessmentScores?.forEach((assessmentScore) => {
        // Student scores need to be determined based on their grade now
        this.incrementDecodingScores(assessmentScore, scoreTotals, (student.grade <= 5));
        this.incrementDecodingScores(assessmentScore, scoreTotalsWindow1, (student.grade <= 5));
        this.incrementDecodingScores(assessmentScore, scoreTotalsWindow2, (student.grade <= 5));
        this.incrementAutomaticityScores(assessmentScore, scoreTotals, (student.grade <= 5));
        this.incrementAutomaticityScores(assessmentScore, scoreTotalsWindow1, (student.grade <= 5));
        this.incrementAutomaticityScores(assessmentScore, scoreTotalsWindow2, (student.grade <= 5));

        let studentGroup = (student.grade <= 5) ? 'elementary' : 'secondary' ;
        assessmentScore.instructionalPriorities = "";
        if (assessmentScore.decodingConsonantOverall < ScoreCutoffs[studentGroup]['consonant']['some-risk']) {
          assessmentScore.instructionalPriorities += "C";
        }

        if (assessmentScore.decodingVowelOverall < ScoreCutoffs[studentGroup]['vowel']['some-risk']) {
          if (assessmentScore.instructionalPriorities.length === 0) {
            assessmentScore.instructionalPriorities = "V";
          } else {
            assessmentScore.instructionalPriorities += " - V";
          }
        }

        if (assessmentScore.systemScore < ScoreCutoffs[studentGroup]['automaticity']['proficient']) {
          if (assessmentScore.instructionalPriorities.length === 0) {
            assessmentScore.instructionalPriorities = "A";
          } else {
            assessmentScore.instructionalPriorities += " - A";
          }
        }

        if ((assessmentScore.decodingScore < ScoreCutoffs[studentGroup]['decoding']['proficient'] && assessmentScore.decodingScore >= ScoreCutoffs[studentGroup]['decoding']['some-risk']) &&
          (assessmentScore.decodingConsonantOverall > ScoreCutoffs[studentGroup]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall > ScoreCutoffs[studentGroup]['vowel']['some-risk']) ||
          ((assessmentScore.decodingScore > ScoreCutoffs[studentGroup]['decoding']['proficient']) && assessmentScore.decodingConsonantOverall < ScoreCutoffs[studentGroup]['consonant']['some-risk'] || assessmentScore.decodingVowelOverall < ScoreCutoffs[studentGroup]['vowel']['some-risk'] )) {
          // Only add this if none of C, V, or A have been added already
          if (assessmentScore.instructionalPriorities === "") {
            assessmentScore.instructionalPriorities += "Borderline";
          }
        }

        if (assessmentScore.decodingConsonantOverall > ScoreCutoffs[studentGroup]['consonant']['some-risk'] && assessmentScore.decodingVowelOverall > ScoreCutoffs[studentGroup]['vowel']['some-risk'] &&
          assessmentScore.systemScore >= ScoreCutoffs[studentGroup]['automaticity']['proficient'] && assessmentScore.decodingScore >= ScoreCutoffs[studentGroup]['decoding']['proficient']) {
          if (assessmentScore.decodingConsonantOverall < ScoreCutoffs[studentGroup]['consonant']['proficient'] || assessmentScore.decodingVowelOverall < ScoreCutoffs[studentGroup]['vowel']['proficient']) {
            assessmentScore.instructionalPriorities += "Proficient-B";
          } else {
            // Only add this if none of C, V, or A have been added already
            if (assessmentScore.instructionalPriorities === "") {
              assessmentScore.instructionalPriorities += "Proficient";
            }
          }
        }

        assessmentScore.dataDecoding = [
          [
            assessmentScore.decodingInitialConsonantSingle, assessmentScore.decodingInitialConsonantDigraph,
            assessmentScore.decodingInitialConsonantCluster, assessmentScore.decodingSecondaryConsonantSingle,
            assessmentScore.decodingSecondaryConsonantDigraph, assessmentScore.decodingSecondaryConsonantCluster,
            assessmentScore.decodingShortVowels, assessmentScore.decodingDipthongRVowels,
            assessmentScore.decodingVowelDigraphs, assessmentScore.decodingLongVowels,
            assessmentScore.decodingIrregularShortVowels, assessmentScore.decodingIrregularVowelSecondary
          ]
        ];

        assessmentScore.dataGeneralization = [
          assessmentScore.generalizationWord, assessmentScore.generalizationNonWord,
          assessmentScore.generalizationOneSyl, assessmentScore.generalizationTwoSyl,
          assessmentScore.generalizationThreeSyl,
          assessmentScore.systemNonSpeeded, assessmentScore.systemSpeeded
        ];

        // As specified in feature-3762, instructional zones are now based on ranges and the database zone value is ignored
        ScoreCutoffs[studentGroup]['zones'].forEach((zone: any, idx: number) => {
          if (assessmentScore.systemScore >= zone.bottom && assessmentScore.systemScore <= zone.top)
          {
            assessmentScore.zone = idx + 1 ;
          }
        }) ;

        if (assessmentScore.dateCompleted)
        {
          assessmentScore.formattedDateCompleted = this.utilityService.formatDateNum(assessmentScore.dateCompleted) ;
        }

        // Keep track of the latest assessment data points in the student object so we can sort directly on it
        student.assessmentDataForSorting = {};
        student.assessmentDataForSorting.decodingScore = assessmentScore.decodingScore;
        student.assessmentDataForSorting.automaticityScore = assessmentScore.systemScore;
        student.assessmentDataForSorting.instructionalPriorities = assessmentScore.instructionalPriorities;
      }) ;
    }) ;
  }

  convertCanvasToImage(canvas: HTMLCanvasElement, image: HTMLImageElement) {
    image.src = canvas.toDataURL();
  }

  sortSchoolFunction(val: any) {
    // @ts-ignore
    return val.data[this.sortColumn] ;
  }

  sortTeacherFunction(val: any) {
    // @ts-ignore
    if (this.sortColumn === 2)
    {
      // @ts-ignore
      return val.data[this.sortColumn][0]
    }

    // @ts-ignore
    return val.data[this.sortColumn]
  }

  checkCleverRequirements(district: District, schoolID: number, teacherID: number): Observable<any> | null
  {
    // As detailed in Redmine 5504, when the detailed reports are being created for Clever,
    // we want to force the user to select an individual teacher to avoid the large teacher
    // in the default Clever school
    if (district.name === 'Clever District' && schoolID === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      });
    }

    if (district.name === 'Clever District' && teacherID === 0)
    {
      return of({
        erred: true,
        message: 'Please select a Teacher',
      }) ;
    }

    return null ;
  }

  private initScoreTotalsProperties(scoreTotals: any) {
    scoreTotals.decodingProficient = 0;
    scoreTotals.decodingSomeRisk = 0;
    scoreTotals.decodingHighRisk = 0;
    scoreTotals.automaticityProficient = 0;
    scoreTotals.automaticitySomeRisk = 0;
    scoreTotals.automaticityHighRisk = 0;
  }

  private incrementDecodingScores(assessmentScore: AssessmentScore, scoreTotals: any, isElementary: boolean) {
    let studentGroup = (isElementary) ? 'elementary' : 'secondary' ;
    if (!scoreTotals.testWindow || (assessmentScore.testWindow === scoreTotals.testWindow))
    {
      if (assessmentScore.decodingScore < ScoreCutoffs[studentGroup]['decoding']['some-risk'])
      {
        scoreTotals.decodingHighRisk += 1;
      }
      else if (assessmentScore.decodingScore < ScoreCutoffs[studentGroup]['decoding']['proficient'])
      {
        scoreTotals.decodingSomeRisk += 1;
      }
      else
      {
        scoreTotals.decodingProficient += 1;
      }
    }
  }

  private incrementAutomaticityScores(assessmentScore: AssessmentScore, scoreTotals: any, isElementary: boolean) {
    let studentGroup = (isElementary) ? 'elementary' : 'secondary' ;
    if (!scoreTotals.testWindow || (assessmentScore.testWindow === scoreTotals.testWindow))
    {
      if (assessmentScore.systemScore < ScoreCutoffs[studentGroup]['automaticity']['some-risk'])
      {
        scoreTotals.automaticityHighRisk += 1;
      }
      else if (assessmentScore.systemScore < ScoreCutoffs[studentGroup]['automaticity']['proficient'])
      {
        scoreTotals.automaticitySomeRisk += 1;
      }
      else
      {
        scoreTotals.automaticityProficient += 1;
      }
    }
  }
}
