<div>
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconEnroll.svg" /> Enroll </h3>
  </div>
  <div>
    <div class="report-section-header split-flex">
      <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="studentIcon"></fa-icon> Roster ClassLink Students</h5>
    </div>
    <div class="margin-top-10">
      Select the ClassLink Classes that you would like to roster, and confirm the listed Grade for each Class
      <div class="alert message alert-secondary margin-top-5">
        <div class="icon"><fa-icon [icon]="infoIcon"></fa-icon></div>
        <div>
          <div class="bold">Note:</div>
          If you select "Other" as the Grade for a Class, students in that Calls will be assigned a Secondary Screener Assessment.
          Select Grades 1 - 4 to assign an Elementary Screener Assessment.
        </div>
      </div>
    </div>
    <div id="roster-message" class="alert message" *ngIf="message" [ngClass]="{ 'alert-danger': messageStatus === 'error', 'alert-success' : messageStatus === 'success', 'alert-warning': messageStatus === 'warning' }">
      <div class="icon">
        <fa-icon [icon]="successIcon" *ngIf="messageStatus === 'success'"></fa-icon>
        <fa-icon [icon]="warningIcon" *ngIf="messageStatus === 'warning'"></fa-icon>
        <fa-icon [icon]="errorIcon" *ngIf="messageStatus === 'error'"></fa-icon>
      </div>
      <div>
        <div class="bold">{{ messageTitle }}</div>
        <div [innerHtml]="message | safeHtml"></div>
      </div>
    </div>
    <table class="report-table">
      <thead>
        <tr>
          <th>
            <input type="checkbox" (click)="toggleAll($event)" />
          </th>
          <th>Class</th>
          <th>Grade</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cData of rosterClasses">
          <td>
            <input type="checkbox" (change)="selectClass($event)" [value]="cData.id" [checked]="cData.checked">
          </td>
          <td>{{ cData.name }}</td>
          <td>
            <select [disabled]="cData.grade.disabled">
              <option *ngFor="let grade of gradeOptions" value="{{ grade.key }}" [selected]="grade.value === cData.grade.value">
                {{ grade.value }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-4 submit-btn margin-top-10">
    <button class="btn btn-clever margin-right-5" (click)="submitClasses()" [disabled]="loading">
      <fa-icon [icon]="rosterIcon" *ngIf="!loading"></fa-icon>
      <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="loading"></fa-icon> Roster Classes
    </button>
    <a routerLink="/students" class="btn btn-secondary"><fa-icon [icon]="backIcon"></fa-icon> Back</a>
  </div>
</div>
