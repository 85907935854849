<div #printContent id="printContent">
  <div *ngIf="!isSchoolReportsUser">
    <div class="print-hide">
      <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools"
        [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" [showTeacher]="false"
        (filterChangeEvent)="filterSchoolTeacher($event)">
      </wf-school-teacher-filter>
    </div>
  </div>
  <div [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="district-report-content">
    <div class="district-report-header">
      <span class="report">Usage</span>
      <button id="printButton" class="btn print-summary-button" (click)="printReport()"><fa-icon [icon]="printIcon" *ngIf="!currentUser!.isTeacherReviewUser()"></fa-icon> Print</button>
    </div>
    <div *ngIf="loadError" class="alert message alert-danger">{{ loadError }}</div>
    <div *ngIf="!areSessionsForAcademicYear" class="alert message alert-warning">There have not been any active sessions yet for the current academic year</div>
    <div class="district-report-row">
      <div class="district-report-column width-25">
        <div class="district-report-panel" id="district-report-usage-enrollment">
          <div class="title">Enrollment</div>
          <div class="stat-tile">
            <div class="icon">
              <img src="/assets/images/reportIcons/iconManagement-Enrolled.svg" />
            </div>
            <div class="stat">
              <div class="stat-value">{{ enrolled }}</div>
              <div class="stat-label">Students Enrolled</div>
            </div>
          </div>
        </div>
        <div class="district-report-panel" id="district-report-usage-started">
          <div class="title">Started</div>
          <div class="stat-tile">
            <div class="icon">
              <img src="/assets/images/reportIcons/iconManagement-Started.svg" />
            </div>
            <div class="stat">
              <div class="stat-value">{{ startedPercent }}%</div>
              <div class="stat-label">Students Started</div>
            </div>
          </div>
        </div>
      </div>
      <div class="district-report-column width-75">
        <div class="district-report-panel" id="district-report-usage-trend">
          <div class="title">Usage Trend</div>
          <div id="usage-trend-chart" class="print-hide">
            <canvas baseChart
                    #usageTrendChart
                    class="chart chart-line"
                    id="usage-chart"
                    [data]="trendData"
                    [options]="trendOpts"
                    [type]="trendType"></canvas>
          </div>
          <img id="usage-chart-img" class="chart-img" #usageTrendImg/>
        </div>
      </div>
    </div>
    <div class="district-report-content-container">
      <div class="district-report-row split-flex page-break-after">
        <div class="district-report-column width-60">
          <div class="district-report-panel" id="district-report-usage-logins">
            <div class="title split-flex">
              <div>Student Logins</div>
              <div class="subtitle">Dates: {{ dateRange.startDisplay }} to {{ dateRange.endDisplay }}</div>
            </div>
            <div class="stat-tile">
              <div class="icon">
                <img src="/assets/images/reportIcons/iconManagement-StudentLogins.svg" />
              </div>
              <div class="stat">
                <div class="stat-value">{{ loginPercentDisplay }}%</div>
                <div class="stat-label">Students with at least <span class="bold">one</span> login</div>
              </div>
              <div class="stat">
                <div class="stat-value">{{ multiLoginPercentDisplay }}%</div>
                <div class="stat-label">Students with at least <span class="bold">two</span> logins</div>
              </div>
            </div>
          </div>
        </div>
        <div class="district-report-column width-35 right-text">
          <div class="print-hide">
            <label class="accent-text">Login date range</label>
            <select [(ngModel)]="dateRange" (change)="loadRangeData()">
              <option *ngFor="let range of dateRanges" [ngValue]="range">{{ range.rangeDisplay }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="district-report-row">
        <div class="district-report-column width-100">
          <div class="district-report-panel">
            <div class="title split-flex">
              <div>Weekly Time Goal</div>
              <div class="subtitle">Dates: {{ dateRange.startDisplay }} to {{ dateRange.endDisplay }}</div>
            </div>
            <div id="district-report-usage-goal">
              <div class="icon">
                <img src="/assets/images/reportIcons/iconManagement-WeelyTimeGoal.svg" />
              </div>
              <div id="usage-info">
                <div id="usage-goal-data" class="usage-row">
                  <div>
                    <div class="usage-column usage-summary">
                      <div class="stat">
                        <div class="stat-value">{{ avgWeeklyUsage }}<span class="stat-unit">min</span></div>
                        <div class="stat-label">Average Weekly Usage</div>
                      </div>
                      <div class="stat">
                        <div class="stat-value">60<span class="stat-unit">min</span></div>
                        <div class="stat-label">Minimum Weekly Usage Goal</div>
                      </div>
                    </div>
                  </div>
                  <div id="usage-breakdown-chart-wrapper" class="print-hide width-60">
                    <canvas baseChart
                            #breakdownChart
                            class="chart chart-bar"
                            id="usage-breakdown-chart"
                            [data]="breakdownData"
                            [type]="breakdownType"
                            [options]="breakdownOpts">
                    </canvas>
                  </div>
                  <img id="usage-breakdown-chart-img" class="chart-img" #breakdownChartImg />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="district-report-row page-break-after" *ngIf="!isSchoolReportsUser">
        <div class="district-report-column width-100">
          <wf-report-aggregate-panel [cols]="schoolCols"
                                     [data]="schoolAggregateData"
                                     [showTable]="showSchoolTable"
                                     (sortTableEvent)="sortSchoolTable($event)">
            <div class="title split-flex" header>
              <div style="display: flex ; column-gap: 20px ; align-items: center ;">
                <span>Schools</span>
                <span class="display-toggle print-hide" (click)="toggleSchoolTableSwitch()">{{ schoolTableSwitch }}</span>
              </div>
              <div class="subtitle">{{ dateRange.startDisplay }} to {{ dateRange.endDisplay }}</div>
            </div>
          </wf-report-aggregate-panel>
        </div>
      </div>
      <div class="district-report-row">
        <div class="district-report-column width-100">
          <wf-report-aggregate-panel [cols]="teacherCols"
                                     [data]="teacherAggregateData"
                                     [showTable]="showTeacherTable"
                                     (sortTableEvent)="sortTeacherTable($event)">
            <div class="title split-flex" header>
              <div style="display: flex ; column-gap: 20px ; align-items: center ;">
                <span>Teachers</span>
                <span class="display-toggle print-hide" (click)="toggleTeacherTableSwitch()">{{ teacherTableSwitch }}</span>
              </div>
              <div class="subtitle">{{ dateRange.startDisplay }} to {{ dateRange.endDisplay }}</div>
            </div>
          </wf-report-aggregate-panel>
        </div>
      </div>
    </div>
  </div>
</div>
