<div class="video-background" [hidden]="!viewResourceVideo"></div>
<div class="videogular-container" [hidden]="!viewResourceVideo">
  <div class="exit-video" (click)="onCompleteVideo()"></div>
  <vg-player (onPlayerReady)="onPlayerReady($event)" class="video-container">
      <vg-controls>
        <vg-play-pause></vg-play-pause>
        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>
        <vg-mute></vg-mute>
        <vg-volume></vg-volume>
        <vg-fullscreen></vg-fullscreen>
      </vg-controls>
      <video
        [src]="videoSource"
        [vgMedia]="$any(videoPlayerElement)"
        #videoPlayerElement
        preload="auto"
        playsinline
        autoplay
      >
      </video>
  </vg-player>
</div>
<div>
  <div id="printContent">
    <div class="header-controls split-flex">
      <h3><img src="/assets/images/iconResources.svg" /> Resources </h3>
    </div>
    <div class="report-section-header split-flex">
      <h5 class="results-report-header"><fa-icon [icon]="resourcesIcon"></fa-icon> {{ resourceTitle | titlecase }}</h5>
      <button id="backButton" (click)="goBack()" class="btn print-summary-button" *ngIf="showGoBack"><fa-icon [icon]="backIcon"></fa-icon> Back</button>
    </div>
    <div style="border-left:  1px solid #DDD ; border-right: 1px solid #DDD ; border-bottom: 1px solid #DDD">
      <div class="student-dropdown-row" [hidden]="!showSubCategoryDropdown">
        <span><strong>Sub-Category</strong></span>
        <select class="form-select" (change)="filterResults()" [(ngModel)]="selectedCategory">
          <option *ngFor="let category of subCategories" [value]="category">{{ category }}</option>
        </select>
      </div>
      <div *ngIf="showLessonFilters">
        <div class="flex flex-column padding-10">
          <div class="alert alert-secondary message" style="background-color: rgba(211, 211, 211, .4) ;">
            <div class="icon">
              <fa-icon [icon]="infoIcon"></fa-icon>
            </div>
            <div>
              <div class="bold">Create a Lesson Plan</div>
              Please select a value from each dropdown to build your lesson. Press the 'Generate Lesson' button to download the lesson plan.
            </div>
          </div>
          <div class="flex flex-row column-gap-10">
            <div>
              <label for="grade-select">Grade</label>
              <select id="grade-select" class="form-select"  [(ngModel)]="selectedGrade">
                <option *ngFor="let grade of gradeUnitParams | keyvalue" [value]="grade.key">{{ gradeUnitParams[grade.key].display }}</option>
              </select>
            </div>
            <div>
              <label for="unit-select">Unit</label>
              <select id="unit-select" class="form-select" [(ngModel)]="selectedUnit" [disabled]="!selectedGrade">
                <option *ngFor="let unit of gradeUnitParams[selectedGrade]?.units" [value]="unit">{{ unit }}</option>
              </select>
            </div>
            <div>
              <label for="zone-select">Zone</label>
              <select id="zone-select" class="form-select" [(ngModel)]="selectedZone" [disabled]="!selectedUnit">
                <option *ngFor="let zone of zoneDayParams | keyvalue" [value]="zone.key">{{ zone.key }}</option>
              </select>
            </div>
            <div>
              <label for="days-select"># of days</label>
              <select id="days-select" class="form-select" [(ngModel)]="selectedNumDays" [disabled]="!selectedZone">
                <option *ngFor="let numDay of zoneDayParams[selectedZone]" [value]="numDay">{{ numDay === 0 ? 'All' : numDay }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="p-3">
          <button id="getPdfButton" (click)="generateLesson()" class="btn btn-success"><fa-icon [icon]="gearIcon"></fa-icon> Generate Lesson</button>
        </div>
      </div>
      <div *ngIf="!showLessonFilters">
        <table class="report-table"  id="resourcesTable" *ngIf="!isKnowledgeBase">
          <thead>
            <tr>
              <th style="border: none ;">Grade</th>
              <th style="border: none ;"></th>
              <th class="sortable-report-table-header" style="cursor: pointer; border: none;" (click)="sortTable('title')">Title
                <div *ngIf="orderByField=='title' && reverseSort" class="arrow-down" style="float: right;"></div>
                <div *ngIf="orderByField=='title' && !reverseSort" class="arrow-up" style="float: right;"></div>
              </th>
              <th style="border: none;">Category</th>
              <th style="border: none ;"></th>
            </tr>
          </thead>
          <tbody>
            <!-- | orderBy:orderByField:reverseSort -->
            <tr *ngFor="let resource of resourceList" (click)="goToLink(resource)" role="button">
              <td>{{ resource.gradeDisplayName }}</td>
              <td>
                <div style="display: inline-block; width: 20px;">
                  <img style="float: left;margin-top: 10%;" src="/assets/images/iconResources_{{resource.icon}}" />
                </div>
              </td>
              <td style="text-align: left; padding-left: 3%;">{{resource.title}}</td>
              <td style="text-align: left;">{{resource.category}}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <wf-knowledge-base [resources]="resourceList" *ngIf="isKnowledgeBase" (itemClicked)="goToLink($event)"></wf-knowledge-base>
      </div>
    </div>
  </div>
</div>
