<div>
  <div class="header-controls">
    <h3><img src="/assets/images/iconEnroll.svg" /> Enroll </h3>
  </div>
  <div>
    <div class="report-section-header">
      <h5 class="results-report-header"><fa-icon [icon]="studentIcon"></fa-icon> {{ formTitle }}</h5>
    </div>
  </div>
  <div class="padding-15" style="border: 1px solid #ddd;">
    <form [formGroup]="enrollmentForm" (ngSubmit)="saveStudent()">
      <div class="row">
        <div class="col-md-4 ">
          <div class="form-group required">
            <label class="control-label" for="first-name">First name*</label>
            <input id="first-name" #firstnameEl type="text" name="first-name" placeholder="first name" class="form-control" formControlName="firstName"/>
            <div *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)" class="help-block small-text">
              <div *ngIf="firstName?.errors?.['required'] || firstName?.errors?.['maxlength']">
                <fa-icon [icon]="errorIcon"></fa-icon> Please enter a first name (50 characters maximum)
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="form-group">
            <label class="control-label" for="middle-name">Middle name</label>
            <input type="text" name="middle-name" id="middle-name" class="form-control" placeholder="middle name" formControlName="middleName"/>
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="form-group required">
            <label class="control-label" for="last-name">Last name*</label>
            <input type="text" name="last-name" id="last-name" class="form-control" placeholder="last name" formControlName="lastName"/>
            <div *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)" class="help-block small-text">
              <div *ngIf="lastName?.errors?.['required'] || lastName?.errors?.['maxlength']">
                <fa-icon [icon]="errorIcon"></fa-icon> Please enter a last name (50 characters maximum)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 ">
          <div class="form-group required">
            <label class="control-label" for="username">Username*</label>
            <input class="form-control" type="text" name="username" id="username" (focus)="initializeUsernameErrorMessage()" title="Must be at least 5 characters" formControlName="username" placeholder="username">
            <p *ngIf="(submitted && enrollmentForm.get('username')?.errors?.['required']) || enrollmentForm.get('username')?.errors?.['pattern'] || invalidUsername"
              class="help-block">
                {{errorMessage || "The username is required and must be between 5 and 20 alphanumeric characters in length."}}
            </p>
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="form-group required">
            <label class="control-label" for="password">Password*</label>
            <input class="form-control" type="{{passwordInputType}}" name="password" id="password" title="{{password.title}}" formControlName="password" placeholder="password">
            <p *ngIf="(submitted && enrollmentForm.get('password')?.errors?.['required']) || enrollmentForm.get('password')?.errors?.['pattern'] || invalidPassword" class="help-block">{{password.helpBlock}}</p>
          </div>
        </div>
        <div class="col-md-3" *ngIf="showCurriculum">
          <div class="form-group required">
            <label class="control-label">Curriculum</label>
            <select class="form-control form-select" name="curriculum" id="curriculumAssigned" formControlName="curriculum">
              <option *ngFor="let curriculum of enrollmentOptions.curriculumOptions | keyvalue">{{curriculum.value}}</option>
            </select>
            <p *ngIf="submitted && enrollmentForm.get('curriculum')?.errors?.['required']" class="help-block">Your curriculum is required.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 enrollment-grade-dropdown mobile-select-item">
          <div class="form-group required">
            <label class="control-label" for="grade">Grade*</label>
            <select class="form-control form-select" name="usergrade" id="grade" formControlName="grade" style="height: 100%"
                  (change)="gradeChanged()">
                  <option *ngFor="let grade of enrollmentOptions.gradeOptions" value="{{grade.key}}">
                    {{grade.value}}
                  </option>
            </select>
            <p *ngIf="submitted && enrollmentForm.get('grade')?.errors?.['required']" class="help-block">Your grade is required.</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="school-class">Class</label>
            <input id="school-class" formControlName="schoolClass" list="studentClasses" class="form-control" type="text" autocomplete="off" placeholder="None">
            <datalist id="studentClasses">
              <option *ngFor="let class of enrollmentOptions.classOptions">{{class.name}}</option>
            </datalist>
          </div>
        </div>
        <div class="col-md-2 mobile-select-item" >
          <div class="form-group required">
            <label class="control-label" for="session-length">Session Time</label>
            <select class="form-control form-select enrollment-session-time-dropdown" name="session-length" id="session-length" formControlName="sessionLength">
              <option *ngFor="let time of timeValues" [value]="time">
                {{time}}
              </option>
            </select>
            <p *ngIf="submitted && enrollmentForm.get('sessionLength')?.errors?.['required']" class="help-block">Your session length is required.</p>
          </div>
        </div>
        <div class="col-md-3" *ngIf="showCheckboxes">
          <div class="form-group checkbox-group">
            <div class="checkbox form-check" *ngIf="showTimerOption">
              <input id="timerbaroption" name="timerbaroption" type="checkbox" class="form-check-input" formControlName="timerEnabled">
              <label class="form-check-label" for="timerbaroption">
                Task Timer Bar
              </label>
            </div>
            <div class="checkbox form-check">
              <input id="extra-support-option" name="extra-support-option" type="checkbox" class="form-check-input" formControlName="extraSupportNeeded">
              <label class="form-check-label" for="extra-support-option">
                Extra Support
              </label>
            </div>
            <div class="checkbox form-check">
              <input id="ell-option" name="ell-option" type="checkbox" class="form-check-input" formControlName="englishLanguageLearner">
              <label class="form-check-label" for="ell-option">
                ELL
              </label>
            </div>
            <div class="checkbox form-check">
              <input id="team-option" name="team-option" type="checkbox" class="form-check-input" formControlName="canBeAssignedToTeam">
              <label class="form-check-label" for="team-option">
                Team Leaderboard
              </label>
            </div>
            <div class="checkbox form-check" *ngIf="userData?.canEnableBetaTasks">
              <input id="beta-task-option" name="beta-task-option" type="checkbox" class="form-check-input" formControlName="betaTasksEnabled">
              <label class="form-check-label" for="beta-task-option">
                Enable Beta Tasks
              </label>
            </div>
            <div class="checkbox form-check" *ngIf="userData?.canEnableBetaTasks">
              <input id="beta-speech-task-option" name="beta-speech-task-option" type="checkbox" class="form-check-input" formControlName="betaSpeechTasksEnabled">
              <label class="form-check-label" for="beta-speech-task-option">
                Enable Beta Speech Tasks
              </label>
            </div>
          </div>
        </div>
      </div>
      <label class="control-label" *ngIf="showSubscriptionType">Subscription Type</label>
      <div class="diagnosticproduct assessments-container" *ngIf="showSubscriptionType">
        <div class="row">
          <div class="col-md-4">
            <div class="form-check">
              <input id="screener-radio" type="radio" class="form-check-input" formControlName="enrolledSubscription" value="{{enrollmentOptions.assessmentTypeOptions[0].SubscriptionTypeId}}">
              <label for="screener-radio" class="form-check-label">{{enrollmentOptions.assessmentTypeOptions[0].DisplayText}}</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="form-check">
                <!--NOTE: forms can't disable individual radios, so setting the disabled attribute instead for these subscription types-->
                <input id="diagnostic-radio" type="radio" class="form-check-input" formControlName="enrolledSubscription" value="{{enrollmentOptions.assessmentTypeOptions[1].SubscriptionTypeId}}" [attr.disabled]="disableDiagnosticLicenses ? 1 : null">
                <label for="diagnostic-radio" class="form-check-label">{{enrollmentOptions.assessmentTypeOptions[1].DisplayText}}</label><br>
              </div>
            </div>
            <div class="row" style="margin-top: 5px ;">
              <h5 *ngIf="showDiagnosticLicenses" style="padding-left: 0 ;">
                <span class="label diagnosticproduct-label">{{numberOfDiagnosticLicenses}}</span>
                <small> Subscriptions Remaining</small>
              </h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="form-check">
                <input id="system-radio" type="radio" class="form-check-input" formControlName="enrolledSubscription" value="{{enrollmentOptions.assessmentTypeOptions[2].SubscriptionTypeId}}" [attr.disabled]="disableInterventionLicenses ? 1 : null">
                <label class="form-check-label" for="system-radio">{{enrollmentOptions.assessmentTypeOptions[2].DisplayText}}</label>
              </div>
            </div>
            <div class="row" style="margin-top: 5px ;">
              <h5 *ngIf="showInterventionLicenses" style="padding-left: 0 ;">
                <span class="label diagnosticproduct-label">{{numberOfInterventionLicenses}}</span>
                <small> Subscriptions Remaining</small>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 submit-btn">
          <button class="btn action-button" id="enrollment-submit" type="submit" [disabled]="!enrollmentForm.valid" (click)="submitted=true">Save Student</button>
        </div>
      </div>
    </form>
  </div>
</div>
