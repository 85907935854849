<div class="school-teacher-filter">
  <div class="school-teacher-filter-wrapper">
    <div *ngIf="showDistrict">
      <label>District</label>
      <ng-select [items]="districts"
                 bindLabel="name"
                 [(ngModel)]="selectedDistrict"
                 [clearable]="false"
                 (change)="changeDistrict()"
                 style="width: 110%; min-width: 128px;">
      </ng-select>
    </div>
    <div>
      <label>School</label>
      <ng-select [items]="schools"
                 bindLabel="name"
                 [(ngModel)]="selectedSchool"
                 [clearable]="false"
                 (change)="changeSchool()"
                 style="width: 110%; min-width: 128px;">
      </ng-select>
    </div>
    <div *ngIf="showTeacher">
      <label>Teacher</label>
      <ng-select [(ngModel)]="selectedTeacher"
                 [clearable]="false"
                 (change)="updateFilter()"
                 style="width: 110%; min-width: 192px;">
        <ng-option *ngFor="let teacher of teachers" [value]="teacher">{{teacher.firstName}} {{teacher.lastName}}</ng-option>
      </ng-select>
    </div>
    <div *ngIf="error" class="school-teacher-filter-error-label vertical_text_container">{{ error }}</div>
  </div>
</div>
