<div id="printContent" style="color: #333;">
  <div class="header-controls">
    <h3><fa-icon [icon]="supportIcon"></fa-icon> Contact Support</h3>
  </div>
  <div class="alert alert-secondary">
    <strong>Have a question or need some help?</strong> Tell us about what's going on and we will get back to you with a response
    as soon as we can!
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">
    {{errorMessage}}
  </div>
  <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm" *ngIf="!submitted">
    <div class="form-group">
      <label for="nameInput"><fa-icon [icon]="userIcon"></fa-icon> Your Name* </label>
      <input type="text" class="form-control" id="nameInput" name="name" [(ngModel)]="fullName" required>
    </div>
    <div class="form-group">
      <label for="topicInput"><fa-icon [icon]="topicIcon"></fa-icon> Topic*</label>
      <select class="form-control" id="topicInput" name="topic" [(ngModel)]="topic" (ngModelChange)="onTopicChange($event)" required>
        <option *ngFor="let option of topicOptions"  [ngValue]="option" >{{option.name}}</option>
        <option value="other">Other...</option>
      </select>
      <input *ngIf="isOtherSelected" type="text" class="form-control mt-2" id="otherInput" name="otherTopic" [(ngModel)]="otherTopic" placeholder="Please specify">
    </div>
    <div class="form-group">
      <label for="messageArea"><fa-icon [icon]="messageIcon"></fa-icon> What's Going On?*</label>
      <textarea class="form-control" id="supportMessage" rows="5" name="message" [(ngModel)]="message" required></textarea>
    </div>
    <div class="form-group">
      <label style="margin-bottom: 12px;"><fa-icon [icon]="contactIcon"></fa-icon> How Should We Follow Up?*</label><br>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="contactPreference" id="emailRadio" value="email" [(ngModel)]="contactPreference" required>
        <label class="form-check-label" for="emailRadio">Email</label>
        <input type="email" class="form-control ml-2" id="emailInput" name="email" placeholder="Email" [(ngModel)]="email" [disabled]="contactPreference !== 'email'" required>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="contactPreference" id="phoneRadio" value="phone" [(ngModel)]="contactPreference" required>
        <label class="form-check-label" for="phoneRadio">Phone</label>
        <input type="text" class="form-control ml-2" id="phoneInput" name="phone" placeholder="Phone number" [(ngModel)]="phone" [disabled]="contactPreference !== 'phone'" required>
      </div>
    </div>
    <button type="submit" class="btn action-button" [disabled]="contactForm.invalid">
      <fa-icon [icon]="sendIcon"></fa-icon> Send
    </button>

  </form>
  <div *ngIf="submitted" style="margin-top: 50px;">
    <h1>Submitted!</h1>
    <p>Thank you for contacting us! We will get back to you as soon as we can.</p>
  </div>
</div>
