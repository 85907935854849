import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, throwError, of,  Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
import { LoginService } from '../services/login.service';

@Injectable()
export class ErrorReportingInterceptor implements HttpInterceptor {
  constructor(
    private sessionStorageService: SessionStorageService,
    private loginService: LoginService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let ssoInfo = this.sessionStorageService.getSSOInfo();

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse): Observable<any> => {
        if (this.sessionStorageService.isUserLoggedIn() && (err.status === 401 || err.status === 403))
        {
          let msg = 'You have been logged out, please login again' ;
          if (ssoInfo?.provider.toLocaleLowerCase() === 'classlink')
          {
            msg = 'You have been logged out of ClassLink, please login again' ;
          }
          else if (ssoInfo?.provider.toLocaleLowerCase() === 'clever')
          {
            msg = 'You have been logged out of Clever, please login again' ;
          }

          this.loginService.logout(true, msg) ;

          return of(msg) ;
        }

        return throwError(() => err) ;
      })
    );
  }
};
