import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { SessionStorageService } from './session-storage.service';

@Injectable({
    providedIn: 'root'
  })

export class AssignDiagnosticService {
  constructor(
    private httpClient: HttpClient,
    private sessionStorage: SessionStorageService,
  ) { }

  assignDiagnosticToStudent(studentID: number){
    const user = this.sessionStorage.getUserData()
    const reqOpts = {
      withCredentials: true
    };

    return this.httpClient.put(`${environment.dataServicesURL}manage/enroll/students/assigndiagnostic/add/${user?.userID}/${studentID}`, {}, reqOpts);
  }

  assignFullProductToStudent(studentID: number){
    const user = this.sessionStorage.getUserData()
    const reqOpts = {
      withCredentials: true
    };

    return this.httpClient.put(`${environment.dataServicesURL}manage/enroll/students/assignintervention/add/${user?.userID}/${studentID}`, {}, reqOpts)

  }

  removeFullProductFromStudent(studentID: number, subscriptionID: number){
    const reqOpts = {
      withCredentials: true
    };

    return this.httpClient.put(`${environment.dataServicesURL}manage/enroll/students/assignintervention/remove/${studentID}/${subscriptionID}`, {}, reqOpts);
  }

  removeDiagnosticFromStudent(studentID: number, subscriptionID: number){
    const reqOpts = {
      withCredentials: true
    };

    return this.httpClient.put(`${environment.dataServicesURL}manage/enroll/students/assigndiagnostic/remove/${studentID}/${subscriptionID}`, {}, reqOpts);
  }
}
