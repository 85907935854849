import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StudentsService } from 'src/app/core/services/students.service';

@Injectable({
  providedIn: 'root'
})
export class StudentEditResolver  {
  constructor (
    private studentService: StudentsService,
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    // TODO: may need better default value if can't get id from route
    let studentId = parseInt(route.paramMap.get('id') || '0', 10);
    return this.studentService.getStudentInformation(studentId);
  }
}
