import { Component, OnDestroy, OnInit } from '@angular/core';

import { NavigationEnd, Router } from '@angular/router';

import { SessionTimerService } from './core/services/session-timer.service';
import { LoginService } from './core/services/login.service';
import { SessionStorageService } from './core/services/session-storage.service';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { PageSession } from './core/models/page-session.model';
import { ManagementSessionService } from 'src/app/core/services/management-session.service';
import {
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'WordFlight Management';
  loggedIn: boolean = false;
  shouldShowSidebar = false;
  districtsBackground: boolean = false;
  loginStatusSubscription: Subscription | undefined;
  sessionTimerSubscription: Subscription | undefined;
  routeChangeSubscription: Subscription | undefined;
  private previousPage: string | null = null;
  announcementIcon = faBullhorn;
  showAnnouncement: boolean = environment.showAnnouncement;

  constructor(
    private sessionTimerService: SessionTimerService,
    private sessionStorageService: SessionStorageService,
    private managementSessionService: ManagementSessionService,
    private loginService: LoginService,
    private router: Router
  ) {
    // Insert our typography url
    let css = document.createElement('link');
    css.setAttribute('rel', 'stylesheet');
    css.setAttribute('type', 'text/css');
    css.setAttribute('href', environment.TYPOGRAPHY_URL);
    document.head.appendChild(css);

    this.routeChangeSubscription = this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          // make sure user is logged in
          if (this.sessionStorageService.isUserLoggedIn()) {
            // End the previous page session
            if (this.previousPage) {
              this.sessionTimerService.endPageSession(this.previousPage);
            }
            // Start the new page session
            this.sessionTimerService.startPageSession(
              event.url,
              this.previousPage || null
            );
            // Update the previous page to the current page for the next navigation event
            this.previousPage = event.url;
          }

          // When we are on the diagnostic details page, we should not show the sidebar
          this.shouldShowSidebar = !event.url.includes('/detail');
          this.districtsBackground = event.url.includes('/reports/district');
          this.sessionStorageService.setCurrentRoute(event.url);
          this.sessionStorageService.setLastAccess(new Date().getTime());
        }
      }
    );
  }

  ngOnInit() {
    // Listen to the login source so we can handle logouts
    this.loginStatusSubscription = this.loginService.loginStatus$.subscribe({
      next: (isLoggedIn: boolean) => {
        this.loggedIn = isLoggedIn;

        if (!isLoggedIn) {
          this.previousPage = null ;
          this.router.navigateByUrl('/login');
        }
      },
    });

    // Subscribe to our logout observable to catch session timer events
    this.sessionTimerSubscription = this.sessionTimerService
      .getLogoutTrigger()
      .subscribe(() => {
        this.loginService.logout();
      });

    // If we reloaded the app while logged in, start our session timers back up and navigate to our page
    if (this.sessionStorageService.isUserLoggedIn()) {
      this.sessionTimerService.startSleepAndInactivityTimers();

      let timeSinceLastAccess =
        this.sessionStorageService.getLastAccess() === null
          ? 0
          : new Date().getTime() - this.sessionStorageService.getLastAccess()!;
      let resumeRoute = this.sessionStorageService.getCurrentRoute();

      // Only go back to our resume route if the time since our last access has not exceeded the session timeout
      if (
        resumeRoute &&
        timeSinceLastAccess <= this.sessionTimerService.getTimeoutThreshold()
      ) {
        this.loginService.notifyLoginStatus(true);
        this.router.navigateByUrl(resumeRoute);
      } else {
        this.loginService.logout();
      }
    }
  }

  ngOnDestroy() {
    this.loginStatusSubscription?.unsubscribe();
    this.sessionTimerSubscription?.unsubscribe();
    this.routeChangeSubscription?.unsubscribe();
  }
}
