<div class="modal-body">
  <div id="nav-container">
    <div>
      <button class="btn loading-btn" (click)="previousPage()"><fa-icon [icon]="prevIcon"></fa-icon> Prev Page</button>
      <button class="btn loading-btn" (click)="nextPage()"><fa-icon [icon]="nextIcon"></fa-icon> Next Page</button>
    </div>
    <div class="action-container">
      <div class="loading-indicator" *ngIf="isLoading"><fa-icon [icon]="loadingIcon" [spin]="true"></fa-icon> Loading Page...</div>
      <div><button (click)="onCloseClick()" class="btn-close" aria-label="Close"></button></div>
    </div>
  </div>
  <img (contextmenu)="disableContextMenu($event)" [src]="imageUrl" width="100%" height="100%" *ngIf="imageUrl" />
</div>
