import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  faCalendarDays,
  faCircleMinus,
  faCirclePlus,
  faInfinity,
  faPrint,
  faUserGraduate,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons';

import * as _ from 'underscore';

import { AssignDiagnosticService } from '../../core/services/assign-diagnostic.service';
import { SidebarService } from '../../core/services/sidebar.service';
import { SubscriptionTypes } from '../../core/models/subscription-types.model';
import { Student } from '../../core/models/student.model' ;
import { UtilityService } from 'src/app/core/services/utility.service';
import { EnrollmentService } from '../../core/services/enrollment.service';
import { StudentsService } from '../../core/services/students.service';
import { SchoolClass } from 'src/app/core/models/school-class.model';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { PrintService } from '../../core/services/print.service';
import { ReportsService } from '../../core/services/reports.service';

@Component({
  selector: 'wf-assign-diagnostics',
  templateUrl: './assign-diagnostics.component.html',
  styles: [
  ]
})
export class AssignDiagnosticsComponent implements OnInit {
  UNLIMITED_LICENSE: number = -1 ;
  showConfirmationDialog: boolean = false;
  showSchedulingButton: boolean = false;
  orderByField: any = null;
  reverseSort: boolean = false;
  fullProduct: string = SubscriptionTypes.FullProduct;
  diagnosticProduct:string = SubscriptionTypes.DiagnosticProduct;
  drawerIsOpen = false;
  openDrawer = this.sidebarService.toggleDrawer();
  numberOfFullProductLicenses: string | number = 0 ;
  numberOfDiagnosticProductLicenses: string | number = 0;
  grades: string[] = [] ;
  classes: SchoolClass[] = [] ;
  students: Student[] = [];
  filteredStudents: Student[] = [];
  printIcon = faPrint ;
  removeDiagnosticIcon = faCircleMinus ;
  addDiagnosticIcon = faCirclePlus ;
  infinityIcon = faInfinity ;
  customizeIcon = faCalendarDays ;
  studentIcon = faUserGraduate ;
  enrollIcon = faUserGroup ;

  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private utilityService: UtilityService,
    private enrollmentService: EnrollmentService,
    private studentService: StudentsService,
    private sessionStorageService: SessionStorageService,
    private printService: PrintService,
    private reportsService: ReportsService,
    private assignDiagnosticService: AssignDiagnosticService,
  ) { }

  ngOnInit(): void {
    this.grades = this.route.snapshot.data['resolveData']['grades'] ;
    this.classes = this.route.snapshot.data['resolveData']['classes'] ;
    this.numberOfDiagnosticProductLicenses = this.route.snapshot.data['resolveData']['diagnosticLicenseCount'] ;
    this.numberOfFullProductLicenses = this.route.snapshot.data['resolveData']['interventionLicenseCount'] ;
    this.students = this.route.snapshot.data['resolveData']['students'] ;
    this.filteredStudents = this.route.snapshot.data['resolveData']['students'] ;
  }

  sortTable(columnName: string) {
    if (this.orderByField === columnName)
    {
      this.reverseSort = !this.reverseSort ;
    }
    this.orderByField = columnName;

    this.filteredStudents = (this.reverseSort) ? _.sortBy(this.filteredStudents, columnName).reverse() : _.sortBy(this.filteredStudents, columnName) ;
  }

  assignDiagnosticSubscription(student: Student) {
    if (parseInt(this.numberOfDiagnosticProductLicenses.toString()) > 0 || this.numberOfDiagnosticProductLicenses == this.UNLIMITED_LICENSE)
    {
       this.assignDiagnosticService.assignDiagnosticToStudent(student.userID).subscribe({
         next: (data:any) => {
           this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
           this.router.onSameUrlNavigation = 'reload' ;
           this.router.navigateByUrl('/students/manage-subscriptions') ;
         }
       });
    }
  }

  removeDiagnosticSubscription(student: Student, subscriptionID: number) {
    this.assignDiagnosticService.removeDiagnosticFromStudent(student.userID, subscriptionID).subscribe({
      next: (data:any) => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
        this.router.onSameUrlNavigation = 'reload' ;
        this.router.navigateByUrl('/students/manage-subscriptions') ;
      }
    })
  }

  assignFullProductSubscription(student: Student) {
    if (parseInt(this.numberOfFullProductLicenses.toString()) > 0 || this.numberOfFullProductLicenses == this.UNLIMITED_LICENSE)
    {
      this.assignDiagnosticService.assignFullProductToStudent(student.userID).subscribe({
        next: (data:any) => {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
          this.router.onSameUrlNavigation = 'reload' ;
          this.router.navigateByUrl('/students/manage-subscriptions') ;
        }
      }) ;
    }
  }

  removeFullProductSubscription(student: Student, subscriptionID: number) {
    this.assignDiagnosticService.removeFullProductFromStudent(student.userID, subscriptionID).subscribe({
      next: (data:any) => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
        this.router.onSameUrlNavigation = 'reload' ;
        this.router.navigateByUrl('/students/manage-subscriptions') ;
      }
    })
  }

  showAddTestButton(student: any, lastRow: any) {
    return (lastRow && student.showAddTestButton && (parseInt(this.numberOfDiagnosticProductLicenses.toString()) > 0 || this.numberOfDiagnosticProductLicenses == this.UNLIMITED_LICENSE));
  }

  showRemoveTestButton(assessment: any) {
    return (assessment != null) && (assessment.state === 'Assigned') && assessment.isDiagnostic;
  }

  showAddFullProductButton(assessment: any, lastRow: any) {
    let showButton = false;
    if ((assessment != null) && (parseInt(this.numberOfFullProductLicenses.toString()) > 0 || this.numberOfFullProductLicenses == this.UNLIMITED_LICENSE)) {
      showButton = lastRow && ((assessment.state === 'Unassigned') || assessment.expired || (assessment.state === 'Completed' && assessment.isDiagnostic));
    }
    return showButton;
  }

  showRemoveFullProductButton(student: any, assessment: any) {
    return (assessment != null) && (student.showRemoveACButton) && (assessment.isPreDiagnostic);
  }

  goToScheduleView() {
    this.router.navigateByUrl('/students/manage-subscriptions/scheduling');
  }

  toggleConfirmation() {
    this.showConfirmationDialog = !this.showConfirmationDialog;
  }

  checkForConfirmation() {
    this.showConfirmationDialog = true;
  }

  printStudents() {
    const userData = this.sessionStorageService.getUserData();
    const teacher = `${userData?.firstName} ${userData?.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, userData?.school || '', teacher);
  }

  filterStudents(filterOpts: any) {
    this.filteredStudents = this.studentService.filterStudents(this.students, filterOpts) ;
  }
}

