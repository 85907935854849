import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {catchError, forkJoin, map} from 'rxjs';

import { environment } from '../../../../../environments/environment';

import { ReportsService } from '../../../../core/services/reports.service';
import { UtilityService } from '../../../../core/services/utility.service';
import { SessionStorageService } from '../../../../core/services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private TIP_OF_THE_WEEK_FILE = '/assets/configuration/tipOfTheWeek.json' ;

  constructor(
    private httpClient: HttpClient,
    private reportsService: ReportsService,
    private utilityService: UtilityService,
    private sessionService: SessionStorageService,
  ) { }

  getStudentsNotLoggedInList() {
    let teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let districtID = this.reportsService.getSelectedDistrictForReports().districtID ;

    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtID}/school/${schoolID}/teacher/${teacherID}/StudentsNotLoggedIn/List`, reqOpts);
  }

  getStudentsNotLoggedInCount() {
    let teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let districtID = this.reportsService.getSelectedDistrictForReports().districtID ;

    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtID}/school/${schoolID}/teacher/${teacherID}/StudentsNotLoggedIn/Count`, reqOpts);
  }

  getMostCommonCurrentUnit() {
    let teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let districtID = this.reportsService.getSelectedDistrictForReports().districtID ;

    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtID}/school/${schoolID}/teacher/${teacherID}/mostCommonCurrentUnit`, reqOpts);
  }

  getNumberOfEnrolledStudentsForFullProduct() {
    let teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let districtID = this.reportsService.getSelectedDistrictForReports().districtID ;

    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtID}/school/${schoolID}/teacher/${teacherID}/numEnrolledStudentsFullProduct`, reqOpts);
  }

  getNumberOfPointsEarned() {
    let teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let districtId = this.reportsService.getSelectedDistrictForReports().districtID ;

    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtId}/school/${schoolID}/teacher/${teacherID}/numPointsEarned`, reqOpts);
  }

  getPreviousWeeksSessionTimeStats() {
    let teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let districtId = this.reportsService.getSelectedDistrictForReports().districtID ;

    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtId}/school/${schoolID}/teacher/${teacherID}/previousWeeksTimeGoalStats`, reqOpts);
  }

  getAvgWeeklyUsagePrevious() {
    let teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let districtID = this.reportsService.getSelectedDistrictForReports().districtID ;

    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtID}/school/${schoolID}/teacher/${teacherID}/avgWeeklyUsagePrevious`, reqOpts);
  }

  getAvgWeeklyUsageAll() {
    let teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    let schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    let districtID = this.reportsService.getSelectedDistrictForReports().districtID ;

    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/district/${districtID}/school/${schoolID}/teacher/${teacherID}/avgWeeklyUsageAll`, reqOpts);
  }

  getTipOfTheWeek() {
    let weekNumber = this.utilityService.getWeekNumber() ;

    // If weekNumber is 53, use the tip for the 52nd week
    if (weekNumber === 53) weekNumber = 52 ;

    // Read in the tip of the week file and return the tip of the week
    return this.httpClient.get(this.TIP_OF_THE_WEEK_FILE).pipe(
      map((resp: any) => {
        if (resp.tips[weekNumber] !== null)
        {
          return resp.tips[weekNumber] ;
        }
        else
        {
          return '--' ;
        }
      }),
      catchError((err) => {
        return '--' ;
      })
    )
  }

  getSystemDashboardData() {
    const teacherID = this.reportsService.getSelectedTeacherForReports().userID ;
    const schoolID = this.reportsService.getSelectedSchoolForReports().schoolID ;
    const selectedDistrict = this.reportsService.getSelectedDistrictForReports() ;
    const checkCleverReqs = this.reportsService.checkCleverRequirements(selectedDistrict, schoolID, teacherID) ;
    if (checkCleverReqs !== null)
    {
      return checkCleverReqs ;
    }

    return forkJoin({
      studentsNotStarted: this.getStudentsNotLoggedInCount(),
      studentsEnrolled: this.getNumberOfEnrolledStudentsForFullProduct(),
      totalPointsEarned: this.getNumberOfPointsEarned(),
      tipOfTheWeek: this.getTipOfTheWeek(),
      previousWeekSessions: this.getPreviousWeeksSessionTimeStats(),
      avgWeeklyUsagePrevious: this.getAvgWeeklyUsagePrevious(),
      avgWeeklyUsageAll: this.getAvgWeeklyUsageAll(),
      mostCommonUnit: this.getMostCommonCurrentUnit(),
    }) ;
  }

  getParentDashboardDataForStudent(studentId: number) {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(`${environment.dataServicesURL}manage/reports/student/${studentId}/parentDashboard`, reqOpts);
  }
}
