import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { SessionStorageService } from '../../core/services/session-storage.service';
import { ReportsService } from '../../core/services/reports.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenerResolver  {
  constructor(
    private sessionService: SessionStorageService,
    private reportService: ReportsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let schoolID = this.reportService.getSelectedSchoolForReports().schoolID ;
    let teacherID = this.reportService.getSelectedTeacherForReports().userID ;

    // We cannot load screener report results if our schoolID is 0 ('All' schools)
    if (schoolID === 0)
    {
      return of({
        erred: true,
        message: 'Please select a School',
      }) ;
    }

    return this.reportService.getScreenerReportData(schoolID, teacherID) ;
  }
}
