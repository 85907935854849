import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  sectionAddMessage: any = {};

  constructor() { }
  // Message to notify user of issues when adding sections from Clever
  setSectionAddMessage(response: any) {
    let studentsWithOtherTeachers = 0;
    this.sectionAddMessage.studentList = [];
    response.duplicateStudentList.forEach((student: any) => {
        if (student.TeacherID !== response.currentUserID) {
        studentsWithOtherTeachers++;
        this.sectionAddMessage.studentList.push(student.FirstName + " " + student.LastName);
      }
    });
    this.sectionAddMessage.showMessage = studentsWithOtherTeachers > 0 ? true : false;
    let plurality = studentsWithOtherTeachers === 1 ? '' : 's';

    this.sectionAddMessage.message = 'We were unable to add ' + studentsWithOtherTeachers
                        + ' student' + plurality + ' because they already have WordFlight accounts'
                        + ' associated with another WordFlight teacher.';
  }

  getSectionAddMessage() {
    return this.sectionAddMessage;
  }

  clearSectionAddMessage() {
    this.sectionAddMessage = {};
  }
}
