<div>
  <svg width="100%" height="100%" viewBox="0 0 1000 500">
    <path fill="none"
          stroke-width="40"
          stroke="#DCDDDE"
          stroke-linecap="round"
          [attr.d]="gaugeFiller"/>

    <path fill="none"
          stroke-width="40"
          [attr.stroke]="color"
          stroke-linecap="round"
          [attr.d]="gaugeComplete"/>
  </svg>
</div>
