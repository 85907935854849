import { Injectable } from '@angular/core';

const PRINT_IFRAME_ID = 'fil-print-iframe';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() { }

  getPrintHeader(school: string, teacher: string) {
    if (school == null){
      school = '';
    } else if (school == 'All'){
      school = 'All Schools';
    }

    if (teacher == null || teacher == 'All'){
      teacher = '';
    }
    let header = '';
    header += '<div style="display: flex; justify-content: space-between; align-items: center; font-family: Helvetica,Arial,sans-serif">';
    header +=   '<div>';
    header +=     '<p style="display: block; margin: auto; margin-bottom: 5px; text-align: left;">' + school + '</p>';
    header +=     '<p style="display: block; margin: auto; text-align: center;">' + teacher + '</p>';
    header +=   '</div>';
    header +=   '<img src="/assets/images/wordflight_vrt_color_pos_rgb.svg" style="max-width: 200px; max-height:50px; float: right;" />';
    header += '</div>';

    return header;
  }

  getPrintHtml(data: HTMLElement, school: string, teacher: string) {
    let printHeader = this.getPrintHeader(school, teacher);
    const linkedSheets: any[] = Array.from(window.document.head.getElementsByTagName('link')) ;

    let html = '<!DOCTYPE html>';
    html += '<html>';
    html +=   '<head>';
    // Load all the style sheets that our parent window had loaded
    linkedSheets.forEach((link) => {
      html +=     `<link href="${link.href}" rel="stylesheet">`;
    }) ;

    html +=   '</head>';
    html +=   '<body>';
    html +=     printHeader;
    html +=     data.innerHTML.toString();
    html +=   '</body>';
    html += '</html>';

    return html;
  }

  removePrintIframes() {
    const iframeElements = document.getElementsByTagName('iframe');
    for (let i = 0; i < iframeElements.length; i++) {
      let currentElement = iframeElements[i];
      if (currentElement.id === PRINT_IFRAME_ID) {
        currentElement.remove();
      }
    }
  }

  closePrint () {
    // Safari seems to need this setTimeout for the cancel print dialog.
    setTimeout(() => {
      this.removePrintIframes();
    }, 100);
  }

  setPrint (iframe: HTMLIFrameElement) {
    setTimeout(() => {
      if (iframe.contentWindow) {
        iframe.contentWindow.onbeforeunload = this.removePrintIframes;
        iframe.contentWindow.onafterprint = this.removePrintIframes;
        // focus required for IE
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }
    }, 250) ;
  }

  openPrintWindow(data: HTMLElement, school: string, teacher: string)  {
      // Create the iframe for the page that we will use to print from.
      let printPreviewHtml = this.getPrintHtml(data, school, teacher);
      let iframeStyle = "position:fixed;right:0;bottom:0;width:0;height:0;border:0";
      // Remove existing print iframes if they exist.
      this.removePrintIframes();

      let newIframe = document.createElement('iframe');
      newIframe.setAttribute('id', PRINT_IFRAME_ID);
      newIframe.setAttribute('src', 'about:blank');
      newIframe.style.cssText = iframeStyle;
      document.body.appendChild(newIframe);

      // Initialize the iframe to open the print preview
      //  dialog when it the html loads.
      let iframe = <HTMLIFrameElement>document.getElementById(PRINT_IFRAME_ID);
      iframe.onload = () => this.setPrint(iframe);

      let iframeDoc = iframe.contentWindow?.document;
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(printPreviewHtml);
        iframeDoc.close();
      }
  }
}
