<div #printContent id="printContent">
  <div class="print-hide" *ngIf="showSchoolFilter">
    <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools" [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" (filterChangeEvent)="filterSchoolTeacher()"></wf-school-teacher-filter>
  </div>
  <div  [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
    <div class="print-hide filter-options">
      <wf-students-filter [grades]="grades" [classes]="classes" [filterExtraSupport]="true" (filterChangeEvent)="filterStudents($event)"></wf-students-filter>
    </div>
  </div>
  <div id="error-list-message" class="alert message" *ngIf="message" [ngClass]="{ 'alert-danger': messageStatus === 'error', 'alert-success' : messageStatus === 'success' }">
    <div class="icon">
      <fa-icon [icon]="successIcon" *ngIf="messageStatus === 'success'"></fa-icon>
      <fa-icon [icon]="errorIcon" *ngIf="messageStatus === 'error'"></fa-icon>
    </div>
    <div>
      <div class="bold">{{ messageTitle }}</div>
      {{ message }}
    </div>
  </div>
  <div>
    <div class="report-section-header split-flex">
      <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="reportIcon"></fa-icon> Screener: Results</h5>
      <button id="print-button" class="btn print-summary-button print-hide" (click)="printSummary()" *ngIf="!currentUser!.isTeacherReviewUser()"><fa-icon [icon]="printIcon"></fa-icon> Print Results</button>
    </div>
  </div>
  <div class="screener-report-summary">
    <div class="license-info print-hide">
      <div class="screener-header bold center">
        <div>{{ diagnosticTitle }}</div>
        <div>Subscriptions Remaining</div>
      </div>
      <h3 class="number-of-licenses-block">
        <span *ngIf="diagnosticLicenses === -1"><fa-icon [icon]="infinityIcon"></fa-icon></span>
        <span *ngIf="diagnosticLicenses !== -1">{{ diagnosticLicenses }}</span>
      </h3>
      <div class="diagnosticproduct-management-assessment-score-footer" *ngIf="isTeacher"># of students selected: {{ selectedStudents.size }}</div>
      <button type="button" class="btn move-selected-button" *ngIf="isTeacher" (click)="moveSelectedStudents('diagnostic')" [disabled]="processingDiag || !studentsAreSelected || (diagnosticLicenses !== -1 && selectedStudents.size > diagnosticLicenses)">
        <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="processingDiag"></fa-icon> Move Selected
      </button>
    </div>
    <div class="license-info print-hide">
      <div class="screener-header bold center">
        <div>{{ systemTitle }}</div>
        <div>Subscriptions Remaining</div>
      </div>
      <h3 class="number-of-licenses-block">
        <span *ngIf="systemLicenses === -1"><fa-icon [icon]="infinityIcon"></fa-icon></span>
        <span *ngIf="systemLicenses !== -1">{{ systemLicenses }}</span>
      </h3>
      <div class="diagnosticproduct-management-assessment-score-footer" *ngIf="isTeacher"># of students selected: {{ selectedStudents.size }}</div>
      <button type="button" class="btn move-selected-button" *ngIf="isTeacher" (click)="moveSelectedStudents('full')" [disabled]="processingFull || !studentsAreSelected || (systemLicenses !== -1 && selectedStudents.size > systemLicenses)">
        <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="processingFull"></fa-icon> Move Selected
      </button>
    </div>
    <div class="screener-report-chart">
      <div class="screener-header bold">
        Results
      </div>
      <div class="report-chart-wrapper">
        <div class="report-chart-legend">
          <div class="legend-wrapper pushed">
            <div class="legend-key legend-green">
              <div class="pie-legend">Proficient</div>
              <img class="legend-img" src="/assets/images/reportIcons/iconScoreGreen.svg" />
              <div class="legend-green-range">
                <span>{{ tierLabels['proficient'] }}</span>
              </div>
            </div>
            <div class="report-chart-value">({{ proficientCnt }})</div>
          </div>
          <div class="recommended-wrapper">
            <div class="legend-wrapper">
              <div class="legend-key legend-yellow">
                <div class="pie-legend">Some Risk</div>
                <img class="legend-img" src="/assets/images/reportIcons/iconScoreYellow.svg" />
                <div class="legend-yellow-range" *ngIf="tierLabels['some-risk']">
                  <span>{{ tierLabels['some-risk'] }}</span>
                </div>
              </div>
              <div class="report-chart-value">({{ someRiskCnt }})</div>
            </div>
            <div class="legend-wrapper">
              <div class="legend-key legend-red">
                <div class="pie-legend">High Risk</div>
                <img class="legend-img" src="/assets/images/reportIcons/iconScoreRed.svg" />
                <div class="legend-red-range" *ngIf="tierLabels['some-risk']">
                  <span>{{ tierLabels['high-risk'] }}</span>
                </div>
              </div>
              <div class="report-chart-value">({{ highRiskCnt }})</div>
            </div>
          </div>
          <div class="legend-key recommended-group">
            Recommended for WordFlight
          </div>
        </div>
        <div class="report-chart-chart">
          <div id="screenerScoresPie" class="diagnosticproduct-management-div-pie print-hide">
            <canvas baseChart
                    #screenerSummaryCanvas
                    [data]="chartData"
                    [type]="chartType"
                    [options]="chartOptions"></canvas>
          </div>
          <img #screenerSummaryImg id='screenerScoresResultsSummaryImg' class="pie-img print-show" />
        </div>
      </div>
    </div>
  </div>
  <table class="report-table">
    <thead>
    <tr>
      <th class="print-hide" *ngIf="isTeacher">
        <input type="checkbox" (click)="toggleAllStudents($event)" [checked]="studentsNotStarted !== 0 && (studentsNotStarted === selectedStudents.size) "/>
      </th>
      <th (click)="sortTable(1)">
        <div class="sortable-report-table-header">
          <div>Student Name</div>
          <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 1) ? 'hidden' : 'visible'" class="print-hide"></div>
        </div>
      </th>
      <th (click)="sortTable(2)">
        <div class="sortable-report-table-header">
          <div>Grade</div>
          <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 2) ? 'hidden' : 'visible'" class="print-hide"></div>
        </div>
      </th>
      <th (click)="sortTable(3)">
        <div class="sortable-report-table-header">
          <div>Results</div>
          <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 3) ? 'hidden' : 'visible'" class="print-hide"></div>
        </div>
      </th>
      <th (click)="sortTable(4)">
        <div class="sortable-report-table-header">
          <div>Date Started</div>
          <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 4) ? 'hidden' : 'visible'" class="print-hide"></div>
        </div>
      </th>
      <th (click)="sortTable(5)">
        <div class="sortable-report-table-header">
          <div>Date Completed</div>
          <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 5) ? 'hidden' : 'visible'" class="print-hide"></div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let studentData of tableData; index as idx">
      <td class="print-hide" *ngIf="isTeacher">
        <input type="checkbox" *ngIf="studentData[0]" [value]="studentData[7]" (change)="studentToggled($event)" [checked]="selectedStudents.has(studentData[7])"/>
      </td>
      <td>{{ studentData[1] }}</td>
      <td>{{ studentData[2] }}</td>
      <td>
        <div *ngIf="studentData[5]">
          <div class="screener-results-score" [ngClass]="studentData[6]">
            {{ studentData[3] }}
          </div>
        </div>
      </td>
      <td>{{ studentData[4] }}</td>
      <td>{{ studentData[5] }}</td>
    </tr>
    </tbody>
  </table>
</div>
