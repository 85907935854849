<div>
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconResources.svg" /> Resources </h3>
  </div>
  <div>
    <div class="report-section-header">
      <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="resourcesIcon"></fa-icon> {{ displayGroup | titlecase }}</h5>
    </div>
  </div>
  <div class="flex flex-column" style="border: 1px solid #DDD ; border-top: none;">
    <div class="student-dropdown-row" style="border-bottom: 1px solid #DDD ;" *ngFor="let category of allCategories; index as idx" (click)="goToLink(idx)">
      <div id="resource-category-button" class="flex flex-row align-items-center column-gap-15 margin-left-50" role="button" >
        <img style="height: 60%;" [src]="category.icon" />
        <h5>{{ category.name }}</h5>
      </div>
    </div>
  </div>
</div>
