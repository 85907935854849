import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getHtmlResource(link: string): Observable<any> {
    // TODO: Should we define a new management assets server URL in the config and use it?
    return this.httpClient.get(`/assets/resources/${link}`, { responseType: 'text'}) ;
  }

  getAvailableLessons(): Observable<any> {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(environment.dataServicesURL + 'manage/lesson/available', reqOpts) ;
  }

  getImageSource(url: string) {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }

    return this.httpClient.get(url, { ...reqOpts, responseType: 'blob' }) ;
  }

  getLessonImage(url: string, data: any) {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    return this.httpClient.post(url, data, {...reqOpts, responseType : 'blob'}) ;
  }
}
